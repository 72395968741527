
export const DEFAULT_CONSIGNOR_ACCOUNT = "Nordiska"

export const ConsignorAccountList = [
    "Onwater AB",
    "Euab2",
    DEFAULT_CONSIGNOR_ACCOUNT
];

export const ConsignorTestAccountList = [
    "Schenker Test",
    "DSV Test 2"
];
