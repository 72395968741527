import React, {useCallback} from "react";
import {FormGroup, Input, Label} from "reactstrap";
import {PaymentInstructionTypes} from "../model/PaymentInstructionType";

const PaymentInstructionRadioGroup = (props) =>
{
    const {model, onChange, isNewFormRenderer} = props

    const handleOnChange = useCallback((event) =>
    {
        const value = event?.target?.value
        if (isNewFormRenderer)
        {
            onChange(value);
        } else
        {
            onChange({value});
        }
    }, [onChange, isNewFormRenderer])

    const createRadio = useCallback((key, value) =>
    {
        return <FormGroup check>
            <Label check>
                <Input
                    key={`radio${value}`}
                    type="radio"
                    value={value}
                    checked={model.paymentInstruction === value}
                    onChange={handleOnChange}
                />
                {key}
            </Label>
        </FormGroup>
    }, [model.paymentInstruction, handleOnChange])

    return <FormGroup>
        {
            Object.entries(PaymentInstructionTypes).map(([key, value]) =>
            {
                return createRadio(key, value)
            })
        }
    </FormGroup>
}

export default PaymentInstructionRadioGroup