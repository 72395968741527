import React from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnContactName from "../common/data-grid/columns/ColumnContactName";
import ColumnEmail from "../common/data-grid/columns/ColumnEmail";
import ColumnsCompany from "../common/data-grid/columns/ColumnsCompany";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {ContactsV2Document} from "../generated/graphql";

const columns = () => {
    return [
        ColumnContactName(),
        ColumnsCompany(),
        {
            field: 'workPhone',
            headerName: 'Work phone',
            searchable: true,
        },
        {
            field: 'mobilePhone',
            headerName: 'Mobile phone',
            searchable: true,
        },
        ColumnEmail(),
        ColumnId(),
        {
            field: 'isActive',
            headerName: 'Active',
            type: "boolean"
        }
    ]
}

const KEY_TABLE = "CONTACT_LIST"

const defaultSort = {sortModel: [{field: 'name', sort: 'asc'}]}

const columnVisibilityModel = {columnVisibilityModel: {id: false, 'company.id': false}}

const ContactListPage = () => {
    return (
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns(),
                initState: {
                    sorting: defaultSort,
                    columns: columnVisibilityModel,
                },
            }}
            query={ContactsV2Document}
            filterTenantId={true}/>
    )
}


export default withApollo(ContactListPage);
