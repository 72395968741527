import React, {Component} from 'react';
import { Mutation } from '@apollo/client/react/components';
import ReassignMutation from "../new-task/graphql/ReassignTask.graphql";
import ModalForm from "./ModalForm";
import {Button} from "reactstrap";
import FormRenderer from "./FormRenderer";


export default class ReassignTaskButton extends Component
{
    render()
    {
        const {task} = this.props;
        if(task)
        {
            return <Mutation mutation={ReassignMutation}>
                {reassign =>
                <ModalForm button={toggle=><Button onClick={()=>toggle()} color="primary">Reassign</Button>} title={"Reassign task"} >
                    {toggle=>
                    {
                        return <FormRenderer formDefinition={[{name:"username",type:"userPicker",required:true}]} onSubmit={async form=>
                        {
                            await reassign({variables:{
                                    id:task.id,
                                    username:form.object.username
                                },
                                refetchQueries:["AllTasks",'tasks', 'getOrder']
                            });
                            toggle();
                            if(this.props.toggle)
                            {
                                this.props.toggle();
                            }

                        }
                        }/>
                    }}
                </ModalForm>
                }
            </Mutation>;
        }
        else
        {
            return null;
        }

    }
}
