import {
    PredictedWorkloadFragmentFragment,
    useGetPredictedWorkloadLazyQuery
} from "../generated/graphql";
import {Button, Spinner, Tooltip} from "reactstrap";
import React, {useCallback, useState} from "react";
import {addWeeks, format, getWeek, startOfWeek} from 'date-fns';
import {sum} from "lodash";
import {Link} from "react-router-dom";
import config, {environment} from "../config";


const COLOR_EMPTY = "#FFFFFF"

const MAX_PREDICTED_VALUE = 200000;

const PredictedWorkloadCalendar = (props: { predictedWorkload: Array<PredictedWorkloadFragmentFragment> }) =>
{
    const {predictedWorkload} = props
    const [activeTooltip, setActiveTooltip] = useState(null)

    const toggleTooltip = useCallback((id) =>
    {
        if (activeTooltip === id)
        {
            setActiveTooltip(null)
        } else
        {
            setActiveTooltip(id)
        }
    }, [activeTooltip])

    const getRedBackgroundColor = useCallback((predictedValue) =>
    {
        if (predictedValue == null)
        {
            return COLOR_EMPTY
        }
        const value = Math.min(predictedValue, MAX_PREDICTED_VALUE);
        const redValue = Math.floor((1 - value / MAX_PREDICTED_VALUE) * 255);
        return `rgb(255, ${redValue}, ${redValue})`;
    }, [])

    const renderPredictedValueUncontrolledTooltip = useCallback((id: string, totalPredictedValue?: number, predictedWorkload?: PredictedWorkloadFragmentFragment) =>
    {
        if (!totalPredictedValue || totalPredictedValue <= 0)
        {
            return null
        }

        return <Tooltip
            isOpen={activeTooltip === id}
            toggle={() => toggleTooltip(id)}
            target={id}
            placement="right"
            trigger="click"
            style={{
                backgroundColor: "white", border: "1px solid #999", color: "black",
                padding: "4px", textAlign: "left"
            }}>
            <small>{`Predicted workload: ${totalPredictedValue}`}</small>
            <table>
                <thead>
                <tr>
                    <th>Art no</th>
                    <th>Predicted</th>
                </tr>
                </thead>
                <tbody>
                {predictedWorkload?.items?.map((e, index) =>
                {
                    return <tr key={`predictedItem${index}`}>
                        <td>
                            <Link to={`/spiralStairDetail/${e.spiralStair.id}`}>{e.spiralStair.artNo}</Link>
                        </td>
                        <td style={{textAlign: "right"}}>{e.predictedValue}</td>
                    </tr>
                })}
                </tbody>
            </table>
        </Tooltip>
    }, [activeTooltip, toggleTooltip])

    const renderWeeklyStatus = () =>
    {
        const allWeekStatus: any = [];

        const today = new Date();
        const endOfWeek = addWeeks(today, 26);
        let currentWeek = startOfWeek(today);
        while (currentWeek <= endOfWeek)
        {
            const weekNumberAndYear = format(currentWeek, 'ww/yy');
            const findPredictedWorkload = predictedWorkload.find((status) => status.weekNumber === weekNumberAndYear);
            const totalPredictedValue = sum(findPredictedWorkload?.items?.map((e) => e.predictedValue))
            const backgroundColor = getRedBackgroundColor(totalPredictedValue)
            const weekNumber = getWeek(currentWeek)
            const id = `predictedValue${weekNumber}`

            allWeekStatus.push(
                <div key={id}
                     id={id}
                     onClick={() => toggleTooltip(id)}
                     style={{
                         backgroundColor,
                         width: '30px',
                         height: "30px",
                         textAlign: "center",
                         cursor: totalPredictedValue && totalPredictedValue > 0 ? "pointer" : ""
                     }}>
                    {weekNumber}

                    {renderPredictedValueUncontrolledTooltip(id, totalPredictedValue, findPredictedWorkload)}
                </div>
            );

            currentWeek = addWeeks(currentWeek, 1);
        }

        return allWeekStatus;
    };

    return <div>
        <div style={{display: 'flex', flexWrap: 'wrap', width: '150px', marginTop: "4px"}}>
            {renderWeeklyStatus()}
        </div>
    </div>
}

export default () =>
{
    const [predictedWorkload, setPredictedWorkload] = useState<Array<PredictedWorkloadFragmentFragment> | undefined>()
    const [getPredictedWorkload] = useGetPredictedWorkloadLazyQuery()
    const [loading, setLoading] = useState(false)
    const [isShow, setIsShow] = useState(false)

    const onClick = useCallback(async () =>
    {
        if (predictedWorkload == null)
        {
            setLoading(true)
            const {data} = await getPredictedWorkload()
            setPredictedWorkload(data?.getPredictedWorkload)
            setLoading(false)
        }

        setIsShow((value) => !value)
    }, [getPredictedWorkload, predictedWorkload])

    if (config.environment === environment.PRODUCTION)
    {
        return null
    }

    return <div style={{marginTop: "4px"}}>
        <Button size="sm" outline style={{fontSize: "8px"}} onClick={onClick}>ESPR predicted workload</Button>

        {loading && <Spinner/>}
        {predictedWorkload && isShow &&
            <PredictedWorkloadCalendar predictedWorkload={predictedWorkload}/>
        }
    </div>
}
