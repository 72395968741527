import React, {useCallback, useState} from 'react';
import {
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane
} from 'reactstrap';
import {SupplierEvaluationType} from "../../generated/graphql";
import {AssembleSupplierEvaluationQuestions, GeneralSupplierEvaluationQuestions} from "./SupplierEvaluationQuestions";
import SupplierEvaluationTable from "./SupplierEvaluationTable";

type SupplierEvaluationPanelProps = {
    supplierId: string
}

const SupplierEvaluationPanel = ({supplierId}: SupplierEvaluationPanelProps) =>
{
    const [activeTab, setActiveTab] = useState(SupplierEvaluationType.General);

    const onTabClicked = useCallback((tab: SupplierEvaluationType) =>
    {
        setActiveTab(tab)
    }, [setActiveTab])

    return <>
        <Nav pills>
            <NavItem>
                <NavLink active={activeTab === SupplierEvaluationType.General}
                         onClick={() => onTabClicked(SupplierEvaluationType.General)}>
                    General
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink active={activeTab === SupplierEvaluationType.Assemble}
                         onClick={() => onTabClicked(SupplierEvaluationType.Assemble)}>
                    Assemble
                </NavLink>
            </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} style={{padding: "16px"}}>
            <TabPane tabId={SupplierEvaluationType.General}>
                <SupplierEvaluationTable supplierId={supplierId} type={SupplierEvaluationType.General}
                                         questions={GeneralSupplierEvaluationQuestions}/>
            </TabPane>
            <TabPane tabId={SupplierEvaluationType.Assemble}>
                <SupplierEvaluationTable supplierId={supplierId} type={SupplierEvaluationType.Assemble}
                                         questions={AssembleSupplierEvaluationQuestions}/>
            </TabPane>
        </TabContent>
    </>
}

export default SupplierEvaluationPanel;
