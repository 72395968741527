import {useState} from "react";
import {Nav, NavItem, NavLink, TabContent, Table} from "reactstrap";
import {useDocumentCategoriesQuery} from "../generated/graphql";
import {format} from "date-fns";

const CategoryTable = ({documentCategories, index}: { documentCategories?: any, index: number }) =>
{
    if (documentCategories == null)
    {
        return <div></div>
    }

    const documentCategory = documentCategories[index]

    return documentCategory.children.map((e) =>
    {
        return <div style={{marginBottom: "30px"}}>
            <h4>{e.name}</h4>
            <Table striped style={{width: '600px'}}>
                <tbody>
                {
                    e.documents.map((document) =>
                    {
                        return <tr>
                            <td>
                                <a href={`${document.downloadUrl}`}>
                                    {document.name}
                                </a>
                            </td>
                            <td style={{textAlign: "end", width: '150px'}}>
                                {format(new Date(document.updatedAt), "yyyy-MM-dd")}
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </Table>
        </div>
    })
}

export default () =>
{
    const res = useDocumentCategoriesQuery();
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    return <div>
        <h1>Documents</h1>

        <Nav tabs fill>
            {
                res.data?.documentCategories?.map((documentCategory, index) =>
                {
                    if (documentCategory == null)
                    {
                        return <div></div>
                    }

                    return <NavItem>
                        <NavLink active={activeTabIndex === index}
                                 onClick={() => setActiveTabIndex(index)}>
                            <p>{documentCategory.name}</p>
                        </NavLink>
                    </NavItem>
                })
            }
        </Nav>

        <TabContent activeTab={activeTabIndex}>
            <CategoryTable documentCategories={res.data?.documentCategories} index={activeTabIndex}/>
        </TabContent>
    </div>

}
