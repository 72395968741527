import React, {useState} from 'react';
import {Button, Card, Col, Row, Form, Input, FormGroup, Label, CardHeader, CardBody} from 'reactstrap';
import {map, reduce} from 'lodash';
import {saveAs} from 'file-saver';
import Auth from "../../Auth";
import NotificationPopup from "../../common/lib/NotificationPopup";
import config from "../../config";
import DatePickerWithWeek from "../../common/DatePickerWithWeek";


const InventoryReportsPage = () => {
    const [currencies, setCurrencies] = useState({
        NOK: 0,
        EUR: 0
    });
    const [date, setDate] = useState(new Date());
    return <div>
        <Row>
            <Col lg={4}>
                <Card>
                    <CardHeader>
                        Inventory report
                    </CardHeader>
                    <CardBody>
                        <Form>
                            <FormGroup>
                                <Label>
                                    Select Date
                                </Label>
                                <Row>
                                    <DatePickerWithWeek defaultValue={date}
                                                        onChange={({value}) => setDate(value)}/>
                                </Row>


                            </FormGroup>
                            <FormGroup>
                                <Label>
                                    Set currency rates
                                </Label>
                                {map(currencies, (rate, currency) => {
                                    return <div key={currency}>
                                        <p>{currency}</p>
                                        <Input value={rate}
                                               onChange={e => setCurrencies({
                                                   ...currencies,
                                                   [currency]: e.target.value
                                               })}
                                               type="number" min={0}/>
                                    </div>
                                })}
                            </FormGroup>
                        </Form>
                        <Button onClick={async () => {
                            const token = Auth.getToken();
                            const res = await fetch(`${config.INVENTORY_URI}articles/createReport`, {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    "Content-Type": "application/json"
                                },
                                body: JSON.stringify({
                                    date: new Date(date),
                                    currencyRates: map(currencies, (rate, currency) => ({
                                        currency,
                                        rate: parseFloat(rate)
                                    }))
                                        .concat({currency: "SEK", rate: 1.0})
                                })
                            });
                            if (res.status === 201) {
                                const blob = await res.blob();
                                saveAs(blob, `inventory-report-${date}.pdf`);
                            } else {
                                const json = await res.json();
                                console.log(json);
                                NotificationPopup.error(`Failed to get report ${json.error || ""}. 
                                ${reduce(json.message, (acc, m) => {
                                    return acc + m;
                                }, "")}`)
                            }

                        }}>
                            Get Inventory Report
                        </Button>
                    </CardBody>
                </Card>
            </Col>
        </Row>


    </div>
}

export default InventoryReportsPage