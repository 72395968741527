import {object,number,string,mixed,boolean,array} from 'yup';
import {
    PlatformTypes, RailingTypes,
    SpiralStairCageTypes,
    SpiralStairPicketTypes, SpiralStairRadii,
    SpiralStairStepTypes,
    SurfaceTreatmentTypes
} from "../../spiralStair/definitions";
import _ from 'lodash';

const labelizeMessage = (collection)=>
{
    return ({path, values})=> `${path} must be one of the following values: ${values.split(",").map(val=>_.find(collection,({id})=>id===val.trim())?.name)}`;
}

const stepsPrRotArray =  [13,14,15,16,18,20,22,24,26,27,29];
const allowedStepTypes = [SpiralStairStepTypes[0].id,
    SpiralStairStepTypes[1].id,
    SpiralStairStepTypes[2].id,
    SpiralStairStepTypes[3].id,
    SpiralStairStepTypes[4].id,
    SpiralStairStepTypes[5].id,
    SpiralStairStepTypes[6].id];


const segmentSchema = object().shape({
    index:number(),
    radius:number().oneOf(SpiralStairRadii),
    stepsPrRot:number().oneOf(stepsPrRotArray),
    height:number().positive().required(),
    supportLegAmount:number(),
    supportLegLength:number().test("supportLegLength","Supportleg length must be stair-height - 100.",function(value)
    {
        if(value && value > 0 && this.parent.supportLegAmount > 0)
        {
            return this.parent.height - 100 === value;
        }
        else
        {
            return true;
        }

    }),
    console:number().label("No. of Consoles"),
    startAngle:number(),
    strutAmount:number().label("No. of Bracings"),
    strutLength:number(),
    stepHeights:number().positive(),
    connectionStep:boolean(),
    platformS1: number().when('connectionStep',{
        is:false,
        then:number().positive(),
        otherwise:number().nullable()
    }).test('platformS1',"Platform S1 exceeding max. Side-exit=1500, Straight-exit=2500",function(value,)
    {

        if(value > 0)
        {
            switch(this.parent.platformType)
            {
                case PlatformTypes.sideExit.id:return value <= 1500 && value >= 600;
                case PlatformTypes.straightExit.id:return value <= 2500 && value >= 600;
                default:return true;
            }
        }
        else
            return true;

    }),
    platformS2:number().when('connectionStep',{
        is:false,
        then:number().positive(),
        otherwise:number().nullable()
    }).test('platformS2',"Platform S2 exceeding maximum. Side-exit=2500, Straight-exit=1500",function(value,)
    {

        if(value > 0)
        {
            switch(this.parent.platformType)
            {
                case PlatformTypes.sideExit.id:return value <= 2500 && value >= 600;
                case PlatformTypes.straightExit.id:return value <= 1500 && value >= 600;
                default:return true;
            }
        }
        else
            return true;

    }),
    platformSpecial:mixed().oneOf([false]),
    platformPicketType:string().oneOf([SpiralStairPicketTypes[0].id,SpiralStairPicketTypes[1].id],labelizeMessage(SpiralStairPicketTypes))
    .label("Type of platform railing"),
    platformMaterial:string().when('connectionStep',{
        is:false,
        then:string().oneOf(allowedStepTypes,labelizeMessage(SpiralStairStepTypes))
        .label("Platform Type"),
        otherwise:string().nullable(),
    }),
    platformNumPickets:number().when('connectionStep',{
        is:false,
        then:number(),
        otherwise:number().nullable()
    }),
    platformRailAmount:number().when('connectionStep',{
        is:false,
        then:number().oneOf([2,3]).label("No. of platform railing"),
        otherwise:number().nullable()
    }),
    platformType:string().when('connectionStep',{
        is:false,
        then:string().defined().oneOf([PlatformTypes.straightExit.id,PlatformTypes.sideExit.id],labelizeMessage(PlatformTypes)).label("Type of platform"),
        otherwise:string().nullable()
    })
})




export const stairSchema = object().shape({
    stepType:mixed().oneOf(allowedStepTypes,labelizeMessage(SpiralStairStepTypes)).label("Step Type"),
    surfaceTreatment:mixed().oneOf(SurfaceTreatmentTypes.map(({id})=>id),labelizeMessage(SurfaceTreatmentTypes)).label("Surface treatment"),
    cage:mixed().oneOf([SpiralStairCageTypes.nocage.id,SpiralStairCageTypes.std.id],labelizeMessage(SpiralStairCageTypes)),
    direction:mixed().oneOf(["left","right"]),
    centerPipeElongation:number(),
    picketType:mixed().oneOf([SpiralStairPicketTypes[0].id,SpiralStairPicketTypes[1].id],labelizeMessage(SpiralStairPicketTypes)).label("Type of ChildSafeSection"),
    railType:mixed().oneOf(["std","stainless"],labelizeMessage(RailingTypes)),
    railHeight:number().min(1100).max(1100),
    cPipeRail:mixed().oneOf([false]).label("Center tube handrail"),
    hasInnerRailing:mixed().label("Extra inner handrail"),
    hasInnerRailingExtension:mixed().label("Extra inner handrail extension"),
    innerRailingType:mixed().notRequired(),
    innerRailingHeight:mixed().notRequired(),
    sameRailTypeOnPlatform:mixed().notRequired(),
    segments:array().min(1).max(1).of(segmentSchema)
})
