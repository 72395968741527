import React, {Component} from 'react';
import FormRenderer from "../../common/FormRenderer";
import { Mutation } from '@apollo/client/react/components';
import GetPurchaseOrder from '../../purchaseOrder/graphql/GetPurchaseOrder.graphql';
import {Button, Col, Row} from "reactstrap";
import PurchaseOrderFormDef from "../../purchaseOrder/PurchaseOrderFormDef";
import StdQuery from "../../common/StdQuery";
import UpdatePurchaseOrderMutation from '../../purchaseOrder/graphql/UpdatePurchaseOrder.graphql';
import {filter} from 'lodash';
import PdfModal from "../../order/PdfModal";


const omitFields = ["deliveryTitle","deliveryStreet","deliveryCountry","deliveryCity","deliveryPostalCode","deliveryFreeText","orderId"];

export default class SendPO extends Component {

    constructor()
    {
        super();
        this.state = {form:"address"}
    }


    setDeliveryAddressButton = (purchaseOrder) => {
        return <Mutation mutation={UpdatePurchaseOrderMutation} variables={{
            id: purchaseOrder.id,
            purchaseOrder: {
                deliveryTitle: 'Eurostair Produktion AB',
                deliveryStreet: 'Säterivägen 30',
                deliveryCity: 'Säffle',
                deliveryCountry: 'Sweden',
                deliveryPostalCode: '661 30',
            }
        }}>
            {setAddress =>
            <Button color='primary' onClick={async () =>
            {
                await setAddress();
                this.setState({form:"edit"});
            }
            }>Set delivery address to Säffle.</Button>
            }
        </Mutation>

    };


    addressForm = (purchaseOrder)=>
    {
        return <div>
            <Mutation mutation={UpdatePurchaseOrderMutation}>
                {updatePO=>
                {

                    return <Row>
                        <Col sm={12}>
                            <FormRenderer columns={1}
                                          onSubmit={async form => {
                                              await updatePO({
                                                  variables: {
                                                      id: purchaseOrder.id,
                                                      purchaseOrder: form.object
                                                  }
                                              });
                                              this.setState({form: "edit"});
                                          }}
                                          object={purchaseOrder}
                                          formDefinition={
                                              [{name: 'deliveryTitle'},
                                                  {name: 'deliveryStreet', required: true},
                                                  {name: 'deliveryPostalCode',pattern:"[0-9 ]+"},
                                                  {name: 'deliveryCity'},
                                                  {name: 'deliveryCountry'},
                                                  {name: 'deliveryFreeText'},]}
                                          customSubmit={
                                              <Row className="justify-content-between">
                                                  <Col sm={2}>
                                                      <Button color={"info"} onClick={()=>this.props.toggle()}>
                                                          Cancel
                                                      </Button>
                                                  </Col>
                                                  <Col sm={4}>
                                                      {this.setDeliveryAddressButton(purchaseOrder)}
                                                  </Col>
                                                  <Col sm={2}>
                                                      <Button color={"success"} type={"submit"}>Save</Button>

                                                  </Col>
                                              </Row>
                                          }
                            />
                        </Col>
                    </Row>
                }}
            </Mutation>
        </div>
    };


    editForm(purchaseOrder)
    {
        return <Mutation mutation={UpdatePurchaseOrderMutation}>
            {updatePO=>
            {

                return <div>
                    <FormRenderer columns={2}
                                  onSubmit={async form =>
                                  {
                                      await updatePO({
                                          variables: {
                                              id:purchaseOrder.id,
                                              purchaseOrder: form.object
                                          }
                                      });
                                      this.setState({form:"pdf"});
                                  }}
                                  object={purchaseOrder}
                                  formDefinition={filter(PurchaseOrderFormDef(purchaseOrder),def=>omitFields.indexOf(def.name)===-1)}
                                  customSubmit={
                                      <Row className="justify-content-between">
                                          <Col sm={2}>
                                              <Button color={"info"} onClick={()=>this.setState({form:"address"})}>
                                                  Back
                                              </Button>
                                          </Col>
                                          <Col sm={2}>
                                              <Button color={"success"} type={"submit"}>Save</Button>
                                          </Col>
                                      </Row>
                                  }
                    />
                </div>
            }}
        </Mutation>;
    }

    render()
    {
        const id = this.props.variables.purchaseOrderId;
        return <StdQuery query={GetPurchaseOrder} variables={{id}}>
        {(data)=>
        {
            const purchaseOrder = data.purchaseOrder;
            switch(this.state.form)
            {
                case "address":return this.addressForm(purchaseOrder);
                case "edit":return this.editForm(purchaseOrder);
                case "pdf":
                    return <div>
                        <PdfModal obj={purchaseOrder} toggle={()=>this.props.toggle()} noModal button={()=>
                            <Button color="success" onClick={()=>this.props.onSubmit()}>
                                Complete task
                            </Button>
                        }/>
                    </div>;
                default:
                    return null;
            }
        }}
        </StdQuery>;
    }
}
