import React, {useState} from 'react';
import {useMutation} from '@apollo/client';
import StdQuery from "../../common/StdQuery";
import {Button, Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";
import DetailRenderer from "../../common/DetailRenderer";
import {Link} from "react-router-dom";
import gql from "graphql-tag";
import {formatToSwedishTime, intoCamundaVariables} from "../../UTIL";
import {QuoteLostReasons} from "../../quote/QuoteDetailPage";
import NewFormRenderer from "../../common/NewFormRenderer";
import {useStdMutation} from "../../spiralStair/lib/Util";
import { withRouter } from 'react-router';
import ConvertToOrderMutation from '../../quote/graphql/ConvertToOrder.graphql';


const markQuoteAsLostMutation = gql`
    mutation markQuoteAsLost($id:ID!,$lostReason:String!,$lostComment:String)
    {
        markQuoteAsLost(id:$id,lostReason:$lostReason,lostComment:$lostComment) {
            id
        }
    }`;

const cancelProcessInstanceMutation = gql`
mutation cancelProcessInstance($id:String!)
{
    cancelProcessInstance(processInstanceId: $id)
}
`

const getTaskQuery = gql`
    query task($id:String!)
    {
        task(id:$id)
        {
            id
            createdAt
            name
            description
            assignee
            owner
            processInstanceId
            variables
            due
            quote
            {
                id
                number
                contact
                {
                    id
                    name
                    mobilePhone
                    workPhone
                    email
                }
                company
                {
                    id
                    name
                }
                project
                {
                    id
                    name
                }
            }
        }
    }`;



const DetailMode = withRouter(({quoteId,history,toggle,task,onSubmit,setMode}) =>
{
    const [convertToOrder] = useStdMutation("convert to order",ConvertToOrderMutation)
    const {subject,followupHistory,description} = task.variables;
    const {company,project,contact,id,number} = task.quote;
    const [deleteProcessInstance] = useStdMutation("Delete followup workflow",cancelProcessInstanceMutation,{variables:{
        id:task.processInstanceId
        },refetchQueries:["AllTasks","tasks"]})
    const details = {company,project,contact};

    return <div>
        <Card>
            <CardHeader>
                <Row className="justify-content-between">
                    <Col sm={3}>
                        <h5>{subject}</h5>
                    </Col>
                    <Col sm={5}>
                        <h5>Due {formatToSwedishTime(task.due)}</h5>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {description && <pre style={{border: "1px dotted", fontSize: "1.1em"}}>
                    {description}
                </pre>}
                {followupHistory && <div>
                    <hr/>
                    <br/>
                    <h5>Follow-up history</h5>
                    <Table bordered hover striped size="sm">
                        <thead>
                        <tr>
                            <td>
                                Subject
                            </td>
                            <td>
                                Description
                            </td>
                            <td>
                                Finished at
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        {followupHistory.map(({finishedAt,description,subject},index)=><tr key={index}>
                            {[subject,description,new Date(finishedAt).toLocaleString()].map(v=><td>{v}</td>)}
                        </tr>)}
                        </tbody>

                    </Table>
                </div>}
            </CardBody>
        </Card>


        <Card body>
            <CardBody>
                <DetailRenderer columns={3} object={details} hideUpdateInfo/>
                {contact && <div>
                    <p><strong>Work phone:</strong> {contact.workPhone}</p>
                    <p><strong>Mobile phone:</strong> {contact.mobilePhone}</p>
                    <p><strong>Email:</strong> <a href={`mailto:${contact.email}`}>{contact.email}</a></p>
                </div>}
                <Row className="justify-content-around">
                    <Col sm={4} >
                        <Link to={`/quoteDetail/${id}`} target="_blank">
                            <Button color="primary" style={{fontSize: "18px"}}>Go to Quote {number}</Button>
                        </Link>
                    </Col>
                </Row>
            </CardBody>
        </Card>



        <br/>
        <hr/>
        <Row className="justify-content-between">
            <Col sm={3}>
                <Button color="danger" onClick={() => setMode("lost")}>
                    Quote lost
                </Button>
                <Button color="warning" onClick={()=>
                {
                    if(window.confirm(`Are you sure you want to delete this task?`))
                    {
                        deleteProcessInstance();
                        toggle();
                    }
                }}>
                    Delete task
                </Button>
            </Col>
            <Col sm={3}>
                <Button color="primary" onClick={() => setMode("schedule-new")}>
                    Schedule new follow-up
                </Button>
            </Col>
            <Col sm={3}>
                <Button color="success" onClick={async() =>
                {
                    if(window.confirm(`Is this quote ready to convert to an order?
(Note that this will finish the followup workflow, which can not be restarted)`))
                    {
                        await onSubmit(intoCamundaVariables({confirmed:true}));
                        const res = await convertToOrder({variables:{quoteId}});
                        console.log(res);
                        history.push(`/orderDetail/${res?.data?.convertToOrder?.id}`)


                    }

                }}>
                    Convert to order
                </Button>
            </Col>
        </Row>
    </div>
});

const LostMode = ({task,onSubmit,setMode,toggle})=>
{
    const [lostFormObj,setLostFormObj] = useState({});    
    const [markQuoteAsLost] = useMutation(markQuoteAsLostMutation,{refetchQueries:["AllTasks","tasks"]})
    return <div>
        <h4>Lost quote</h4>
        <NewFormRenderer object={lostFormObj} onChange={(name, value) => setLostFormObj(prev =>
        ({
            ...prev, [name]: value
        }))}
         formDefinition={[
             {name: "lostReason", type: "select", options: QuoteLostReasons, required: true},
             {name: "lostComment", type: "textarea"}
         ]}
        />
        <Row className="justify-content-between">
            <Col sm={3}>
                <Button color="danger" onClick={() => setMode("detail")}>
                    Go Back
                </Button>
            </Col>
            <Col sm={3}>
                <Button color="success" onClick={() =>
                {
                    markQuoteAsLost({variables:{
                        id:task.quote.id,
                        lostReason:lostFormObj.lostReason,
                        lostComment:lostFormObj.lostComment
                        }});
                    toggle();
                }}>
                    Submit
                </Button>
            </Col>
        </Row>
    </div>
};

const ScheduleNewFollowup = ({task,onSubmit,setMode})=>
{
    let {subject,followupHistory,description} = task.variables;
    if(!followupHistory)
    {
        followupHistory = [];
    }

    const [formState,setFormState] = useState({subject:"Follow-up"});
    return <div>
        <h4>Schedule new follow-up</h4>
        <Row>
            <Col sm={6}>
                <NewFormRenderer formDefinition={[
                    {name:"subject",required:true},
                    {name:"description",type:"textarea"},
                    {required:true,name:"due",label:"Select date for follow-up",type:"datetime"}]
                } object={formState} onChange={(name,value)=>setFormState(prev=>({...prev,[name]:value}))}  />
            </Col>
        </Row>
        <Row className="justify-content-between">
            <Col sm={3}>
                <Button color="danger" onClick={() => setMode("detail")}>
                    Go Back
                </Button>
            </Col>
            <Col sm={3}>
                <Button color="success" onClick={() =>
                {
                    onSubmit(intoCamundaVariables({...formState,
                        followupHistory: {
                            value:JSON.stringify(followupHistory.concat([{
                                finishedAt:new Date(),
                                subject,
                                description
                            }])),
                            type: "json"
                        },newFollowup:true,lost:true}))
                }}>
                    Submit
                </Button>
            </Col>
        </Row>
    </div>;
};

export default ({task,variables,onSubmit,toggle})=>
{
    const [mode,setMode] = useState("detail");
    return <div style={{marginTop:"15px"}}>
        <StdQuery query={getTaskQuery} variables={{id:task.id}} >
        {data=>
        {
            const {task} = data;
            console.log(task);
            if(!task)
            {
                return `Failure to find task by id: ${task.id}`;
            }

            switch(mode)
            {
                case "detail":return <DetailMode quoteId={variables.quoteId} toggle={toggle}
                                                 task={task} onSubmit={onSubmit} setMode={setMode} />
                case "lost":return <LostMode task={task} onSubmit={onSubmit} setMode={setMode} toggle={toggle} />;
                case "schedule-new":return <ScheduleNewFollowup task={task} onSubmit={onSubmit} setMode={setMode} />;
                default:throw new Error(`Unknown mode ${mode}`);
            }
        }}
    </StdQuery>
    </div>

}
