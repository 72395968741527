import React from 'react';
import {formatCurrency, OrderStatuses} from "../UTIL";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnOrderNumber from "../common/data-grid/columns/ColumnOrderNumber";
import ColumnsProject from "../common/data-grid/columns/ColumnsProject";
import ColumnsStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnDate from "../common/data-grid/columns/ColumnDate";

const orderColumns = () => {
    return [
        ColumnOrderNumber(),
        ColumnsProject(),
        {
            field:'projectStage',
            headerName: 'Part of project',
        },
        ColumnsStatus(OrderStatuses),
        {
            field: 'orderSum',
            headerName: 'Value',
            filterable: false,
            type: 'number',
            renderCell: (params) => {
                const orderSum = params.value

                if (orderSum && orderSum.currency) {
                    return <span>{formatCurrency(orderSum.totalNoVAT)} {orderSum.currency}</span>
                } else {
                    return "N/A";
                }
            },
        },
        ColumnDate('preferredDeliveryDate', 'Preferred Delivery Date'),
        ColumnAssignee(),
        ColumnCreatedAt(),
        ColumnId(),
    ]
}

const orderColumnVisibilityModel = {
    columnVisibilityModel: {
        id: false,
        'project.id': false,
    }
}

const orderDefaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const TABLE_KEY = "ORDER_SUB_PANEL"

export default ({orders, hideProject}) => {
    if (hideProject) {
        orderColumnVisibilityModel.columnVisibilityModel['project.name'] = false;
    }

    return (
        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: orderColumns(),
            initState: {
                sorting: orderDefaultSort,
                columns: orderColumnVisibilityModel
            }
        }} data={orders}/>
    )
}
