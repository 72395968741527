import {store} from 'react-notifications-component';


const defaultOptions = {
    insert: "top",
    container: "top-center",
    width:250,
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: false
    }
};

export default {
    info(message)
    {
        store.addNotification({
            title:"INFO",
            message,
            type: "info",
           ...defaultOptions
        });
    },
    success(message)
    {
        store.addNotification({
            title:"SUCCESS",
            message,
            type: "success",
            ...defaultOptions
        });
    },
    error(message)
    {
        store.addNotification({
            title:"ERROR",
            message,
            type: "danger",
            ...defaultOptions,
            dismiss: {
                duration: 0,
                onScreen: false
            }
        });
    }
}
