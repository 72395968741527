import {useCallback, useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React from 'react';
import {OptionTypeBase} from "react-select";
import gql from "graphql-tag";
import Picker from "../../common/Picker";

const searchArticlesQuery = gql`
    query searchArticles($term:String!)
    {
        searchArticle(term: $term)
        {
            id
            name
            artNo
        }
    }`;

export default ({
                    open,
                    toggle,
                    returnArtNoCallback
                }: { open: boolean, toggle: () => void, returnArtNoCallback: (artNo: string) => void }) =>
{
    const [article, setArticle] = useState<OptionTypeBase | null>(null);

    const handleArticleSelected = useCallback((article) =>
    {
        setArticle(article);
    }, [setArticle]);

    const handleSelectButtonClick = useCallback(() =>
    {
        console.log(article);
        returnArtNoCallback(article?.value);
        toggle();
    }, [article, toggle, returnArtNoCallback]);

    return <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Search article</ModalHeader>
        <ModalBody>
            No article is mapped to this code, please pick an article to associate the code.
            <Picker query={searchArticlesQuery}
                    mapFn={article => ({
                        value: article.id,
                        label: `${article.artNo} - ${article.name}`,
                    })}
                    onChange={handleArticleSelected}/>
        </ModalBody>
        <ModalFooter>
            <Button onClick={handleSelectButtonClick}>Select</Button>
            <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
    </Modal>

};
