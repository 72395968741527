import React, {useCallback, useMemo, useRef} from "react";
import {Button, Form, FormGroup, Spinner, Col, Label, Input} from "reactstrap";
import {Formik} from "formik";
import {forEach} from "lodash";
import {saveAs} from "file-saver";
import Trash from "../icons/Trash";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import Auth from "../../Auth";
import config from "../../config";
import NotificationPopup from "../../common/lib/NotificationPopup";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";

const KEY_TABLE = "TABLE_INVENTORY_ARTICLE_FILES"

const fileListQuery = gql`
    query fileList($articleId:Int, $purchaseOrderId:Int){
        result:fileList(
            articleId:$articleId,
            purchaseOrderId:$purchaseOrderId,
        ){
            id
            fileName
            comment
            createdAt
            creator
        }
    }`;

export default ({articleId, purchaseOrderId}: { articleId?: number, purchaseOrderId?: number }) =>
{
    const fileRef = useRef<any>(null);
    const {data, loading, refetch} = useQuery(fileListQuery, {
        variables: {
            articleId: articleId,
            purchaseOrderId: purchaseOrderId
        }
    });
    const onSubmit = useCallback(async (values, {resetForm}) =>
    {
        try
        {
            const token = Auth.getToken();
            const formData = new FormData();
            forEach(values.fileList, file =>
            {
                formData.append("files", file, file.name);
            });
            articleId && formData.append("articleId", articleId.toString());
            purchaseOrderId && formData.append("purchaseOrderId", purchaseOrderId.toString());
            formData.append("comment", values.comment);
            formData.append("creator", Auth.getUsername() ?? "")

            const res = await fetch(`${config.INVENTORY_URI}file-storage/fileUpload`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`
                    },
                    body: formData
                });
            if (res.ok)
            {
                NotificationPopup.success(`File uploaded`);
                resetForm();
                fileRef.current.value = null;
                await refetch();
            } else
            {
                NotificationPopup.error(`failed to upload file: ${(await res.json()).message}`)
            }
        } catch (e: any)
        {
            console.error(`failed. ${e.message}`);
        }

    }, [articleId, purchaseOrderId, refetch]);

    const onDownload = useCallback(async (fileId, filename) =>
    {
        const token = Auth.getToken();
        const res = await fetch(`${config.INVENTORY_URI}file-storage/download/${fileId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
        const blob = await res.blob();
        saveAs(blob, filename);
    }, [])


    const onRemoveClick = useCallback(async (file) =>
    {
        if (window.confirm(`Are you sure want to delete the file ${file.fileName}?`))
        {
            const token = Auth.getToken();
            await fetch(`${config.INVENTORY_URI}file-storage/deleteFile/${file.id}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
            await refetch();
        }
    }, [refetch])

    const columns = useMemo(() =>
    {
        return [
            {
                field: "fileName",
                renderCell: (params) =>
                {
                    const value = params.value
                    return <span className={"link"} onClick={async () =>
                    {
                        await onDownload(params.id, value)
                    }}>{value}</span>
                }
            },
            {field: "comment"},
            ColumnCreatedAt(),
            {field: "creator"},
            {
                field: "remove",
                renderCell: (params) =>
                {
                    return <span className={"link"}
                                 onClick={async () => await onRemoveClick(params.row)}>
                            <Trash/>
                        </span>
                }
            }
        ]
    }, [onDownload, onRemoveClick])

    if (loading)
    {
        return <Spinner variant={"warning"} animation='border'/>
    }

    return <>
        <Formik initialValues={{comment: "", fileList: []}} onSubmit={onSubmit}>
            {({handleChange, isSubmitting, handleSubmit, values, setFieldValue}) =>
                <Form onSubmit={handleSubmit as (e?: (React.FormEvent<HTMLElement> | undefined)) => void}>
                    <FormGroup>
                        <Label>
                            Upload File..
                        </Label>
                        <Input required multiple name={"fileList"} type={"file"} ref={fileRef} onChange={(e: any) =>
                            setFieldValue("fileList", e.currentTarget.files)}/>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label>Leave a comment</Label>
                        <Input as="textarea" name={"comment"} value={values.comment} rows={3}
                               style={{maxWidth: "500px"}} onChange={handleChange}/>
                    </FormGroup>
                    {!isSubmitting && <Button type={"submit"}>
                        Submit
                    </Button>}
                    {isSubmitting && <Spinner animation='border' variant={"warning"}/>}
                </Form>
            }
        </Formik>
        <hr/>
        {data?.result.length !== 0 ?
            <Col lg={10}>
                <NonPaginatedDataGrid
                    definition={{
                        tableKey: KEY_TABLE,
                        columns: columns,
                        pageSize: 20,
                    }}
                    data={data.result}/>
            </Col>
            :
            <span>There are no files here yet.</span>
        }
    </>
};
