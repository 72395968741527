import {Input} from "../common/FormRenderer";
import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {flowRight as compose} from "lodash";
import {graphql} from "@apollo/client/react/hoc";
import gql from "graphql-tag";

const cancelOrderMutation = gql`
    mutation cancelOrder($id:ID!, $sendMail:Boolean)
    {
        cancelOrder(id:$id, sendMail:$sendMail)

    }
`;

const ConfirmCancelModel = ({order, isOpen, toggle, cancelOrder}) => {
    const [sendMail, setSendMail] = useState(true);

    return <div>
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
               Cancel Order
            </ModalHeader>
            <ModalBody>
                <p>
                    Are you sure you want to cancel this order #{order.number} ?
                </p>
                <p style={{paddingLeft:"40px"}}>
                    <Input className='big-checkbox' type={'checkbox'} checked={sendMail}
                           onChange={e=>setSendMail(e.target.checked)}/> Send mail
                </p>
                <Button style={{float:"left"}} color={"primary"} onClick={async (e)=>{
                    e.target.disabled = true;
                    e.target.innerHTML = "Canceling..."
                    await cancelOrder({
                        variables: {id:order.id, sendMail},
                        refetchQueries:["getOrder", "tasks"],
                    });
                    toggle();
                }}>Ok</Button>
                <Button style={{float:"right"}} onClick={toggle}>Back</Button>
            </ModalBody>
        </Modal>
    </div>
}

export default compose(
    graphql(cancelOrderMutation,{name:"cancelOrder"})
)(ConfirmCancelModel);