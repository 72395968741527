import React, {Component} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import { IsAdmin } from 'Auth';

export default class SupplierSidebar extends Component
{
    render()
    {
        return (
            <IsAdmin>
            <Nav pills vertical>
                <NavItem>                    
                    <NavLink to="/createSupplier" className="nav-link">Create supplier</NavLink>
                </NavItem>
            </Nav>
            </IsAdmin>
        );
    }
}



