type DeliveryMethod = {
    id: string
    name: string
}

export const DeliveryMethodTypes: DeliveryMethod[] =
    [
        {id: "PARCEL", name: "Parcel"},
        {id: "SYSTEM", name: "System"},
        {id: "FULL_LOAD", name: "Full load"},
        {id: "PART_LOAD", name: "Part load"}
    ]