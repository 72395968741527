import React from "react";
import {PackageFragmentFragment} from "../../generated/graphql";
import {Col, Row} from "reactstrap";


type Props = {
    packages: PackageFragmentFragment[] | null | undefined
}

const requiredFields = [
    'deliveryTitle',
    'deliveryStreet',
    'deliveryPostalCode',
    'deliveryCity',
    'deliveryCountry'
];

export default (props: Props) =>
{
    const {packages} = props

    const isOrderDataComplete = (order) =>
    {
        return requiredFields.every(field => order[field]);
    };

    const isOrderDifferentFromFirst = (order, firstOrder) =>
    {
        return requiredFields.some(field => order[field] !== firstOrder[field]);
    };

    const getConsigneeAddress = (orders) =>
    {
        if (!orders || orders.length === 0)
        {
            return <p style={{color: "red"}}>No delivery info</p>;
        }

        const firstOrder = orders[0];

        if (!isOrderDataComplete(firstOrder))
        {
            return <p style={{color: "red"}}>Order {firstOrder.number} delivery address or contract info
                uncompleted</p>;
        }

        const differentOrder = orders.find(order => isOrderDifferentFromFirst(order, firstOrder));
        if (differentOrder)
        {
            return <p style={{color: "red"}}>Order {firstOrder.number} delivery address or contract info is
                different</p>;
        }

        return (
            <div>
                <p>{firstOrder.deliveryTitle}</p>
                <p>{firstOrder.deliveryStreet}</p>
                <p>{firstOrder.deliveryPostalCode} {firstOrder.deliveryCity} {firstOrder.deliveryCountry}</p>
                <p>{firstOrder.deliveryContactName}</p>
                <p>{firstOrder.deliveryContactPhone}</p>
                <p>{firstOrder.deliveryFreeText}</p>
            </div>
        );
    };

    return <div style={{padding: "8px 0"}}>
        <h3>Delivery Info</h3>
        <Row>
            <Col>
                <h6>Consignee</h6>
                {getConsigneeAddress(packages?.flatMap((p) => p.packageItems?.map(e => e.articleRow?.order))
                    .filter((o) => o !== null))
                }
            </Col>
        </Row>
    </div>
}
