import React, {memo, useMemo} from "react";
import {
    ArticleRowFragmentFragment,
} from "../generated/graphql";
import {Link} from "react-router-dom";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import CreatePackageModalPanel from "../package/component/CreatePackageModalPanel";


const TABLE_KEY = "TABLE_KEY_ORDER_PACKAGE"

type Props = {
    articleRows: ArticleRowFragmentFragment[]
    orderId: string
}

const OrderPackagePanel = ({articleRows, orderId}: Props) =>
{
    const linkedPackages = useMemo(() =>
    {
        const packages = articleRows.flatMap((ar) =>
        {
            return ar.packageItems?.map((e) => e?.package)
        }).filter((e) => e)

        const uniquePackages = new Map(packages.map(p => [p?.id, p]));
        return Array.from(uniquePackages.values())
    }, [articleRows])

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'mark',
                headerName: 'Shipping mark',
                renderCell: (params) =>
                {
                    return <Link to={`/package-detail/${params.id}`}>{params.value}</Link>
                }
            },
            {field: 'type', width: 100},
            {field: 'quantity', type: 'number', width: 100},
            {field: 'weight', type: 'number', width: 100},
            {field: 'length', type: 'number', width: 100},
            {field: 'width', type: 'number', width: 100},
            {field: 'height', type: 'number', width: 100},
        ]
    }, [])

    return <>
        <div style={{display: 'flex'}}>
            <h4 style={{marginRight: '10px'}}>Packages</h4>
            <CreatePackageModalPanel orderId={orderId}
                                     openSearchOrder={true}/>
        </div>

        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: columns,
            disableTooBar: true,
        }} data={linkedPackages}/>
    </>
}

export default memo(OrderPackagePanel)