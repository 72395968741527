import {FilterOperatorStringEquals} from "../FilterOperatorDefinitions";

const ColumnId = () =>
{
    return {
        field: 'id',
        headerName: 'id',
        width: 80,
        customFilterOperators: FilterOperatorStringEquals,
        parseFilterValue: (value) => Number(value),
    }
}

export default ColumnId