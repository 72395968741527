import React, {Component} from 'react';
import {Button, Table, UncontrolledTooltip} from "reactstrap";
import UploadFile from "../../common/UploadFile";
import _ from 'lodash';
import Auth from "../../Auth";
import FileList from "../../common/FileList";


export default class Start extends Component {


    constructor(){
        super();
        this.onUpload = this.onUpload.bind(this);
        this.submit = this.submit.bind(this);
        this.state = {files:[],selectedArticles:{}}
    }

    submit()
    {
        const order = this.props.variables.order;
        const articles = _.map(order.articleRows,ar=>ar.article);
        const selectedArticles = _.filter(_.map(this.state.selectedArticles,(val,key)=>
        {
            if(val)
            {
                // eslint-disable-next-line eqeqeq
                const article =  _.find(articles, ar => ar.id == key);
                console.log(article);
                return article;
            }
            else{
                return null;
            }
        }),_.negate(_.isNull));
        if(_.size(selectedArticles) === 0)
        {
            alert(`Please select more than one article`);
            return;
        }
        this.props.onSubmit({
            customerDocumentation: {
                value: JSON.stringify(_.map(this.state.files)),
                type: "json",
            },
            orderId:{
                value:order.id,
                type:"Integer"
            },
            taskNamePrefix:{
                value:`${Auth.getTenant()}: ${order.number}`,
                type:"String"
            },
            articles:{
                value: JSON.stringify(selectedArticles),
                type: "json",
            },
            initiator:
            {
                value: Auth.getUsername(),
                type: "String"
            },
        })
    }

    onUpload(files)
    {
        this.setState({files});
    }


    render()
    {
        const order = this.props.variables.order;
        const articles = _.filter(_.map(order.articleRows,ar=>ar.article),_.negate(_.isNull));
        return <div>
            <ol>
                <li>Select articles</li>
                <li>Upload customer documentation for selected articles</li>
            </ol>
            <Table>
                <thead>
                <tr>

                    <th>
                        <span className="link" id="SelectAllTooltip" onClick={()=>
                        {
                            const selectedArticles = {};
                            articles.forEach(article =>{ selectedArticles[article.id]=true});
                            this.setState({selectedArticles:selectedArticles});
                        }}>
                        Selected
                        </span>
                        <UncontrolledTooltip target='SelectAllTooltip'>Select all articles</UncontrolledTooltip>
                    </th>
                    <th>Name</th>
                    <th>Art No</th>
                </tr>
                </thead>
                <tbody>
                {articles.map(article => <tr key={article.id}>

                    <td>
                        <input className='big-checkbox' required={true} type='checkbox'
                               checked={this.state.selectedArticles[article.id] || false}
                               onChange={(e)=>
                               {
                                   console.log(article.id);
                                   console.log(e.target.checked);
                                   this.setState({selectedArticles:
                                       {...this.state.selectedArticles,
                                           [article.id]:e.target.checked}})
                               }}/>
                    </td>
                    <td>{article.name}</td>
                    <td>{article.artNo}</td>
                </tr>)}
                </tbody>
            </Table>
            <h2>Upload Files</h2>
            <FileList files={this.state.files}/>
            <UploadFile onSubmit={this.onUpload}/>
            <Button onClick={this.submit}>
                Submit
            </Button>
        </div>
    }
}

