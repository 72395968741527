import React from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {Link} from 'react-router-dom';
import ColumnAssignee from "../../common/data-grid/columns/ColumnAssignee";
import ColumnsStatus from "../../common/data-grid/columns/ColumnStatus";
import {PurchaseOrderStatus} from "../../UTIL";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import {Comparator, PurchaseOrdersV2Document} from "../../generated/graphql";
import ColumnTenantId from "../../common/data-grid/columns/ColumnTenantId";
import {InventoryRoute} from "../InventoryRouters";

const KEY_TABLE = 'TABLE_KEY_INVENTORY_ORDER_LIST'
const SUPPLIER_NAME = 'Eurostair Produktion AB'

const columns = () =>
{
    return [
        {
            field: 'number',
            headerName: 'Number',
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`${InventoryRoute.CRM_PURCHASE_ORDER_DETAIL}/${params.id}`}>{params.value}</Link>
            },
        },
        ColumnsStatus(PurchaseOrderStatus),
        {
            field: 'order.number',
            headerName: 'Customer Order',
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`${InventoryRoute.CRM_ORDER_DETAIL}/${params.row.order.id}`}>{params.value}</Link>
            },
        },
        ColumnTenantId(),
        ColumnCreatedAt(),
        ColumnAssignee('creator.username', 'Created by', 'creatorId'),
        ColumnId(),
    ]
}

const defaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const InventoryOrderListPage = () =>
{
    return (
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns(),
                initState: {
                    sorting: defaultSort,
                },
            }}
            query={PurchaseOrdersV2Document}
            persistFilter={[{key: 'supplier.name', value: SUPPLIER_NAME, comparator: Comparator.Equals}]}
        />
    )
}


export default withApollo(InventoryOrderListPage);
