import React from 'react';

export default () =>
{
    return <svg width="24px" height="24px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
        <path d="M12 24L24 12L36 24" stroke="#B21807" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12 36L24 24L36 36" stroke="#B21807" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


}

