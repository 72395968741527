import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import {Button, FormGroup, Label, Spinner} from 'reactstrap';
import Select from "react-select";
import {useStdMutation} from "../../spiralStair/lib/Util";
import {useQuery} from "@apollo/client";
import {GetSuppliersDocument} from "../../generated/inventoryGraphql";

const currencies = ["EUR", "SEK", "DKK", "NOK"];

const createPurchaseOrder = gql`mutation($po:PurchaseOrderCreateInputType!){
    createPurchaseOrder(purchaseOrder: $po)
    {
        id
    }
}`

export default ({onSubmit}) => {
    const [create, {error}] = useStdMutation(`create purchase order`, createPurchaseOrder, {});
    const [supplier, setSupplier] = useState(null);
    const [currency, setCurrency] = useState(null);
    useEffect(() => {
        if (supplier) {
            setCurrency({value: supplier.currency, label: supplier.currency});
        }
    }, [supplier])
    const {data, loading} = useQuery(GetSuppliersDocument);
    if (loading) {
        return <Spinner color={"warning"}/>
    }
    if (error) {
        return <p>Error..{error.message}</p>
    }

    return <div>

        <FormGroup>
            <Label>
                Select supplier
            </Label>
            <Select
                options={data.suppliers.list}
                value={supplier}
                onChange={(option) => setSupplier(option)}
            />
        </FormGroup>
        <FormGroup>
            <Label>
                Select Currency
            </Label>
            <Select
                options={currencies.map(c => ({value: c, label: c}))}
                value={currency}
                onChange={(option) => setCurrency(option)}
            />
        </FormGroup>
        <Button color={"success"} onClick={async () => {
            const res = await create({
                variables:
                    {
                        po: {
                            supplierId: supplier.value,
                            currency: currency.value
                        },
                    }
            })
            onSubmit(res.data.createPurchaseOrder);
        }}>
            Submit
        </Button>
    </div>
}
