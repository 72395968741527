import React from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {Link} from 'react-router-dom';
import {PurchaseOrderStatus} from "../UTIL";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnsStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {PurchaseOrdersV2Document} from "../generated/graphql";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import ColumnSupplierName from "../common/data-grid/columns/ColumnSupplierName";

const columns = () => {
    return [
        {
            field: 'number',
            headerName: 'Number',
            searchable: true,
            renderCell: (params) => {
                return <Link to={`/purchaseOrderDetail/${params.id}`}>{params.value}</Link>
            },
        },
        ColumnSupplierName(),
        ColumnAssignee(),
        ColumnsStatus(PurchaseOrderStatus),
        ColumnDate('receivedDate', 'Received shipment'),
        ColumnDate('deliveryDate', 'Delivery date'),
        ColumnCreatedAt(),
        ColumnAssignee('creator.username', 'Created by', 'creatorId'),
        ColumnId(),
    ]
}


const KEY_TABLE = 'PURCHASE_ORDER'

const defaultSort = {sortModel: [{field: 'createdAt', sort: 'desc'}]}

const columnVisibilityModel = {columnVisibilityModel: {id: false}}

const PurchaseOrderListPage = () => {
    return (
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns(),
                initState: {
                    sorting: defaultSort,
                    columns: columnVisibilityModel
                },
            }}
            query={PurchaseOrdersV2Document}
            filterTenantId={true}/>
    )
}


export default withApollo(PurchaseOrderListPage);
