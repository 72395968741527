import React, {useState} from 'react';
import StdQuery from "../StdQuery";
import GetFile from './graphql/GetFile.graphql';
import {Input} from "reactstrap";
import logger from '../../Logger';
import Auth from "../../Auth";
import {useUploadFiles} from "../../UTIL";
import NotificationPopup from "../lib/NotificationPopup";

export default (props)=>
{
    const {fileId,required,onChange} = props;
    const [id,setId] = useState(fileId);
    const uploadFiles = useUploadFiles();
    return <div>
        {id && <StdQuery query={GetFile} variables={{id}}>
            {data =>
            {
                if(data.file == null)
                {
                    return "failed to find file";
                }
                const {filename,downloadUrl} = data.file;
                return <div>
                    <a href={downloadUrl}>{filename}</a>
                </div>
            }}
        </StdQuery>}
        <Input
        type="file"
        required={required}
        onChange={async({
                            target: {
                                validity,
                                files
                            }
                        }) =>
        {
            if(validity.valid && files.length === 1)
            {
                const res = await  uploadFiles(files);
                if(!res || res.length === 0)
                {
                    NotificationPopup.error(`Failed to upload files`);
                    return;
                }
                const fileId = res[0].id;
                if(fileId)
                {
                    setId(fileId);
                    onChange({value:fileId});
                }

            }
            else
            {
                window.alert(`failed to upload.. `);
                logger.error(`${Auth.getUsername()} failed to upload file`)
            }
        }}
        />
    </div>
}
