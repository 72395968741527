import React, {Component} from 'react';
import {Alert, Button, Col, Row} from "reactstrap";
import FileList from "../../common/FileList";
import ArticleList from "./ArticleList";
import getFilesQuery from './graphql/GetFiles.graphql';
import {filter} from 'lodash';
import StdQuery from "../../common/StdQuery";
import {CustomerDocCategoryType, DrawingsCategoryType, ProductionDrawingsCategoryType} from "../../common/UploadFile";
import {OrderInfo} from "../Order/SelectArticles";
import {CommentPanel} from "./CommentComponents";

export default class ReviewDrawings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: props.variables.comments ?? [],
        };
    }
    render()
    {
        const { articles, orderId, autoDrawArticles, initiator, drawingComment } = this.props.variables;
        return <div>
            <OrderInfo orderId={orderId}/>
            <h3>Articles</h3>
            <ArticleList articles={articles} orderId={orderId}/>
            {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
                <ArticleList articles={autoDrawArticles} orderId={orderId}/>
            </>}
            {drawingComment && drawingComment.length > 0 && <><h4>Comment from {initiator}</h4>
                <Alert color="warning">
                     <pre>
                         {drawingComment}
                     </pre>
                </Alert></>}
            <StdQuery query={getFilesQuery} variables={{orderId}}>
                {data => {
                    const drawings = filter(data.files, file => file.fileCategory.id === DrawingsCategoryType.id || file.fileCategory.id === ProductionDrawingsCategoryType.id);
                    const customerDocumentation = filter(data.files, file => file.fileCategory.id === CustomerDocCategoryType.id);
                    return <React.Fragment>
                        <h3>Customer documentation files</h3>
                        <FileList files={customerDocumentation}/>
                        <hr/>
                        <h3>Uploaded drawings on order</h3>
                        <FileList files={drawings}
                                  onDelete={() => {
                                  }}/>
                    </React.Fragment>
                }
                }
            </StdQuery>

            <hr/>
            <CommentPanel comments={this.state.comments}
                          setComments={(newComments) => {
                              this.setState({comments: newComments});
                          }}/>
            <hr/>

            <Row className="justify-content-between">
                <Col sm={2}>
                    <Button onClick={() => this.props.toggle()}>
                        Cancel
                    </Button>
                </Col>
                <Col sm={2}>
                    <Button color="success" onClick={() => this.props.onSubmit({})}>
                        Complete task
                    </Button>
                </Col>
            </Row>

        </div>
    }
}

