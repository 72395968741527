import React, {useCallback, useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table} from "reactstrap";
import {mapValues,keyBy,map,filter} from 'lodash';
import NotificationPopup from "../common/lib/NotificationPopup";
import {formatCurrency} from "../UTIL";
import gql from "graphql-tag";
import {useStdMutation} from "../spiralStair/lib/Util";


const mutation = gql`
    mutation($orderId:ID!,$prices:[ArticleRowPriceInputType])
    {
        createInternalProxyVismaInvoice(orderId: $orderId,prices:$prices)
    }
`




export default ({order,isOpen,toggle})=>
{
    const [selectedArticleRows,setSelectedArticleRows] = useState(mapValues(keyBy(order.purchaseOrders.flatMap((e)=> e.articleRows),"id"),ar=>!!ar.article));
    const [selectedQuantities,setSelectedQuantities] = useState(mapValues(keyBy(order.purchaseOrders.flatMap((e)=> e.articleRows),"id"),
    'quantity'));
    const [prices,setPrices] = useState(mapValues(keyBy(order.purchaseOrders.flatMap((e)=> e.articleRows),"id"),
        'price'));
    const [createInvoiceMutation] = useStdMutation(`Create internal visma invoice`,mutation);
    const [loading,setLoading] = useState(false);
    map(selectedArticleRows,(val,key)=>console.log(`val:${val} key:${key}`));

    const createInvoice = useCallback(async()=>
    {
        setLoading(true);
        try
        {

            await createInvoiceMutation({variables:
                {
                    prices:filter(map(selectedArticleRows,
                    (val,key)=>{
                        if(val)
                        {
                            return {id:key,price:parseFloat(prices[key] || 0),quantity:parseFloat(selectedQuantities[key])};
                        }
                        else
                            return null;

                    }),e=>e!==null),
                    orderId:order.id
                }});
            toggle();
        }
        catch(e)
        {
            NotificationPopup.error(`Failed to create internal invoice. ${e.message}`);
            console.error(e);
        }

        setLoading(false);
    },[createInvoiceMutation,prices,toggle,setLoading,order,selectedArticleRows,selectedQuantities]);

    return <Modal isOpen={isOpen} toggle={toggle} size={"xl"}>
        <ModalHeader toggle={toggle}>
                Create internal proxy invoice
        </ModalHeader>
        <ModalBody>
            <p>
                Make sure the date on all associated purchase orders are correct.
                Select what article rows and quantities should be listed on the internal proxy invoice.
            </p>
            <Table>
                <thead>
                    <tr>
                        <th>Select</th>
                        <th>Art No</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>
                            Selected Quantity
                        </th>

                    </tr>
                </thead>
                <tbody>
                {
                    order.purchaseOrders.flatMap((purchaseOrder)=> {
                        return purchaseOrder.articleRows.sort((a,b)=> a.index - b.index).map(({id,article,name,quantity,price,stockArticle})=>
                         {
                             return  <tr key={id}>
                                <td>
                                    <input className='big-checkbox' required={true} type='checkbox'
                                           checked={selectedArticleRows[id] || false}
                                           onChange={(e) => {
                                               const checked = e.target.checked;
                                               setSelectedArticleRows(sAr => ({...sAr, [id]: checked}));
                                           }}/>
                                </td>
                                {[article?.artNo || stockArticle?.artNo, name].map(v => <td key={v}>{v}</td>)}
                                <td>
                                    {(article || stockArticle) && `uses PO price`}
                                    {(!(article || stockArticle)) && <div>
                                        <small>
                                            Enter in SEK
                                        </small>
                                        <input type={"number"} value={prices[id] || 0} onChange={e => {
                                            const value = e.target.value
                                            setPrices(p => ({...p, [id]: value}))
                                        }}/>
                                        <small style={{display: "block"}}>
                                            {formatCurrency(prices[id])} SEK
                                        </small>
                                    </div>}
                                </td>
                                <td>
                                    {quantity}
                                </td>
                                <td>

                                    <input value={selectedQuantities[id]} type={"number"} onChange={e => {
                                        const value = e.target.value;
                                        setSelectedQuantities(q => ({...q, [id]: value}));
                                    }}/>

                                </td>
                            </tr>
                        }
                        )
                    })
                }
                </tbody>

            </Table>
        </ModalBody>
        <ModalFooter>
            <Button color={"secondary"} onClick={()=>toggle()}>
                Cancel
            </Button>
            {!loading && <Button color={"success"} onClick={()=>createInvoice()}>
                Submit
            </Button>}
            {loading && <Spinner color={"warning"}/>}
        </ModalFooter>
    </Modal>
}
