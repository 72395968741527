import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {camelToTitle} from "../../UTIL";
import {StraightStairSurfaceTreatmentFragmentFragment} from "../../generated/graphql";


const StraightStairSurfaceTreatmentModal = ({surfaceTreatments, isOpen, toggle, onUpdate}: {
    surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[],
    isOpen: boolean,
    toggle: () => void,
    onUpdate: (surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[]) => void
}) =>
{
    const [surfaceTreatmentsData, setSurfaceTreatmentsData] = useState<StraightStairSurfaceTreatmentFragmentFragment[]>(surfaceTreatments);

    const selectAllPowderCoatingRef = useRef<any>();
    const selectAllPlatingRef = useRef<any>();
    const [selectAllPowderCoating, setSelectAllPowderCoating] = useState(false);
    const [selectAllPlating, setSelectAllPlating] = useState(false);

    const onUpdateClick = useCallback(async () =>
    {
        onUpdate(surfaceTreatmentsData)
        toggle()
    }, [onUpdate, surfaceTreatmentsData, toggle]);

    const onCheckBoxAllClick = useCallback((field: string, checked: boolean) =>
    {
        setSurfaceTreatmentsData(prevState =>
        {
            const copy = [...prevState]
            copy.forEach((s) => s[field] = checked)
            return copy
        })
    }, [])

    const onCheckBoxClick = useCallback((index: number, field: string, checked: boolean) =>
    {
        setSurfaceTreatmentsData(prevState =>
        {
            const copy = [...prevState]
            copy[index][field] = checked;
            return copy
        })
    }, [])

    useEffect(() =>
    {
        setSurfaceTreatmentsData(surfaceTreatments)
    }, [surfaceTreatments]);

    useEffect(() =>
    {
        if (!isOpen || !selectAllPowderCoatingRef.current) return;

        const isAllPowderCoatingChecked = surfaceTreatmentsData.every((e) => e.isCheckedPC)
        selectAllPowderCoatingRef.current.indeterminate = !isAllPowderCoatingChecked
        setSelectAllPowderCoating(isAllPowderCoatingChecked)

        const isAllPlatingChecked = surfaceTreatmentsData.every((e) => e.isCheckedPl)
        selectAllPlatingRef.current.indeterminate = !isAllPlatingChecked
        setSelectAllPlating(isAllPlatingChecked)
    }, [isOpen, surfaceTreatmentsData]);

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            Surface treatment
        </ModalHeader>
        <ModalBody>
            <table className={"powderCoatingTable"}>
                <thead>

                <tr>
                    <th/>
                    <th className={"plating"}>Galvanization</th>
                    <th className={"powderCoating"}>Powder Coating</th>
                </tr>
                </thead>
                <tbody>

                <tr>
                    <th>Part Name</th>
                    <th style={{textAlign: "center", lineHeight: "0px"}} className={"plating"}>
                        <input ref={selectAllPlatingRef} className='big-checkbox' type='checkbox'
                               checked={selectAllPlating}
                               onChange={(e) => onCheckBoxAllClick("isCheckedPl", e.target.checked)}/>
                    </th>
                    <th style={{textAlign: "center", lineHeight: "0px"}} className={"powderCoating"}>
                        <input ref={selectAllPowderCoatingRef} className='big-checkbox' type='checkbox'
                               checked={selectAllPowderCoating}
                               onChange={(e) => onCheckBoxAllClick("isCheckedPC", e.target.checked)}/>
                    </th>
                </tr>
                {surfaceTreatmentsData.map((sur, index) =>
                {

                    return <tr key={"pc_" + index}>
                        <td>{camelToTitle(sur.partName)}</td>
                        <td style={{textAlign: "center"}} className={"plating"}>
                            <input className='big-checkbox' type='checkbox'
                                   checked={sur.isCheckedPl}
                                   onChange={(e) => onCheckBoxClick(index, "isCheckedPl", e.target.checked)}
                            />
                        </td>

                        <td style={{textAlign: "center"}} className={"powderCoating"}>
                            <input className='big-checkbox' type='checkbox'
                                   checked={sur.isCheckedPC}
                                   onChange={(e) => onCheckBoxClick(index, "isCheckedPC", e.target.checked)}
                            />
                        </td>
                    </tr>;
                })}
                </tbody>
            </table>
        </ModalBody>
        <ModalFooter>
            <Button onClick={onUpdateClick}>Update</Button>
        </ModalFooter>
    </Modal>
}

export default StraightStairSurfaceTreatmentModal