import React, {useMemo, useState} from 'react';
import {Button, Spinner, Modal, Row, Col, ModalHeader, ModalBody, FormGroup, Label, Input} from "reactstrap";
import {filter} from 'lodash';
import moment from 'moment';
import {useStdMutation} from "../../spiralStair/lib/Util";
import {mapSeries} from "../../UTIL";
import NotificationPopup from "../../common/lib/NotificationPopup";
import gql from "graphql-tag";

export default ({articleRows}) => {
    const [createStockTransaction, {loading}] = useStdMutation(`create stock transaction`, gql`
        mutation createStockTransaction($id:ID!,$quantity:Float!,$received:DateTime)
        {
            createStockTransactionArticleRow(articleRowId: $id,quantity: $quantity,received: $received)
            {
                id
            }
        }
    `, {refetchQueries: ["getInventoryPurchaseOrder"]});
    const today = moment().format('YYYY-MM-DD');
    const [transactedDate, setTransactedDate] = useState(today);
    const markAllAsReceived = useMemo(() => {
        return async () => {
            const ars = filter(articleRows, ar => ar.article)
            await mapSeries(ars, ar => {
                const quantity = ar.quantity - ar.receivedQuantity;
                if (quantity > 0) {
                    createStockTransaction({
                        variables: {
                            id: ar.id,
                            quantity,
                            received: transactedDate
                        }
                    })
                }

            })
            NotificationPopup.success(`Marked ${ars.length} article rows as received`);
        }
    }, [createStockTransaction, articleRows, transactedDate]);
    const toggle = () => setOpen(o => !o);
    const [open, setOpen] = useState();
    return <>
        <Modal isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Are you sure you want to mark everything in this order as received?
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={8} sm={12}>
                        <FormGroup>
                            <Label>
                                Received
                            </Label>
                            <Input type={"date"} value={transactedDate} max={today}
                                          onChange={e => setTransactedDate(e.target.value)}/>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Button color={"secondary"} onClick={toggle}>
                            Cancel
                        </Button>
                    </Col>
                    <Col sm={6}>
                        <Button color={"primary"}
                                onClick={async () => {
                                    markAllAsReceived();
                                    toggle();
                                }}>
                            Mark everything as received
                        </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
        {loading && <Spinner color={"warning"}/>}
        {!loading && <Button color={"primary"} onClick={() => {
            setOpen(true);
        }}>
            Mark everything as received
        </Button>}

    </>
}
