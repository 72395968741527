import TaskFooter from "../../common/task-workflow/TaskFooter";
import React, {useState} from "react";
import {FormGroup, Label} from "reactstrap";

export default ({onSubmit,toggle})=>
{
    const [skipSendMail,setSkipSendMail] = useState(true);
    return <div>

        <p style={{marginTop:"30px"}}>
            Confirm supplier has sent contents of Order directly to customer
        </p>
        <FormGroup>
            <Label>
                Skip sending E-Mail notification to customer.
            </Label>
            <input style={{display:"block"}} type="checkbox" className={"big-checkbox"}
                   checked={skipSendMail} onChange={e=>setSkipSendMail(e.target.checked)} />
        </FormGroup>

        <TaskFooter toggle={toggle} submit={()=>
        {
            if(window.confirm(`Have you made sure that the PO has been sent from the supplier?`))
            {
                return onSubmit({
                    skipSendMail:{
                        value:skipSendMail,type:"Boolean"
                    }
                });
            }
        }} />

    </div>
}
