import {Modal, ModalHeader, Spinner} from "reactstrap";
import {SpiralStair, UpdateSpiralStairStatusMutation, useGetSpiralStairBomQuery} from "../../generated/graphql";
import React from "react";
import {ok} from "assert";
import BillOfMaterialsSection from "./BillOfMaterialsSection";

export default ({
                    spiralStair,
                    toggle,
                    onUpdateStatusSuccess,
                }: {
    spiralStair: SpiralStair & {articleRow: {id: string}},
    toggle: () => void,
    onUpdateStatusSuccess: (response: UpdateSpiralStairStatusMutation) => void
}) =>
{
    const orderId = spiralStair.purchaseOrder?.order?.id
    ok(orderId, `Unable to retrieve OrderId.`)
    const articleId = spiralStair.articleId

    const {data} = useGetSpiralStairBomQuery({variables: {orderId, articleId}})

    return <Modal size="md" style={{maxWidth: "800px"}} toggle={toggle} isOpen={true} backdrop={false}>
        <ModalHeader toggle={toggle}>Bill of material {spiralStair.purchaseOrder?.order?.number}</ModalHeader>
        {
            data == null ? <Spinner/>
                : <BillOfMaterialsSection orderId={orderId} articleId={articleId} articleRowId={spiralStair.articleRow?.id}
                                          boms={data?.getSpiralStairBom ?? []}
                                          spiralStairStatus={spiralStair.status}
                                          artNo={spiralStair.artNo ?? ""}
                                          onUpdateStatusSuccess={onUpdateStatusSuccess}
                                          isEditable={true}
                                          toggle={toggle}
                                          statusUpdatedBy={spiralStair.statusUpdatedBy}
                                          statusUpdatedAt={spiralStair.statusUpdatedAt}
                                          freeText={spiralStair.freeText}
                                          onUpdateFreeTextSuccess={(freeText) => spiralStair.freeText = freeText}/>
        }
    </Modal>
}
