import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import StripedDataGrid from "../common/data-grid/StripedDataGrid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {formatCurrency} from "../UTIL";

export type ArticleListDataRowsType = {
    id: string
    count: number
    name: string | undefined
    unitPrice: number
    price: number
    currency: string
}[]

export type ArticleListDataType = {
    columns: GridColDef<any, any, any>[]
    rows: ArticleListDataRowsType,
    length?: number,
}

export default ({isOpen, toggle, data}: {isOpen:boolean, toggle:()=>void, data:ArticleListDataType}) => {
    const currency = data.rows[0]?.currency;
    const formattedDataRows = data.rows.map(row => {
        return { ...row, unitPrice: `${formatCurrency(row.unitPrice)} ${currency}`, price:`${formatCurrency(row.price)} ${currency}`}
    });
    const sum = data.rows.reduce((sum, row )=> sum+row.price, 0);

    return <Modal size='lg' isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Article List</ModalHeader>
        <ModalBody>
            <StripedDataGrid
                rows={formattedDataRows}
                columns={data.columns}
                rowCount={data.length}
                pagination={false}
                filterMode="client"
                sortingMode="client"
            />
            <div style={{width:"100%", textAlign:"right", padding:"10px 25px"}}>Summarized price: {formatCurrency(sum)} {currency}</div>
            <Button
                color="success"
                style={{float:"right"}}
                onClick={toggle}
            >
                Close
            </Button>
        </ModalBody>
    </Modal>
}