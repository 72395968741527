import {PreferedMaterialType, StringerMaterial} from "../generated/graphql";


export const maxLoadOptions = [
    {id: 3, name: "3"},
    {id: 5, name: "5"}
]

export const deflectionOptions = [
    {id: "L/250", name: "L/250"},
    {id: "L/200", name: "L/200"}
]

export const scpOptions = [
    {id: "L1", name: "L1"},
    {id: "L2", name: "L2"},
    {id: "W1", name: "W1"},
    {id: "W2", name: "W2"}]

export const getStringerMaterialOptions = (preferedMaterial: PreferedMaterialType) =>
{
    switch (preferedMaterial)
    {
        case PreferedMaterialType.Pls:
            return [StringerMaterial.Pls150x8, StringerMaterial.Pls150x10, StringerMaterial.Pls200x8, StringerMaterial.Pls200x10,
                StringerMaterial.Pls200x12, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12]
        case PreferedMaterialType.Upe:
            return [StringerMaterial.Upe140, StringerMaterial.Upe160, StringerMaterial.Upe180, StringerMaterial.Upe200]
    }
}