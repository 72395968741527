import {
    PreferedMaterialType,
    StepType, StraightStairV2FragmentFragment,
    StringerMaterial
} from "generated/graphql";
import {maxBy} from "lodash";
import StringerCalculatorData from "./StringerCalculatorData.json";

export const generateNewStringer = (stair: StraightStairV2FragmentFragment): StringerMaterial[] =>
{
    const allSegmentGeometry = stair.segments.map(e => e.geometry)
    const lx = maxBy(allSegmentGeometry, (e) => e?.stairsHorizontalLength ?? 0)?.stairsHorizontalLength ?? 0
    const lc = maxBy(allSegmentGeometry, (e) => e?.stringerLength ?? 0)?.stringerLength ?? 0
    const width = maxBy(stair.segments, (e) => e?.stepWidth ?? 0)?.stepWidth ?? 0

    const def = stair.deflection === "L/250" ? 1 : 0
    const preferedMaterial = stair.preferedMaterial === PreferedMaterialType.Pls ? 1 : 0

    let stepType: number;
    switch (stair.stepType)
    {
        case StepType.StandardGrate:
        case StepType.TightMeshGrate:
        case StepType.PrepForCarpet:
        case StepType.PrepForWood:
        case StepType.Tearplate:
            stepType = 0
            break;
        case StepType.PlateForConcrete:
        case StepType.PrepForTiles:
        case StepType.PrepForTerrazzo:
            stepType = 1
            break;
    }

    const stringerCalculatorData = StringerCalculatorData.find((e) =>
    {
        return e.stepWidth === width &&
            e.horizontalLength >= lx &&
            e.stringerLength >= lc &&
            e.maxLoad === stair.maxLoad &&
            e.stepType === stepType &&
            e.preferedMaterial === preferedMaterial &&
            e.deflection === def
    })

    if (!stringerCalculatorData)
    {
        return []
    }

    const items: StringerMaterial[] = [];

    switch (stringerCalculatorData.stringer)
    {
        case 12:
            items.push(StringerMaterial.Upe200);
            break;
        case 11:
            items.push(StringerMaterial.Upe180, StringerMaterial.Upe200);
            break;
        case 10:
            items.push(StringerMaterial.Upe160, StringerMaterial.Upe180, StringerMaterial.Upe200);
            break;
        case 9:
            items.push(StringerMaterial.Upe140, StringerMaterial.Upe160, StringerMaterial.Upe180, StringerMaterial.Upe200);
            break;
        case 8:
            items.push(StringerMaterial.Pls300x12);
            break;
        case 7:
            items.push(StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
        case 6:
            items.push(StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
        case 5:
            items.push(StringerMaterial.Pls200x12, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
        case 4:
            items.push(StringerMaterial.Pls200x10, StringerMaterial.Pls200x12, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
        case 3:
            items.push(StringerMaterial.Pls200x8, StringerMaterial.Pls200x10, StringerMaterial.Pls200x12, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
        case 2:
            items.push(StringerMaterial.Pls150x10, StringerMaterial.Pls200x8, StringerMaterial.Pls200x10, StringerMaterial.Pls200x12, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
        case 1:
            items.push(StringerMaterial.Pls150x8, StringerMaterial.Pls150x10, StringerMaterial.Pls200x8, StringerMaterial.Pls200x10, StringerMaterial.Pls250x8, StringerMaterial.Pls250x10, StringerMaterial.Pls300x12);
            break;
    }

    return items
}