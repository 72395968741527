import React, {useCallback, useState} from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {formatCurrency, OrderStatuses, orderTotal, PurchaseOrderStatus,} from "../UTIL";
import Transport from "../common/icons/Transport";
import {Badge, Button} from "reactstrap";
import _ from 'lodash';
import Paragraph from "../common/Paragraph";
import ColumnsCompany from "../common/data-grid/columns/ColumnsCompany";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {OrdersV2Document} from "../generated/graphql";
import ColumnsProject from "../common/data-grid/columns/ColumnsProject";
import ColumnsStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import Auth from "../Auth";
import ColumnOrderNumber from "../common/data-grid/columns/ColumnOrderNumber";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import {format} from "date-fns";
import ColumnComments from "../common/data-grid/columns/ColumnComments";
import ValidateOrderInformationModal, { getOrderInvalidFields } from './ValidateOrderInformationModal';
import ColumnPrioritizedProject from "../common/data-grid/columns/ColumnPrioritizedProject";


const KEY_TABLE = 'ORDER'

const OrderTableDefaultSort = {sortModel: [{field: 'number', sort: 'desc'}]}

const filterMyUndeliveredOrders = {
    items: [
        {field: 'status', value: ['started', 'waitDeliver', 'waitSuppliers'], operator: 'in'},
        {field: 'assignee.username', value: Auth.getUsername(), operator: 'is'},
    ],
}

const filterPresets = [
    {
        name: "My undelivered orders",
        filters: filterMyUndeliveredOrders,
        sort: [{field: 'number', sort: 'desc'}]
    },
    {
        name: "My delivered orders",
        filters: {
            items: [
                {field: 'status', value: ["delivered", "done"], operator: 'in'},
                {field: 'assignee.username', value: Auth.getUsername(), operator: 'is'},
            ],
        },
        sort: [{field: 'dateOfDelivery', sort: 'desc'}]
    },
    {
        name: "My Prioritized projects",
        filters: {
            items: [
                {field: 'prioritizedProject', value: ["high"], operator: 'in'},
                {field: 'assignee.username', value: Auth.getUsername(), operator: 'is'},
            ],
        },
        sort: [{field: 'dateOfDelivery', sort: 'desc'}]
    }
]


const OrderListColumnDefinition = (onValidateClick: (order) => void) =>
{
    return [
        ColumnOrderNumber(),
        ColumnsCompany(),
        ColumnsProject(),
        {
            field:'projectStage',
            headerName: 'Part of project',
        },
        ColumnsStatus(OrderStatuses),
        ColumnPrioritizedProject(),
        {
            field: 'value',
            headerName: 'Value',
            filterable: false,
            sortable: false,
            type: 'number',
            renderCell: (params) =>
            {
                const order = {
                    articleRows: params.row.articleRows,
                    shippingCost: params.row.shippingCost,
                    packagingCost: params.row.packagingCost
                }
                return <div style={{textAlign: 'right'}}>{formatCurrency(orderTotal(order))} {params.row.currency}</div>
            },
        },
        {
            field: 'purchaseOrders',
            headerName: 'PO statuses',
            filterable: false,
            sortable: false,
            renderCell: (params) =>
            {
                return <div>
                    {params.value.map(po =>
                    {
                        const status = _.find(PurchaseOrderStatus, s => s.id === po.status);
                        if (!status)
                        {
                            return po.status;
                        }
                        return <Paragraph key={po.id} style={{verticalAlign: "bottom", marginTop: "5px"}}>
                            {_.truncate(po.supplier.name, {length: 16})}
                            <Badge size='sm' style={{backgroundColor: status.color}}>
                                {status.name}
                            </Badge>
                        </Paragraph>
                    })}
                </div>
            },
        },
        {
            field: 'valid',
            headerName: 'Valid',
            filterable: false,
            sortable: false,
            renderCell: (params) =>
            {
                const order = params.row
                const isValid = getOrderInvalidFields(order).length === 0
                if (isValid)
                {
                    return null
                }
                return <>
                    <Button color={"danger"} size="sm"
                            onClick={() => onValidateClick(order)}>Missing data</Button>
                </>
            },
        },
        ColumnDate('preferredDeliveryDate', 'Preferred delivery date'),
        {
            field: 'dateOfDelivery',
            headerName: 'Date of delivery',
            type: 'date',
            valueGetter: ({value}) => value && new Date(value),
            renderCell: (params) =>
            {
                return params.value
                    ? <span style={{color: "green"}}>{format(new Date(params.value), 'yyyy-MM-dd')}&nbsp;
                        <Transport/></span>
                    : null
            }
        },
        ColumnComments(),
        ColumnAssignee(),
        ColumnCreatedAt(),
        ColumnId(),
    ]
}

const OrderListPage = () =>
{
    const [validateOrder, setValidateOrder] = useState<any>()

    const toggleValidateOrderModal = useCallback(() =>
    {
        setValidateOrder(null)
    }, [setValidateOrder])

    return <>
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: OrderListColumnDefinition((order) => setValidateOrder(order)),
                initState: {
                    sorting: OrderTableDefaultSort,
                    filter: {
                        filterModel: filterMyUndeliveredOrders
                    }
                },
                buttons: filterPresets
            }}
            query={OrdersV2Document}
            filterTenantId={true}/>
        {validateOrder && <ValidateOrderInformationModal order={validateOrder} isOpen={validateOrder} toggle={toggleValidateOrderModal}/>}
    </>
}


export default withApollo(OrderListPage);
