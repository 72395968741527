import {Modes, State} from "./ModuleRampPage";
import {IndustrialPole, IndustrialRailing, IndustrialRailingCurvePiece} from "./Module/IndustrialPole";
import {
    degreeToQuadrant,
    getAngleBetweenVectorsByAxisY,
    isNodesCanJointWithDegreeAnd2Length
} from "./utils";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {ModuleObject, Node} from "./ShpaeObject";
import ModuleTemplate, {getModuleTemplate, ModuleData, pairNodeDistance, store} from "./ModuleTemplate";
import {nodeRailingForIndustrialPole} from "./NodeFilterFunctions";
import SceneManager, {SceneName} from "./SceneManager";
import {IndustrialRailingPieceType, ModuleRampType} from "../generated/graphql";
import {Tools} from "@babylonjs/core";

export const makeIndustrialRailingPiece = (selectedModule:ModuleTemplate, poleTopNode) => {
    const allNodes = State.getInstance().models.flatMap(m=>m.nodes);

    selectedModule.slopeDegree = poleTopNode.parent.moduleTemplate.slopeDegree;
    const railingNode = poleTopNode.attach.find(nodeRailingForIndustrialPole);
    if(railingNode?.parent.startNode === railingNode){
        selectedModule.slopeDegree *= -1;
    }

    let filterFunction = (anotherNode)=>false;
    let reachableNodes:Node[] = [];
    switch (selectedModule.otherProps.pieceType){
        case IndustrialRailingPieceType.JointPlaneRamp:
            filterFunction = (anotherNode)=>{
                // lengthMM * 2 * scaleRatio = 3
                return (
                    anotherNode.parent instanceof IndustrialPole &&
                    Math.abs(Vector3.Distance(anotherNode.origin, poleTopNode.origin) - 3) < pairNodeDistance
                );
            }
            reachableNodes = allNodes.filter(filterFunction);
            if(reachableNodes.length===0){
                State.getInstance().mode = Modes.noAvailableNode;
                return;
            }
            const attachedRailing =  reachableNodes[0].attach[0].parent as IndustrialRailing;
            selectedModule.slopeDegree *= -1;
            selectedModule.otherProps.jointTargetSlopeDegree = attachedRailing.moduleTemplate.slopeDegree;
            if(attachedRailing.startNode === reachableNodes[0].attach[0]){
                selectedModule.otherProps.jointTargetSlopeDegree *= -1;
            }

            break;
        case IndustrialRailingPieceType.Joint90degree:
            filterFunction = (anotherNode)=>{
                if(!(anotherNode.parent instanceof IndustrialPole)) return false;
                // curveRadiusMM+extensionLengthMM / 2 * scaleRatio = 1.5
                const turnDegree = 90;
                return isNodesCanJointWithDegreeAnd2Length(anotherNode.origin, poleTopNode.origin, turnDegree, 1.5);
            }
            reachableNodes = allNodes.filter(filterFunction);
            if(reachableNodes.length===0){
                return;
            }
            const targetNode = reachableNodes[0];
            const targetVector = targetNode.origin.subtract(poleTopNode.origin);
            const angle = getAngleBetweenVectorsByAxisY(targetVector, (poleTopNode.parent as IndustrialPole).vector);
            const targetQuadrant = degreeToQuadrant(Tools.ToDegrees(angle));
            selectedModule.otherProps.targetQuadrant = targetQuadrant;
            selectedModule.slopeDegree = (poleTopNode.parent as IndustrialPole).moduleTemplate.slopeDegree;
            break;
    }

    selectedModule.origin = new Vector3(-selectedModule.width-2, 0, 0);
    let selectedModuleModel = new IndustrialRailingCurvePiece(selectedModule, SceneManager.getInstance()[SceneName.main]);
    let selectedNode = (selectedModuleModel as IndustrialRailingCurvePiece).startNode;
    // attach selected module to another with their nodes
    if(!selectedNode){ return; }
    selectedNode.attachTo(poleTopNode);
    return selectedModuleModel;
}


export const industrialRailingPieceAutoComplete = ():ModuleObject[] => {
    let industrialPoleNodes:(Node|null)[] = State.getInstance().models.flatMap(m=>m.nodes).filter((node)=>{
        if(node.attach.length>=2) return false;
        if(!(node.parent instanceof IndustrialPole)) return false;
        return node === node.parent.railingNode;
    });


    /*
    * 1. jointPlaneRamp
    * 2. joint90degree
    * 3. endPlug
    * */
    const generatedPieces:IndustrialRailingCurvePiece[] = [];
    for (let index=0; index<industrialPoleNodes.length; index++) {
        const nodeA = industrialPoleNodes[index];
        if(nodeA===null) continue;

        const pairNode = industrialPoleNodes.find((nodeB)=>{
            if(!nodeB) return false;
            return Math.abs(Vector3.Distance(nodeB.origin, nodeA.origin) - 3) < pairNodeDistance;
        });

        if(pairNode){
            const moduleType = store[ModuleRampType.IndustrialRailingPiece].find(e=>e.pieceType ===IndustrialRailingPieceType.JointPlaneRamp);
            const index = store[ModuleRampType.IndustrialRailingPiece].indexOf(moduleType as ModuleData);

            const newPiece = makeIndustrialRailingPiece(getModuleTemplate({type:ModuleRampType.IndustrialRailingPiece, id:index}), nodeA);
            if(newPiece) {
                generatedPieces.push(newPiece);
            }

            if(pairNode.attach.length>=2){
                industrialPoleNodes[industrialPoleNodes.indexOf(pairNode)] = null;
            }
            if(nodeA.attach.length>=2){
                industrialPoleNodes[industrialPoleNodes.indexOf(nodeA)] = null;
            }
        }
    }

    for (let index=0; index<industrialPoleNodes.length; index++) {
        const nodeA = industrialPoleNodes[index];
        if(nodeA===null) continue;

        const pairNode = industrialPoleNodes.find((nodeB)=>{
            if(!nodeB) return false;
            const turnDegree = 90;
            return isNodesCanJointWithDegreeAnd2Length(nodeB.origin, nodeA.origin, turnDegree, 1.5);
        });

        if(pairNode) {
            const moduleType = store[ModuleRampType.IndustrialRailingPiece].find(e => e.pieceType === IndustrialRailingPieceType.Joint90degree);
            const index = store[ModuleRampType.IndustrialRailingPiece].indexOf(moduleType as ModuleData);

            const newPiece = makeIndustrialRailingPiece(getModuleTemplate({type: ModuleRampType.IndustrialRailingPiece, id: index}), nodeA);
            if(newPiece) {
                generatedPieces.push(newPiece);
            }

            if(pairNode.attach.length>=2){
                industrialPoleNodes[industrialPoleNodes.indexOf(pairNode)] = null;
            }
            if(nodeA.attach.length>=2){
                industrialPoleNodes[industrialPoleNodes.indexOf(nodeA)] = null;
            }
        }
    }

    industrialPoleNodes.forEach((node) => {
        if(node===null) return;

        const railingNode = node.attach.find(nodeRailingForIndustrialPole);
        if(!railingNode) return;

        const moduleType = store[ModuleRampType.IndustrialRailingPiece].find(e=>e.pieceType ===IndustrialRailingPieceType.EndPlug);
        const index = store[ModuleRampType.IndustrialRailingPiece].indexOf(moduleType as ModuleData);

        const newPiece = makeIndustrialRailingPiece(getModuleTemplate({type:ModuleRampType.IndustrialRailingPiece, id:index}), node);
        if(newPiece) {
            generatedPieces.push(newPiece);
        }
    })

    return generatedPieces;
}
