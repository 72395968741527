import React from 'react';
import StdQuery from "../../common/StdQuery";
import getFilesQuery from './graphql/GetFiles.graphql';
import {filter} from 'lodash';
import FileList from "../../common/FileList";
import {CustomerDocCategoryType, DrawingsCategoryType, ProductionDrawingsCategoryType} from '../../common/UploadFile';


export const DrawingsFileList = ({orderId})=>
{
    return <StdQuery query={getFilesQuery} variables={{orderId}}>
        {data =>
        {
            const drawings = filter(data.files,file=>file.fileCategory.id === DrawingsCategoryType.id  || file.fileCategory.id === ProductionDrawingsCategoryType.id);
            return <React.Fragment>
                <h3>Uploaded drawings on order</h3>
                <FileList files={drawings}
                          onDelete={()=>{}}/>
            </React.Fragment>
        }
        }
    </StdQuery>
};

export const CustomerDocsFileList = ({orderId})=>
{
    return <StdQuery query={getFilesQuery} variables={{orderId}}>
        {data =>
        {
            const drawings = filter(data.files,file=>file.fileCategory.id === CustomerDocCategoryType.id);
            return <React.Fragment>
                <h3>Uploaded customer documentation</h3>
                <FileList files={drawings}
                          onDelete={()=>{}}/>
            </React.Fragment>
        }
        }
    </StdQuery>
};
