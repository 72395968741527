import StdQuery from "../common/StdQuery";
import React, {useState} from "react";
import FormRenderer, {Input} from "../common/FormRenderer";
import gql from "graphql-tag";
import {Col, Row} from "reactstrap";
import UpdateUserProfile from './graphql/UpdateUserProfile.graphql';
import {useStdMutation} from "../spiralStair/lib/Util";
import Auth from "../Auth";

const getProfile = gql`
query userProfile
{ 
    userProfile
    {
        phone
        mobilePhone
        signature
        {
            filename
            id
        }
    }
}`;

const formDef = [
    {name:"phone"},
    {name:"mobilePhone"},
    {hint:<p>Your signature should be 175px X 50px</p>, name:"signatureId",type:"file"},
];
export default ()=>
{
    const [updateUserProfile] = useStdMutation(`Update user profile`,UpdateUserProfile,{refetchQueries:["userProfile"]});
    const [searchAllTenant, setSearchAllTenant] = useState(Auth.getSearchAllTenant()==='true');

    return <><StdQuery query={getProfile} >
        {data=>
        {
            return <Row>
                <Col sm={6}>
                    <FormRenderer object={data.userProfile} formDefinition={formDef} onSubmit={async(form)=>
                    {
                        await updateUserProfile({variables:{userProfile:form.object}});
                    }} />
                </Col>
            </Row>

        }}

    </StdQuery>
        <hr/>
    <Row>
        <Col>
            <div className={"form-group"} style={{marginLeft:"20px"}}>
                <Input className='big-checkbox' type={'checkbox'} checked={searchAllTenant}
                       onChange={e=>{
                           setSearchAllTenant(e.target.checked);
                           Auth.setSearchAllTenant(e.target.checked);
                       }}/>
                <label>Search all tenant at navigation bar</label>
            </div>
        </Col>
    </Row></>
}