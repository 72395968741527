import {useCallback, useMemo} from "react";
import {PaymentInstructionTypes} from "../model/PaymentInstructionType";
import {ConsignorAccountList, ConsignorTestAccountList} from "../model/ConsignorAccountList";
import {ConsigneeAccountList} from "../model/ConsigneeAccountList";
import {selectRenderer} from "../../common/FormRenderer";
import {isProd} from "../../workflow-forms/DrawJob/util";

const PaymentAccountSelect = (props) =>
{
    const {model, onChange, isNewFormRenderer} = props

    const getList = useMemo(() =>
    {
        const paymentInstruction = model.paymentInstruction
        if (paymentInstruction === PaymentInstructionTypes.consignor)
        {
            return isProd ? ConsignorAccountList : ConsignorTestAccountList
        }
        if (paymentInstruction === PaymentInstructionTypes.consignee)
        {
            return isProd ? ConsigneeAccountList : []
        }

        return []
    }, [model.paymentInstruction])

    const handleOnChange = useCallback((value) =>
    {
        const id = value?.id
        if (isNewFormRenderer)
        {
            onChange(id);
        } else
        {
            onChange({value: id});
        }
    }, [onChange, isNewFormRenderer])

    return selectRenderer({name: "paymentAccount", options: getList},
        model.paymentAccount, handleOnChange)
}

export default PaymentAccountSelect