import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import React from "react";


export default () =>
{
    return (
        <Nav pills vertical>
            <NavItem>
                <NavLink to="/create-shipment" className="nav-link">Create shipment</NavLink>
            </NavItem>
        </Nav>);
}