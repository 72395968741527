import React, {useEffect, Component, useState, useCallback} from 'react';
import {Link} from "react-router-dom";
import {filter, map, sum} from 'lodash';
import gql from "graphql-tag";
import {round} from "../spiralStair/lib/Util";
import Paragraph from "../common/Paragraph";
import {
    Button, Badge, Progress, Spinner, UncontrolledPopover,
    PopoverBody, UncontrolledTooltip
} from "reactstrap";
import {downloadFile, FORMAT_DATE, formatToSwedishTime} from "../UTIL";
import {useLazyQuery} from "@apollo/client";
import AnslAngle from "../spiralStair/lib/AnslAngle";
import _ from 'lodash';
import Collapser from "../common/Collapser";
import SurfaceTreatmentTag, {getSurfaceTreatmentType, SurfaceTreatmentType} from "../common/SurfaceTreatmentTag";
import Dot from "../common/icons/Dot";
import Up from "../common/icons/Up";
import DoubleUp from "../common/icons/DoubleUp";
import Wood from "../common/icons/Wood";
import ColumnId from "../common/data-grid/columns/ColumnId";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import {
    GridFilterInputSingleSelect
} from "@mui/x-data-grid";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import {
    SpiralStairStatus,
    useSpiralStairsInProductionLazyQuery
} from "../generated/graphql";
import NotificationPopup from "../common/lib/NotificationPopup";
import UpdateSpiralStairStatusModal from "../spiralStair/bom/UpdateSpiralStairStatusModal";
import {getSpiralStairStatusColor} from "../spiralStair/SpiralStairStatusExt";
import {MultiSelectFilterOperators} from "../common/data-grid/MultiSelectInput";
import SimpleSurfaceTreatmentSubPanel from "../spiralStair/SimpleSurfaceTreatmentSubPanel";

const TABLE_KEY = 'SPIRAL_STAIRS'

const Radius = (segments) => {
    return <div>
        {
            map(segments, (seg) =>
                <Paragraph key={seg.id} style={{marginLeft: "7px"}}>
                    <small>Section {seg.index + 1}:</small>
                    <strong>{seg.radius}</strong></Paragraph>)
        }</div>

}

const Extra = (extra) => {
    return <div>
        {map(extra, (ext) =>
            <Paragraph key={ext.id} style={{marginLeft: "7px"}}>
                <small>{ext.name}:</small>
                <strong>{ext.price}</strong></Paragraph>)}
    </div>
}

const defaultSort = {sortModel: [{field: 'purchaseOrder.order.preferredDeliveryDate', sort: 'asc'}]}

const buttons = [
    {
        name: "Pending",
        filters: {items:[{field: "status", operator: "in", value: "pending"}]},
    },
    {
        name: "Prepackaged",
        filters: {items:[{field: "status", operator: "in", value: "prepackaged"}]},
    },
    {
        name: "Finished",
        filters: {items:[{field: "status", operator: "in", value: "finished"}]},
    },
]

const visibilityModel = {
    columnVisibilityModel: {
        id: false,
        direction: false,
        extra: false,
        stepType: false,
        platingType: false,
        segments: false
    }
}

const columns = (articleRowsRes, onBomClick) => {
    return [
        {
            field: 'purchaseOrder.order.number',
            headerName: 'Order number',
            width: 120,
            renderCell: (params) => {
                const orderId = params.row.purchaseOrder.order.id
                return <Link to={`/orderDetail/${orderId}`}>{params.value}</Link>
            },
        },
        {
            field: 'purchaseOrder.order.prioritisation',
            headerName: 'Prioritisation',
            type: 'singleSelect',
            customFilterOperators: [
                {
                    label: 'is',
                    value: '=',
                    getApplyFilterFn: (filterItem) => {
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                            return null;
                        }

                        return (params) => {
                            return filterItem.value === params.value.level;
                        };
                    },
                    InputComponent: GridFilterInputSingleSelect
                },
            ],
            valueOptions: [
                {value: 0, label: 'Normal'},
                {value: 1, label: 'High'},
                {value: 2, label: 'Highest'},
            ],
            renderCell: (params) => {
                const value = params.value
                let sign = "";
                switch (value.level) {
                    case 0:
                        sign = <Dot/>;
                        break;
                    case 1:
                        sign = <Up/>;
                        break;
                    case 2:
                        sign = <DoubleUp/>;
                        break;
                    default:
                        break;
                }
                return <>{sign} {value.name}</>
            },
        },
        {
            field: 'artNo',
            headerName: 'Art no.',
            width: 120,
            renderCell: (params) => {
                const {id, stepType, innerRailingType} = params.row
                const hasWoodSteps = stepType === "oak30mm" || innerRailingType === "oak";
                const woodId = `wood-${id}`;
                return <>
                    <Link to={`/spiralStairDetail/${id}`}>{params.value}</Link>
                    {hasWoodSteps && <><UncontrolledTooltip target={woodId}>Wooden steps</UncontrolledTooltip><span
                        id={woodId} style={{textDecoration: "underline", fontSize: "5px"}}><Wood/></span></>}
                </>;
            },
        },
        {
            field: 'details',
            headerName: 'Details',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const {id, direction, description, segments, extra} = params.row

                return <div>
                    <div style={{textAlign: 'center', width: "100%"}}>
                        <Button style={{border: 0}} id={"openToolTip" + id} outline>show details</Button>
                    </div>
                    <UncontrolledTooltip
                        style={{
                            backgroundColor: "#fff", border: "1px solid #999", color: "#333",
                            opacity: 1, padding: "15px", textAlign: "left"
                        }}
                        placement="left"
                        target={"openToolTip" + id}
                        autohide={false}
                        flip={"true"}
                        trigger="hover">
                        <h6>Direction</h6>
                        <Paragraph style={{marginLeft: "7px"}}>
                            {direction}
                        </Paragraph>
                        {description && <>
                            <h6><br/>Comment</h6>
                            <Paragraph style={{marginLeft: "7px"}}>
                                {description}
                            </Paragraph>
                        </>}
                        {segments && <h6><br/>Radius</h6>}
                        {Radius(segments)}
                        {extra.length > 0 && <h6><br/>Extra</h6>}
                        {Extra(extra)}
                    </UncontrolledTooltip>
                </div>
            }
        },
        {
            field: 'surfaceTreatmentOverview',
            headerName: 'Surface treatment',
            valueGetter: (params) => ({
                surfaceTreatmentOverview: params.value,
                platingType: params.row.platingType
            }),
            renderCell: (params) => {
                return <SurfaceTreatmentTag spiralStair={params.value}/>
            },
            sortComparator: (v1, v2) => {
                const spiralStair1 = getSurfaceTreatmentType(v1)
                const spiralStair2 = getSurfaceTreatmentType(v2)
                return Object.keys(SurfaceTreatmentType).indexOf(spiralStair1) > Object.keys(SurfaceTreatmentType).indexOf(spiralStair2)
            },
            type: 'singleSelect',
            valueOptions: ['vfz', 'vfz_fe_zn', 'vfz_lack', 'pulverlack', 'obehandlad'],
            customFilterOperators: [
                {
                    label: 'is',
                    value: '=',
                    getApplyFilterFn: (filterItem) => {
                        if (!filterItem.field || !filterItem.value || !filterItem.operator) {
                            return null;
                        }
                        return (params) => {
                            const surfaceTreatmentValue = getSurfaceTreatmentType(params.value)
                            return filterItem.value === surfaceTreatmentValue;
                        };
                    },
                    InputComponent: GridFilterInputSingleSelect
                },
            ],
        },
        ColumnDate('purchaseOrder.order.preferredDeliveryDate', 'Preferred delivery date'),
        {
            field: 'simpleSurfaceTreatments',
            headerName: 'RAL colors',
            renderCell: (params) => {
                const value = params.value
                return <SimpleSurfaceTreatmentSubPanel value={value}/>
            },
        },
        {
            field: 'purchaseOrder.order.files',
            headerName: 'Drawings',
            filterable: false,
            sortable: false,
            width: 80,
            renderCell: (params) => {
                const value = params.value || []
                return <DrawingsOverlay files={value} stairId={params.id}/>
            }
        },
        {
            field: 'progress',
            headerName: 'Progress',
            type: 'number',
            renderCell: (params) => {
                const {id, artNo, progress} = params.row
                if (articleRowsRes.loading) {
                    return <Spinner size={"sm"} color={"warning"}/>
                } else if (articleRowsRes.data) {
                    const articleRow = _.find(articleRowsRes.data.articleRows, ar => ar.article &&
                        ar.article.artNo === artNo && ar.schedulerTasks && ar.schedulerTasks.length > 0);
                    if (!articleRow) {
                        return <div>
                            <Badge color="secondary">Not estimated yet.</Badge>
                        </div>
                    }
                    const total = <div>
                                        <span style={{whiteSpace: "nowrap"}}>
                                            {progress}%
                                            {articleRow.productionStatus && articleRow.productionStatus.estimatedFinishDate &&
                                                <><span id={"dateInfo" + id} style={{
                                                    fontSize: "8px",
                                                    marginLeft: "7px"
                                                }}>{articleRow.productionStatus.estimatedFinishDate}</span>
                                                    <UncontrolledTooltip
                                                        style={{
                                                            backgroundColor: "#fff",
                                                            border: "1px solid #999",
                                                            color: "#333",
                                                            padding: "9px",
                                                            textAlign: "left"
                                                        }}
                                                        placement="top"
                                                        target={"dateInfo" + id}
                                                        autohide={false}
                                                        flip={"true"}
                                                        trigger="hover"
                                                    >estimated finish date</UncontrolledTooltip></>
                                            }
                                        </span>
                        <Progress style={{"height": "3px"}} value={progress}/>
                    </div>;

                    return <div style={{width: '100%'}}>
                        {total}
                    </div>;
                } else {
                    return null;
                }
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            type: 'singleSelect',
            customFilterOperators: MultiSelectFilterOperators(Object.entries(SpiralStairStatus)
                    .map(([key, value]) => ({id: value, name: key})),
                "Status"),
            renderCell: (params) => {
                const value = params.value
                return <Badge color={getSpiralStairStatusColor(value)}>{value}</Badge>
            }
        },
        {
            field: 'bom',
            headerName: 'Bill of material',
            renderCell: (params) => {
                return <Button size="sm" color="primary"
                               onClick={() => onBomClick(params.id)}>
                    BoM
                </Button>
            }
        },
        {
            field: 'direction',
            headerName: 'Direction',
            type: 'singleSelect',
            valueOptions: ['left', 'right']
        },
        {
            field: 'segments',
            headerName: 'Radius',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return Radius(params.value)
            },
        },
        {
            field: 'extra',
            headerName: 'Extra',
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                return Extra(params.value)
            },
        },
        {
            field: 'stepType',
        },
        ColumnId(),
    ]
}

const articleRowsQuery = gql`
    query articleRows($ids:[ID!])
    {
        articleRows(articleIds: $ids)
        {
            id
            article
            {
                id
                artNo
            }
            order
            {
                id
                number
            }
            schedulerTasks
            {
                duration
                finishedAt
                startedAt
                id
            }
            productionStatus
            {
                estimatedFinishDate
            }
        }
    }
`

const sortStringKeys = (a, b) => String(a[0]).localeCompare(b[0]);

class Summary extends Component {
    constructor(props) {
        super();
        this.state = {
            data: props.data
        };
    }

    render() {
        if (typeof this.state.data === "undefined") {
            return null;
        }
        let steps = new Map();
        let pickets = new Map();
        this.state.data.forEach((stair) => {
            if (stair.status === SpiralStairStatus.Finished) {
                return;
            }
            if (typeof stair.stepType !== 'undefined' && stair.stepType && typeof steps.get(stair.stepType) === 'undefined') {
                steps.set(stair.stepType, new Map());
            }
            if (typeof stair.picketType !== 'undefined' && stair.picketType && typeof pickets.get(stair.picketType) === 'undefined') {
                pickets.set(stair.picketType, new Map());
            }
            stair.segments.forEach((segment) => {
                if (typeof stair.stepType !== 'undefined' && stair.stepType) {
                    if (typeof steps.get(stair.stepType).get(segment.radius) === 'undefined') {
                        steps.get(stair.stepType).set(segment.radius, 0);
                    }
                    steps.get(stair.stepType).set(segment.radius, steps.get(stair.stepType).get(segment.radius) + segment.stepHeights - 1);
                }
                if (typeof stair.picketType !== 'undefined' && stair.picketType) {
                    const ccMeasure = AnslAngle[segment.radius][segment.stepsPrRot].ccMeasure;
                    if (typeof pickets.get(stair.picketType).get(ccMeasure) === 'undefined') {
                        pickets.get(stair.picketType).set(ccMeasure, {"left": 0, "right": 0});
                    }

                    const newPicketsValueSet = pickets.get(stair.picketType).get(ccMeasure);
                    newPicketsValueSet[stair.direction] += segment.stepHeights - 1;
                    pickets.get(stair.picketType).set(ccMeasure, newPicketsValueSet);

                }
            });
        });

        steps = new Map([...steps].sort(sortStringKeys));
        pickets = new Map([...pickets].sort(sortStringKeys));
        if (steps.size === 0 && pickets.size === 0) {
            return (
                <div style={{marginBottom: '0.5em'}}><Collapser label={'Show summary for stairs in production'}>
                    <div style={{padding: '1em'}}>No steps and picket data</div>
                </Collapser></div>
            )
        }
        return (
            <div style={{marginBottom: '0.5em'}}>
                <Collapser label={'Show summary for stairs in production'}>
                    {steps.size > 0 &&
                        <div style={{display: 'inline-block', marginRight: "2em", verticalAlign: "top"}}>
                            <h2>
                                Summary only for stairs in <strong>PRODUCTION</strong>
                            </h2>
                            <table border="1">
                                <thead>
                                <tr>
                                    <th style={{padding: "1em"}}>Step type</th>
                                    <th style={{padding: "1em"}}>Radius</th>
                                    <th style={{padding: "1em"}}># Steps</th>
                                </tr>
                                </thead>
                                <tbody>
                                {[...steps].map(([key1, value1], idx1) => {
                                    value1 = new Map([...value1].sort((a, b) => {
                                        return a[0] - b[0];
                                    }));
                                    return [...value1].map(([key2, value2], idx2) => {
                                        return (
                                            <tr key={key2}>
                                                {idx2 === 0 ? <td rowSpan={value1.size} style={{
                                                    textAlign: 'center',
                                                    padding: "0.5em"
                                                }}>{key1}</td> : null}
                                                <td style={{textAlign: 'right', padding: "0.5em"}}>{key2}</td>
                                                <td style={{textAlign: 'right', padding: "0.5em"}}>{value2}</td>
                                            </tr>)
                                    });
                                })}
                                </tbody>
                            </table>
                        </div>}
                    {pickets.size > 0 &&
                        <div style={{display: 'inline-block', verticalAlign: "top"}}>
                            <table border="1">
                                <thead>
                                <tr>
                                    <th style={{padding: "1em"}}>Picket type</th>
                                    <th style={{padding: "1em"}}>CC measure</th>
                                    <th style={{padding: "1em"}}>#Left Pickets</th>
                                    <th style={{padding: "1em"}}>#Right Pickets</th>
                                </tr>
                                </thead>
                                <tbody>
                                {[...pickets].map(([key1, value1], idx1) => {
                                    value1 = new Map([...value1].sort((a, b) => {
                                        return a[0] - b[0];
                                    }));
                                    return [...value1].map(([key2, value2], idx2) => {
                                        return (
                                            <tr key={key2}>
                                                {idx2 === 0 ? <td rowSpan={value1.size} style={{
                                                    textAlign: 'center',
                                                    padding: "0.5em"
                                                }}>{key1}</td> : null}
                                                <td style={{textAlign: 'right', padding: "0.5em"}}>{key2}</td>
                                                <td style={{textAlign: 'right', padding: "0.5em"}}>{value2.left}</td>
                                                <td style={{textAlign: 'right', padding: "0.5em"}}>{value2.right}</td>
                                            </tr>)
                                    });
                                })}
                                </tbody>
                            </table>
                        </div>}

                </Collapser>
            </div>
        );
    }
}

const DrawingsOverlay = (props) => {
    let drawings = props.files.filter(file => file.fileCategory.name === 'Production');
    if (drawings.length === 0) {
        return null;
    }
    drawings = drawings.reverse();
    return <React.Fragment>
        <div style={{textAlign: "center"}}><span id={`drawings-${props.stairId}`} role="img" title="Show drawings"
                                                 aria-label="Show drawings">📐</span></div>
        <UncontrolledPopover
            placement="left"
            target={`drawings-${props.stairId}`}
            trigger="hover"
        >
            <PopoverBody>
                <table style={{borderCollapse: "collapse"}}>
                    <thead>
                    <tr>
                        <th>File</th>
                        <th>Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        drawings.map((drawing, i) => {
                            return <tr key={i} style={{border: "1px solid #000"}}>
                                <td className='link' onClick={async () => {
                                    await downloadFile(drawing.id, drawing.filename);
                                }}>{drawing.filename}</td>
                                <td align="right">{formatToSwedishTime(drawing.createdAt, FORMAT_DATE)}</td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </PopoverBody>
        </UncontrolledPopover>
    </React.Fragment>;
}

export default () => {
    const [bomModalData, setBomModalData] = useState()

    const [getStairs] = useSpiralStairsInProductionLazyQuery();
    const [getArticleRows, articleRowsRes] = useLazyQuery(articleRowsQuery);

    const [spiralStairsInProduction, setSpiralStairsInProduction] = useState(null);

    useEffect(() => {
        async function asyncEffect() {
            const stairsRes = await getStairs();
            setSpiralStairsInProduction(stairsRes.data.spiralStairsInProduction);

            if (stairsRes.data.spiralStairsInProduction) {
                const articleRowsRes = await getArticleRows({variables: {ids: _.map(stairsRes.data.spiralStairsInProduction, s => s.articleId)}});
                const sip = [...stairsRes.data.spiralStairsInProduction];
                for (let index in sip) {
                    const articleRow = articleRowsRes.data.articleRows.find(ar => ar.article && ar.order &&
                        ar.article.artNo === sip[index]?.artNo)
                    sip[index].articleRow = articleRow
                    if (!articleRow || !articleRow.schedulerTasks || articleRow.schedulerTasks.length === 0) {
                        sip[index].progress = -1;
                    } else {
                        const totalSum = sum(map(sip[index].articleRow?.schedulerTasks, t => t.duration));
                        const sumDone = sum(map(filter(sip[index].articleRow?.schedulerTasks, task => task.finishedAt), task => task.duration));
                        sip[index].progress = round((sumDone / totalSum) * 100);
                    }
                }
                setSpiralStairsInProduction(sip);
            }
        }

        asyncEffect().then();
    }, [getStairs, getArticleRows]);

    const onUpdateSpiralStairStatusSuccess = useCallback(async (response) => {
        try {
            const data = response.updateSpiralStairStatus

            const newSpiralStairs = [...spiralStairsInProduction]
            const foundSpiralStair = newSpiralStairs.find((e) => e.articleId === data.id)
            // foundSpiralStair.status = response.status

            if (foundSpiralStair)
            {
                foundSpiralStair.status = data.status
                foundSpiralStair.statusUpdatedBy = data.statusUpdatedBy
                foundSpiralStair.statusUpdatedAt = data.statusUpdatedAt
            }

            setSpiralStairsInProduction(newSpiralStairs)
        } catch (e) {
            NotificationPopup.error(`${e}`)
        }

    }, [spiralStairsInProduction, setSpiralStairsInProduction])

    const onBomClick = (id) => {
        const found = spiralStairsInProduction?.find((e) => e.id === id)
        setBomModalData(found)
    }

    return <div>
        {!spiralStairsInProduction && <Spinner color={"warning"}/>}
        {spiralStairsInProduction && <React.Fragment>
            <Summary data={spiralStairsInProduction}/>
            <NonPaginatedDataGrid
                definition={{
                    tableKey: TABLE_KEY,
                    columns: columns(articleRowsRes, onBomClick),
                    initState: {
                        sorting: defaultSort,
                        columns: visibilityModel
                    },
                    pageSize: 20,
                    buttons: buttons
                }}
                data={spiralStairsInProduction}/>
        </React.Fragment>}

        {
            bomModalData && <UpdateSpiralStairStatusModal spiralStair={bomModalData}
                                                          toggle={() => setBomModalData(null)}
                                                          onUpdateStatusSuccess={onUpdateSpiralStairStatusSuccess}
            />
        }

    </div>

}
