export const ConsigneeAccountList = [
    "NCC",
    "Dynawind",
    "BE Group",
    "Konstruktionssvets AB",
    "ETP Transmission AB",
    "Brålanda",
    "Vänerply AB",
    "Stora Enso",
    "Tornum",
    "Wallens Sport",
    "Lademetall",
    "Bröderna Edstrand",
    "Frimo",
    "Dalslands Gris",
    "Per Eriksson",
    "Fredrik",
    "Aros Industri Partner",
    "Bröderna E, Sundsvall",
    "Tveten",
    "Wenko"
];
