import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {Badge} from "reactstrap";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import React, {useMemo} from "react";
import ColumnId from "../common/data-grid/columns/ColumnId";

const TABLE_KEY = "TABLE_MEMBER_SHIP"
const GetUsers = gql`query
{
    users
    {
        id
        email
        displayName
        groupName
        tenantName
    }
}`

export function MembershipPage()
{
    const {data, loading} = useQuery(GetUsers);

    const columns = useMemo(() =>
    {
        return [
            {field: 'displayName', headerName: 'Name'},
            {field: 'email', headerName: 'E-mail'},
            {
                field: 'groupName', headerName: 'Group', width: 300,
                filterable: false,
                sortable: false,
                renderCell: (params) =>
                {
                    return <div>{params.value.map(group => (
                        <Badge style={{marginLeft: "7px"}} color="info">{group}</Badge>))}
                    </div>
                }
            },
            {
                field: 'tenantName', headerName: 'Tenant', width: 300,
                filterable: false,
                sortable: false,
                renderCell: (params) =>
                {
                    return <div>{params.value.map(tenant => (
                        <Badge style={{marginLeft: "7px"}} color="primary">{tenant}</Badge>))
                    }</div>
                }
            },
            ColumnId()
        ]
    }, [])

    if (loading) return <></>

    return <>
        <NonPaginatedDataGrid
            definition={{
                tableKey: {TABLE_KEY},
                columns: columns,
                pageSize: 20
            }}
            data={data.users}
        />
    </>
}