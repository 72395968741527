import React from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import gql from "graphql-tag";
import {useStdMutation} from "../../spiralStair/lib/Util";
import FormRenderer from "../../common/FormRenderer";

export default ({stockTransaction, toggle}) => {
    const [update] = useStdMutation("Update stock transaction",
        gql`mutation updateSt($id:ID!,$stockTransaction:StockTransactionUpdateInputType!)
        {
            updateStockTransaction(id:$id,stockTransaction: $stockTransaction)
            {
                id
                quantity
                transactedAt
            }
        }`, {refetchQueries: ["getInventoryArticle", "getInventoryPurchaseOrder"]});

    if (stockTransaction) {
        return <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Update stock transaction
            </ModalHeader>
            <ModalBody>
                <FormRenderer onSubmit={({object}) => {
                    toggle();
                    update({
                        variables: {
                            id: stockTransaction.id,
                            stockTransaction: object
                        }
                    })
                }} object={stockTransaction}
                              formDefinition={[{
                                  name: "quantity",
                                  type: "currency",
                                  required: true
                              }, {name: "transactedAt", type: "date", required: true}]}/>
            </ModalBody>
        </Modal>
    } else {
        return null;
    }
}
