import {Cell} from "react-table";
import {FORMAT_DATE, formatCurrency, formatToSwedishTime} from "../../UTIL";

export const dateCellRenderer = (formatString = FORMAT_DATE) =>
{
    return (cell: Cell) =>
    {
        const {value} = cell;
        return value ? formatToSwedishTime(new Date(value), formatString) : "";
    };
}

export const priceCellRenderer = (currencyKey = "currency") =>
{
    return (cell: any) =>
    {
        const {value, row} = cell;
        return `${formatCurrency(value)} ${row.original[currencyKey] || ""}`;
    };
}

export const transformSelectValue = ({value}) => value?.label ?? ''
export const mapSelectValue = ({value}) => value?.value
