import React from 'react';
import {Alert} from 'reactstrap';
import {withRouter} from "react-router";
import {
    QuoteTableDefaultSort,
    QuoteTableDefinitionV2,
} from "../../quote/QuoteListPage";
import NonPaginatedDataGrid from "../data-grid/NonPaginatedDataGrid";

const TABLE_KEY = "QUOTE_SUB_PANEL"

export default withRouter(({noCompanyName, noContactName, quotes}) => {
    if (!quotes) {
        return (<Alert color="danger">No quotes could be loaded..</Alert>);
    }

    const columnVisibility = {
        columnVisibilityModel: {
            id: false,
            remove: false
        }
    }

    if (noCompanyName) {
        columnVisibility.columnVisibilityModel['company.name'] = false;
    }
    if (noContactName) {
        columnVisibility.columnVisibilityModel['contact.name'] = false;
    }

    return (
        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: QuoteTableDefinitionV2(),
            initState: {
                sorting: QuoteTableDefaultSort,
                columns: columnVisibility
            }
        }} data={quotes}/>
    )
})
