import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {
    Axis,
    CreateBox,
    LinesMesh,
    Mesh,
    MeshBuilder,
    SubMesh,
    Tools,
} from "@babylonjs/core";
import * as earcut from "earcut"
import {ModuleObject, Node, ShapeObject} from "../ShpaeObject";
import ModuleTemplate, {childSafePole, supportLegConst} from "../ModuleTemplate";
import {MaterialName} from "../Materials";
import {getAngleBetweenVectorsByAxisY, instanceOf} from "../utils";
import {ScrewLeg, SupportLeg} from "./SupportLeg";
import {Scene} from "@babylonjs/core/scene";
import {ModuleRampType} from "../../generated/graphql";
import {nodeForRampEdge} from "../NodeFilterFunctions";

export class RampEdge extends ShapeObject{
    children: Node[] = [];
    line:LinesMesh | undefined;
    slopeDegree = 0;

    constructor(
        parent:ShapeObject,
        origin:Vector3,
        vector:Vector3,
        slopeDegree =0,
        scene,
    )
    {
        super(parent, origin);
        this.vector = vector;
        this.slopeDegree = slopeDegree;
        // this.line = CreateLines(`edge-${this.id}`,{points:[this.origin, this.origin.add(this.vector)]}, scene);
        // this.meshes.push(this.line);

        // create eventBox
        /*this.eventBox = CreateBox(`eventTrigger-${this.id}`,{width:vector.length(), height:0.5, depth:0.5});
        this.setupEventBox();
        this.eventBox.translate(vector, 0.5);
        const normal = Vector3.Cross(Vector3.Right(), vector).normalize();
        const angle = Vector3.GetAngleBetweenVectors(Vector3.Right(), vector, normal);
        this.eventBox.rotate(Vector3.Backward(), angle);*/

        // create nodes
        const {poleSpacing} = childSafePole;
        const nodeAmount = Math.round(vector.length() / poleSpacing);

        // step length is half-length between two nodes, 1 step to first node from start
        // | - o - - o - - o - |
        const halfScaler = new Vector3(nodeAmount*2, nodeAmount*2, nodeAmount*2);
        const stepVector = vector.divide(halfScaler);

        let pointer = origin.clone();
        for(let i=0; i<nodeAmount; i++){
            pointer = pointer.add(stepVector);
            const newNode = new Node(this, pointer.clone(), nodeForRampEdge, scene);
            // newNode.nodeAttachableTypes = [NodeAttachableType.ramp, NodeAttachableType.pole];
            this.children.push(newNode);
            this.nodes.push(newNode);
            pointer = pointer.add(stepVector);
        }
    }

    // rotate(axis:Vector3, degree:number, origin:Vector3 = this.origin) {
    //     super.rotate(axis, degree, origin);
    //
    //     // edge with slope degree can't attach ramp
    //     if(Vector3.Dot(this.vector, Axis.Y)!==0){
    //         this.children.forEach((node)=>{
    //             node.nodeAttachableTypes = [NodeAttachableType.pole];
    //         })
    //     } else {
    //         this.children.forEach((node)=>{
    //             node.nodeAttachableTypes = [NodeAttachableType.ramp, NodeAttachableType.pole];
    //         })
    //     }
    // }
    enableEdgesRendering(){}
    disableEdgesRendering(){}
    dispose(){this.line?.dispose()}
}

export class Ramp extends ModuleObject
{
    type:ModuleRampType = ModuleRampType.Ramp;
    rotateAxis:Vector3 = Axis.X.clone(); //All modules attached to this ramp should rotate with this axis (poles, railings...)

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    _pickMaterial(){
        const {slopeDegree} = this.moduleTemplate;
        if(slopeDegree!==null && slopeDegree!==0){
            this.material = this.scene.materialStore[MaterialName.rampUpMaterial];
        }else{
            this.material = this.scene.materialStore[MaterialName.rampMaterial];
        }
    }

    build(){
        this.moduleTemplate.slopeDegree = Math.abs(this.moduleTemplate.slopeDegree);
        const {width, height, length, slopeDegree, origin} = this.moduleTemplate;
        this.origin = origin;
        // orign is default top right
        const edges:RampEdge[] = []; // [right, bottom, left, top] (clockwise)

        const topRight = this.origin.clone();
        const bottomRight = topRight.add(new Vector3(0,0,length));
        const bottomLeft = bottomRight.add(new Vector3(width,0,0));
        const topLeft = bottomLeft.add(new Vector3(0,0,-length));

        edges[0] = new RampEdge(this, topRight, new Vector3(0,0,length), slopeDegree, this.scene); // ↓
        edges[1] = new RampEdge(this, bottomRight, new Vector3(width,0,0), 0, this.scene); // ←
        edges[2] = new RampEdge(this, bottomLeft, new Vector3(0,0,-length), -slopeDegree, this.scene); // ↑
        edges[3] = new RampEdge(this, topLeft, new Vector3(-width,0,0), 0, this.scene); // →
        edges.forEach((edge)=>{
            this.children.push(edge);
            this.nodes = this.nodes.concat(edge.nodes);
        });

        const nodes:Node[] = [];
        // support leg nodes
        nodes[0] = new Node(this, topRight.add(new Vector3(supportLegConst.margin, 0, supportLegConst.margin)), this._supportLegNodeTest, this.scene);
        nodes[1] = new Node(this, bottomRight.add(new Vector3(supportLegConst.margin, 0, -supportLegConst.margin)), this._supportLegNodeTest, this.scene);
        nodes[2] = new Node(this, bottomLeft.add(new Vector3(-supportLegConst.margin, 0, -supportLegConst.margin)), this._supportLegNodeTest, this.scene);
        nodes[3] = new Node(this, topLeft.add(new Vector3(-supportLegConst.margin, 0, supportLegConst.margin)), this._supportLegNodeTest, this.scene);
        nodes.forEach((node)=>{
            this.children.push(node);
            this.nodes.push(node);
        });

        const box = CreateBox(`mesh-ramp-${this.id}`,{width:width, height:height, depth:length}, this.scene);
        const offset = new Vector3(width, height, length);
        box.translate(offset, 0.5);
        box.translate(this.origin, 1);
        this.meshes.push(box);
        // const m = new StandardMaterial("metalMaterial", this.scene);
        // m.diffuseColor = new Color3(1, 0.5, 1);
        // box.material = m;

        if(slopeDegree!==null && slopeDegree!==0) {
            this.rotate(Axis.X, slopeDegree);
        }
        this._pickMaterial();

        box.subMeshes = [];
        const verticesCount = box.getTotalVertices();
        new SubMesh(1, 0, verticesCount, 0, 6, box);
        new SubMesh(1, 0, verticesCount, 6, 6, box);
        new SubMesh(1, 0, verticesCount, 12, 6, box);
        new SubMesh(1, 0, verticesCount, 18, 6, box);
        new SubMesh(0, 0, verticesCount, 24, 6, box);
        new SubMesh(0, 0, verticesCount, 30, 6, box);
    }

    _supportLegNodeTest(node:Node){
        if(node.attach.length===0){
            return instanceOf(node.ancestor, [SupportLeg, ScrewLeg]);
        }else{
            return false;
        }
    }
/*
    disableEdgesRendering(){
        this.meshes.forEach((mesh)=>{
            mesh.disableEdgesRendering();
            this._pickMaterial(mesh);
        })

        this.children.forEach((module)=>{
            module.disableEdgesRendering();
        })
    }*/

    attachTo(attachNode, fromNode){
        if(attachNode.parent instanceof RampEdge && fromNode.parent instanceof RampEdge){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector.negate(), attachNode.parent.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    enableEdgesRendering(options){
        super.enableEdgesRendering(options);

        if(this.moduleTemplate.slopeDegree===0){
            this.meshes.forEach((mesh)=>{
                mesh.material = this.scene.materialStore[MaterialName.transMaterial];
            });
        }else{
            this.meshes.forEach((mesh)=>{
                mesh.material = this.scene.materialStore[MaterialName.rampUpMaterialForView];
            });
        }

    }

    clone(scene:Scene){
        const cloned = new Ramp(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}

export class TrianglePlatform extends ModuleObject {
    type:ModuleRampType = ModuleRampType.Ramp;
    rotateAxis:Vector3 = Axis.X.clone(); //All modules attached to this ramp should rotate with this axis (poles, railings...)

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {height, width, length, origin, otherProps} = this.moduleTemplate;
        const {angle} = otherProps;
        this.origin = origin;

        const angleRadius = Tools.ToRadians(angle);
        const triangle = [
            new Vector3(0, 0, 0),
            new Vector3(0, 0, width),
            new Vector3(width*Math.sin(angleRadius), 0, width*Math.cos(angleRadius)),
        ];

        const edge1 = new RampEdge(this, triangle[0].add(this.origin), triangle[1].subtract(triangle[0]), 0, this.scene);
        const edge2 = new RampEdge(this, triangle[1].add(this.origin), triangle[2].subtract(triangle[1]), 0, this.scene);
        const edge3 = new RampEdge(this, triangle[2].add(this.origin), triangle[0].subtract(triangle[2]), 0, this.scene);
        this.children.push(edge1, edge2, edge3);
        this.nodes = this.nodes.concat(edge1.nodes, edge2.nodes, edge3.nodes);

        const box = MeshBuilder.ExtrudePolygon("mesh-triangle-platform", {shape:triangle, depth:height, sideOrientation: Mesh.DOUBLESIDE }, this.scene, earcut);


        box.translate(this.origin, 1);
        box.translate(new Vector3(0, height, 0), 1);
        // box.rotate(Axis.Y, Tools.ToRadians(90));

        if(length>600){
            this.material = this.scene.materialStore[MaterialName.rampMaterial];
        }else{
            this.material = this.scene.materialStore[MaterialName.rampMaterial_small];
        }

        this.meshes.push(box);

        box.subMeshes = [];
        const verticesCount = box.getTotalVertices();
        new SubMesh(0, 0, verticesCount, 0, 6, box);
        new SubMesh(1, 0, verticesCount, 30, 18, box);

    }

    enableEdgesRendering(options){
        super.enableEdgesRendering(options);

        this.meshes.forEach((mesh)=>{
            mesh.material = this.scene.materialStore[MaterialName.transMaterial];
        });
    }

    attachTo(attachNode, fromNode){
        if(attachNode.parent instanceof RampEdge && fromNode.parent instanceof RampEdge){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector.negate(), attachNode.parent.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    clone(scene:Scene){
        const cloned = new TrianglePlatform(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}


export class StartingRamp extends ModuleObject {
    type:ModuleRampType = ModuleRampType.Ramp;
    rotateAxis:Vector3 = Axis.X.clone(); //All modules attached to this ramp should rotate with this axis (poles, railings...)

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }
    build(){
        const {height, width, length, origin} = this.moduleTemplate;
        this.origin = origin;
        const edge = new RampEdge(this, this.origin.clone().add(new Vector3(width,0,0)), new Vector3(-width,0,0), 0, this.scene);
        this.children.push(edge);
        this.nodes = this.nodes.concat(edge.nodes);

        //Shape profile in XY plane
        const myShape = [
            new Vector3(0, 0, 0),
            new Vector3(0, height, 0),
            new Vector3(length, 0, 0),

        ];

        const myPath = [
            new Vector3(0, 0, width),
            new Vector3(0, 0, 0),
        ];

        const box = MeshBuilder.ExtrudeShape(`mesh-startramp-${this.id}`, {shape: myShape, closeShape:true, path: myPath, cap: Mesh.CAP_ALL, sideOrientation: Mesh.DOUBLESIDE}, this.scene);

        box.translate(this.origin, 1);
        box.rotate(Axis.Y, Tools.ToRadians(90));

        this.material = this.scene.materialStore[MaterialName.rampUpMaterial];
        this.meshes.push(box);

        box.subMeshes = [];
        const verticesCount = box.getTotalVertices();
        new SubMesh(0, 0, verticesCount, 42, 6, box);
        new SubMesh(1, 0, verticesCount, 90, 42, box);
        new SubMesh(0, 0, verticesCount, 132, 6, box);
        new SubMesh(1, 0, verticesCount, 144, 36, box);

    }

    enableEdgesRendering(options){
        super.enableEdgesRendering(options);

        this.meshes.forEach((mesh)=>{
            mesh.material = this.scene.materialStore[MaterialName.rampUpMaterialForView];
        });
    }

    attachTo(attachNode, fromNode){
        if(attachNode.parent instanceof RampEdge && fromNode.parent instanceof RampEdge){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector.negate(), attachNode.parent.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    clone(scene:Scene){
        const cloned = new StartingRamp(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}

export class BetweenRamp extends ModuleObject {

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.type = ModuleRampType.BetweenRamp;
        this.init();
    }

    // This Module doesn't display in viewport. Only calculate when updateCSV_Data()
    build(){}
}
