import {SpiralStairStatus} from "../generated/graphql";


export const getSpiralStairStatusColor = (status: SpiralStairStatus)=>{
    switch (status){
        case SpiralStairStatus.Finished:
            return "success"
        case SpiralStairStatus.Pending:
            return "warning"
        case SpiralStairStatus.Prepackaged:
            return "secondary"
    }
}