import React, {Component} from 'react';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import {Spinner} from "reactstrap";
import logger from "../Logger";
import Auth from "../Auth";

class StdQuery extends Component
{
    render()
    {
        const {query,variables,children,noLoadingRender} = this.props;
        return <Query fetchPolicy={this.props.fetchPolicy || "network-only"} query={query} variables={variables||{}}>
            {({data,loading,error,refetch})=>
            {
                if(loading)
                {
                    if(noLoadingRender)
                    {
                        return null;
                    }
                    else
                    {
                        return <Spinner color="warning"/>
                    }

                }
                else if(error)
                {
                    if(error.networkError && error.networkError.statusCode !== 401)
                    {
                        logger.error(`STDQuery errored ${error.message}`,{graphqlErrors:error.graphQLErrors,user:Auth.getUsername(),msg:error.message,stack:error.stack});
                    }
                    return <p style={{color:"red"}}>Error..{error.message}</p>;
                }
                else {
                    return children(data,refetch);
                }
            }}
        </Query>
    }
}

StdQuery.propTypes = {
    query:PropTypes.object.isRequired,
    variables:PropTypes.object,
    children:PropTypes.any.isRequired,
    noLoadingRender:PropTypes.bool,
    fetchPolicy:PropTypes.string
};

export default StdQuery;
