import React, {Component} from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {flowRight as compose} from 'lodash';
import GetProjectQuery from './graphql/GetProject.graphql';
import QuotesSubPanel from '../common/subpanels/QuotesSubPanel';
import CreateUpdateInfo from "../common/CreateUpdateInfo";
import Collapser from "../common/Collapser";
import CreateQuote from "../common/CreateQuote";
import OrderSubPanel from "../order/OrderSubPanel";

class ProjectDetailPage extends Component
{

    async removeProject(project)
    {
        if(window.confirm(`Are you sure you want to remove ${project.name}?`))
        {
            await this.props.mutate({id:project.id});
            this.props.history.push("/projects");
        }
    }

    render()
    {
        const data = this.props.data;
        if(data.loading)
        {
            return (<p>Loading..</p>);
        }
        else if(data.error)
        {
            return (<p>Error!</p>);
        }
        else if(data.project == null)
        {
            return (<p>Not found :(</p>);
        }
        const project = data.project;
        return (
            <div>
                <Row>
                    <Col sm={5}>
                        <h1>{project.name}</h1>
                        <hr/>
                    </Col>
                    <Col sm={2}>
                        <Link to={`/projectEdit/${project.id}`}><Button color="secondary">Edit</Button></Link>
                        &nbsp;
                        <Button color='secondary' onClick={this.removeProject.bind(this,project)}>Remove</Button>
                    </Col>
                </Row>
                <Row>
                    <Col sm={3}>
                        <Label>Name</Label>
                        <p>{project.name}</p>
                        <Label>Description</Label>
                        <p>{project.description}</p>
                    </Col>
                    <Col sm={3}>
                        <Collapser>
                            <CreateUpdateInfo object={project}/>
                        </Collapser>

                    </Col>
                </Row>
                <hr/>
                <Collapser button label="Show quotes" open>
                    <Row>
                        <Col sm={2}>
                            <h2>Quotes</h2>
                        </Col>
                        <Col sm={2}>
                            <CreateQuote project={project} refetchQuery={"getProject"} />
                        </Col>
                    </Row>
                    <QuotesSubPanel quotes={project.quotes}/>
                </Collapser>
                <hr/>

                <Collapser button label="Show Orders" open>
                    <h2>Orders</h2>
                    <OrderSubPanel orders={project.orders} hideProject />
                </Collapser>
                <hr/>
                {/*<Collapser label={"Show Configurator quotes"} button open >*/}
                {/*    <h2>Configurator quotes</h2>*/}
                {/*    <OldQuotesSubPanel noProject quotes={project.oldQuotes} />*/}
                {/*</Collapser>*/}

            </div>);
    }


}

const removeProjectMutation = gql`
    mutation removeProject($id:ID!)
    {
        removeProject(id:$id)
    }
`;

export default compose(graphql(GetProjectQuery,{
    options:(props) =>
    {
        return {variables:{id:parseInt(props.match.params.id)}};
    }
}),graphql(removeProjectMutation,{
    options:(props) =>
    {
        return {variables:{id:parseInt(props.match.params.id)}};
    }
}))(withRouter(ProjectDetailPage));
