import React, {Component} from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import FormRenderer from "./FormRenderer";
import {graphql,withApollo} from '@apollo/client/react/hoc'
import logger from "../Logger";
import UpdateArticleRowMutation from './graphql/UpdateArticleRow.graphql';

class UpdateArticleRow extends Component
{
    constructor(props)
    {
        super(props);

        this.save = this.save.bind(this);

    }

    getFormDefinition = () => {
        return [
            {
                name: "name",
                required: true,
            },
            {
                name: "quantity",
                type: "number",
                step: "any"
            },
            {
                name: "unit",
            },
            {
                name: "price",
                type: "currency",
            },
            {
                name: "discount",
                type: "number",
                min: 0,
                max: 100,
            }
        ];
    }

    async save(form)
    {
        try
        {
            await this.props.updateArticleRow({awaitRefetchQueries:true,
                //shouldnt need to refetch for quote,order or po here.
                refetchQueries:["getStockArticles"],
                variables: {id: this.props.articleRow.id, articleRow: form.object}
            });
            this.props.toggle();
        }
        catch(e)
        {
            console.log(e);
            logger.error(`Something went wrong when trying to update article`,{msg:e.message,stack:e.stack});
            throw e;
        }

    }

    render()
    {
        if(!this.props.articleRow)
        {
            return null;
        }
        const formDefinition = this.getFormDefinition();
        return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}>
            <ModalHeader toggle={this.props.toggle}>Update Article Row</ModalHeader>
            <ModalBody>
                <FormRenderer object={this.props.articleRow} onSubmit={this.save} formDefinition={formDefinition}/>
            </ModalBody>
        </Modal>
    }
}
export default graphql(UpdateArticleRowMutation, {name: "updateArticleRow"})(withApollo(UpdateArticleRow));
