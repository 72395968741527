import Message from "../../icons/Message";
import {UncontrolledTooltip} from "reactstrap";
import React from "react";

const ColumnComments = () =>
{
    return {
        field: 'comments',
        headerName: 'Comments',
        filterable: false,
        sortable: false,
        width: 50,
        renderCell: (params) =>
        {
            const value = params.value
            if (value.length > 0)
            {
                return <>
                    <span id={"commentsPreview" + params.id}
                          style={{fontSize: "8px", marginLeft: "7px"}}><Message/> {value.length}
                    </span>
                    <UncontrolledTooltip
                        style={{textAlign: "left"}}
                        placement="top"
                        target={"commentsPreview" + params.id}
                        flip={true}
                        trigger="hover">
                        <span style={{fontWeight: "bold"}}>Latest comment:</span>
                        <br/>
                        {value[0].content}
                        {value.length > 1 && <><br/><br/>
                            <span style={{fontWeight: "bold"}}>2nd latest comment:</span>
                            <br/>
                            {value[1].content}
                        </>}
                    </UncontrolledTooltip>
                </>;
            }

            return null;
        }
    }
}

export default ColumnComments