import {useGridApiContext} from "@mui/x-data-grid-pro";
import {gridPageCountSelector, GridPagination, useGridSelector} from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";
import React from "react";

const Pagination = ({page, onPageChange, count, rowsPerPage, className}) =>
{
    const apiRef = useGridApiContext();
    const pageCountSelector = useGridSelector(apiRef, gridPageCountSelector)
    const pageCount = count ? Math.ceil(count / rowsPerPage)
        : pageCountSelector

    return (
        <MuiPagination
            color="primary"
            size="small"
            showFirstButton
            showLastButton
            count={pageCount}
            className={className}
            siblingCount={2}
            page={page + 1}
            onChange={(event, newPage) =>
            {
                onPageChange(event, newPage - 1);
            }}
        />
    );
}


const CustomDataGridPagination = (props) =>
{
    return <GridPagination ActionsComponent={Pagination} {...props} />;
}

export default CustomDataGridPagination