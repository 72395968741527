import {ShipmentStatus} from "../../generated/graphql";


export namespace ShipmentStatusExt
{
    export const color = (status: ShipmentStatus): string =>
    {
        switch (status)
        {
            case ShipmentStatus.Booked:
                return "#17a2b8"
            case ShipmentStatus.Saved:
                return "#28a745"
            case ShipmentStatus.Failed:
                return "#dc3545"
            case ShipmentStatus.NotBooked:
                return "#007bff"
            case ShipmentStatus.CustomerPickup:
                return "#6c757d"
        }
    }

    export const label = (status: ShipmentStatus): string =>
    {
        switch (status)
        {
            case ShipmentStatus.Booked:
                return "Booked"
            case ShipmentStatus.Saved:
                return "Saved"
            case ShipmentStatus.Failed:
                return "Failed"
            case ShipmentStatus.NotBooked:
                return "Not booked"
            case ShipmentStatus.CustomerPickup:
                return "Customer pickup"
        }
    }
}