import {PriceSuggestionFragmentFragment, useGetStraightStairV2PriceLazyQuery} from "../../generated/graphql";
import {useCallback, useEffect, useState} from "react";
import {ArticleIdProps} from "../StraightStairV2Types";

const useStraightStairPriceHook = (props: ArticleIdProps) =>
{
    const {articleRowId, articleId} = props;
    const [priceSuggestion, setPriceSuggestion] = useState<PriceSuggestionFragmentFragment | null>(null)

    const [getPriceQuery, {error: getPriceError}] = useGetStraightStairV2PriceLazyQuery({
        variables: {
            articleId: articleId,
            articleRowId: articleRowId
        }
    })

    const getPrice = useCallback(async () =>
    {
        try
        {
            const response = await getPriceQuery()
            const priceSuggestion = response.data?.straightStairV2?.priceSuggestion ?? null
            setPriceSuggestion(priceSuggestion)
            return priceSuggestion
        } catch (e: any)
        {
            setPriceSuggestion(null)
        }
    }, [getPriceQuery])

    useEffect(() =>
    {
        getPrice()
    }, [getPrice]);

    return {priceSuggestion, setPriceSuggestion, getPrice, getPriceError}
}

export default useStraightStairPriceHook;