import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Input, Table, UncontrolledTooltip} from "reactstrap";
import {debounce, find} from 'lodash';
import Select from 'react-select';
import {useApolloClient} from "@apollo/client";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";
import {withRouter} from "react-router";
import QuestionMark from "../icons/QuestionMark";


export const SelectFilter = (options) =>
    ({column: {filterValue, setFilter}}) => {
        return <Select isClearable={true} options={options} value={find(options, ({value}) => value === filterValue)}
                       onChange={(val) => {
                           setFilter(val ? val.value : null);
                       }}/>
    }

function DefaultColumnFilter({
                                 column: {filterValue, setFilter},
                             }) {
    return (
        <Input
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined);
            }}
            placeholder={`Filter me`}
        />
    )
}

export const TableComponent = (props) => {
    const {
        columns,
        defaultFilters,
        defaultSort,
        data,
        fetchData,
        loading,
        pageCount: controlledPageCount,
        history
    } = props

    const defaultColumn = useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
            disableFilters: true,
            disableSortBy: true,
        }), []);
    const [bPageIndex, setPageIndex] = useState(0);
    const [bSortBy, setSortBy] = useState(defaultSort || []);
    const [bFilters, setFilters] = useState(defaultFilters || []);
    const urlParams = new URLSearchParams(window.location.search);
    let paramSortBy = defaultSort || [];
    if (urlParams.has('sortBy')) {
        paramSortBy = JSON.parse(urlParams.get('sortBy'));
    }
    let paramFilters = defaultFilters || [];
    if (urlParams.has('filters')) {
        paramFilters = JSON.parse(urlParams.get('filters'));
    }
    const paramPageIndex = urlParams.has('pageIndex') ? parseInt(urlParams.get('pageIndex')) : 0;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: {pageIndex, pageSize, sortBy, filters},
    } = useTable(
        {
            defaultColumn,
            columns,
            data,
            initialState: {pageIndex: paramPageIndex, filters: paramFilters, sortBy: paramSortBy}, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            pageCount: controlledPageCount,
            manualSortBy: true,
            disableMultiSort: true,
            manualFilters: true,
            useControlledState: (state) => {
                let found = false;
                if (JSON.stringify(bSortBy) !== JSON.stringify(state.sortBy)) {
                    if (state.sortBy.length > 0 && state.sortBy[0].id) {
                        urlParams.set('sortBy', JSON.stringify(state.sortBy));
                    } else {
                        urlParams.delete('sortBy');
                    }
                    setSortBy(state.sortBy);
                    found = true;
                }
                if (JSON.stringify(bFilters) !== JSON.stringify(state.filters)) {
                    if (state.filters.length > 0 && state.filters[0].id) {
                        urlParams.set('filters', JSON.stringify(state.filters));
                    } else {
                        urlParams.delete('filters');
                    }
                    setFilters(state.filters);
                    found = true;
                }
                if (bPageIndex !== state.pageIndex) {
                    urlParams.set('pageIndex', state.pageIndex);
                    setPageIndex(state.pageIndex);
                    found = true;
                }
                if (found) {
                    history?.push({
                        pathname: window.location.pathname,
                        search: `?${urlParams.toString()}`
                    })
                }
                return state;
            }

        },
        useFilters,
        useSortBy,
        usePagination,
    )
    
    // eslint-disable-next-line
    const debounced = useCallback(debounce((pageIndex, pageSize, sortBy, filters) => {
        fetchData({pageIndex, pageSize, sortBy, filters})
    }, 300), [fetchData]);

    useEffect(() => {
        if (data.length === 0) {
            //if no data, fetch immedietly. Debounce only on user input.
            debounced(pageIndex, pageSize, sortBy, filters);
            debounced.flush();
        } else {
            debounced(pageIndex, pageSize, sortBy, filters);
        }

        //eslint recommends adding data.length here, but we only want to check data.length for the first run,
        // which is run regardless of dependencies.
        // eslint-disable-next-line
    }, [debounced, fetchData, pageIndex, pageSize, sortBy, filters])

    const listener = useCallback(() => {
        fetchData({pageIndex, pageSize, sortBy, filters})
    }, [fetchData, pageIndex, pageSize, sortBy, filters]);
    useEffect(() => {
        document.addEventListener("refetch-data", listener);
        return () => document.removeEventListener("refetch-data", listener)
    }, [listener]);
    // Render the UI for your table
    return (
        <>
            {/*<pre>*/}
            {/*  <code>*/}
            {/*    {JSON.stringify(*/}
            {/*    {*/}
            {/*        filters*/}
            {/*    },*/}
            {/*    null,*/}
            {/*    2*/}
            {/*    )}*/}
            {/*  </code>*/}
            {/*</pre>*/}
            <Table bordered striped {...getTableProps()} responsive>
                <thead>
                {headerGroups.map((headerGroup, index) => (
                    <React.Fragment key={index}>
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column,index) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}

                                    {column.Info &&
                                        <span id={`uncontrolledTooltip${index}`}>
                                            <QuestionMark/>
                                            <UncontrolledTooltip target={`uncontrolledTooltip${index}`}
                                                                 placement="bottom">
                                                {column.Info}
                                            </UncontrolledTooltip>
                                        </span>
                                    }

                                    <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' ↓'
                                                    : ' ↑'
                                                : ''}
                                        </span>
                                </th>
                            ))}
                        </tr>
                        <tr {...{
                            ...headerGroup.getHeaderGroupProps(),
                            key: `${headerGroup.getHeaderGroupProps().key}-filter`
                        }}>
                            {headerGroup.headers.map(column =>
                                <th key={`${column.Header}`}>
                                    <div>
                                        {column.canFilter ? column.render("Filter") : null}
                                    </div>
                                </th>)}
                        </tr>
                    </React.Fragment>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                    )
                })}
                <tr>
                    {loading ? (
                        // Use our custom loading state to show a loading indicator
                        <td colSpan="10000">Loading...</td>
                    ) : (
                        <td colSpan="10000">
                            Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
                            results
                        </td>
                    )}
                </tr>
                </tbody>
            </Table>
            <div className="pagination">
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>
                {' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>
                {' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>
                {' '}
                <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>
                {' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    | Go to page:{' '}
                    <input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e.target.value ? Number(e.target.value) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{width: '100px'}}
                    />
                </span>{' '}
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value))
                    }}
                >
                    {[5, 10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    )
}


const PaginatedTable =  (props) => {
    const {query, columns, variables, defaultFilters, defaultSort = []} = props
    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [pageCount, setPageCount] = React.useState(0)
    const [error, setError] = React.useState(null);
    const fetchIdRef = React.useRef(0)
    const client = useApolloClient();
    const fetchData = React.useCallback(async ({pageSize, pageIndex, sortBy, filters}) => {
        const fetchId = ++fetchIdRef.current;
        setLoading(true)
        try {
            const {data} = await client.query({
                query, fetchPolicy: "network-only", variables: {
                    ...variables,
                    pagination: {
                        pageIndex,
                        pageSize
                    },
                    ordering: {
                        ...(sortBy ? sortBy[0] : {})
                    },
                    filtering: filters.map(filter => {
                        return {
                            key: filter.id,
                            value: typeof filter.value === 'number' ? filter.value.toString() : filter.value,
                            comparator: typeof filter.value === 'number' ? "EQUALS" : "LIKE"
                        };
                    })
                }
            });
            if (fetchId === fetchIdRef.current) {
                setData(data.result.list)
                setPageCount(Math.ceil(data.result.total / pageSize))
                setLoading(false);
            }
        } catch (e) {
            console.error(`we catched an error ${e.message}`);
            setError(e);
        }

    }, [client, query, variables])

    if (error) {
        return <p style={{color: "red"}}>Encountered an error: ${error.message}</p>
    }
    return <TableComponent {...props} defaultSort={defaultSort} defaultFilters={defaultFilters} data={data} loading={loading}
                           columns={columns}
                           pageCount={pageCount} fetchData={fetchData} />
}

export default withRouter(PaginatedTable)
