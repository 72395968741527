import React, {useState} from 'react';
import DetailRenderer from "./DetailRenderer";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Col, Row, Table} from "reactstrap";
import _ from "lodash";
import FormRenderer from "./FormRenderer";
import CreateTaskMutation from '../new-task/graphql/CreateNewTask.graphql';
import {useMutation} from '@apollo/client';
import { camundaDateFix } from "../UTIL";

const editForm = (task, update, setEditMode) => {
    return <React.Fragment>
        {task.order && <div>Order {task.order.number}</div>}
        {task.quote && <div>Quote {task.quote.number}</div>}
        {task.company && <div>Company {task.company.name}</div>}
        <FormRenderer customSubmit={<Row className="justify-content-between">
            <Col sm={2}>
                <Button color="secondary" onClick={() => setEditMode(false)} >Cancel</Button>
            </Col>
            <Col sm={2}>
                <Button color="success" type="submit">Save</Button>
            </Col>
        </Row>} object={task} formDefinition={[
            { name: "name", required: true },
            { name: "description", type: "textarea" },
            { name: "due", type: "datetime", disablePast: true }
        ]}
            onSubmit={({ object }) => update(object)} />

    </React.Fragment>
};

const History = ({history})=>
{
    if(history && history.length > 0)
    {
        return <div>
            <h5>Follow-up history</h5>
            <Table bordered hover striped size="sm">
                <thead>
                <tr>
                    <td>
                        Subject
                    </td>
                    <td>
                        Description
                    </td>
                    <td>
                        Finished at
                    </td>
                </tr>
                </thead>
                <tbody>
                {history.map(({finishedAt,description,subject},index)=><tr key={index}>
                    {[subject,description,new Date(finishedAt).toLocaleString()].map(v=><td>{v}</td>)}
                </tr>)}
                </tbody>

            </Table>
        </div>
    }
    else {
        return null;
    }
};

const completionForm = (task,setCompletionMode,onSubmit,createTask)=>
{
    const hideField = model=>model.noWorkFlow;
    return <div>
        <FormRenderer customSubmit={<Row className="justify-content-between">
            <Col sm={2}>
                <Button color="secondary" onClick={()=>setCompletionMode(false)} >Cancel</Button>
            </Col>
            <Col sm={2}>
                <Button color="success" type="submit">Complete</Button>
            </Col>
        </Row>} formDefinition={[
            {name:"noWorkFlow",label:"I do not wish to follow-up this task.",type:"checkbox"},
            {name: "subject",type:"text",hideField},
            {name: "description",type:"textarea",hideField},
            { name: "due", label: "Due Date", type: "datetime", defaultValue: new Date(),hideField }
        ]}
          onSubmit={async ({object}) => {
              if(!object.noWorkFlow) {
                  await createTask({
                      variables: {
                          task:
                          {
                              name: object.subject || "Follow-up",
                              description: object.description,
                              due: camundaDateFix(object.due),
                              variables:
                              {
                                  ...task.variables,
                                  history:
                                  {
                                      type:"json",
                                      value:JSON.stringify((task.variables.history || []).concat(
                                      [{subject:task.name,finishedAt:new Date(),description:object.description}]))
                                  },
                              }
                          }
                      }
                  });
              }
              onSubmit({object: {}});
          }}/>
    </div>
};

const detailMode = (task,onSubmit,setEditMode,toggle,setCompletionMode) =>
{
    let details = _.pick(task, ['due', 'description', 'createdAt']);
    let contact;
    let company;
    let project;
    if(task.quote)
    {
        company = task.quote.company;
        project = task.quote.project;
        contact = task.quote.contact;
    }
    if(task.order)
    {
        company = task.order.company;
        project = task.order.project;
        contact = task.order.contact;
    }
    if(task.company) {
        company = task.company;
    }
    if(task.quote || task.order)
    {
        details = {company,project,due:details.due,contact,description:details.description};
    }
    return <div>
        <History history={task.variables.history} />
        <DetailRenderer columns={3} object={details} hideUpdateInfo/>
        {contact && <div>
            <p><strong>Work phone:</strong> {contact.workPhone}</p>
            <p><strong>Mobile phone:</strong> {contact.mobilePhone}</p>
        </div>}

        {task.order && <Link to={`/orderDetail/${task.order.id}`} target="_blank">
            <Button color="primary" style={{fontSize:"18px"}}>Go to Order {task.order.number}</Button>
        </Link>}
        {task.quote && <Link to={`/quoteDetail/${task.quote.id}`} target="_blank">
            <Button color="primary" style={{fontSize:"18px"}}>Go to Quote {task.quote.number}</Button>
        </Link>}
        {task.company && <Link to={`/companyDetail/${task.company.id}`} target="_blank">
            <Button color="primary" style={{fontSize:"18px"}}>Go to Company</Button>
        </Link>}
        {task.variables.quoteLink && <a href={task.variables.quoteLink} target="_blank">
            <span style={{fontSize:"24px"}}>Link to Quote</span>
        </a>}
        <br/>
        <hr/>
        <Row className="justify-content-between">
            <Col sm={2}>
                <Button color="secondary" onClick={()=>toggle()}>
                    Cancel
                </Button>
            </Col>
            <Col sm={4}>
                <ButtonGroup>
                    <Button color="primary" onClick={() => setEditMode(true)}>Edit</Button>
                    <Button color="success" onClick={() => company ? setCompletionMode(true) : onSubmit({object: {}})}>Complete Task</Button>
                </ButtonGroup>
            </Col>
        </Row>

    </div>
};

export default (props)=>
{
    const {task,onSubmit,update} = props;
    const [editMode,setEditMode] = useState(false);
    const [completionMode,setCompletionMode] = useState(false);
    const [createTask] = useMutation(CreateTaskMutation);

    if(editMode)
    {
        return editForm(task,update,setEditMode);
    }
    else if(completionMode)
    {
        return completionForm(task,setCompletionMode,onSubmit,createTask);
    }
    else
    {
        return detailMode(task,onSubmit,setEditMode,props.toggle,setCompletionMode);
    }
};
