import {ModuleObject, Node} from "../ShpaeObject";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Axis, CreateCylinder} from "@babylonjs/core";
import ModuleTemplate, {scaleRatio, store} from "../ModuleTemplate";
import {instanceOf} from "../utils";
import {Ramp} from "./Ramp";
import {MaterialName} from "../Materials";
import {Scene} from "@babylonjs/core/scene";
import {ModuleRampType} from "../../generated/graphql";

const supportLegNodeTest = (node:Node) => {
    if(node.attach.length===0){
        return instanceOf(node.ancestor, [Ramp]);
    }else{
        return false;
    }
}

export const pickSupportLegModuleByHeight = (heightMM:number) => {
    console.log("heightMM", heightMM)
    // find the longest leg supports the height
    return store[ModuleRampType.SupportLeg]
        .sort((a,b) => a.minHeightMM < b.minHeightMM ? 1 : -1)
        // .find(m => m.minHeightMM>heightMM && m.maxHeightMM<heightMM);
        .find(m => {
            console.log("m.minHeightMM, m.maxHeightMM", m.minHeightMM, m.maxHeightMM)
            return m.minHeightMM < heightMM && m.maxHeightMM > heightMM
        });
}

export const pickScrewLegModuleByHeight = (heightMM:number) => {
    // find the longest leg supports the height
    return store[ModuleRampType.ScrewLeg]
        .sort((a,b) => a.minHeightMM < b.minHeightMM ? 1 : -1)
        .find(m => m.minHeightMM<heightMM && m.maxHeightMM>heightMM);
}

export class SupportLeg extends ModuleObject {
    vector:Vector3 = Axis.Y.clone();
    topNode:Node|undefined;

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {diameter, height} = this.moduleTemplate;
        const cylinder = CreateCylinder(`mesh-leg-${this.id}`, {
            height,
            diameterTop: diameter,
            diameterBottom: diameter,
        }, this.scene);
        cylinder.translate(this.origin, 1);
        cylinder.translate(Axis.Y, height/2);
        this.meshes.push(cylinder);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        this.vector = new Vector3(0, height,0);
        this.topNode = new Node(this, this.origin.add(new Vector3(0,height,0)), supportLegNodeTest);
        this.children.push(this.topNode);
        this.nodes.push(this.topNode);
    }

    clone(scene:Scene){
        const cloned = new SupportLeg(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}


export class ScrewLeg extends ModuleObject {
    vector:Vector3 = Axis.Y.clone();
    topNode:Node|undefined;

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {diameter, height, otherProps} = this.moduleTemplate;
        const {footDiameterMM, footHeightMM} = otherProps;
        const footDiameter = footDiameterMM*scaleRatio;
        const footHeight = footHeightMM*scaleRatio;

        const cylinder = CreateCylinder(`mesh-screwLeg-${this.id}`, {
            height,
            diameterTop: diameter,
            diameterBottom: diameter,
        }, this.scene);
        cylinder.translate(this.origin, 1);
        cylinder.translate(Axis.Y, height/2);
        this.meshes.push(cylinder);

        const cylinderFoot = CreateCylinder(`mesh-legFoot-${this.id}`, {
            height: footHeight,
            diameterTop: footDiameter,
            diameterBottom: footDiameter,
        }, this.scene);
        cylinderFoot.translate(this.origin, 1);
        cylinderFoot.translate(Axis.Y, footHeight/2);
        this.meshes.push(cylinderFoot);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        this.vector = new Vector3(0, height,0);
        this.topNode = new Node(this, this.origin.add(new Vector3(0,height,0)), supportLegNodeTest);
        this.children.push(this.topNode);
        this.nodes.push(this.topNode);
    }

    clone(scene:Scene){
        const cloned = new ScrewLeg(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }

}