import React, {Component} from 'react';
import gql from "graphql-tag";
import {Button, ModalBody, Table} from "reactstrap";
import StdQuery from "../common/StdQuery";
import ModalForm from "../common/ModalForm";
import DetailRenderer from "../common/DetailRenderer";
import _ from 'lodash';
import {idToName} from "../UTIL";
import {shippingCompanies} from "../workflow-forms/Order/DeliverOrder";

const BringTracking = gql`
    query bringTracking($num:String!)
    {
        bringTracking(shippingNumber:$num)
    }
`;
export class OrderTracking extends Component
{


    eventSet = (eventSet)=>
    {
        return <Table>
            <thead>
            <tr>
                <th>
                    Description
                </th>
                <th>
                    Date
                </th>
                <th>
                    Status
                </th>

            </tr>
            </thead>

            <tbody>
            {_.map(eventSet,({status,displayDate,displayTime,description},index)=>
                <tr key={index}>
                    <td>
                        {description}
                    </td>
                    <td>
                        {displayDate} {displayTime}
                    </td>
                    <td>
                        {status}
                    </td>
                </tr>)}
            </tbody>
        </Table>
    }

    render()
    {
        const order = this.props.order;

        if(order.shippingNumber)
        {
            switch(order.shippingCompany)
            {
                case "schenker": return <ModalForm button={toggle=>
                    <Button size="lg" color="primary" onClick={()=>toggle()}>See tracking status</Button>
                } title={"Tracking delivery"}>
                    {() =>
                        <ModalBody>
                            <a target="_blank" href={`https://eschenker.dbschenker.com/app/tracking-public/?refNumber=${order.shippingNumber}`}>{order.shippingNumber}</a>
                        </ModalBody>
                    }
                </ModalForm>;
                case "bring":return <ModalForm size="lg" button={toggle=>
                    <Button size="lg" color="primary" onClick={()=>toggle()}>See tracking status</Button>
                } title={"Tracking delivery"}>
                    {() =>
                        <ModalBody>
                            <StdQuery query={BringTracking} variables={{num:order.shippingNumber}} >
                                {({bringTracking})=>
                                {
                                    const consignment = _.get(bringTracking,"consignmentSet.0");
                                    return <div>
                                        <DetailRenderer hideUpdateInfo object={_.pick(consignment,["senderName","senderReference"])}/>
                                        <div style={{border:"solid 2px"}}>
                                            <h4>Receiver address</h4>
                                            <DetailRenderer hideUpdateInfo object={consignment.recipientAddress}/>
                                        </div>

                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Package No.
                                                    </th>
                                                    <th>
                                                        Volume in DM3
                                                    </th>
                                                    <th>
                                                        Weight in Kgs
                                                    </th>
                                                    <th>
                                                        Height in CM
                                                    </th>
                                                    <th>
                                                        Length in CM
                                                    </th>
                                                    <th>
                                                        Width in CM
                                                    </th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                            {_.map(consignment.packageSet,(p,index)=>
                                                <tr key={index}>
                                                    {["packageNumber","volumeInDm3","weightInKgs","heightInCm","lengthInCm","widthInCm"].map(key=><td key={key}>{p[key]}</td>)}
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                        <h3>Events</h3>
                                        {_.map(consignment.packageSet,p=><React.Fragment key={p.packageNumber}>
                                            <h4>Package: {p.packageNumber}</h4>
                                            {this.eventSet(p.eventSet)}
                                        </React.Fragment>)}

                                    </div>;
                                }}
                            </StdQuery>
                        </ModalBody>
                    }
                </ModalForm>;
                default:return <div>
                    <p>Shipping number: {order.shippingNumber}</p>
                    <p>Shipping company: {idToName(shippingCompanies,order.shippingCompany)}</p>
                </div>;
            }

        }
        else
        {
            return null;
        }
    }
}
