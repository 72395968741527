import config from "./config";
import StackTrace from 'stacktrace-js';

const Logger =
    {

        _send(level,message,meta)
        {
            const xhr = new XMLHttpRequest();
            xhr.open('post', `${config.BACKEND_URI}crmLogger/${level}`);
            xhr.setRequestHeader('Content-type', 'application/json');
            xhr.responseType = 'json';
            xhr.addEventListener('load', () => {
                if (xhr.status >= 200 && xhr.status < 300) {

                } else {
                    console.error(xhr.statusText);
                    this._send("error",`Failed to log a message.`,{statusText:xhr.statusText});
                }
            });
            StackTrace.get().then((stackFrames)=>
            {
                xhr.send(JSON.stringify({href:window.location.href,message,meta,stacktrace:stackFrames.toString()}));
            }).catch((err)=>
            {
                console.error(err.message);
            });
        },

        error(msg,meta)
        {
            console.error(msg,meta);
            this._send("error",msg,meta);
        },
        info(msg,meta)
        {
            console.log(msg,meta);
            this._send("info",msg,meta);
        },
    };
export default Logger;
