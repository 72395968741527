import {renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import React, {useCallback} from "react";
import dayjs from "dayjs";

export default (props) =>
{
    const {onChange, defaultValue} = props

    const handleOnChange = useCallback((date) =>
    {
        const value = date != null ? date.format("HH:mm") : null
        onChange({value})
    }, [onChange])

    const getDefaultValue = useCallback(() =>
    {
        if (!defaultValue)
        {
            return null
        }
        if(defaultValue.includes("T")){
            return dayjs(defaultValue)
        }
        return dayjs(`1970-01-01T${defaultValue}`) // The date is not important.
    }, [defaultValue])

    return <div>
        <TimePicker
            viewRenderers={{
                hours: renderTimeViewClock,
                minutes: renderTimeViewClock,
                seconds: renderTimeViewClock,
            }}
            minutesStep={5}
            onChange={handleOnChange}
            defaultValue={getDefaultValue()}
        />
    </div>
}