import React from 'react';
import { useMutation } from '@apollo/client';
import FormRenderer from "../common/FormRenderer";
import CreateQuoteFollowupTask from './graphql/CreateNewFollowupTask.graphql';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default (props) => {
    const { quoteId, isOpen, toggle, refetchQueries } = props;
    const [createQuoteFollowupTask] = useMutation(CreateQuoteFollowupTask, { refetchQueries: refetchQueries });

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            Create follow-up task
        </ModalHeader>
        <ModalBody>
            <FormRenderer formDefinition={[
                { name: "subject", required: true, defaultValue: "Follow-up" },
                { name: "description", type: "textarea" },
                { required: true, name: "due", label: "Select date for follow-up", defaultValue: new Date(), type: "datetime" }
            ]}
                onSubmit={async ({ object: { due, description, subject } }) => {
                    await createQuoteFollowupTask({
                        variables:
                        {
                            id: quoteId,
                            due,
                            description,
                            subject,
                        }
                    });
                    toggle();
                }} />
        </ModalBody>
    </Modal>
};
