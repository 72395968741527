import React, {useState} from 'react';
import _, {get} from 'lodash';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row, Spinner,
    TabContent,
    Table,
    TabPane
} from "reactstrap";
import { useGetCompanyContactsQuery} from "../generated/graphql";

enum Company
{
    ESSE = "ESSE",
    ESPR = "ESPR",
    ESNO = "ESNO",
    ESDE = "ESDE",
    ESPL = "ESPL"
}

const getTitle = (company: Company): string =>
{
    switch (company)
    {
        case Company.ESSE:
            return "Eurostair AB"
        case Company.ESPR:
            return "Eurostair Produktion AB"
        case Company.ESNO:
            return "Eurostair AS"
        case Company.ESDE:
            return "Eurostair GmbH"
        case Company.ESPL:
            return "Eurostair PL"
    }
}

const getFooter = (company: Company) =>
{
    switch (company)
    {
        case Company.ESSE:
            return esseFooter()
        case Company.ESPR:
            return esprFooter()
        case Company.ESNO:
            return esnoFooter()
        case Company.ESDE:
            return esdeFooter()
        case Company.ESPL:
            return esplFooter()
    }
}

const esseFooter = () =>
{
    return <table className="muted">
        <tbody>
        <tr>
            <td width="200">Regnvindsgatan 8 B<br/>
                652 21 Karlstad
            </td>
            <td width="200" valign="top">
                Tel: 054-85 00 40<br/>
                Fax: 054-85 00 60
            </td>
            <td width="200"><a target="_blank" href="http://www.eurostair.se">www.eurostair.se</a><br/>
                <a href="mailto:info@eurostair.se">info@eurostair.se</a></td>
        </tr>
        </tbody>
    </table>
}

const esprFooter = () =>
{
    return <table className="muted">
        <tbody>
        <tr>
            <td width="200">Säterivägen 30<br/>661 30 SÄFFLE</td>
            <td width="200" valign="top">
                Tel: 0533-156 10<br/>
                Fax:
            </td>
            <td width="200"><a target="_blank" href="http://www.eurostair.se">www.eurostair.se</a><br/>
                <a href="mailto:info@eurostair.se">info@eurostair.se</a></td>
        </tr>
        </tbody>
    </table>
}

const esnoFooter = () =>
{
    return <table className="muted">
        <tbody>
        <tr>
            <td width="200">Johan K. Skanckesvei 1-3<br/>
                NO-1430 Ås
            </td>
            <td width="200" valign="top">
                Tel: +46 699 205 00<br/>
                Fax: +46 54 85 00 60
            </td>
            <td width="200"><a target="_blank" href="http://www.eurostair.no">www.eurostair.no</a><br/>
                <a href="mailto:info@eurostair.no">info@eurostair.no</a></td>
        </tr>
        </tbody>
    </table>
}

const esdeFooter = () =>
{
    return <table className="muted">
        <tbody>
        <tr>
            <td width="200">
                Juteweberstr. 5<br/>
                48432 Rheine<br/>
                Deutschland<br/>
            </td>
            <td width="200" valign="top">
                <br/>
            </td>
            <td width="200"><a target="_blank" href="http://www.eurostair.de">www.eurostair.de</a><br/>
                <a href="mailto:info@eurostair.de">info@eurostair.de</a></td>
        </tr>
        </tbody>
    </table>
}

const esplFooter = () =>
{
    return <table className="muted">
        <tbody>
        <tr>
            <td width="200">
                EUROSTAIR POLSKA Sp. z o.o.<br/>
                Kąkolewo, ul. Gostyńska 9<br/>
                64-113 OSIECZNA<br/>
            </td>
            <td width="200" valign="top">
                fax/tel +48 65 5342668
                <br/>
                NIP 697-11-33-379
                <br/>
                KRS 0000612256
            </td>
            <td width="200">
                <a target="_blank" href="http://www.eurostair.pl">www.eurostair.pl</a>
                <br/>
                <a href="mailto:piotr.reisner@eurostair.pl">piotr.reisner@eurostair.pl</a>
            </td>
        </tr>
        </tbody>
    </table>
}


const CompanyContactContent = (props: { company: Company }) =>
{
    const {company} = props
    const {data, loading} = useGetCompanyContactsQuery({variables: {company: company}})

    return <TabPane tabId={company}>
        <Card>
            <CardHeader><h1>{getTitle(company)}</h1></CardHeader>
            {
                loading ? <Spinner/>
                    : <CardBody>
                        <Table bordered striped>
                            <tbody>
                            {_.sortBy(data?.companyContacts, c => c?.name).map((contact, index) => <tr key={index}>
                                {["title", "name", "redirect", "phone", "mobilePhone"].map(key =>
                                    <td key={key}>{get(contact,key)}</td>
                                )}
                                <td>
                                    <a href={`mailto:${contact?.email}`}>
                                        {contact?.email}
                                    </a>
                                </td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </CardBody>
            }
            <CardFooter>
                {getFooter(company)}
            </CardFooter>
        </Card>
    </TabPane>
}

export default () =>
{
    const [activeTab, setActiveTab] = useState(Company.ESSE)

    const TabItem = (props: { company: Company }) =>
    {
        const {company} = props
        return <NavItem>
            <NavLink active={activeTab === company} onClick={() => setActiveTab(company)}>
                <h4>{company}</h4>
            </NavLink>
        </NavItem>
    }

    return <Row>
        <Col>
            <Nav tabs fill>
                {
                    Object.keys(Company).map((e) =>
                    {
                        return <TabItem company={Company[e]}/>
                    })
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                {
                    Object.keys(Company).map((e) =>
                    {
                        return <CompanyContactContent company={Company[e]}/>
                    })
                }
            </TabContent>
        </Col>
    </Row>

}
