import React, {Component} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';

export default class ContactSideBar extends Component {
    render()
    {
        return (
            <Nav pills vertical>
                {/*<NavItem>*/}
                    {/*<Link to="/contacts" className="nav-link">My contacts (Not implemented)</Link>*/}
                {/*</NavItem>*/}
                <NavItem>
                    <NavLink to="/createContact" className="nav-link">Create contact</NavLink>
                </NavItem>
            </Nav>);
    }
}



