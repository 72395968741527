import jsPDF from "jspdf";
import logo from 'assets/images/Eurostair_vit_CMYK.png';

async function loadImage(url):Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const image = new Image();

        image.onload = () => {
            resolve(image);
        };

        image.onerror = (error,x) => {
            console.log("error", error,x)
            reject(new Error('Image failed to load'));
        };

        image.src = url;
    });
}

const getImageWidthHeight = (image:HTMLImageElement, lineWidth:number, scale = 1):{width:number, height:number} => {
    return {
        width: lineWidth*scale,
        height: lineWidth*scale*image.height/image.width,
    }
}

const downloadDrawingPDF = async (img:HTMLImageElement, topImageData:string) => {
    const doc = new jsPDF();
    const logoImage = await loadImage(logo);
    const lineWidth = doc.getLineWidth()*1000;
    const margin = 10;
    const padding = 3;
    let cursorIndex = {x:margin, y:margin};

    const logoImageWH = getImageWidthHeight(logoImage, lineWidth, 0.3);
    doc.setFillColor("#666")
    doc.rect(cursorIndex.x-padding, cursorIndex.y-padding, logoImageWH.width+padding*2, logoImageWH.height+padding*2,"F");
    doc.addImage(logoImage, "png", cursorIndex.x, cursorIndex.y, logoImageWH.width, logoImageWH.height);
    cursorIndex.x += logoImageWH.width + margin;

    doc.setFontSize(12);
    doc.text("Ramp ID:0000000", cursorIndex.x, cursorIndex.y);
    cursorIndex.x = margin;
    cursorIndex.y += logoImageWH.height + margin;

    doc.setFontSize(20);
    doc.text("Top view", cursorIndex.x, cursorIndex.y);
    cursorIndex.y += 20 + margin;

    const topImageWH = getImageWidthHeight(logoImage, lineWidth);
    doc.addImage(topImageData, "png", cursorIndex.x, cursorIndex.y, topImageWH.width, topImageWH.height);

    doc.save("images.pdf");
}

export default downloadDrawingPDF;
