import React, {useState} from "react";
import {OrderInfo} from "../Order/SelectArticles";
import ArticleList from "./ArticleList";
import {CustomerDocsFileList} from "./FileListComponents";
import UploadFile, {CustomerDocCategoryType} from "../../common/UploadFile";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import {CommentPanel} from "./CommentComponents";

export default ({variables, onSubmit, toggle}) => {
    const [files, setFiles] = useState([]);
    const {articles, orderId, autoDrawArticles} = variables;
    const [comments, setComments] = useState(variables.comments ?? [])

    return <div>
        <OrderInfo orderId={orderId}/>
        <h3>Articles</h3>
        <ArticleList articles={articles} orderId={orderId}/>
        {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
            <ArticleList articles={autoDrawArticles} orderId={orderId}/>
        </>}
        <CustomerDocsFileList orderId={orderId}/>
        <hr/>

        <h3>Upload files</h3>
        <UploadFile orderId={orderId} fileCategory={CustomerDocCategoryType}
                    onSubmit={files => setFiles(oldFiles => oldFiles.concat(files))}/>

        <hr/>

        <CommentPanel comments={comments}
                      setComments={(newComments)=> {
                          setComments(newComments)
                      }}/>

        <TaskFooter submit={() => onSubmit({
            moreInformationAddedFiles: {
                type: "json",
                value: JSON.stringify(files)
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        })} toggle={toggle}/>

    </div>
}