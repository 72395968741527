import {isNodesOnSameVector, setPickedModule} from "./utils";
import {Ramp, RampEdge, StartingRamp} from "./Module/Ramp";
import {childSafePole, getModule, getModuleTemplate, pairNodeDistance, scaleRatio, store} from "./ModuleTemplate";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {IndustrialPole, IndustrialRailing} from "./Module/IndustrialPole";
import {
    nodeForIndustrialRailing,
    nodeOfRampForKickPlate,
    nodeOfRampForPole
} from "./NodeFilterFunctions";
import {Tools} from "@babylonjs/core";
import {ModuleObject, Node} from "./ShpaeObject";
import {makeIndustrialRailingPiece} from "./AutoCompleteIndustrailPiece";
import {ChildSafeSection} from "./Module/ChildSafeSection";
import {makeChildSafeRailingPiece} from "./AutoCompleteChildSafePiece";
import {pickScrewLegModuleByHeight, pickSupportLegModuleByHeight, SupportLeg} from "./Module/SupportLeg";
import {Modes, State} from "./ModuleRampPage";
import SceneManager, {SceneName} from "./SceneManager";
import {ModuleRampType} from "../generated/graphql";
import {KickPlate} from "./Module/KickPlate";

export const setupEditorMode = (messageOverlay, newStep, ground, setShowNewModuleCanvas) => {
    let selectedModuleModel:ModuleObject | undefined;
    let selectedNode:Node | undefined;
    const registeredNodes:Node[] = [];

    State.getInstance().modeObservable.add((mode) => {
        const selectedModule = State.getInstance().selectedModule;
        const allNodes = State.getInstance().models.flatMap(m=>m.nodes);
        let allAttachableNodes;
        let testModule;
        setPickedModule(null);

        switch (mode) {
            case Modes.debugShowAllNodes:
                allNodes.forEach((node)=>{
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        console.log(node);
                        State.getInstance().mode = Modes.normal;
                    });
                });
                return;
            case Modes.saved:
                messageOverlay.text = "Ramp data saved";
                messageOverlay.isVisible = true;
                State.getInstance().mode = Modes.normal;
                return;

            case Modes.noAvailableNode:
                messageOverlay.text = "There's no available node to pick, try another node or put more module first.";
                messageOverlay.isVisible = true;
                State.getInstance().setSelectedModule(null);
                State.getInstance().mode = Modes.normal;
                return;

            case Modes.noLegFitsHeight:
                messageOverlay.text = "The height can't find a leg to fit, please try another type of leg";
                messageOverlay.isVisible = true;
                State.getInstance().setSelectedModule(null);
                State.getInstance().mode = Modes.normal;
                return;

            case Modes.belowGround:
                messageOverlay.text = "Can't build module below ground, please try again";
                messageOverlay.isVisible = true;
                State.getInstance().setSelectedModule(null);
                State.getInstance().mode = Modes.normal;
                return;

            case Modes.unableToAttach:
                messageOverlay.text = "Unable to attach here, please try another node to attach.";
                messageOverlay.isVisible = true;
                State.getInstance().setSelectedModule(null);
                State.getInstance().mode = Modes.normal;
                return;

            case Modes.selectionErr:
                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });
                registeredNodes.length = 0;
                messageOverlay.text = "Module not found, pick another one";
                messageOverlay.isVisible = true;
                State.getInstance().setSelectedModule(null);
                State.getInstance().mode = Modes.normal;
                return;

            case Modes.normal:
                if(State.getInstance().selectedModule){
                    State.getInstance().setSelectedModule(null);
                }
                selectedNode = undefined;
                selectedModuleModel = undefined;
                setShowNewModuleCanvas(false); // Changing this state will cause rerender
                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });
                registeredNodes.length = 0;

                State.getInstance().models.forEach(model=>{
                    model.activateEventBox();
                });

                setTimeout(()=>{
                    messageOverlay.isVisible = false;
                }, 2000);
                return;
        }

        if(!selectedModule){
            State.getInstance().mode = Modes.selectionErr;
            return;
        }

        // selectedModule required modes
        switch(mode) {
            case Modes.pickRampAttachA:
                const isFirstRamp = !State.getInstance().models.some(model=>{
                    return model instanceof Ramp || model instanceof StartingRamp;
                })
                messageOverlay.text = "Pick a node from selected module";
                messageOverlay.isVisible = true;

                if(!isFirstRamp){
                    selectedModuleModel = getModule(selectedModule, SceneManager.getInstance()[SceneName.addModule]);
                    // const offsetY = selectedModuleModel.getBoundingBox().minimum.y - ground.position.y;
                    // if(offsetY<0){
                    //     selectedModuleModel.move(new Vector3(-selectedModule.width-2, -offsetY,0));
                    // }
                    setShowNewModuleCanvas(true);
                    selectedModuleModel.nodes.filter(node=>
                        node.parent instanceof RampEdge
                    ).forEach(node => {
                        registeredNodes.push(node);
                        node.activateEventBox();
                        node.onClick(() => {
                            selectedNode = node;
                            State.getInstance().mode = Modes.pickAttachB;
                        });
                    });
                } else {
                    selectedModuleModel = getModule(selectedModule, SceneManager.getInstance()[SceneName.main]);
                    newStep(State.getInstance().models.concat(selectedModuleModel));
                    State.getInstance().mode = Modes.normal;
                }
                break;

            case Modes.pickIndustrialPoleAttachA:
                // selectedModule.origin = new Vector3(-selectedModule.width-2, 0, 0);
                selectedModuleModel = getModule(selectedModule, SceneManager.getInstance()[SceneName.addModule]);
                selectedNode = (selectedModuleModel as IndustrialPole).bottomNode;
                setShowNewModuleCanvas(true);
                State.getInstance().mode = Modes.pickAttachB;

                break;
            case Modes.pickIndustrialRailingAttachA:
                messageOverlay.text = "Pick a node where the railing start.";
                messageOverlay.isVisible = true;
                setShowNewModuleCanvas(null); //use this to refresh setting bar, letting create button refresh state
                selectedModuleModel = getModule(selectedModule, SceneManager.getInstance()[SceneName.addModule]);
                allNodes.filter(nodeForIndustrialRailing).forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        selectedNode = node;
                        State.getInstance().mode = Modes.pickIndustrialRailingAttachB;
                    });
                });
                break;
            case Modes.pickIndustrialRailingAttachB: {
                if(!selectedNode) {
                    State.getInstance().mode = Modes.selectionErr;
                    break;
                }

                messageOverlay.text = "Pick a node where the railing end.";
                messageOverlay.isVisible = true;

                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });

                const attachableNodes = allNodes.filter(nodeForIndustrialRailing).filter((node)=>{
                    if(!selectedNode) return false;
                    if(!(node.parent instanceof IndustrialPole)) return false;
                    if(node.parent.railingNode !== node) return false;

                    // The bottom of pole must attach to a ramp edge
                    if(!node.parent.bottomNode?.attach || node.parent.bottomNode?.attach.length < 1) { return false; }
                    const rampEdge = node.parent.bottomNode?.attach[0].parent;
                    if(!(rampEdge instanceof RampEdge)) return false;

                    if(!isNodesOnSameVector(selectedNode.origin, node.origin, rampEdge.vector)){ return false; }
                    const railingLengthOptions = Object.values(store[ModuleRampType.IndustrialRailing]).map(e => e.lengthMM);
                    return railingLengthOptions.some((railingLength=0)=>{
                        return selectedNode && Math.abs(Vector3.Distance(selectedNode.origin, node.origin) - railingLength * scaleRatio) < pairNodeDistance;
                    })
                });

                // const attachableNodes = selectedNode.filterAttachable(reachableNodes);

                attachableNodes.forEach((node)=>{
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        if(!selectedNode) return;
                        const railingLength = Vector3.Distance(node.origin, selectedNode.origin);
                        const id = Object.values(store[ModuleRampType.IndustrialRailing]).findIndex(e => {
                            return e?.lengthMM ? Math.abs(e.lengthMM * scaleRatio - railingLength) < pairNodeDistance : false;
                        });

                        const railingVector = node.origin.subtract(selectedNode.origin);
                        const pole = selectedNode.parent as IndustrialPole;
                        if(!pole.bottomNode?.attach || pole.bottomNode?.attach.length < 1) { return; }
                        const edge = pole.bottomNode?.attach[0].parent as RampEdge;
                        if(!edge){
                            messageOverlay.text = "error: Selected pole haven't attach to an ramp edge.";
                            messageOverlay.isVisible = true;
                            return;
                        }
                        const normal2 = Vector3.Cross(railingVector, edge.vector).normalize();
                        const radiansBetweenSectionVector = Vector3.GetAngleBetweenVectors(railingVector, edge.vector, normal2);
                        const degree = Tools.ToDegrees(radiansBetweenSectionVector);

                        const module = getModuleTemplate({type:ModuleRampType.IndustrialRailing, id, slopeDegree:pole.moduleTemplate.slopeDegree});
                        module.origin = Vector3.Zero();
                        const newIndustrialRailing = new IndustrialRailing(module, SceneManager.getInstance()[SceneName.main]);

                        if(Math.abs(degree)>90){
                            newIndustrialRailing.startNode?.attachTo(node);
                        }else{
                            newIndustrialRailing.startNode?.attachTo(selectedNode);
                        }

                        newStep(State.getInstance().models.concat(newIndustrialRailing));
                        State.getInstance().mode = Modes.normal;
                    });
                })

                break;
            }
            case Modes.pickIndustrialRailingPieceAttachA:
                messageOverlay.text = "Pick a node from your modules";
                messageOverlay.isVisible = true;
                setShowNewModuleCanvas(null); //use this to refresh setting bar, letting create button refresh state

                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });
                registeredNodes.length = 0;
                selectedModule.origin = Vector3.Forward();
                testModule = getModule(selectedModule, SceneManager.getInstance()[SceneName.main]);

                allAttachableNodes = testModule.startNode?.filterAttachable(allNodes) as Node[];
                testModule.remove();

                allAttachableNodes.forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(() => {
                        selectedModuleModel = makeIndustrialRailingPiece(selectedModule, node);
                        if(selectedModuleModel){
                            newStep(State.getInstance().models.concat(selectedModuleModel));
                            State.getInstance().mode = Modes.normal;
                        }else{
                            State.getInstance().mode = Modes.noAvailableNode;
                        }
                    });
                });

                break;
            case Modes.pickChildSafeAttachA:
                messageOverlay.text = "Pick a node where the section start.";
                messageOverlay.isVisible = true;

                setShowNewModuleCanvas(null); //use this to refresh setting bar, letting create button refresh state
                // selectedModuleModel = getModule(selectedModule, SceneManager.getInstance()[SceneName.addModule]);

                allNodes.filter(nodeOfRampForPole).forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        selectedNode = node;
                        State.getInstance().mode = Modes.pickChildSafeAttachB;
                    });
                });

                break;
            case Modes.pickChildSafeAttachB:
                messageOverlay.text = "Pick a node where the section end.";
                messageOverlay.isVisible = true;
                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });

                const sectionLengthOptions = Object.values(store[ModuleRampType.ChildSafeSection]).map(e=>e.lengthMM);
                const nodesOnVector:Node[] = [];
                State.getInstance().models.forEach(model=> {
                    //model.getAllAttachableNodes([NodeAttachableType.pole]).forEach(node => {
                    model.nodes.forEach(node => {
                        if(!selectedNode || !(node.parent instanceof RampEdge)) return;
                        if(isNodesOnSameVector(selectedNode.origin, node.origin, node.parent.vector)){
                            nodesOnVector.push(node);
                        }
                    })
                });

                nodesOnVector.forEach((node)=>{
                    // Find nodes that can fit available section
                    sectionLengthOptions.forEach((sectionLength=0) => {
                        if(selectedNode && Math.abs(Vector3.Distance(selectedNode.origin, node.origin) - sectionLength*scaleRatio + childSafePole.poleSpacing) < pairNodeDistance){
                            registeredNodes.push(node);
                            node.activateEventBox();
                            node.onClick(()=>{
                                if(!selectedNode) return;

                                const id = Object.values(store[ModuleRampType.ChildSafeSection]).findIndex(e=>e.lengthMM===sectionLength);
                                const sectionVector = node.origin.subtract(selectedNode.origin);

                                const edge = selectedNode.parent as RampEdge;
                                const normal2 = Vector3.Cross(sectionVector, edge.vector).normalize();
                                const radiansBetweenSectionVector = Vector3.GetAngleBetweenVectors(sectionVector, edge.vector, normal2);
                                const degree = Tools.ToDegrees(radiansBetweenSectionVector);

                                const module = getModuleTemplate({type:ModuleRampType.ChildSafeSection, id, slopeDegree: -edge.slopeDegree});
                                module.origin = node.origin;
                                const newChildSafeSection = new ChildSafeSection(module, SceneManager.getInstance()[SceneName.main]);
                                if(Math.abs(degree)>90){
                                    newChildSafeSection.poleNodes[0].attachTo(selectedNode);
                                }else{
                                    newChildSafeSection.poleNodes[0].attachTo(node);
                                }

                                newStep(State.getInstance().models.concat(newChildSafeSection));
                                selectedNode = undefined;
                                State.getInstance().mode = Modes.normal;
                            });
                        }
                    });
                });

                break;
            case Modes.pickChildSafePieceAttachA:
                messageOverlay.text = "Pick a node from your modules";
                messageOverlay.isVisible = true;

                setShowNewModuleCanvas(null); //use this to refresh setting bar, letting create button refresh state

                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });
                registeredNodes.length = 0;

                selectedModule.origin = Vector3.Forward();
                testModule = getModule(selectedModule, SceneManager.getInstance()[SceneName.main]);
                allAttachableNodes = testModule.startNode?.filterAttachable(allNodes) as Node[];
                testModule.remove();

                allAttachableNodes.forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        selectedModuleModel = makeChildSafeRailingPiece(selectedModule, node);
                        if(selectedModuleModel){
                            newStep(State.getInstance().models.concat(selectedModuleModel));
                            State.getInstance().mode = Modes.normal;
                        }else{
                            State.getInstance().mode = Modes.noAvailableNode;
                        }
                    });
                });

                break;
            case Modes.pickKickPlateAttachA:
                messageOverlay.text = "Pick a node where the kick plate start.";
                messageOverlay.isVisible = true;

                setShowNewModuleCanvas(null); //use this to refresh setting bar, letting create button refresh state

                allNodes.filter(nodeOfRampForKickPlate).forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        selectedNode = node;
                        State.getInstance().mode = Modes.pickKickPlateAttachB;
                    });
                });

                break;
            case Modes.pickKickPlateAttachB:
                messageOverlay.text = "Pick a node where the section end.";
                messageOverlay.isVisible = true;
                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });
                const { poleSpacing } = childSafePole;
                const kickPlateLengthOptions = Object.values(store[ModuleRampType.KickPlate]).map(e=>e.lengthMM);
                const attachableNodes = allNodes.filter(nodeOfRampForKickPlate).filter((node) => {
                    if(!selectedNode) return false;

                    const rampEdge = node.parent as RampEdge;
                    if(!isNodesOnSameVector(selectedNode.origin, node.origin, rampEdge.vector)){ return false; }
                    return kickPlateLengthOptions.some((length=0) => {
                        return selectedNode && Math.abs(Vector3.Distance(selectedNode.origin, node.origin) + poleSpacing - length * scaleRatio) < pairNodeDistance;
                    })
                });

                attachableNodes.forEach((node)=>{
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        if(!selectedNode) return;
                        const railingLength = Vector3.Distance(node.origin, selectedNode.origin);
                        const id = Object.values(store[ModuleRampType.KickPlate]).findIndex(e => {
                            return e?.lengthMM ? Math.abs(e.lengthMM * scaleRatio - railingLength - poleSpacing) < pairNodeDistance : false;
                        });

                        const railingVector = node.origin.subtract(selectedNode.origin);
                        const rampEdgeVector = (selectedNode.parent as RampEdge).vector;
                        // const pole = selectedNode.parent as IndustrialPole;
                        // if(!pole.bottomNode?.attach || pole.bottomNode?.attach.length < 1) { return; }
                        // const edge = pole.bottomNode?.attach[0].parent as RampEdge;
                        // if(!edge){
                        //     messageOverlay.text = "error: Selected pole haven't attach to an ramp edge.";
                        //     messageOverlay.isVisible = true;
                        //     return;
                        // }
                        const normal2 = Vector3.Cross(railingVector, rampEdgeVector).normalize();
                        const radiansBetweenSectionVector = Vector3.GetAngleBetweenVectors(railingVector, rampEdgeVector, normal2);
                        const degree = Tools.ToDegrees(radiansBetweenSectionVector);

                        const module = getModuleTemplate({type:ModuleRampType.KickPlate, id, slopeDegree:(selectedNode.parent as RampEdge).slopeDegree});
                        module.origin = Vector3.Zero();
                        const newKickPlate = new KickPlate(module, SceneManager.getInstance()[SceneName.main]);

                        if(Math.abs(degree)>90){
                            newKickPlate.startNode?.attachTo(selectedNode);
                        }else{
                            newKickPlate.startNode?.attachTo(node);
                        }

                        newStep(State.getInstance().models.concat(newKickPlate));
                        State.getInstance().mode = Modes.normal;
                    });
                })

                break;
            case Modes.pickSupportLegAttachA:
                messageOverlay.text = "Pick a node to attach leg";
                messageOverlay.isVisible = true;
                setShowNewModuleCanvas(null); //use this to refresh setting bar, letting create button refresh state

                // selectedModule.origin = new Vector3(-selectedModule.width-2, 0, 0);
                const tempLeg = getModule(selectedModule, SceneManager.getInstance()[SceneName.main]) as SupportLeg;
                allAttachableNodes = tempLeg.topNode?.filterAttachable(allNodes);
                tempLeg.remove();

                allAttachableNodes?.forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        const distanceToGround = node.origin.y- ground.position.y;
                        //selectedModule.height = distanceToGround;
                        let reselectedModuleContent;
                        if(selectedModule.type===ModuleRampType.SupportLeg){
                            reselectedModuleContent = pickSupportLegModuleByHeight(distanceToGround/scaleRatio);
                        } else {
                            reselectedModuleContent = pickScrewLegModuleByHeight(distanceToGround/scaleRatio);
                        }

                        if(!reselectedModuleContent){
                            State.getInstance().mode = Modes.noLegFitsHeight;
                            return;
                        }

                        reselectedModuleContent.type = selectedModule.type;
                        reselectedModuleContent.heightMM = distanceToGround/scaleRatio;
                        const reselectedModule = getModuleTemplate(reselectedModuleContent)
                        selectedModuleModel = getModule(reselectedModule, SceneManager.getInstance()[SceneName.main]);
                        selectedNode = (selectedModuleModel as SupportLeg).topNode;

                        if(!selectedNode){ return; }
                        selectedNode.attachTo(node);
                        newStep(State.getInstance().models.concat(selectedModuleModel));

                        selectedModuleModel = undefined;
                        selectedNode = undefined;

                        State.getInstance().mode = Modes.normal;
                    });
                });
                break;
            case Modes.pickAttachB:
                messageOverlay.text = "Pick a node from your modules";
                messageOverlay.isVisible = true;
                if(!selectedModuleModel || !selectedNode) {
                    State.getInstance().mode = Modes.selectionErr;
                    break;
                }
                registeredNodes.forEach( node => {
                    node.deactivateEventBox();
                    node.onClick(null);
                });
                registeredNodes.length = 0;

                allAttachableNodes = selectedNode.filterAttachable(allNodes);

                allAttachableNodes.forEach(node => {
                    registeredNodes.push(node);
                    node.activateEventBox();
                    node.onClick(()=>{
                        // attach selected module to another with their nodes
                        if(!selectedModuleModel || !selectedNode){ return; }
                        const nodeIndex = selectedModuleModel.nodes.indexOf(selectedNode);
                        const cloned = selectedModuleModel.clone(SceneManager.getInstance()[SceneName.main]);
                        const clonedSelectedNode = cloned.nodes[nodeIndex];
                        const attachSuccess = clonedSelectedNode.attachTo(node);
                        selectedModuleModel.remove();

                        const absolutePosition = cloned.meshes[0]?.getAbsolutePosition();
                        if(absolutePosition.y < 0){
                            cloned.remove();
                            State.getInstance().mode = Modes.belowGround;
                            selectedModuleModel = undefined;
                            selectedNode = undefined;
                            return;
                        }
                        if(!attachSuccess){
                            cloned.remove();
                            State.getInstance().mode = Modes.unableToAttach;
                            selectedModuleModel = undefined;
                            selectedNode = undefined;
                            return;
                        }

                        // const ancestorBoundingBox = new BoundingBox(
                        //     new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
                        //     new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE)
                        // );
                        //
                        // cloned.meshes.forEach((mesh) =>{
                        //     mesh.showBoundingBox = true;
                        //     console.log("mesh", mesh)
                        //     const boundingBox = mesh.getBoundingInfo().boundingBox;
                        //     console.log("boundingBox", boundingBox)
                        //     ancestorBoundingBox.reConstruct(
                        //         Vector3.Minimize(ancestorBoundingBox.minimumWorld, boundingBox.minimumWorld),
                        //         Vector3.Maximize(ancestorBoundingBox.maximumWorld, boundingBox.maximumWorld)
                        //     );
                        // });

                        newStep(State.getInstance().models.concat(cloned));

                        selectedModuleModel = undefined;
                        selectedNode = undefined;

                        State.getInstance().mode = Modes.normal;
                    });
                });

                break;


        }
        /*

                    const meshes = sceneInstance.meshes.filter(mesh => mesh.name.includes('mesh'));

                    if(mode === Modes.normal || mode === Modes.noAvailableNode || mode === Modes.selectionErr ){
                        meshes.forEach( mesh => {
                            mesh.isPickable = true;
                        });
                    } else {
                        meshes.forEach( mesh => {
                            mesh.isPickable = false;
                        });
                    }
        */

    });
}
