import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector, GridToolbarExport,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from "@mui/x-data-grid";
import React from "react";
import {IsEconomy} from "../../Auth";

export default () =>
{
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
            <IsEconomy altRender={null}>
                <GridToolbarExport/>
            </IsEconomy>
            <GridToolbarQuickFilter debounceMs={500}/>
        </GridToolbarContainer>
    );
}
