import {GetConsignmentTemplatesDocument} from "../../generated/graphql";
import {TransportServiceTypes} from "./TransportServiceTypes";
import {DeliveryMethodTypes} from "./DeliveryMethodTypes";
import {PaymentInstructionTypes} from "./PaymentInstructionType";
import {FORMAT_DATE, formatToSwedishTime} from "../../UTIL";
import PaymentInstructionRadioGroup from "../component/PaymentInstructionRadioGroup";
import React from "react";
import PaymentAccountSelect from "../component/PaymentAccountSelect";


export const shipmentFormDefinitions = (isCreate: boolean) =>
{
    const definitions: any = []
    definitions.push({
        name: "template", label: `Template`,
        type: 'select',
        required: true,
        query: GetConsignmentTemplatesDocument,
        mapFn: e =>
        {
            return {id: e.name, name: e.name, value: e.name}
        }
    })

    if (isCreate)
    {
        definitions.push({name: "blank", type: "hidden", hideField: () => true})
        definitions.push({name: "blank", type: "hidden", hideField: () => true})
    }else{
        definitions.push({
            name: "number",
            label: "Shippers ref",
            required: true,
            type: "text",
        })
        definitions.push({
            name: "consigneesReference",
            type: "text",
        })
    }


    definitions.push(...[
        {
            name: "transportService",
            label: `Transport service`,
            type: 'select',
            options: TransportServiceTypes,
            isClearable: true
        },
        {
            name: "deliveryMethod",
            type: 'select',
            options: DeliveryMethodTypes,
            isClearable: true,
            required: true,
            text: "• Parcel: max 180 cm, 30 kg, 0.35 volume.\n" +
                "• System: max 600 cm, 999 kg, 3.57 volume.\n" +
                "• Partload: Unlimited, Loading space on the truck: 7.2 flat meters, Trailer 12 flat meters, Total for the truck and trailer 19.2 flat meters."
        },
        {name: "blank", type: "hidden", hideField: () => true},
        {
            name: "paymentInstruction",
            label: `Freight payer`,
            type: 'custom',
            input: (model, onChange) =>
            {
                return <PaymentInstructionRadioGroup model={model} onChange={onChange}
                                                     isNewFormRenderer={isCreate}/>
            },
            transformValue: ({value}) =>
            {
                return Object.keys(PaymentInstructionTypes).find(x => PaymentInstructionTypes[x] === value)
            },
        },
        {
            name: "paymentAccount",
            label: `Freight account`,
            type: 'custom',
            input: function (model, onChange)
            {
                return <PaymentAccountSelect model={model} onChange={onChange} isNewFormRenderer={isCreate}/>
            }
        },
        {name: "blank", type: "hidden", hideField: () => true},
        {
            name: "pickupDate", label: `Pickup date`,
            type: 'date',
            required: true,
            transformValue: ({value}) => `${formatToSwedishTime(value, FORMAT_DATE)}`
        },
        {
            name: "pickupEarliest", label: `Pickup earliest time`,
            type: 'time',
            required: true,
        },
        {
            name: "pickupLatest", label: `Pickup latest time`,
            type: 'time',
            required: true,
        },
        {
            name: "pickInstruction",
            type: 'textarea',
        },
        {
            name: "advice",
            label: "Notify",
            type: 'checkbox',
            text: "Parcel or System - advice\n" +
                "Part load - driver notification",
        }
    ])

    if (!isCreate)
    {
        definitions.push({
            name: "price", label: `Shipping cost`, disabled: true,
            transformValue: ({value}) => `${value ?? "None"}`
        })
    }

    return definitions;
}