import React, { useCallback, useState } from 'react';
import FileList from '../../common/FileList';
import getFilesQuery from './graphql/GetFiles.graphql';
import StdQuery from '../../common/StdQuery';
import {CustomerDocCategoryType, DrawingsCategoryType, ProductionDrawingsCategoryType} from '../../common/UploadFile';
import {filter} from 'lodash';
import { Button, Col, Row } from 'reactstrap';
import {AddCommentModal, CommentTable} from "./CommentComponents";


export default ({variables,onSubmit,toggle})=>
{
    const {orderId, moreInformationAddedFiles, comments} = variables;

    const [rejectModal,setRejectModal] = useState(false);
    const toggleRejectModal = useCallback(()=>
    {
        setRejectModal(val=>!val);
    },[setRejectModal]);

    const approve = useCallback(async()=>
    {
        await onSubmit({
            moreInformationApproved:
            {
                type:"Boolean",
                value:true
            }
        });
        toggle();
    },[toggle,onSubmit]);

    const reject = useCallback(async (newComments) => {
        await onSubmit({
            comments: {
                value: JSON.stringify(newComments),
                type: "json"
            },
            moreInformationApproved: {
                type: "Boolean",
                value: false
            }
        });
    }, [onSubmit])

    return <div>
        <h3>Added files</h3>
        <FileList files={moreInformationAddedFiles}/>
        <h3>All customer documentation</h3>
        <StdQuery query={getFilesQuery} variables={{orderId}}>
            {data =>
            {
                const drawings = filter(data.files,file=>file.fileCategory.id === DrawingsCategoryType.id  || file.fileCategory.id === ProductionDrawingsCategoryType.id);
                const customerDocumentation = filter(data.files,file=>file.fileCategory.id === CustomerDocCategoryType.id);
                return <React.Fragment>
                    <h3>Customer documentation files</h3>
                    <FileList files={customerDocumentation}/>
                    <hr/>
                    <h3>Uploaded drawings on order</h3>
                    <FileList files={drawings}
                              onDelete={()=>{}}/>
                </React.Fragment>
            }
            }
        </StdQuery>
        <hr/>

        <CommentTable comments={comments}/>

        <Row>
            <Col>
                <Button color={'secondary'} onClick={()=>toggle()}>
                    Cancel
                </Button>
            </Col>
            <Col sm={3}>
                <div style={{display:'flex',gap:"15px"}}>
                    <AddCommentModal open={rejectModal}
                                     toggle={toggleRejectModal}
                                     submit={reject}
                                     comments={comments}
                                     title={`Reject information provided`}   />

                    <Button color={'danger'} onClick={()=>toggleRejectModal()}>
                        Reject
                    </Button>
                    <Button color={'success'} onClick={()=>approve()}>
                        Approve
                    </Button>
                </div>

            </Col>
        </Row>
    </div>


}
