import React, {useCallback, useState} from 'react';
import UploadFile, {
    CustomerDocCategoryType,
    DrawingsCategoryType,
    ProductionDrawingsCategoryType
} from "../../common/UploadFile";
import {filter, map} from 'lodash';
import ArticleList from "./ArticleList";
import FileList from "../../common/FileList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import SelectUser from "../../common/SelectUser";
import {Alert, Col, Row} from "reactstrap";
import {OrderInfo} from "../Order/SelectArticles";
import {useGetFilesQuery} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import {CommentPanel} from "./CommentComponents";

export default ({variables,onSubmit,update,toggle})=>
{
    const res = useGetFilesQuery({variables:{orderId:variables.orderId}});
    const [files,setFiles] = useState(variables.productionDrawings || []);
    const [reviewer,setReviewer] = useState(variables.reviewer || null);
    const [comments, setComments] = useState(variables.comments ?? [])

    const save = useCallback((toggle)=>
    {
        update({
            productionDrawings: {
                value: JSON.stringify(map(files)),
                type: "json",
            },
            reviewer:
            {
                value:reviewer,
                type:"string"
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        },toggle)
    },[update,files,reviewer, comments]);
    const submit = useCallback(()=>
    {
        const submit = ()=>
        {
            onSubmit({
                productionDrawings: {
                    value: JSON.stringify(map(files)),
                    type: "json",
                },
                reviewer:
                {
                    value:reviewer,
                    type:"string"
                },
                comments: {
                    value: JSON.stringify(comments),
                    type: "json"
                }
            })
        };
        if(!reviewer)
        {
            window.alert(`Please select a reviewer!`);
            return;
        }
        if(files.length > 0)
        {
            submit();
        }
        else
        {
            if(window.confirm(` You have not uploaded any drawings. Are you sure you want to proceed?`))
            {
                submit();
            }
        }
    },[onSubmit,reviewer,files, comments]);
    const drawings = filter(res.data?.files,file=>file.fileCategory?.id === DrawingsCategoryType.id || file.fileCategory?.id === ProductionDrawingsCategoryType.id);
    const customerDocumentation = filter(res.data?.files,file=>file.fileCategory?.id === CustomerDocCategoryType.id);
    const {articles,comment,orderId,autoDrawArticles,initiator,drawingComment} = variables;
    return <div>
        <>
            <OrderInfo orderId={orderId}/>
            {comment && <div>
                <h2>Comment</h2>
                <p>
                    {comment}
                </p>
            </div>}
            <h3>Articles</h3>
            <ArticleList articles={articles} orderId={orderId}/>
            {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
                <ArticleList articles={autoDrawArticles} orderId={orderId}/>
            </>}
            {drawingComment && drawingComment.length > 0 && <><h4>Comment from {initiator}</h4>
                <Alert color="warning">
                     <pre>
                         {drawingComment}
                     </pre>
                </Alert></>}

            <DefaultQueryHandler res={res}>
                <React.Fragment>
                    <h3>Customer documentation files</h3>
                    <FileList files={customerDocumentation}/>
                    <hr/>
                    <h3>Uploaded drawings on order</h3>
                    <FileList files={drawings}
                              onDelete={() =>
                              {
                              }}/>
                </React.Fragment>
            </DefaultQueryHandler>

            <hr/>
            <h3>Uploaded files</h3>
            <FileList files={files}
                      onDelete={(deletedFile) =>
                      {
                          setFiles(files => filter(files, file => file.id !== deletedFile.id));
                          save(false);
                      }
                      }/>
            <UploadFile fileCategory={ProductionDrawingsCategoryType}
                        onSubmit={uploadedFiles => setFiles(files => files.concat(uploadedFiles))}/>
            <hr/>
            <h3>Select reviewer</h3>
            <Row>
                <Col sm={6}>
                    <SelectUser userId={reviewer} onChange={(selected) =>
                    {
                        setReviewer(selected.value);
                    }}/>
                </Col>
            </Row>

            <hr/>
            <CommentPanel comments={comments}
                          setComments={(newComments) =>
                          {
                              setComments(newComments)
                          }}/>

            <TaskFooter toggle={toggle} save={() => save(false)} submit={() => submit()}/>
        </>
    </div>
}

