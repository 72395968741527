import {GridValueFormatterParams} from "@mui/x-data-grid";
import {FORMAT_DATE, formatToSwedishTime} from "../../../UTIL";

const ColumnDate = (field: string, headerName: string, customFormatter?: (value) => string, noValueText?:string) =>
{
    return {
        field: field,
        headerName: headerName,
        type: 'date',
        valueGetter: ({value}) => value && new Date(value),
        valueFormatter: (params: GridValueFormatterParams<Date>) =>
        {
            const value = params.value
            if (value == null)
            {
                return noValueText??'';
            }
            if (customFormatter)
            {
                return customFormatter(value)
            }

            return formatToSwedishTime(value, FORMAT_DATE)
        },
        parseFilterValue: (value) => new Date(value),
    }
}

export default ColumnDate