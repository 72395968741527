import React, {useEffect, useState} from "react";
import {Badge} from "reactstrap";


const ShortenText = ({text, lengthToCollapse}) =>
{
    const [isExpand, setIsExpand] = useState(false);
    useEffect(() =>
    {
        const collapseLength = Math.max(text.substring(0, text.indexOf(":")), lengthToCollapse);
        if (collapseLength < text.length)
        {
            setIsExpand(true);
        }
    }, [text, setIsExpand, lengthToCollapse])
    return (<>
        {
            !isExpand ? (
                <>
                    <span>{text.substring(0, text.indexOf(":"))} </span>
                    <Badge style={{cursor: "pointer"}} onClick={() =>
                    {
                        setIsExpand(true)
                    }}>...</Badge>
                </>
            ) : text
        }
    </>);
}

export default ShortenText