import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import {Comparator, PackageFragmentFragment, useGetPackagesQuery} from "../../generated/graphql";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {getPackageColumns} from "../../package/PackageListPage";
import PackageExpandedPanel, {calculatePackageExpandedHeight} from "../../package/component/PackageExpandedPanel";

const TABLE_KEY_PACKAGE = "SELECT_PACKAGE_FOR_SHIPMENT"

type Props = {
    selectedPackages?: PackageFragmentFragment[] | null
}

const SelectPackagesForShipmentPanel = React.forwardRef((props: Props, ref) =>
{
    const {selectedPackages} = props
    const [selectedRowIdList, setSelectedRowIdList] = useState<GridRowSelectionModel>([])
    const [packages, setPackages] = useState<PackageFragmentFragment[]>([])

    const {data, loading} = useGetPackagesQuery({
        variables: {
            filtering: {
                items: [
                    {key: "tenantId", value: "ESSE", comparator: Comparator.Equals},
                    {key: "shipmentId", value: "null", comparator: Comparator.Equals}]
            }
        }
    })

    useEffect(() =>
    {
        const list: PackageFragmentFragment[] = []
        if (selectedPackages)
        {
            list.push(...selectedPackages)
            setSelectedRowIdList(selectedPackages.map(e=>e.id))
        }
        if (data?.result?.list)
        {
            list.push(...data.result.list)
        }

        setPackages(list)
    }, [data,selectedPackages])

    const onRowCheckChange = useCallback((newRowSelectionModel) =>
    {
        setSelectedRowIdList(newRowSelectionModel)
    }, [setSelectedRowIdList])

    const getSelectedPackageIdList = useCallback(() =>
    {
        return selectedRowIdList
    }, [selectedRowIdList])

    const getSelectedPackageList = useCallback(() =>
    {
        return data?.result?.list?.filter((e)=> selectedRowIdList.includes(e.id)) ?? []
    }, [selectedRowIdList, data])

    useImperativeHandle(ref, () => ({getSelectedPackageIdList, getSelectedPackageList}))

    return <NonPaginatedDataGrid
        {...props}
        definition={{
            tableKey: TABLE_KEY_PACKAGE,
            columns: getPackageColumns(),
            initState: {columns: {columnVisibilityModel: {id: false}}}
        }}
        loading={loading}
        checkboxSelection
        data={packages}
        rowSelectionModel={selectedRowIdList}
        onRowSelectionModelChange={onRowCheckChange}
        getDetailPanelContent={({row}) => <PackageExpandedPanel packageInfo={row}/>}
        getDetailPanelHeight={calculatePackageExpandedHeight}
    />
})

export default SelectPackagesForShipmentPanel