import {useEffect, useState} from "react";
import {filter,some,each} from 'lodash';
import config, {environment} from "../../config";

export const useArticleSelect = (variables)=>
{
    const [selectedArticles,setSelectedArticles] = useState( {});
    const [autoArticles,setAutoArticles] = useState( {});
    useEffect(()=>
    {
        const selectedArticles = {};
        const autoArticles = {};
        each(variables.articles,a=>{selectedArticles[a.id]=true})
        each(variables.autoDrawArticles,a=>{autoArticles[a.id]=true});
        setAutoArticles(autoArticles);
        setSelectedArticles(selectedArticles);
        // eslint-disable-next-line
    },[setAutoArticles,setSelectedArticles])
    return [autoArticles,setAutoArticles,selectedArticles,setSelectedArticles];
}

export const filterArticles = (filterCollection,articles)=>
{
    return filter(articles,a=>
    {
        return !some(filterCollection,(f,key)=>f && a.id === key)
    });
}

export const isProd = config.environment === environment.PRODUCTION
