import React, {useCallback, useEffect, useRef, useState} from "react";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {
    ActionManager,
    ArcRotateCamera,
    Axis,
    Color3,
    ExecuteCodeAction,
    Mesh,
    MeshBuilder,
    Observable,
    StandardMaterial,
    Texture,
    Tools,
} from "@babylonjs/core";
import {AdvancedDynamicTexture, TextBlock} from "@babylonjs/gui";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import * as _ from "lodash";

import ModuleSettingBar from "./ModuleSettingBar";
import MaterialPanel from "./ModulePanel";
import ModuleTemplate, {getModule, getModuleTemplate, pairNodeDistance, scaleRatio, store} from "./ModuleTemplate";
import {ModuleObject, Node} from "./ShpaeObject";
import SceneControlBar from "./SceneControlBar";
import {MaterialName} from "./Materials";
import InfoBox from "./InfoBox";
import {setupEditorMode} from "./EditorMode";
import SceneManager, {SceneName} from "./SceneManager";
import {setPickedModule} from "./utils";

import {
    ModuleRampPart,
    ModuleRampPartInputType,
    ModuleRampType,
    useGetInventoryRampArticleListQuery,
    useGetModuleRampByArticleRowLazyQuery,
    useUpdateModuleRampMutation
} from "../generated/graphql";

import './app.css';
import grass from "./image/grass.jpg";
import {Ramp} from "./Module/Ramp";
import {Col, Row} from "reactstrap";
import LoadingPlaceHolder from "./LoadingPlaceHolder";
import downloadDrawingPDF from "./DrawingPDF";
import {ArticleListDataRowsType, ArticleListDataType} from "./ArticleListModal";
import useStepRecordHook from "./hooks/useStepRecordHook";
import useCameraHook, { CameraType } from "./hooks/useCameraHook";

const createMessageOverlay = (scene,advancedTexture)=>
{
    const textBlock = new TextBlock();
    textBlock.color = "white";
    textBlock.fontSize = 24;
    textBlock.isVisible = false;
    advancedTexture.addControl(textBlock);
    return textBlock;
}

export enum Modes
{
    pickRampAttachA,
    pickIndustrialPoleAttachA,
    pickIndustrialRailingAttachA,
    pickIndustrialRailingAttachB,
    pickIndustrialRailingPieceAttachA,
    pickChildSafeAttachA,
    pickChildSafePieceAttachA,
    pickKickPlateAttachA,
    pickKickPlateAttachB,
    pickChildSafeAttachB,
    pickSupportLegAttachA,
    pickAttachB,
    saved,
    normal,
    noAvailableNode,
    noLegFitsHeight,
    belowGround,
    unableToAttach,
    selectionErr,
    debugShowAllNodes,
}

// export enum ThreeView {
//     top,
//     front,
//     side,
// }

export class State {
    get mode(): Modes
    {
        return this._mode;
    }

    set mode(value: Modes)
    {
        this._mode = value;
        this.modeObservable.notifyObservers(value);
    }
    camera:ArcRotateCamera | undefined;

    models:ModuleObject[] = [];
    pickedModule: ModuleObject|null = null;

    private static _singleton:State|null = null
    private _mode:Modes = Modes.normal;
    modeObservable:Observable<Modes> = new Observable<Modes>();
    private _selectedModule:ModuleTemplate | null = null;

    setSelectedModule(module:ModuleTemplate|null) {
        const removeMeshes = SceneManager.getInstance()[SceneName.addModule].meshes;
        for(let i=removeMeshes.length-1; i>=0; i--){ // must dispose backward
            removeMeshes[i].dispose();
        }

        this._selectedModule = module;
        switch (module?.type){
            case ModuleRampType.StartRamp:
            case ModuleRampType.TrianglePlatform:
            case ModuleRampType.Ramp:
            case ModuleRampType.RampToPlatform:
                this.mode = Modes.pickRampAttachA;
                break;
            case ModuleRampType.IndustrialPole:
                this.mode = Modes.pickIndustrialPoleAttachA;
                break;
            case ModuleRampType.IndustrialRailing:
                this.mode = Modes.pickIndustrialRailingAttachA;
                break;
            case ModuleRampType.IndustrialRailingPiece:
                this.mode = Modes.pickIndustrialRailingPieceAttachA;
                break;
            case ModuleRampType.ChildSafeSection:
                this.mode = Modes.pickChildSafeAttachA;
                break;
            case ModuleRampType.ChildSafeRailingPiece:
                this.mode = Modes.pickChildSafePieceAttachA;
                break;
            case ModuleRampType.KickPlate:
                this.mode = Modes.pickKickPlateAttachA;
                break;
            case ModuleRampType.SupportLeg:
            case ModuleRampType.ScrewLeg:
                this.mode = Modes.pickSupportLegAttachA;
                break;
            case ModuleRampType.WoodPole:
                this.mode = Modes.pickIndustrialPoleAttachA;
                break;
            default:
                this.mode = Modes.normal;
                // this._selectedModule = null;
                break;
        }
    }

    pickEdge() {
        this._selectedModule = null;
        this.mode = Modes.normal;
    }

    get selectedModule(): ModuleTemplate | null
    {
        return this._selectedModule;
    }
    getMode()
    {
        return this._mode;
    }


    static getInstance()
    {
        if(!this._singleton)
        {
            this._singleton = new State();
        }
        return this._singleton;
    }
}


export let setPickedModuleData;


export default ({match})=>
{
    // sideBar
    const [selectedModuleType, setSelectedModuleType] = useState<any>([]);
    const [showNewModuleCanvas, setShowNewModuleCanvas] = useState(false);
    const [shouldShowModuleSettingBar, setShouldShowModuleSettingBar] = useState(true);
    const newModuleCanvasRef = useRef<HTMLCanvasElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { data:rampArticleListData, loading:inventoryRampArticleListQueryLoading } = useGetInventoryRampArticleListQuery();

    const previewRef = useRef(null);
    const [models, setModels] = useState<ModuleObject[]>([]);
    const [selectedRampData, setSelectedRampData] = useState<any>(undefined);
    const [price, setPrice] = useState<number>(0);
    const [dataForGrid, setDataForGrid] = useState<any>({columns:[], rows:[]});
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [getModuleRampByArticleRow] = useGetModuleRampByArticleRowLazyQuery();
    const [updateModuleRamp] = useUpdateModuleRampMutation({refetchQueries:["articleRow"]});
    setPickedModuleData = setSelectedRampData;

    const {undo, redo, newStep, canUndo, canRedo} = useStepRecordHook({setModels})
    const {moveCamera} = useCameraHook()

    const updateCSV_Data = useCallback(()=>{
        const data = [["artNo", "count", "name"]];
        const dataRowsForGrid:ArticleListDataRowsType = [];
        let allModuleTemplate:(ModuleTemplate|null)[] = State.getInstance().models.map(m=>m.moduleTemplate);
        let splitModuleTemplate:any = [];
        allModuleTemplate.forEach(moduleTemplate => {
            const {artNos , ...others} = moduleTemplate as ModuleTemplate;
            const singleModuleTemplate = artNos.map(artNo=>{
                return {artNo, ...others};
            });

            splitModuleTemplate.push(...singleModuleTemplate);
        });

        // Calculate BetweenRamp
        let allRampNodes:Node[] = [];
        let betweenRampCount = 0;
        const betweenRamp = store[ModuleRampType.BetweenRamp][0];
        State.getInstance().models.forEach((model)=>{
            if (model.type === ModuleRampType.Ramp){
                allRampNodes = allRampNodes.concat(model.nodes);

            }
        })

        allRampNodes.forEach(node=>{
            if((node.attach[0]?.ancestor as ModuleObject)?.type === ModuleRampType.Ramp &&
                (node.ancestor as Ramp).moduleTemplate.slopeDegree !== (node.attach[0].ancestor as Ramp).moduleTemplate.slopeDegree
            ){
                betweenRampCount++;
            }
        })
        betweenRampCount = Math.floor(betweenRampCount/2); // We double count on each side of node
        splitModuleTemplate = splitModuleTemplate.concat(Array(betweenRampCount).fill({artNo: betweenRamp.artNos[0], name:betweenRamp.name}));

        const rampArticleList = rampArticleListData?.Inventory_searchArticle;
        const content = _.chain(splitModuleTemplate)
            .groupBy('artNo')
            .map(function(m, artNo) {
                const foundArt = rampArticleList?.find(art => {
                    return art.artNo === m[0]?.artNo;
                });
                const unitPrice = foundArt?.price ?? NaN;
                const price =  unitPrice*m.length;
                const currency = foundArt?.currency as string;

                dataRowsForGrid.push({
                    id: artNo,
                    count: m.length,
                    name: m[0]?.name,
                    unitPrice,
                    price,
                    currency,
                });
                return [ artNo, m.length, m[0]?.name, unitPrice, price, currency ];
            })
            .sortBy('artNo')
            .value();
        data.push(...content as string[][]);

        const columns: GridColDef[] = [
            { field: 'id', headerName: 'Article ID', width: 150 },
            { field: 'count', headerName: 'Count', width: 50 },
            { field: 'name', headerName: 'Name', width: 250 },
            { field: 'unitPrice', headerName: 'Unit Price', width: 150, align: 'right', headerAlign:'right' },
            { field: 'price', headerName: 'Sum Price', width: 150, align: 'right', headerAlign:'right' },
        ];

        const dataForGrid: ArticleListDataType = {columns, rows:dataRowsForGrid};
        setDataForGrid(dataForGrid);
    }, [setDataForGrid, rampArticleListData]);

    const handleUndo = useCallback(() =>
    {
        const result = undo()
        if (result)
        {
            setUnsavedChanges(true);
            updateCSV_Data();
        }
    }, [updateCSV_Data, undo]);

    const handleRedo = useCallback(() =>
    {
        const result = redo()
        if (result)
        {
            setUnsavedChanges(true);
            updateCSV_Data();
        }
    }, [updateCSV_Data, redo]);

    const handleNewStep = useCallback((modules: ModuleObject[]) =>
    {
        newStep(modules)
        State.getInstance().models = modules;
        setUnsavedChanges(true);
        updateCSV_Data();
    }, [updateCSV_Data, newStep]);

    const save = useCallback(async ()=>{
        //TODO: Check if price is changed and already have purchase order, ask to update purchase order price
        let moduleRampParts:ModuleRampPartInputType[] = State.getInstance().models.map(m=>{
            const {origin, otherProps, ...others} = m.moduleTemplate;
            return {
                originX: origin.x,
                originY: origin.y,
                originZ: origin.z,
                ..._.omit(otherProps, ["heightForCalculate", "lengthForCalculate", "minHeightMM", "maxHeightMM"]),
                ...others,
            }
        });

        await updateModuleRamp({variables:{
                rampId: match.params.id,
                articleRowId: match.params.articleRowId,
                moduleRampParts,
                articleRow:{
                    price
                }
            }
        });
        State.getInstance().mode = Modes.saved;
        setUnsavedChanges(false);
    }, [updateModuleRamp, match.params.id, match.params.articleRowId, price]);

    const load = useCallback((moduleRampParts:ModuleRampPart[])=>{
        State.getInstance().models.forEach(model=>{
            model.remove();
        });

        const models:ModuleObject[] = [];
        moduleRampParts.forEach( moduleRampPart => {
            const {
                originX,
                originY,
                originZ,
                length,
                width,
                height,
                diameter,
                type,
                slopeDegree,
                ...otherProps
            } = moduleRampPart;

            const moduleTemplate = new ModuleTemplate({
                origin: new Vector3(originX, originY, originZ),
                type: type as ModuleRampType,
                lengthMM: length/scaleRatio,
                widthMM: width/scaleRatio,
                heightMM: height/scaleRatio,
                diameterMM: diameter/scaleRatio,
                slopeDegree,
                ..._.omit(otherProps,"__typename", "id")
            });

            models.push(getModule(moduleTemplate, SceneManager.getInstance()[SceneName.main]));
        })

        // attach nodes
        const allNotAttachedNodes:(Node|null)[] = models.flatMap(m=>m.nodes);
        for(let i=0; i<allNotAttachedNodes.length; i++){
            const node1 = allNotAttachedNodes[i];
            if(!node1) continue;
            for(let j=0; j<allNotAttachedNodes.length; j++){
                const node2 = allNotAttachedNodes[j];
                if(!node2) continue;
                if(node1 === node2) continue;
                if(Vector3.Distance(node1.origin, node2.origin) < pairNodeDistance ){
                    node1.attach.push(node2);
                    node2.attach.push(node1);
                    allNotAttachedNodes[i] = null;
                    allNotAttachedNodes[j] = null;
                }
            }
        }
        State.getInstance().models = models;
        setModels(models);
        setUnsavedChanges(false);
        updateCSV_Data();
        handleNewStep(models)
    }, [setModels, updateCSV_Data, handleNewStep]);

    const deleteModel = useCallback((model:ModuleObject|null)=>{
        if(!model) return;
        const after = State.getInstance().models.filter(m => m!==model);
        model.remove();
        setSelectedRampData(null);
        handleNewStep(after);
    }, [handleNewStep, setSelectedRampData]);

    // Diff effect
    useEffect(()=>{
        if(State.getInstance().models === models) return;

        const additional = models.filter(m => !State.getInstance().models.includes(m));
        const removal = State.getInstance().models.filter(m => !models.includes(m));

        additional.forEach((model)=>{
            model.init();
        });
        removal.forEach((model)=>{
            model.remove();
        });
        State.getInstance().models = models;
    }, [models])

    const camera3DView = useCallback(()=>{
        setShouldShowModuleSettingBar(true);
        moveCamera(CameraType.threeDimensions)
        if (!previewRef.current)
        {
            return;
        }
        const img: HTMLImageElement = previewRef.current;
        img.style.display = "none";
    }, [moveCamera]);

    const cameraThreeView = useCallback((type:CameraType)=>{
        setShouldShowModuleSettingBar(false);
        moveCamera(type)

        if (!canvasRef.current || !previewRef.current)
        {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const img: HTMLImageElement = previewRef.current;
        img.style.display = "block";
        img.src = canvas.toDataURL("image/png");
    }, [moveCamera]);

    const downloadScreenshot = useCallback(() => {
        const mainScene = SceneManager.getInstance()[SceneName.main];
        mainScene.render();
        mainScene.render();
        if(!canvasRef.current) {return;}

        const canvas:HTMLCanvasElement = canvasRef.current;
        const link = document.createElement('a');
        link.href = canvas.toDataURL("image/png");
        link.download = 'image.png';
        link.click();
    }, []);

    const downloadDrawing = useCallback(async () => {
        if(!previewRef.current) {return;}
        const img:HTMLImageElement = previewRef.current;

        cameraThreeView(CameraType.top);
        const topImageData = img.src;
        camera3DView();
        await downloadDrawingPDF(img, topImageData);
    }, [cameraThreeView, camera3DView]);

    const handleDebugShowAllNodesClick = useCallback(() => {
        State.getInstance().mode = Modes.debugShowAllNodes;
    }, []);

    // init
    useEffect(() => {
        if(inventoryRampArticleListQueryLoading) return;
        const sceneManager = SceneManager.getInstance();
        sceneManager.initScene(SceneName.main, canvasRef);
        sceneManager.initScene(SceneName.addModule, newModuleCanvasRef);

        // store ref for disposing
        const scene1 = sceneManager[SceneName.main];
        const scene2 = sceneManager[SceneName.addModule];

        const advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", undefined, sceneManager[SceneName.main]);
        const messageOverlay = createMessageOverlay(sceneManager[SceneName.main], advancedTexture);
        const initModule = getModuleTemplate({type:ModuleRampType.StartRamp, id:0}); //TODO: use selection to pick
        initModule.origin = Vector3.Zero();
        if(initModule){
            const initRamp = getModule(initModule, SceneManager.getInstance()[SceneName.main]);
            setModels(State.getInstance().models.concat(initRamp));
        }else {
            messageOverlay.text = "Cannot initialize because initModule is not valid";
            messageOverlay.isVisible = true;
        }

        const hideInThreeView = new Mesh("hideInThreeView", sceneManager[SceneName.main]);
        // skybox
        const skybox = MeshBuilder.CreateSphere("skyBox", {diameter:10000.0}, sceneManager[SceneName.main]);
        skybox.rotate(Axis.X, Tools.ToRadians(180));
        skybox.material = sceneManager[SceneName.main].materialStore[MaterialName.skyboxMaterial];
        hideInThreeView.addChild(skybox);


        const ground = MeshBuilder.CreateDisc("ground", {radius:5000}, sceneManager[SceneName.main]);
        ground.rotate(Axis.X, Tools.ToRadians(90));

        const groundMaterial = new StandardMaterial("grassMat", sceneManager[SceneName.main]);
        groundMaterial.diffuseTexture = new Texture(`${process.env.PUBLIC_URL + grass}`, sceneManager[SceneName.main]);
        // @ts-ignore
        groundMaterial.diffuseTexture.uScale = 600
        // @ts-ignore
        groundMaterial.diffuseTexture.vScale = 600;
        groundMaterial.diffuseColor = new Color3(1, 1, 1);
        groundMaterial.specularColor = new Color3(0, 0, 0);

        ground.material = sceneManager[SceneName.main].materialStore[MaterialName.groundMaterial];
        // ground.material = groundMaterial;
        // ground.material = groundMaterial;
        //setPickedModuleData(null)
        ground.actionManager = new ActionManager(sceneManager[SceneName.main]);
        const onMouseClickAction = new ExecuteCodeAction(ActionManager.OnPickTrigger, ()=>{setPickedModule(null)});
        ground.actionManager.registerAction(onMouseClickAction);
        hideInThreeView.addChild(ground);

        // const axisMesh = createAxis(sceneManager[SceneName.main],500);
        // hideInThreeView.addChild(axisMesh);

        setupEditorMode(messageOverlay, handleNewStep, ground, setShowNewModuleCanvas);

        const asyncFn = async () => {
            const moduleRampRes = await getModuleRampByArticleRow({variables:{ id: match.params.articleRowId }});
            const articleRow = {...moduleRampRes.data?.articleRow};
            const moduleRampParts = {...articleRow?.article?.moduleRamp?.moduleRampParts} as any;
            if(!moduleRampParts) return;
            // const data = moduleRampParts.map((part)=>({
            //     type: part.type,
            //     diameter:0,
            //     height:0,
            //     length:0,
            // }))
            load(Object.values(moduleRampParts));

            setPrice(articleRow?.price as number);
        }
        asyncFn().then();

        return ()=>{
            console.log("exit")
            State.getInstance().modeObservable.clear();
            State.getInstance().models.forEach((m)=>{m.clearAction()});
            scene1.dispose();
            scene2.dispose();
            scene1.engine.dispose();
            scene2.engine.dispose();
        }
    }, [handleNewStep, getModuleRampByArticleRow, match.params.articleRowId, load, inventoryRampArticleListQueryLoading]);

    useEffect(()=>{
        if(!canvasRef.current || !previewRef.current) {return;}

        const canvas:HTMLCanvasElement = canvasRef.current;
        const img:HTMLImageElement = previewRef.current;
        img.width = canvas.width;
        img.height = canvas.height;
        img.style.left = `${canvas.offsetLeft}px`;

    }, []);

    const _handleSetPrice = useCallback((value)=>{
        setUnsavedChanges(true);
        setPrice(parseFloat(value));
    }, [setPrice]);

    const resizeCanvas = useCallback(() =>
    {
        const canvas = canvasRef.current;
        if (canvas)
        {
            canvas.width = window.innerWidth;
        }
    }, [])

    useEffect(() =>
    {
        resizeCanvas();
        window.addEventListener('resize', resizeCanvas);
        return () =>
        {
            window.removeEventListener('resize', resizeCanvas);
        };
    }, [resizeCanvas]);

    const newModuleCanvasClassName = showNewModuleCanvas ? "newModuleCanvas show" : "newModuleCanvas"

    return <div>
        <Row>
            <SceneControlBar
                articleRowId={match.params.articleRowId}
                canUndo={canUndo}
                canRedo={canRedo}
                undo={handleUndo}
                redo={handleRedo}
                save={save}
                unsavedChanges={unsavedChanges}
                camera3DView={camera3DView}
                cameraThreeView={cameraThreeView}
                downloadScreenshot={downloadScreenshot}
                downloadDrawing={downloadDrawing}
            />
        </Row>

        <Row>
            <Col md={2} style={{maxWidth: "130px"}}>
                <div style={{height: "42px"}}/>
                <MaterialPanel
                    selectedModuleType={selectedModuleType}
                    setSelectedModuleType={setSelectedModuleType}
                />
            </Col>
            <Col>
                <Row>
                    <ModuleSettingBar
                        selectedModuleType={selectedModuleType}
                        newStep={handleNewStep}
                        canvasRef={canvasRef}
                        shouldShow={shouldShowModuleSettingBar}
                    />
                </Row>
                <Row>

                        <div className="canvasWrapper">
                            {inventoryRampArticleListQueryLoading ?<LoadingPlaceHolder />:null }
                            <img
                                className="previewImage"
                                ref={previewRef}
                                alt=""/>
                            <canvas
                                style={{width: "100%", height: "720px"}}
                                ref={canvasRef}
                                id="renderCanvas"
                                touch-action="none"
                                onFocus={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    const x = window.scrollX;
                                    const y = window.scrollY;

                                    setTimeout(() => {
                                        window.scrollTo(x, y);
                                    }, 100)
                                }}
                            />
                            <canvas
                                width={300}
                                height={300}
                                ref={newModuleCanvasRef}
                                id="newModuleCanvas"
                                touch-action="none"
                                className={newModuleCanvasClassName}
                            />

                        </div>

                </Row>
            </Col>
            <Col md={2} style={{maxWidth: "200px"}}>
                <>
                    <InfoBox
                        rampGlobalData={dataForGrid}
                        selectedRampData={selectedRampData}
                        price={price}
                        setPrice={_handleSetPrice}
                        deleteModel={() =>
                        {
                            deleteModel(State.getInstance().pickedModule)
                        }}
                        debugLog={() =>
                        {
                            console.log("pickedModule", State.getInstance().pickedModule)
                        }}
                        onDebugShowAllNodesClick={handleDebugShowAllNodesClick}
                    />
                </>
            </Col>
        </Row>
    </div>
}

