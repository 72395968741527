import React from "react";

const ColumnEmail = () =>
{
    return  {
        field: 'email',
        headerName: 'E-Mail',
        width: 250,
        searchable: true,
        renderCell: (params) => {
            return params.value ? <a href={`mailto:${params.value}`}>{params.value}</a>
                : null
        }
    }
}
export default ColumnEmail