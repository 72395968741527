import {
    Bom,
    Comparator,
    SpiralStairStatus,
    UpdateSpiralStairStatusMutation,
    useCreateBoMsMutation,
    useGetCommentsQuery,
    useGetPackagesLazyQuery,
    User,
    useUpdateBoMsMutation,
    useUpdateSpiralStairMutation,
    useUpdateSpiralStairStatusMutation
} from "../../generated/graphql";
import React, {useCallback, useEffect, useRef, useState} from "react";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {
    Badge,
    Button,
    Dropdown, DropdownItem, DropdownMenu,
    DropdownToggle,
    Input,
    ModalBody,
    ModalFooter,
    Spinner,
    Table
} from "reactstrap";
import {getSpiralStairStatusColor} from "../SpiralStairStatusExt";
import {BomListPanel} from "./ControlSpiralStairBom";
import {ok} from "assert";
import {Link} from "react-router-dom";
import CommentList from "../../common/comment/CommentList";
import Collapser from "../../common/Collapser";
import {formatToSwedishTime} from "../../UTIL";
import CreatePackageModalPanel from "../../package/component/CreatePackageModalPanel";

const PackageTablePanel = (props: { articleRowId: string }) =>
{
    const {articleRowId} = props
    const [getPackagesQuery, {loading, data}] = useGetPackagesLazyQuery({
        variables: {
            filtering:
                {
                    items: [{
                        key: "packageItems.some.articleRow.id",
                        value: Number(articleRowId), comparator: Comparator.Equals
                    }]
                }
        },
    })

    useEffect(() =>
    {
        getPackagesQuery().then()
    }, [getPackagesQuery])

    return <>
        <Collapser label={`Show packages(${data?.result?.list?.length ?? 0})`} open={false}>
            <h5>Packages</h5>
            {loading && <Spinner/>}
            {data?.result?.list && data.result.list.length > 0
                ? <Table bordered striped>
                    <thead>
                    <tr>
                        <th>Shipping mark</th>
                        <th>Type</th>
                        <th>Quantity</th>
                        <th>Weight</th>
                        <th>Length</th>
                        <th>Height</th>
                        <th>Width</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.result.list.map((e) =>
                    {
                        const {id, type, mark, quantity, weight, length, height, width} = e
                        return <tr key={id}>
                            <td>
                                <Link target="_blank" to={`/package-detail/${id}`}>{mark}</Link>
                            </td>
                            <th>{type}</th>
                            <td style={{textAlign: 'right'}}>{quantity}</td>
                            <td style={{textAlign: 'right'}}>{weight}</td>
                            <td style={{textAlign: 'right'}}>{length}</td>
                            <td style={{textAlign: 'right'}}>{height}</td>
                            <td style={{textAlign: 'right'}}>{width}</td>
                        </tr>
                    })}
                    </tbody>
                </Table>
                : <p>no package</p>
            }
        </Collapser>
    </>
}

const CommentsPanel = (props: { articleId: string }) =>
{
    const {articleId} = props
    const {data} = useGetCommentsQuery({variables: {articleId: articleId}})

    return <>
        <Collapser label={`Show comments(${data?.comments.length ?? 0})`} open={false}>
            <h5>Comments</h5>
            <CommentList articleId={articleId} comments={data?.comments ?? []}/>
        </Collapser>
    </>

}

const CreateBomButton = ({createBoms, isCreating}) =>
{
    return <Button onClick={createBoms}
                   disabled={isCreating}>Create Bill of materials {isCreating && <Spinner/>}
    </Button>
}

const SaveButton = ({disabled, onSaveClick, isUpdating}) =>
{
    return <Button size={"sm"} disabled={disabled}
                   onClick={onSaveClick}>Save {isUpdating && <Spinner/>}
    </Button>
}


export default (props: {
    orderId: string,
    articleId: string,
    articleRowId: string,
    boms: Bom[],
    spiralStairStatus: SpiralStairStatus,
    artNo: string,
    onUpdateStatusSuccess: (response: UpdateSpiralStairStatusMutation) => void,
    isEditable: boolean,
    toggle: () => void,
    statusUpdatedBy?: User | null
    statusUpdatedAt?: Date | null
    freeText?: string | null,
    onUpdateFreeTextSuccess?: (freeText:string)=> void
}, element: JSX.Element = <>
    <div>Comment list</div>
</>) =>
{
    const {
        orderId,
        articleId,
        articleRowId,
        spiralStairStatus,
        artNo,
        onUpdateStatusSuccess,
        isEditable,
        toggle,
        statusUpdatedBy,
        statusUpdatedAt,
        freeText,
        onUpdateFreeTextSuccess
    } = props
    const boms = useRef(props.boms)

    const [createBomsMutation, {loading: isCreating}] = useCreateBoMsMutation();
    const [updateBoms, {loading: isUpdating}] = useUpdateBoMsMutation();
    const [updateSpiralStair] = useUpdateSpiralStairMutation();
    const [updateSpiralStairStatusMutation] = useUpdateSpiralStairStatusMutation();

    const [statusDropDownExpand, setStatusDropDownExpand] = useState(false)
    const [spiralStairBom, setSpiralStairBom] = useState<Bom[]>(boms.current)
    const [removeBoms, setRemoveBoms] = useState<Bom[]>([])
    const [freeTextInputValue, setFreeTextInputValue] = useState(freeText ?? '');

    const createBoms = useCallback(async () =>
    {
        const response = await createBomsMutation({variables: {orderId, articleId}})
        const data = response.data?.createBillOfMaterials ?? []
        boms.current = data
        setSpiralStairBom(data)
    }, [createBomsMutation, setSpiralStairBom, orderId, articleId])

    const updateFreeText = useCallback(async () =>
    {
        await updateSpiralStair({
            variables: {
                id: articleId,
                spiralStair: {
                    freeText: freeTextInputValue
                }
            }
        })
        if(onUpdateFreeTextSuccess){
            onUpdateFreeTextSuccess(freeTextInputValue)
        }
    }, [freeTextInputValue, articleId, updateSpiralStair, onUpdateFreeTextSuccess])

    const onSaveClick = useCallback(async (e) =>
    {
        e?.preventDefault();
        if (spiralStairBom.some((e) => !e.quantity || e.quantity <= 0 || !e.artNo))
        {
            NotificationPopup.error(`Error: No article or quantity error.`)
            return
        }

        try
        {
            const boms = spiralStairBom.map((e) => (
                {
                    orderId: orderId,
                    articleId: articleId,
                    artNo: e.artNo ?? "",
                    quantity: e.quantity ? parseInt(String(e.quantity)) : 0,
                }
            ))

            removeBoms.forEach((e) =>
            {
                boms.push({
                    orderId: orderId,
                    articleId: articleId,
                    artNo: e.artNo ?? "",
                    quantity: 0,
                })
            })

            await updateBoms({variables: {boms: boms}});
            setRemoveBoms([])

            if(freeText !== freeTextInputValue){
                await updateFreeText()
            }

            NotificationPopup.success("BOM save success")
            toggle()
        } catch (e)
        {
            NotificationPopup.error(`BOM save failed. error: ${e}`)
        }
    }, [spiralStairBom, updateBoms, orderId, articleId, removeBoms, toggle, freeText, freeTextInputValue, updateFreeText])

    const updateSpiralStairStatus = useCallback(async (updateStatus) => {
        try {
            const response = await updateSpiralStairStatusMutation({variables: {articleId: articleId, status: updateStatus}});
            // const response = await updateSpiralStairStatusMutation({variables: {articleId: articleId}});
            const data = response.data;
            ok(data, `Update spiral stair status response error`);
            onUpdateStatusSuccess(data);
            // await onSaveClick(null);
        } catch (e) {
            NotificationPopup.error(`Update spiral stair status error: ${e}`);
        }

    }, [updateSpiralStairStatusMutation, onUpdateStatusSuccess, articleId]);

    return <>
        <ModalBody>
            <h5>{artNo}
                <span>
                    <Dropdown style={{display: "inline-block"}} isOpen={statusDropDownExpand} toggle={()=>{setStatusDropDownExpand(!statusDropDownExpand)}} >
                        <DropdownToggle nav>
                            <Badge style={{marginLeft: "4px"}}
                                   color={getSpiralStairStatusColor(spiralStairStatus)}>{spiralStairStatus}▼</Badge>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={()=>updateSpiralStairStatus(SpiralStairStatus.Pending)}>
                                <Badge color={getSpiralStairStatusColor(SpiralStairStatus.Pending)}>{SpiralStairStatus.Pending}</Badge>
                            </DropdownItem>
                            <DropdownItem onClick={()=>updateSpiralStairStatus(SpiralStairStatus.Prepackaged)}>
                                <Badge color={getSpiralStairStatusColor(SpiralStairStatus.Prepackaged)}>{SpiralStairStatus.Prepackaged}</Badge>
                            </DropdownItem>
                            <DropdownItem onClick={()=>updateSpiralStairStatus(SpiralStairStatus.Finished)}>
                                <Badge color={getSpiralStairStatusColor(SpiralStairStatus.Finished)}>{SpiralStairStatus.Finished}</Badge>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </span>
            </h5>
            {
                spiralStairBom.length === 0 && removeBoms.length === 0
                    ? <CreateBomButton createBoms={createBoms} isCreating={isCreating}/>
                    : <BomListPanel bomList={spiralStairBom} isEditable={isEditable}
                                    setBomList={bomList => setSpiralStairBom(bomList)}
                                    onRemoveBomClick={(bom) =>
                                    {
                                        if (boms.current.includes(bom))
                                        {
                                            setRemoveBoms([...removeBoms, bom])
                                        }
                                        setSpiralStairBom(spiralStairBom.filter(e => e !== bom))
                                    }}
                    />
            }
            {statusUpdatedBy?.displayName && <p style={{textAlign: 'right'}}>
                Updated status at {formatToSwedishTime(statusUpdatedAt)} by {statusUpdatedBy?.displayName}
            </p>}

            <hr/>
            <h5>Free text</h5>
            <Input
                type="textarea"
                placeholder="Enter free text here"
                value={freeTextInputValue}
                onChange={(e) => setFreeTextInputValue(e.target.value)}
            />

            <hr/>
            <CommentsPanel articleId={articleId}/>
            <hr/>
            {articleRowId && <PackageTablePanel articleRowId={articleRowId}/>}
        </ModalBody>

        <ModalFooter>
            <SaveButton disabled={isUpdating || (spiralStairBom.length === 0 && removeBoms.length === 0)}
                        onSaveClick={onSaveClick}
                        isUpdating={isUpdating}/>
            {articleRowId && <CreatePackageModalPanel orderId={orderId}
                                                      articleRowIds={[articleRowId]}
                                                      openSearchOrder={false}/>}
        </ModalFooter>
    </>
}
