import {groupBy} from "lodash";
import React from "react";
import {SimpleSurfaceTreatment} from "../generated/graphql";

type Props = {
    value: SimpleSurfaceTreatment[]
}

export default (props: Props) =>
{
    const {value} = props
    const filtered = value.filter((e) => e.isCheckedPC);
    const grouped = groupBy(filtered, (e) => e.ralCode ?? 'N/A');

    return (
        <div>
            {Object.entries(grouped).map(([key, items]) => (
                <div key={key} style={{ marginBottom: '10px' }}>
                    <p style={{ fontWeight: 'bold', margin: '0' }}>RAL Code: {key}</p>
                    <p style={{ margin: '0' }}>No. of parts with powdercoating: {items.length}</p>
                </div>
            ))}
        </div>
    );
}
