import {useCallback} from "react";
import SceneManager, {SceneName} from "../SceneManager";
import {BoundingBox, Camera, Color3, Color4} from "@babylonjs/core";
import {createMeasurement, setPickedModule} from "../utils";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Modes, State} from "../ModuleRampPage";

const cameraState = {
    position: Vector3.Zero(),
    target: Vector3.Zero(),
    rotation: Vector3.Zero(),
    radius: 1,
};

export enum CameraType
{
    threeDimensions,
    top,
    front,
    side
}

const useCameraHook = () =>
{
    const camera3DView = useCallback(() =>
    {
        const {models} = State.getInstance();
        const mainScene = SceneManager.getInstance()[SceneName.main];
        const {camera} = mainScene;

        mainScene.clearColor = new Color4(0.2, 0.2, 0.3, 1.0);
        mainScene.getMeshByName("hideInThreeView")?.setEnabled(true);
        const measurements = mainScene.meshes.filter(mesh => mesh.name.includes('measurement'));
        measurements.forEach(measurement =>
        {
            measurement.dispose();
        })

        if (camera)
        {
            camera.mode = Camera.PERSPECTIVE_CAMERA;
            models.forEach((model) =>
            {
                model.disableEdgesRendering();
            });

            camera.setTarget(cameraState.target)
            camera.position = cameraState.position;
            camera.radius = cameraState.radius;
        }
    }, []);

    const cameraThreeView = useCallback((type: CameraType) =>
    {
        const {models} = State.getInstance();
        const mainScene = SceneManager.getInstance()[SceneName.main];
        const {camera} = mainScene;

        State.getInstance().mode = Modes.normal;
        mainScene.clearColor = Color3.White() as unknown as Color4;
        mainScene.getMeshByName("hideInThreeView")?.setEnabled(false);
        setPickedModule(null);
        const measurements = mainScene.meshes.filter(mesh => mesh.name.includes('measurement'));
        measurements.forEach(measurement =>
        {
            measurement.dispose();
        })

        const meshes = mainScene.meshes.filter(mesh => mesh.name.includes('ramp'));
        const sceneBoundingBox = new BoundingBox(
            new Vector3(Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE),
            new Vector3(Number.MIN_VALUE, Number.MIN_VALUE, Number.MIN_VALUE)
        );

        meshes.forEach((mesh) =>
        {
            const boundingBox = mesh.getBoundingInfo().boundingBox;
            sceneBoundingBox.reConstruct(
                Vector3.Minimize(sceneBoundingBox.minimumWorld, boundingBox.minimumWorld),
                Vector3.Maximize(sceneBoundingBox.maximumWorld, boundingBox.maximumWorld)
            );
        });

        let cameraDistance = 0;
        if (camera)
        {
            if (camera.mode === Camera.PERSPECTIVE_CAMERA)
            {
                cameraState.position = camera.position.clone();
                cameraState.target = camera.getTarget().clone();
                cameraState.rotation = camera.rotation.clone();
                cameraState.radius = camera.radius;
            }
            camera.mode = Camera.ORTHOGRAPHIC_CAMERA;

            const center = sceneBoundingBox.center;
            let length = 0;

            switch (type)
            {
                case CameraType.front:
                    const extendXY = sceneBoundingBox.extendSizeWorld.clone();
                    extendXY.z = 0;
                    length = extendXY.length();
                    camera.position = new Vector3(1 + center.x, center.y, center.z);
                    break;
                case CameraType.side:
                    const extendYZ = sceneBoundingBox.extendSizeWorld.clone();
                    extendYZ.x = 0;
                    length = extendYZ.length();
                    camera.position = new Vector3(center.x, center.y, 1 + center.z);
                    break;
                case CameraType.top:
                    const extendXZ = sceneBoundingBox.extendSizeWorld.clone();
                    extendXZ.y = 0;
                    length = extendXZ.length();
                    camera.position = new Vector3(center.x, 1 + center.y, center.z);
                    break;
            }
            cameraDistance = length * 1.2 / (3 * Math.tan((mainScene.activeCamera?.fov || 0) / 2));
            camera.setTarget(center);
            camera.radius = cameraDistance;
            models.forEach((model) =>
            {
                model.enableEdgesRendering({edgesWidth: 4, edgesColor: new Color4(0, 0, 0, 1)});
            })
        }

        const measureStartX = new Vector3(sceneBoundingBox.minimum.x, 0, sceneBoundingBox.minimum.z);
        const measureEndX = new Vector3(sceneBoundingBox.maximum.x, 0, sceneBoundingBox.minimum.z);

        const measureStartZ = new Vector3(sceneBoundingBox.maximum.x, 0, sceneBoundingBox.minimum.z);
        const measureEndZ = new Vector3(sceneBoundingBox.maximum.x, 0, sceneBoundingBox.maximum.z);

        const textSize = Math.sqrt(cameraDistance) * 30;
        createMeasurement(measureStartX, measureEndX, textSize, Vector3.Up(), SceneManager.getInstance()[SceneName.main]);
        createMeasurement(measureStartZ, measureEndZ, textSize, Vector3.Up(), SceneManager.getInstance()[SceneName.main]);

        mainScene.render();
        mainScene.render(); // render twice to ensure no blank image
    }, []);

    const moveCamera = useCallback((type: CameraType) =>
    {
        switch (type)
        {
            case CameraType.threeDimensions:
                camera3DView()
                break;
            case CameraType.top:
            case CameraType.front:
            case CameraType.side:
                cameraThreeView(type)
                break;
        }
    }, [camera3DView, cameraThreeView]);

    return {moveCamera}
}

export default useCameraHook