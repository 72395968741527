import React, {useCallback} from "react";
import {
    useGetInventoryCommentsQuery,
    InventoryComment,
    useDeleteInventoryCommentMutation, CreateInventoryCommentDocument
} from "../../../generated/inventoryGraphql";
import QueryResult from "../../../common/QueryResult";
import {Col, Row, Spinner} from "reactstrap";
import {formatToSwedishTime} from "../../../UTIL";
import Trash from "../../../common/icons/Trash";
import ListGroupItem from "reactstrap/es/ListGroupItem";
import ListGroup from "reactstrap/es/ListGroup";
import ModalForm from "../../../common/ModalForm";
import {Mutation} from "@apollo/client/react/components";
import FormRenderer from "../../../common/FormRenderer";
import {useMutationHandler} from "../../../custom-hook/useMutationHandler";

type CommentsPanelProps = {
    articleId: string
}

type CommentRowProps = {
    comment: Omit<InventoryComment, "updatedAt" | "articleId">
}

const CommentRow = ({comment}: CommentRowProps) =>
{
    const {executeMutation: deleteCommentMutation, loading} = useMutationHandler(useDeleteInventoryCommentMutation)

    const onDeleteClick = useCallback(async () =>
    {
        if (window.confirm(`Are you sure you want to delete this comment?`))
        {
            await deleteCommentMutation({
                variables: {id: comment.id},
                refetchQueries: ["getInventoryComments"]
            })
        }
    }, [comment, deleteCommentMutation])

    return <ListGroupItem key={comment.id}>
        <Row>
            <Col>
                <small>
                    {comment.creator} - {formatToSwedishTime(comment.createdAt)}
                </small>
                <hr/>
                <pre style={{fontSize: "1.1em", whiteSpace: "pre-wrap"}}>
                        {comment.content}
                    </pre>

            </Col>
            {
                loading ? <Spinner color="warning"/>
                    : <Col sm={2}>
                        <span style={{float: "right"}} className="link" onClick={onDeleteClick}>
                            <Trash/>
                        </span>
                    </Col>
            }
        </Row>
    </ListGroupItem>
}

const CreateCommentPanel = ({articleId}: { articleId: string }) =>
{
    return <ModalForm title={"Create comment"} activationButtonText={"Create comment"}>
        {toggle =>
        {
            return <Mutation mutation={CreateInventoryCommentDocument}>
                {createComment =>
                    <FormRenderer
                        formDefinition={[{name: "content", type: "textarea", required: true}]}
                        onSubmit={async ({object}) =>
                        {
                            await createComment({
                                variables: {comment: {...object, articleId: articleId},},
                                refetchQueries: ["getInventoryComments"]
                            });
                            toggle();
                        }}/>
                }
            </Mutation>
        }}
    </ModalForm>
}

export default ({articleId}: CommentsPanelProps) =>
{
    const {data, loading, error} = useGetInventoryCommentsQuery({variables: {articleId: articleId}})

    return <>
        <CreateCommentPanel articleId={articleId}/>
        <br/>
        <br/>
        <Col sm={6} style={{padding: '0'}}>
            <QueryResult loading={loading} error={error}>
                {
                    data?.comments && <ListGroup>
                        {data.comments.map((e, index) => <CommentRow comment={e} key={`comment${index}`}/>)}
                    </ListGroup>
                }
            </QueryResult>
        </Col>
    </>
}