import React, {useMemo} from "react";
import {withRouter} from "react-router";
import {GridInitialStatePro} from "@mui/x-data-grid-pro/models/gridStatePro";
import {lookup} from "../../UTIL";
import {ColumnDefinition} from "./ColumnDefinition";
import BaseDataGrid, {KEY_PAGE_SIZE, TableButton} from "./BaseDataGrid";
import {DataGridProProps} from "@mui/x-data-grid-pro";
import {GridValidRowModel} from "@mui/x-data-grid";
import {GridFilterModel} from "@mui/x-data-grid/models/gridFilterModel";
import {Button, ButtonToolbar} from "reactstrap";

type NonPaginatedTableDefinition = {
    tableKey: string
    disableTooBar?: boolean
    columns: ColumnDefinition []
    initState?: GridInitialStatePro
    pageSize?: number,
    recorder?: (data: any) => string
    buttons?: TableButton[]
}

type Props = DataGridProProps<GridValidRowModel> & {
    definition: NonPaginatedTableDefinition
    data: any[]
}

const DEFAULT_PAGE_SIZE = 5

const NonPaginatedDataGrid = (props: Props) =>
{
    const {definition, data} = props

    const [filterModel, setFilterModel] = React.useState<GridFilterModel>(definition?.initState?.filter?.filterModel ?? {items: []});

    const getRowData = useMemo(() =>
    {
        return data.map((e) =>
            {
                let row: any = {}
                definition.columns.forEach((column) =>
                {
                    row[column.field] = lookup(e, column.field)
                })
                if (definition.recorder)
                {
                    row['__reorder__'] = definition.recorder(e)
                }
                return {...row, ...e}
            }
        )
    }, [data, definition])

    const buttons = useMemo(() =>
    {
        const buttons = definition.buttons

        if (!buttons)
        {
            return null
        }

        return <ButtonToolbar style={{marginBottom: '16px'}}>
            {buttons.map(button =>
            {
                return <Button outline size="sm" key={button.name}
                               style={{marginRight: "8px"}}
                               onClick={() =>
                               {
                                   if (button.filters)
                                   {
                                       setFilterModel(button.filters)
                                   }
                               }}>
                    {button.name}
                </Button>;
            })}
            <Button outline color="warning" size="sm"
                    onClick={() => setFilterModel({items: []})}>
                Clear all filters
            </Button>
        </ButtonToolbar>
    }, [definition.buttons])

    return (
        <>
            {buttons}
            <BaseDataGrid
                {...props}
                autoHeight
                tableKey={definition.tableKey}
                disableTooBar={definition.disableTooBar}
                initialState={
                    {
                        ...definition.initState,
                        columns: {
                            ...definition.initState?.columns,
                            columnVisibilityModel: {
                                ...definition.initState?.columns?.columnVisibilityModel,
                                id: definition.initState?.columns?.columnVisibilityModel?.id ?? false,
                            },
                        },
                        pagination: {
                            paginationModel: {
                                pageSize: localStorage.getItem(`${definition.tableKey}${KEY_PAGE_SIZE}`)
                                    ? parseInt(localStorage.getItem(`${definition.tableKey}${KEY_PAGE_SIZE}`) ?? '0')
                                    : definition.pageSize ?? DEFAULT_PAGE_SIZE,
                            },
                        },
                    }}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                rows={getRowData}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                columns={definition.columns}
            />
        </>
    );
}

export default withRouter(NonPaginatedDataGrid);
