import React, {Component} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';

export default class ProjectSidebar extends Component
{
    render()
    {
        return (
        <Nav pills vertical>
            <NavItem>
                <NavLink to="/createProject" className="nav-link">Create project</NavLink>
            </NavItem>
        </Nav>);
    }
}



