import React, { Component } from 'react';
import UploadFile, {CustomerDocCategoryType, DrawingsCategoryType} from "../../common/UploadFile";
import {filter, map} from 'lodash';
import FileList from "../../common/FileList";
import ArticleList from "./ArticleList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import StdQuery from "../../common/StdQuery";
import getFilesQuery from './graphql/GetFiles.graphql';
import {OrderInfo} from "../Order/SelectArticles";
import { Alert, Button } from 'reactstrap';
import {CommentPanel} from "./CommentComponents";


export default class UploadCustomerDrawing extends Component {

    constructor(props) {
        super(props);
        this.onUpload = this.onUpload.bind(this);
        this.state = {
            files: props.variables.customerDrawings || [],
            moreInformationModal:false,
            comments: props.variables.comments ?? [],
        };
    }

    onUpload(files)
    {
        this.setState({files:this.state.files.concat(files)});
    }

    save = (toggle = true) => {
        this.props.update({
            customerDrawings: {
                value: JSON.stringify(map(this.state.files)),
                type: "json",
            },
            comments: {
                value: JSON.stringify(this.state.comments),
                type: "json"
            }
        }, toggle);
    };

    submit = ()=>
    {
        const submit = ()=>
        {
            this.props.onSubmit({
                requiresMoreInformation: {
                    value: false,
                    type: "Boolean",
                },
                customerDrawings: {
                    value: JSON.stringify(map(this.state.files)),
                    type: "json",
                },
                comments: {
                    value: JSON.stringify(this.state.comments),
                    type: "json"
                }
            })
        };
        if(this.state.files.length > 0)
        {
            submit();
        }
        else
        {
            if(window.confirm(`You have not uploaded any drawings. Are you sure you want to proceed?`))
            {
                submit();
            }
        }
    };

    handleRequestMoreInformationClick = () => {
        if (window.confirm(`Please confirm if more information is needed (if comments are to be added, please add them first`)) {
            this.props.onSubmit({
                comments: {
                    value: JSON.stringify(this.state.comments),
                    type: "json"
                },
                requiresMoreInformation: {
                    value: true,
                    type: "Boolean",
                }
            })
        }
    }

    render()
    {
        const {articles,comment,orderId,autoDrawArticles,initiator,drawingComment} = this.props.variables;
        return <div>

            <OrderInfo orderId={orderId}/>
            {comment && <div>
                <h2>Comment</h2>
                <p>
                    {comment}
                </p>
            </div>}
            <h3>Articles</h3>
            <ArticleList articles={articles} orderId={orderId}/>
            {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
                <ArticleList articles={autoDrawArticles} orderId={orderId}/>
            </>}
            {drawingComment && drawingComment.length > 0 && <><h4>Comment from {initiator}</h4>
                <Alert color="warning">
                     <pre>
                         {drawingComment}
                     </pre>
                </Alert></>}

            <h3>Customer documentation files</h3>
            <StdQuery query={getFilesQuery} variables={{orderId}}>
                {data => {
                    const customerDocumentation = filter(data.files, file => file.fileCategory.id === CustomerDocCategoryType.id);
                    return <React.Fragment>
                        <FileList files={customerDocumentation}/>
                    </React.Fragment>
                }
                }
            </StdQuery>
            <hr/>
            <h3>Uploaded files</h3>
            <FileList files={this.state.files}
                      onDelete={(deletedFile) => {
                          this.setState({files: filter(this.state.files, file => file.id !== deletedFile.id)});
                          this.save(false);
                      }
                      }/>
            <UploadFile fileCategory={DrawingsCategoryType} onSubmit={this.onUpload}/>
            <hr/>

            <CommentPanel comments={this.state.comments}
                          oldComment={comment}
                          setComments={(newComments) => {
                              this.setState({comments: newComments});
                          }}/>
            <div style={{marginTop:"16px"}}>
                <Button color={'danger'} onClick={this.handleRequestMoreInformationClick}>
                    Request more information
                </Button>
            </div>
            <TaskFooter toggle={this.props.toggle} save={() => this.save()} submit={() => this.submit()}/>
        </div>
    }
}

