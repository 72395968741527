import {ModuleObject, Node} from "../ShpaeObject";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import ModuleTemplate, {scaleRatio} from "../ModuleTemplate";
import {Axis, CreateBox, CreateCylinder, CreateTube, Mesh, Space, Tools} from "@babylonjs/core";
import {RampEdge} from "./Ramp";
import {getAngleBetweenVectorsByAxisY, makeCurve} from "../utils";
import {
    nodeOfRampForPole, nodeForIndustrialRailing, nodeRailingForIndustrialPole
} from "../NodeFilterFunctions";
import {MaterialName} from "../Materials";
import {Scene} from "@babylonjs/core/scene";
import { IndustrialRailingPieceType } from "generated/graphql";

export class IndustrialRailing extends ModuleObject {
    startNode:Node|undefined;
    endNode:Node|undefined;

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
        //
        // const endModule = getModule({type:ModuleType.industrialRailingPiece, id:0});
        // const end = new IndustrialRailingCurvePiece(sceneInstance, Vector3.Zero(),endModule);
        // if(this.startNode){
        //     console.log("attach", end.startNode)
        //     end.startNode?.attachTo(this.startNode);
        // }
    }

    build(){
        const {diameter, length, slopeDegree} = this.moduleTemplate;
        const railingSpace = 2;
        const railingUpper = CreateCylinder(`mesh-industrialRailing-${this.id}`, {height:length, diameterTop:diameter, diameterBottom:diameter}, this.scene);
        railingUpper.translate(this.origin, 1);
        railingUpper.rotate(Axis.X, Tools.ToRadians(90), Space.WORLD);
        railingUpper.translate(Axis.Z, length/2, Space.WORLD);
        railingUpper.translate(new Vector3(0, -diameter/2,0), 1, Space.WORLD);

        /*const railingLower = CreateCylinder(`mesh-industrialRailing-${this.id}`, {height:length, diameterTop:diameter, diameterBottom:diameter}, this.scene);
        railingLower.translate(this.origin, 1);
        railingLower.rotate(Axis.X, Tools.ToRadians(90), Space.WORLD);
        railingLower.translate(Axis.Z, length/2, Space.WORLD);
        railingLower.translate(Axis.Y, -railingSpace, Space.WORLD);
        railingLower.translate(new Vector3(diameter, -diameter/2,0), 1, Space.WORLD);*/

        this.meshes.push(railingUpper);
        this.vector = new Vector3(0, 0, length);
        this.startNode = new Node(this, this.origin.clone(), ()=>true);
        this.endNode = new Node(this, this.origin.clone().add(this.vector), ()=>true);
        this.children.push(this.startNode, this.endNode);
        this.nodes.push(this.startNode, this.endNode);
        this.rotate(Axis.X, slopeDegree);
        const railingLower = railingUpper.clone();
        railingLower.translate(Axis.Y, -railingSpace, Space.WORLD);
        this.meshes.push(railingLower);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];

    }

    attachTo(attachNode, fromNode){
        const rampEdge = attachNode.parent.bottomNode?.attach.find(m=>m.parent instanceof RampEdge).parent;
        if(rampEdge && fromNode.parent instanceof IndustrialRailing){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector, rampEdge.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    clone(scene:Scene){
        const cloned = new IndustrialRailing(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}

export class IndustrialRailingCurvePiece extends ModuleObject {
    startNode:Node|undefined;
    endNode:Node|undefined;
    upperPiece:Mesh|undefined;
    lowerPiece:Mesh|undefined;

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build() {
        const {diameter, length, slopeDegree, otherProps} = this.moduleTemplate;
        const {pieceType, curveRadiusMM, extensionLengthMM, jointTargetSlopeDegree, targetQuadrant=1} = otherProps;
        const curveRadius = curveRadiusMM*scaleRatio;
        const extensionLength = extensionLengthMM*scaleRatio;
        const railingSpace = 2;

        this.vector = new Vector3(0, 0, -1);
        switch (pieceType){
            case IndustrialRailingPieceType.EndFront:
                const railingEndUpperCurve = makeCurve(90, curveRadius);
                railingEndUpperCurve.push(new Vector3(curveRadius,0,-extensionLength));
                railingEndUpperCurve.unshift(new Vector3(-extensionLength,0,curveRadius));

                this.upperPiece = CreateTube(`mesh-industrialRailingEndPlug-${this.id}`, {path: railingEndUpperCurve, radius: diameter/2, cap:Mesh.CAP_ALL}, this.scene);
                this.upperPiece.translate(this.origin, 1);
                this.upperPiece.rotate(Axis.Z, Tools.ToRadians(90), Space.WORLD);
                this.upperPiece.translate(new Vector3(0, -curveRadius-diameter/2, (curveRadius-diameter/2+extensionLength)/2), 1, Space.WORLD);
                this.meshes.push(this.upperPiece);

                this.lowerPiece = CreateCylinder(`mesh-industrialRailingEndPlug-${this.id}`, {height:length, diameterTop:diameter, diameterBottom:diameter}, this.scene);
                this.lowerPiece.translate(this.origin, 1);
                this.lowerPiece.rotate(Axis.X, Tools.ToRadians(90), Space.WORLD);
                this.lowerPiece.translate(new Vector3(0, -diameter/2,length/2), 1, Space.WORLD);
                this.meshes.push(this.lowerPiece);

                this.startNode = new Node(this, this.origin.clone(), nodeForIndustrialRailing);
                this.children.push(this.startNode);
                this.nodes.push(this.startNode);

                this.rotate(Axis.X, slopeDegree);
                this.lowerPiece.translate(Axis.Y, -railingSpace, Space.WORLD);
                break;

            case IndustrialRailingPieceType.EndPlug:
                this.upperPiece = CreateCylinder(`mesh-industrialRailingEndPlug-${this.id}`, {height:length, diameterTop:diameter, diameterBottom:diameter}, this.scene);
                this.upperPiece.translate(this.origin, 1);
                this.upperPiece.rotate(Axis.X, Tools.ToRadians(90), Space.WORLD);
                this.upperPiece.translate(new Vector3(0, -diameter/2,length/2), 1, Space.WORLD);
                this.meshes.push(this.upperPiece);

                this.lowerPiece = this.upperPiece.clone()
                this.meshes.push(this.lowerPiece);

                this.startNode = new Node(this, this.origin.clone(), nodeForIndustrialRailing);
                this.children.push(this.startNode);
                this.nodes.push(this.startNode);

                this.rotate(Axis.X, slopeDegree);
                this.lowerPiece.translate(Axis.Y, -railingSpace, Space.WORLD);
                break;

            case IndustrialRailingPieceType.Joint90degree:
                const railing90Curve = makeCurve(90, curveRadius);
                railing90Curve.push(new Vector3(curveRadius,0,-extensionLength));
                railing90Curve.unshift(new Vector3(-extensionLength,0,curveRadius));

                this.upperPiece = CreateTube(`mesh-industrial90joint-${this.id}`, {path: railing90Curve, radius: diameter/2, cap:Mesh.CAP_ALL}, this.scene);
                this.upperPiece.translate(this.origin, 1);

                switch(targetQuadrant){
                    case 1:
                        this.upperPiece.translate(new Vector3((curveRadius+diameter+extensionLength)/2, -diameter/2 - Math.sin(Tools.ToRadians(slopeDegree))/2, -(curveRadius-diameter+extensionLength)/2), 1, Space.WORLD);
                        this.upperPiece.rotate(Axis.Y, Tools.ToRadians(180 ), Space.WORLD);
                        this.upperPiece.rotate(Axis.X, Tools.ToRadians(slopeDegree));
                        break;
                    case 2:
                        this.upperPiece.translate(new Vector3((curveRadius+diameter+extensionLength)/2, -diameter/2 + Math.sin(Tools.ToRadians(slopeDegree))/2, (curveRadius-diameter+extensionLength)/2), 1, Space.WORLD);
                        this.upperPiece.rotate(Axis.Y, Tools.ToRadians(-90 ), Space.WORLD);
                        this.upperPiece.rotate(Axis.Z, Tools.ToRadians(slopeDegree));
                        break;
                    case 3:
                        this.upperPiece.translate(new Vector3(-(curveRadius+diameter+extensionLength)/2, -diameter/2 - Math.sin(Tools.ToRadians(slopeDegree))/2, (curveRadius-diameter+extensionLength)/2), 1, Space.WORLD);
                        this.upperPiece.rotate(Axis.X, Tools.ToRadians(slopeDegree));
                        break;
                    case 4:
                        this.upperPiece.translate(new Vector3(-(curveRadius+diameter+extensionLength)/2, -diameter/2 + Math.sin(Tools.ToRadians(slopeDegree))/2, -(curveRadius-diameter+extensionLength)/2), 1, Space.WORLD);
                        this.upperPiece.rotate(Axis.Y, Tools.ToRadians(90 ), Space.WORLD);
                        this.upperPiece.rotate(Axis.Z, Tools.ToRadians(slopeDegree));
                        break;
                }

                this.meshes.push(this.upperPiece);

                this.lowerPiece = this.upperPiece.clone()
                this.meshes.push(this.lowerPiece);

                this.startNode = new Node(this, this.origin.clone(), nodeForIndustrialRailing);

                switch(targetQuadrant){
                    case 1: default:
                        this.endNode = new Node(this, this.origin.clone().add(new Vector3(extensionLength+curveRadius,0,-(extensionLength+curveRadius))), nodeForIndustrialRailing);
                        break;
                    case 2:
                        this.endNode = new Node(this, this.origin.clone().add(new Vector3(extensionLength+curveRadius,0,extensionLength+curveRadius)), nodeForIndustrialRailing);
                        break;
                    case 3:
                        this.endNode = new Node(this, this.origin.clone().add(new Vector3(-(extensionLength+curveRadius),0,extensionLength+curveRadius)), nodeForIndustrialRailing);
                        break;
                    case 4:
                        this.endNode = new Node(this, this.origin.clone().add(new Vector3(-(extensionLength+curveRadius),0,-(extensionLength+curveRadius))), nodeForIndustrialRailing);
                        break;
                }
                this.children.push(this.startNode, this.endNode);
                this.nodes.push(this.startNode, this.endNode);

                this.lowerPiece.translate(Axis.Y, -railingSpace, Space.WORLD);
                break;

            case IndustrialRailingPieceType.JointPlaneRamp:
                const railingJointCurve:Vector3[] = [];
                railingJointCurve.push(Vector3.Zero());
                const middleOfJoint = new Vector3(0,length*Math.sin(Tools.ToRadians(slopeDegree)),length*Math.cos(Tools.ToRadians(slopeDegree)));
                railingJointCurve.push(middleOfJoint);
                const endPosition = new Vector3(0,length*Math.sin(Tools.ToRadians(jointTargetSlopeDegree)), length*Math.cos(Tools.ToRadians(jointTargetSlopeDegree))).add(middleOfJoint)
                railingJointCurve.push(endPosition);

                this.upperPiece = CreateTube(`mesh-industrial90joint-${this.id}`, {path: railingJointCurve, radius: diameter/2, cap:Mesh.CAP_ALL}, this.scene);
                this.upperPiece.translate(this.origin, 1);
                this.upperPiece.translate(new Vector3(0, -diameter/2, 0), 1, Space.WORLD);
                this.meshes.push(this.upperPiece);

                this.lowerPiece = this.upperPiece.clone()
                this.meshes.push(this.lowerPiece);

                this.startNode = new Node(this, this.origin.clone(), nodeForIndustrialRailing);
                this.endNode = new Node(this, this.origin.clone().add(endPosition), nodeForIndustrialRailing);
                this.children.push(this.startNode, this.endNode);
                this.nodes.push(this.startNode, this.endNode);

                this.lowerPiece.translate(Axis.Y, -railingSpace, Space.WORLD);
                break;
        }
        this.material = this.scene.materialStore[MaterialName.metalMaterial];
    }

    attachTo(attachNode, fromNode){
        const pole = attachNode.parent as IndustrialPole;
        const rampEdge = pole?.bottomNode?.attach.find(m=>m.parent instanceof RampEdge)?.parent as RampEdge;
        if(rampEdge && fromNode.parent instanceof IndustrialRailingCurvePiece){
            let angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector, rampEdge.vector);

            const railingNode = attachNode.attach.find(nodeRailingForIndustrialPole);
            if (!railingNode)
            {
                return;
            }
            if(railingNode.parent.endNode === railingNode &&  fromNode.parent.moduleTemplate.otherProps.pieceType !== IndustrialRailingPieceType.Joint90degree){
                angle += Tools.ToRadians(180);
            }
            // if(attachNode === attachNode.parent.endNode){
            //     angle += Tools.ToRadians(180);
            // }
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
        }
    }

    clone(scene:Scene){
        const cloned = new IndustrialRailingCurvePiece(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }
}

export class IndustrialPole extends ModuleObject {
    vector:Vector3 = Axis.Y.clone();
    bottomNode:Node|undefined;
    railingNode:Node|undefined;
    slopeDegree = 0;
    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {width, height, length, otherProps} = this.moduleTemplate;
        const {offsetMM} = otherProps;
        const box = CreateBox(`mesh-industrialPole-${this.id}`,{width, height, depth:length}, this.scene);

        box.translate(this.origin, 1);
        box.translate(new Vector3(-offsetMM*scaleRatio,height/2,0), 1);
        this.meshes.push(box);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        this.vector = new Vector3(0,0,1);
        this.bottomNode = new Node(this, this.origin.clone(), nodeOfRampForPole);
        this.railingNode = new Node(this, this.origin.clone().add(new Vector3(0,height,)), nodeRailingForIndustrialPole);
        this.children.push(this.bottomNode, this.railingNode);
        this.nodes.push(this.bottomNode, this.railingNode);
    }

    attachTo(attachNode, fromNode){
        const rampEdge = attachNode.parent as RampEdge;
        if(rampEdge && fromNode.parent instanceof IndustrialPole){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector, rampEdge.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
            this.moduleTemplate.slopeDegree = rampEdge.slopeDegree;
            this.vector = rampEdge.vector.clone();
            this.slopeDegree = rampEdge.slopeDegree;
        }
    }

    clone(scene:Scene){
        const cloned = new IndustrialPole(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }

}