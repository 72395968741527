import React, {Component, useCallback, useMemo, useState} from 'react';
import {downloadCEDocumentByArticleRowId, formatCurrency, formatToSwedishTime} from "../UTIL";
import Trash from "./icons/Trash";
import {graphql} from '@apollo/client/react/hoc'
import RemoveArticleRowMutation from './graphql/RemoveArticleRow.graphql';
import UpdateArticleRow from "./UpdateArticleRow";
import {arrayMove} from 'react-sortable-hoc';
import PropTypes from 'prop-types';

import {
    Button,
    Col, Input, PopoverBody,
    PopoverHeader,
    Progress,
    Row,
    Spinner, UncontrolledPopover
} from "reactstrap";
import _, {flowRight as compose, sortBy} from 'lodash';
import Paragraph from "./Paragraph";
import Checkmark from "./icons/Checkmark";
import Pen from "./icons/Pen";
import Clear from "./icons/Clear";
import {round, sortByDate, useStdMutation} from "../spiralStair/lib/Util";
import Copy from "./icons/Copy";
import gql from "graphql-tag";
import NotificationPopup from "./lib/NotificationPopup";
import GetOrder from '../order/graphql/GetOrder.graphql';
import GetQuote from '../quote/graphql/Quote.graphql';
import ColumnId from "./data-grid/columns/ColumnId";
import NonPaginatedDataGrid from "./data-grid/NonPaginatedDataGrid";
import ColumnDate from "./data-grid/columns/ColumnDate";
import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector
} from "@mui/x-data-grid";
import CellArticleRow from "./data-grid/cell/CellArticleRow";
import ColumnCreatedAt from "./data-grid/columns/ColumnCreatedAt";
import PdfIcon from "./icons/PdfIcon";
import DownloadCEDocumentModal from "./DownloadCEDocumentModal";


const TABLE_KEY = "ARTICLE_ROWS"

const duplicateSpiralStair = gql`
    mutation duplicateSpiralStair($articleRowId:ID!)
    {
        duplicateSpiralStair(articleRowId:$articleRowId)
        {
            id
        }
    }
`;

const duplicateStraightStair = gql`
    mutation duplicateStraightStair($articleRowId:ID!)
    {
        duplicateStraightStair(articleRowId:$articleRowId)
        {
            id
        }
    }
`;

const duplicateStraightStairV2 = gql`
    mutation duplicateStraightStairV2($articleRowId:ID!)
    {
        duplicateStraightStairV2(articleRowId:$articleRowId)
        {
            id
        }
    }
`;

const duplicateArticleMutation = gql`
    mutation duplicateArticle($articleRowId:ID!)
    {
        duplicateArticle(articleRowId:$articleRowId)
        {
            id
        }
    }
`;

const Duplicate = ({articleRow: {id, quoteId, article}}) => {
    const [duplicateSpiral, {loadingSpiral}] = useStdMutation("Duplicate Spiral stair", duplicateSpiralStair, {refetchQueries: ["quote"]});
    const [duplicateStraight, {loadingStraight}] = useStdMutation("Duplicate Straight stair", duplicateStraightStair, {refetchQueries: ["quote"]});
    const [duplicateStraightV2, {loadingStraightV2}] = useStdMutation("Duplicate Straight stair v2", duplicateStraightStairV2, {refetchQueries: ["quote"]});

    const [duplicateArticle, {loadingArticle}] = useStdMutation("Duplicate Article", duplicateArticleMutation, {refetchQueries: ["quote"]});
    if (article && quoteId) {
        if (loadingSpiral || loadingStraight || loadingArticle || loadingStraightV2) {
            return <Spinner color={"warning"}/>
        }
        return <span className='link' onClick={() => {
            if (article.spiralStair) {
                if (window.confirm(`Are you sure you want to duplicate this stair?`)) {
                    duplicateSpiral({variables: {articleRowId: id}});
                }
            } else if (article.straightStair) {
                if (window.confirm(`Are you sure you want to duplicate this stair?`)) {
                    duplicateStraight({variables: {articleRowId: id}});
                }
            } else if (article.straightStairV2) {
                if (window.confirm(`Are you sure you want to duplicate this stair?`)) {
                    duplicateStraightV2({variables: {articleRowId: id}});
                }
            }else if (!article.spiralStair && !article.straightStair) {
                if (window.confirm(`Are you sure you want to duplicate this article?`)) {
                    duplicateArticle({variables: {articleRowId: id}});
                }
            }
        }
        }><Copy/></span>
    } else {
        return null;
    }

}

const CEDocumentSection = (props) => {
    const {articleRow} = props

    const [modal, setModal] = useState(false);

    const hasWeldability = useMemo(() => {
        return articleRow?.article?.weldability
    }, [articleRow.article])

    const toggle = useCallback(() => {
        setModal(prevState => !prevState)
    }, [setModal])

    const handleDownloadClick = useCallback(async (locale) => {
        const artNo = articleRow?.article?.artNo
        if(!artNo){
            return
        }
        const fileName = `dop-${artNo}`
        await downloadCEDocumentByArticleRowId(articleRow.id, locale, fileName)
    }, [articleRow])

    if (articleRow.quoteId || articleRow.purchaseOrderId || articleRow.stockArticle || !articleRow.article) {
        return null
    }

    return (
        <>
            <span className='link' onClick={toggle} style={{marginTop: '4px'}}>
                           <PdfIcon size={30}/>
            </span>
            <DownloadCEDocumentModal toggle={toggle} isOpen={modal} hideWarning={hasWeldability}
                                     onClick={handleDownloadClick}/>
        </>
    );
};

class ArticleRows extends Component {
    toggleArticleRowModal() {
        this.setState({
            articleRowModal: !this.state.articleRowModal,
        });
    }

    constructor() {
        super();
        this.state = {
            updateModalOpen: false,
            articleRow: null,
            articleRowModal: false,
            updating: false,
            startDrawQueueFormOpen: false,
            article: null,
            viewFilesModal: false,
            files: null,
            editing: {},

        };
        this.toggleArticleRowModal = this.toggleArticleRowModal.bind(this);
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    async remove(original) {
        if (window.confirm(`Are you sure you wish to remove ${original.name}?`)) {
            await this.props.removeArticleRow({
                awaitRefetchQueries: true,
                refetchQueries: ["quote", "getOrder", "getPurchaseOrder"],
                variables: {id: original.id}
            });
            //TODO:ADD NOTIFICATION HERE.
        }
    }

    async update(articleRows) {
        await this.props.updateArticleRows({
            refetchQueries: ["getPurchaseOrder"],
            update: (cache, {data: {updateArticleRows}}) => {
                if (this.props.orderId) {
                    const data = cache.readQuery({query: GetOrder, variables: {id: parseInt(this.props.orderId)}});
                    cache.writeQuery({
                        query: GetOrder,
                        variables: {id: parseInt(this.props.orderId)},
                        data: {
                            order:
                                {
                                    ...data.order, articleRows: _.map(updateArticleRows, ar => {
                                        const foundRes = _.find(data.order.articleRows, ({id}) => ar.id === id);
                                        if (foundRes) {
                                            return foundRes;
                                        } else {
                                            throw new Error(`Failed to find article row ar.id: ${ar.id}`);
                                        }
                                    }),
                                }
                        }
                    })
                }

                if (this.props.quoteId) {
                    console.log(`writing query`);
                    const id = parseInt(this.props.quoteId);
                    const data = cache.readQuery({query: GetQuote, variables: {id}});
                    cache.writeQuery({
                        query: GetQuote,
                        variables: {id},
                        data: {
                            quote:
                                {
                                    ...data.quote, articleRows: _.map(updateArticleRows, ar => {
                                        const foundRes = _.find(data.quote.articleRows, ({id}) => ar.id === id);
                                        if (foundRes) {
                                            return foundRes;
                                        } else {
                                            throw new Error(`Failed to find article row ar.id: ${ar.id}`);
                                        }
                                    }),
                                }
                        }
                    })
                }
            },
            variables: {articleRows: articleRows.map((row, index) => ({id: row.id, index}))}
        });

    }

    onSortEnd = async ({oldIndex, newIndex}) => {
        if (!this.state.updating) {
            const items = arrayMove(sortBy(this.props.articleRows, item => item.index), oldIndex, newIndex);
            this.setState({updating: true, items});
            await this.update(items);
            this.setState({updating: false});
        }
    };


    submitPriceChange = async (articleRow, price) => {
        const {id} = articleRow;
        if (price) {
            try {
                await this.props.updateArticleRows({
                    update: (cache, {data: {updateArticleRows}}) => {
                        cache.writeFragment({
                            data: updateArticleRows[0],
                            fragment: gql`fragment UpdateArticleRow on ArticleRow{
                                id
                                price
                            }`
                        });
                    },
                    variables:
                        {
                            articleRows: [{id: id, price: parseFloat(price)}]
                        }
                });
            } catch (e) {
                NotificationPopup.error(`Failed to update price`);
            }

        }
        this.setState({editing: _.omit(this.state.editing, id)});
    };

    clearPrice = async (id) => {
        await this.props.updateArticleRows({
            awaitRefetchQueries: true, refetchQueries: ["getOrder", "getPurchaseOrder"],
            variables:
                {
                    articleRows: [{id: id, price: null}]
                }
        });
        this.setState({[`${id}_price`]: null, editing: _.omit(this.state.editing, id)});
    };

    duplicate = (articleRow) => {

    };

    sortList = (list) => {
        return sortBy(list, item => item.index)
    }

    render() {
        const currency = this.props.currency;

        const getColumns = () => {
            const columns = [
                {
                    headerName: 'Art no',
                    field: 'article',
                    sortable: false,
                    renderCell: (params) => {
                        return <CellArticleRow articleRowId={params.id} article={params.value}
                                               stockArticle={params.row.stockArticle}/>
                    }
                },
                {
                    headerName: 'Name',
                    field: 'name',
                    sortable: false,
                    renderCell: (params) => {
                        return <div className='link'
                                    onClick={() => {
                                        this.setState({articleRowModal: true, articleRow: params.row})
                                    }}>{params.value}</div>
                    }
                },
                {
                    field: "quantity",
                    headerName: "Quantity",
                    type: 'number',
                    sortable: false,
                    width: 70,
                },
            ]

            const displayReceivedQuantityAndDate = this.props.displayReceivedQuantityAndDate;
            if (displayReceivedQuantityAndDate) {
                columns.push({
                    headerName: "Received Quantity",
                    field: "receivedQuantity",
                    type: 'number',
                    sortable: false,
                    renderCell: (params) => {
                        const {article} = params.row
                        if (!article || article.supplier?.name === "Eurostair Produktion AB") {
                            return "-"
                        }
                        return <div>{params.value}</div>
                    }
                })
                columns.push(ColumnDate("receivedDate", "Received Date"))
            }

            columns.push(...[
                    {
                        field: "unit",
                        headerName: "Unit",
                        width: 70,
                        sortable: false,
                    },
                    {
                        field: "price",
                        headerName: "Price Per Unit",
                        type: 'number',
                        width: 180,
                        sortable: false,
                        renderCell: (params) => {
                            if (this.state.editing[params.id]) {
                                const priceKey = `${params.id}_price`;
                                const price = this.state[priceKey] == null ? params.price : this.state[priceKey];
                                return <Row style={{margin: 0, width: "120px"}}>
                                    <Col sm={10} style={{padding: 0}}>
                                        <Input autoFocus onFocus={e => e.currentTarget.select()} onKeyUp={(e) => {
                                            //Enter key
                                            if (e.keyCode === 13) {
                                                this.submitPriceChange(params.row, price);
                                            }
                                        }} bsSize="sm" type="number" value={price} onChange={(e) => {
                                            this.setState({[priceKey]: e.target.value});
                                        }}/>
                                    </Col>
                                    <Col sm={2} style={{padding: 0}}>
                                <span className="link" onClick={async () => {
                                    this.submitPriceChange(params.row, price);
                                }}>
                                    <Checkmark/>
                                </span>
                                    </Col>
                                </Row>

                            }
                            return <span>
                        {formatCurrency(params.value)} {currency}
                                <span className="link"
                                      onClick={() => this.setState({editing: {...this.state.editing, [params.id]: true}})}>
                            <Pen/>
                        </span>
                        <span className="link" onClick={() => this.clearPrice(params.id)}>
                            <Clear/>
                        </span>
                    </span>;
                        }
                    },
                    {
                        field: "discount",
                        headerName: "Discount",
                        type: 'number',
                        width: 70,
                        sortable: false,
                        renderCell: (params) => <Paragraph>{params.value} {params.value != null && "%"}</Paragraph>
                    },
                    {
                        field: "Amount",
                        headerName: "Total Amount",
                        type: 'number',
                        sortable: false,
                        renderCell: (params) => {
                            const {price, quantity, discount} = params.row
                            const amount = (price * quantity * ((100 - discount) / 100));
                            return <span>
                        {price && formatCurrency(amount)} {currency} &nbsp;
                    </span>;
                        }
                    },
                    {
                        field: "Actions",
                        sortable: false,
                        width: 100,
                        renderCell: (params) => {
                            return <React.Fragment>
                            <span className='link'
                                  onClick={this.remove.bind(this, params.row)}><Trash/></span>&nbsp;&nbsp;
                                <Duplicate articleRow={params.row}/>
                            </React.Fragment>
                        }
                    },
                ]
            )

            if (this.props.isDisplayCePdf) {
                columns.push({
                    field: "ce",
                    headerName: "CE Document",
                    sortable: false,
                    width: 100,
                    renderCell: (params) => {
                        return <CEDocumentSection articleRow={params.row}/>
                    }
                })
            }

            if (!this.props.hideProdInfo) {
                columns.push({
                    headerName: "Estimated finish production date",
                    field: "productionStatus",
                    sortable: false,
                    renderCell: (params) => {
                        const productionStatus = params.value
                        const {schedulerTasks} = params.row
                        if (productionStatus) {
                            const {estimatedFinishDate, status} = productionStatus;
                            const totalSum = _.sum(_.map(schedulerTasks, t => t.duration));
                            const sumDone = _.sum(_.map(_.filter(schedulerTasks, task => task.finishedAt), task => task.duration));
                            const total = <div>
                                <Paragraph>Total finished: {round(sumDone / totalSum) * 100}%</Paragraph>
                                <Progress value={(sumDone / totalSum) * 100}/>
                                <small>Does not account for delays in tasks.</small>
                            </div>;


                            const taskProgress = _.map(schedulerTasks, task => {
                                if (task.finishedAt) {
                                    return <div key={task.id}>
                                        <Paragraph>{task.taskType.name} finished. {formatToSwedishTime(task.finishedAt)}</Paragraph>
                                        <Progress value={100} color="success"/>
                                    </div>
                                }
                                return <div key={task.id}>
                                    <Paragraph>{task.taskType.name} &nbsp;{round(task.productionStatus * 100)}%</Paragraph>
                                    {task.productionStatus > 1.0 &&
                                        <Paragraph style={{color: "red"}}>Delayed</Paragraph>}
                                    <Progress value={task.productionStatus * 100}/>
                                </div>;
                            });
                            const popoverId = `ProductionStatus-${params.id}`;
                            const lastFinishedAt = _.last(_.map(schedulerTasks, task => task.finishedAt).sort(sortByDate));
                            return <div>
                                {(status === "Finished" || estimatedFinishDate) &&
                                    <span style={{marginRight: "7px"}}>
                                    <UncontrolledPopover trigger="legacy" placement="bottom"
                                                         target={popoverId}>
                                        <PopoverHeader>Production status details</PopoverHeader>
                                        <PopoverBody>
                                            {total}
                                            <hr/>
                                            {taskProgress}
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                        &nbsp;&nbsp;
                                        <Button size="sm" color="primary" id={popoverId}>
                                        Details
                                    </Button>
                                </span>
                                }
                                {status !== "Finished" && `${status} ${estimatedFinishDate ? "till " + formatToSwedishTime(estimatedFinishDate) : ""}`}
                                {status === "Finished" && `${status} at ${formatToSwedishTime(lastFinishedAt)}`}

                            </div>
                        } else {
                            return "Not scheduled";
                        }
                    }
                });
            }

            columns.push(ColumnCreatedAt())
            columns.push(ColumnDate("updatedAt", "Updated At"))
            columns.push(ColumnId())
            return columns
        }

        return (
            <div>
                <UpdateArticleRow isOpen={this.state.articleRowModal} toggle={this.toggleArticleRowModal}
                                  articleRow={this.state.articleRow}/>
                {this.state.updating && <div className={"article-rows-spinner"}>
                    <Spinner size={"lg"} color={"warning"}/>
                </div>}
                <div style={{width: '100%'}}>
                    <NonPaginatedDataGrid
                        rowReordering
                        disableColumnFilter
                        onRowOrderChange={async (row) => {
                            await this.onSortEnd({
                                oldIndex: row.oldIndex,
                                newIndex: row.targetIndex
                            })
                        }}
                        definition={{
                            tableKey: TABLE_KEY,
                            columns: getColumns(),
                            pageSize: 100,
                            recorder: (data) => {
                                let number = data.article?.artNo ?? data.stockArticle?.artNo ?? ''
                                return `${number} ${data.name}`
                            }
                        }}
                        slots={{toolbar: ArticleRowDataGridToolbar}}
                        loading={this.state.updating}
                        data={this.sortList(this.props.articleRows)}/>
                </div>
            </div>
        );
    }
}


ArticleRows.propTypes = {
    articleRows: PropTypes.arrayOf(PropTypes.object).isRequired,
    hideProdInfo: PropTypes.bool,
    isDisplayCePdf: PropTypes.bool
};

const UpdateArticleRowsMutation = gql`
    mutation updateArticleRows($articleRows:[ArticleRowInputType!])
    {
        updateArticleRows(articleRows:$articleRows)
        {
            id
            index
            price
        }
    }`;

const ArticleRowDataGridToolbar = () => {
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarDensitySelector/>
        </GridToolbarContainer>
    );
}

export default compose(graphql(RemoveArticleRowMutation, {name: "removeArticleRow"}),
    graphql(UpdateArticleRowsMutation, {name: 'updateArticleRows'}))(ArticleRows);
