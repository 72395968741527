import React from 'react';
import {omit} from "lodash";

/**
 * No-margin paragraph :)
 * @returns {*}
 */
export default (props)=>
{
    return <p style={{margin:0,...props.style}} {...omit(props, ["style","children"])} >
        {props.children}
    </p>
}