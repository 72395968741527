import {Card, CardBody, CardHeader} from "reactstrap";
import React, {useCallback, useEffect, useState} from "react";
import NewFormRenderer from "../NewFormRenderer";
import DetailRenderer from "../DetailRenderer";
import _ from 'lodash';
import {
    useGetSupplierByProductTypeIdQueryLazyQuery,
    useProductTypesQuery
} from "../../generated/graphql";
import StdQuery from "../StdQuery";
// @ts-ignore
import GetSupplier from '../../supplier/graphql/GetSupplier.graphql';
import {selectRenderer} from "../FormRenderer";
import {WeldabilityTypes} from "./WeldabilityTypes";

export default ({isOrder, article, onChange, hasPurchaseOrder}) => {
    const [getSupplier] = useGetSupplierByProductTypeIdQueryLazyQuery();
    const {data: productTypesData} = useProductTypesQuery({variables:{filtered:true}});
    const [supplierData, setSupplierData] = useState<any>([]);
    const {
        productType,
        supplier,
    } = article;

    let formDefinition:any=[];

    if(!isOrder){
        formDefinition = [
            {name:"productTypeId",label:"productType",type:'select',
                options:_.get(productTypesData,"productTypes") || []},
            {name:"supplierId",label:"Supplier",type:'select',
                options:_.get(supplierData,"productType.suppliers") || []},
        ]
    }
    formDefinition = formDefinition.concat([
        {name: "description", type: "text"},
        {name: "blank",type:"hidden",hideField:()=>true},

        {
            name: "inPrice",
            type: "number",
            required: true,
            disabled: hasPurchaseOrder,
            text: hasPurchaseOrder ? "Edit inPrice on purchase order" : ""
        },
        {name:"inPriceCurrency",required:true,type:'custom',input:function(model,onChange)
            {
                if(model.supplierId) {
                    return <StdQuery query={GetSupplier} variables={{id:model.supplierId}}>
                        {(data)=>
                        {
                            if(data.supplier && data.supplier.currency) {
                                model.inPriceCurrency = data.supplier.currency.toUpperCase();
                                return <p>{data.supplier.currency.toUpperCase()}</p>
                            } else {
                                return selectRenderer({required:true,name:'inPriceCurrency',options:["SEK","NOK","DKK","EUR"]},
                                model.inPriceCurrency,(val)=>onChange(val?.id));
                            }
                        }}
                    </StdQuery>
                }
                return <p>Select supplier...</p>;
            }},
        {
            name: "weldability", type: "select", options: WeldabilityTypes
        },
    ]);

    const _onChange = useCallback(async (key, value)=>{
        if(key==="productTypeId"){
            const res = await getSupplier({variables:{id:value}});
            setSupplierData(res.data);
            article.supplierId = null;
        }
        onChange(key, value);
    }, [onChange, article, getSupplier, setSupplierData]);

    useEffect(() => {
        const getSupplierFn = async () => {
            const res = await getSupplier({variables:{id:article.productTypeId}});
            setSupplierData(res.data);
        }
        if(article.productTypeId){
            getSupplierFn().then();
        }
    }, [getSupplier, setSupplierData, article]);

    return (<Card>
        <CardHeader>
            Article
        </CardHeader>
        <CardBody>
            {isOrder && <DetailRenderer
                hideUpdateInfo
                columns={4}
                object={{
                    productType: productType.name,
                    supplier: supplier.name,
                }}
            />}
            <NewFormRenderer
                formDefinition={formDefinition}
                columns={2}
                object={article}
                onChange={_onChange}
            />
        </CardBody>
    </Card>);
}
