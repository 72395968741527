import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import OrderInvoices from "../OrderInvoices";
import React, {useCallback, useState} from "react";
import {Invoice} from "../../generated/graphql";

enum InvoiceTab
{
    INVOICE,
    INTERVAL,
    INTERVAL_PROXY
}

type InvoiceParam = {
    invoices: Invoice[]
    internalInvoices: Invoice[]
    internalProxyInvoices: Invoice[]
}

const InvoiceSubPanel = ({invoices, internalInvoices, internalProxyInvoices}: InvoiceParam) =>
{
    const [activeTab, setActiveTab] = useState(InvoiceTab.INVOICE)

    const handleNavClick = useCallback((newTab) =>
    {
        setActiveTab(newTab);
    }, [])

    const InvoiceNavItem = ({text, tag}: { text: string, tag: InvoiceTab }) =>
    {
        return <NavItem>
            <NavLink active={activeTab === tag} onClick={() => handleNavClick(tag)}>
                <h6>{text}</h6>
            </NavLink>
        </NavItem>
    }

    return <div>
        <Nav pills>
            <InvoiceNavItem text={'Invoice'} tag={InvoiceTab.INVOICE}/>
            <InvoiceNavItem text={'Internal Invoice'} tag={InvoiceTab.INTERVAL}/>
            <InvoiceNavItem text={'Internal Proxy Invoice'} tag={InvoiceTab.INTERVAL_PROXY}/>
        </Nav>

        <TabContent activeTab={activeTab} style={{marginTop: "30px"}}>
            <TabPane tabId={InvoiceTab.INVOICE}>
                <OrderInvoices invoices={invoices}/>
            </TabPane>
            <TabPane tabId={InvoiceTab.INTERVAL}>
                <OrderInvoices invoices={internalInvoices}/>
            </TabPane>
            <TabPane tabId={InvoiceTab.INTERVAL_PROXY}>
                <OrderInvoices invoices={internalProxyInvoices}/>
            </TabPane>
        </TabContent>
    </div>
}

export default InvoiceSubPanel