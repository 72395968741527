import React, {useCallback, useEffect, useState} from 'react';
import {Table, Input, FormGroup, Label, Button, Alert} from 'reactstrap';
import {SupplierEvaluationQuestion} from "./SupplierEvaluationQuestions";
import {
    SupplierEvaluationType,
    useCreateSupplierEvaluationMutation,
    useGetSupplierEvaluationQuery, useUpdateSupplierEvaluationMutation
} from "../../generated/graphql";
import Spinner from "reactstrap/es/Spinner";
import DatePickerWithWeek from "../../common/DatePickerWithWeek";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {FORMAT_DATE, formatToSwedishTime} from "../../UTIL";


type SupplierEvaluationTableProps = {
    supplierId: string
    type: SupplierEvaluationType
    questions: SupplierEvaluationQuestion[]
}

type Answer = {
    [key: string]: { answer?: string | null, expirationDate?: string | null }
}

export default ({supplierId, type, questions}: SupplierEvaluationTableProps) =>
{
    const {data, loading} = useGetSupplierEvaluationQuery({variables: {supplierId, type}})
    const [createMutation] = useCreateSupplierEvaluationMutation({refetchQueries: ["getSupplierEvaluation"]})
    const [updateMutation] = useUpdateSupplierEvaluationMutation({refetchQueries: ["getSupplierEvaluation"]})

    const [answers, setAnswers] = useState<Answer>(questions.reduce((acc, q) =>
    {
        q.questions.forEach(e =>
        {
            acc[e.key] = {answer: null, expirationDate: null};
        });
        return acc;
    }, {}))

    const handleAnswerChange = useCallback((key: string, value: string | null) =>
    {
        setAnswers(prevState =>
        {
            const temp = prevState[key]
            temp.answer = value
            return {...prevState, [key]: temp}
        });
    }, [setAnswers])

    const handleDateChange = useCallback((key: string, value: string) =>
    {
        setAnswers(prevState =>
        {
            const temp = prevState[key]
            temp.expirationDate = value
            return {...prevState, [key]: temp}
        });
    }, [setAnswers])

    const handleSubmit = useCallback(async () =>
    {
        if (!answers)
        {
            return
        }
        try
        {
            const items = Object.entries(answers)
                .map(([key, value]) =>
                {
                    return ({question: key, answer: value.answer, expirationDate: value.expirationDate})
                })

            if (data && data.supplierEvaluation)
            {
                await updateMutation({
                    variables: {
                        id: data.supplierEvaluation.id,
                        supplierEvaluation: {
                            items: items
                        }
                    }
                })
                NotificationPopup.success(`Update success`)
            } else
            {
                await createMutation({
                    variables: {
                        supplierEvaluation: {
                            supplierId: supplierId,
                            type: type,
                            items: items
                        }
                    }
                })
                NotificationPopup.success(`Create success`)
            }
        } catch (e: any)
        {
            NotificationPopup.error(`${e?.message}`)
        }
    }, [updateMutation, createMutation, answers, data, supplierId, type])

    const renderRadios = (question: {
        key: string,
        text: string
    }, yesText: string, noText: string, hasApplicable: boolean) =>
    {
        return <>
            <FormGroup check inline>
                <Label check>
                    <Input
                        type="radio"
                        name={question.key}
                        onChange={() => handleAnswerChange(question.key, "true")}
                        checked={answers?.[question.key]?.answer === "true"}
                    />
                    {yesText}
                </Label>
            </FormGroup>
            <FormGroup check inline>
                <Label check>
                    <Input
                        type="radio"
                        name={question.key}
                        onChange={() => handleAnswerChange(question.key, "false")}
                        checked={answers?.[question.key]?.answer === "false"}
                    />
                    {noText}
                </Label>
            </FormGroup>
            {
                hasApplicable && <FormGroup check inline>
                    <Label check>
                        <Input
                            type="radio"
                            name={question.key}
                            onChange={() => handleAnswerChange(question.key, null)}
                            checked={answers?.[question.key]?.answer === null}
                        />
                        Not Applicable
                    </Label>
                </FormGroup>
            }
        </>
    }

    const renderQuestionInput = (question, type) =>
    {
        switch (type)
        {
            case 'radioWithDate':
                return <>
                    <td>
                        {renderRadios(question, "Yes", "No", true)}
                    </td>
                    <td>
                        <DatePickerWithWeek
                            name={`${question.key}`}
                            defaultValue={data?.supplierEvaluation?.items?.find((e) => e.question === question.key)?.expirationDate}
                            onChange={(date) => handleDateChange(question.key, date.value)}/>
                    </td>
                </>
            case 'radio':
                return <td>
                    {renderRadios(question, "Approved", "Not approved", true)}
                </td>
            case 'radioWithoutNotApplicable':
                return <td>
                    {renderRadios(question, "Approved", "Not approved", false)}
                </td>
            case 'text':
                return (
                    <td>
                        <Input
                            type="textarea"
                            name={question.key}
                            value={answers?.[question.key]?.answer ?? ""}
                            onChange={(e) => handleAnswerChange(question.key, e.target.value)}
                        />
                    </td>
                );
            default:
                return null;
        }
    };

    useEffect(() =>
    {
        if (data && data.supplierEvaluation)
        {
            setAnswers(data.supplierEvaluation.items.reduce((acc, e) =>
            {
                acc[e.question] = {answer: e.answer, expirationDate: e.expirationDate}
                return acc;
            }, {}))
        }
    }, [data, setAnswers])

    return (
        <div>
            {loading && <Spinner/>}
            {
                !(data && data.supplierEvaluation) && <Alert color="danger">Not evaluated yet</Alert>
            }

            {
                (data && data.supplierEvaluation) && <>
                    <p>Updated by {data.supplierEvaluation.creator?.displayName}</p>
                    <p>Updated date {formatToSwedishTime(data.supplierEvaluation.updatedAt, FORMAT_DATE)}</p>
                </>
            }

            {data && questions.map(section => (
                <div key={section.header}>
                    <h2>{section.header}</h2>
                    <Table striped>
                        <thead>
                        <tr>
                            <th>Question</th>
                            <th>Answer</th>
                            {
                                section.type === "radioWithDate" && <th>Expiration Date</th>
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {section.questions.map(question => (
                            <tr key={question.key}>
                                <td>{question.text}</td>
                                {renderQuestionInput(question, section.type)}
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            ))}

            <Button color="primary" onClick={handleSubmit}>Submit</Button>

        </div>
    );
}
