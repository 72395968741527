import {StraightStairHookProps} from "../StraightStairV2Types";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CreateSeparateKickPlateMutation,
    SeparateKickPlateFragmentFragment,
    useCreateSeparateKickPlateMutation,
    useDeleteSeparateKickPlateMutation
} from "../../generated/graphql";
import {useCallback} from "react";


const useSeparateKickHook = (props: StraightStairHookProps) =>
{
    const {straightStairV2, setStraightStairV2} = props;

    const {
        executeMutation: createSeparateKickPlateMutation,
        loading: addSeparateKickPlateLoading
    } = useMutationHandler(useCreateSeparateKickPlateMutation)

    const {
        executeMutation: deleteSeparateKickPlateMutation,
        loading: deleteSeparateKickPlateLoading
    } = useMutationHandler(useDeleteSeparateKickPlateMutation)

    const setStraightStairSeparateKickPlate = useCallback((onUpdate: (newSeparateKickPlate: SeparateKickPlateFragmentFragment[]) => SeparateKickPlateFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, separateKickPlates: onUpdate([...prevState.separateKickPlates])}
        })
    }, [setStraightStairV2])

    const updateSeparateKickPlate = useCallback((separateKickPlate: SeparateKickPlateFragmentFragment) =>
    {
        setStraightStairSeparateKickPlate((newSeparateKickPlates) =>
        {
            const index = newSeparateKickPlates.findIndex(e => e.id === separateKickPlate.id);
            if (index === -1)
            {
                return newSeparateKickPlates;
            }

            newSeparateKickPlates[index] = separateKickPlate;
            return newSeparateKickPlates;
        })
    }, [setStraightStairSeparateKickPlate])

    const onCreateSeparateKickPlateSuccess = useCallback((data: CreateSeparateKickPlateMutation | null) =>
    {
        const separateKickPlate = data?.createStraightStairSeparateKickPlate
        if (separateKickPlate)
        {
            setStraightStairSeparateKickPlate((newSeparateKickPlates) =>
            {
                newSeparateKickPlates.push(separateKickPlate)
                return newSeparateKickPlates;
            })
        }
    }, [setStraightStairSeparateKickPlate])

    const addSeparateKickPlate = useCallback(async () =>
    {
        if (!straightStairV2)
        {
            return
        }

        return await createSeparateKickPlateMutation({variables: {straightStairV2Id: straightStairV2.id}},
            {onSuccess: onCreateSeparateKickPlateSuccess})
    }, [createSeparateKickPlateMutation, onCreateSeparateKickPlateSuccess, straightStairV2])

    const onDeleteSeparateKickPlateSuccess = useCallback((id: string) =>
    {
        setStraightStairSeparateKickPlate((newSeparateKickPlates) =>
        {
            return newSeparateKickPlates.filter((e) => e.id !== id)
        })
    }, [setStraightStairSeparateKickPlate])

    const deleteSeparateKickPlate = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }
        await deleteSeparateKickPlateMutation({variables: {id: id}},
            {onSuccess: () => onDeleteSeparateKickPlateSuccess(id)})
    }, [straightStairV2, deleteSeparateKickPlateMutation, onDeleteSeparateKickPlateSuccess])

    return {
        addSeparateKickPlateLoading, deleteSeparateKickPlateLoading,
        addSeparateKickPlate, deleteSeparateKickPlate, updateSeparateKickPlate,
    }
}

export default useSeparateKickHook;