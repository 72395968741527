import {DatePicker} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, {useCallback} from "react";


export default (props) =>
{
    const {defaultValue, onChange, disableFuture, disablePast} = props

    const handleOnChange = useCallback((date) =>
    {
        const value = date ? date.format("YYYY-MM-DD") : null
        onChange({value})
    }, [onChange])

    return <DatePicker displayWeekNumber
                       disableFuture={disableFuture}
                       disablePast={disablePast}
                       slotProps={{
                           textField: {fullWidth: true},
                           actionBar: {
                               actions: ['today'],
                           },
                       }}
                       format="YYYY-MM-DD"
                       defaultValue={defaultValue ? dayjs(defaultValue) : null}
                       onChange={handleOnChange}/>
}