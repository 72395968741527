import React, {Component} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';

export default class OrderSidebar extends Component
{
    render()
    {
        return (
        <Nav pills vertical>
            <NavItem>
                <NavLink to="/orderCreate" className="nav-link">Create Order</NavLink>
            </NavItem>
        </Nav>);
    }
}



