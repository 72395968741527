import React, {Component} from 'react';
import {Badge, Button, Collapse} from "reactstrap";


export default class Collapser extends Component{

    constructor(props)
    {
        super();
        this.state = { isOpen:props.open || false};
    }

    toggle()
    {
        this.setState({isOpen:!this.state.isOpen});
    }

    render()
    {
        const {children,label,badge,button} = this.props;
        const {isOpen} = this.state;
        return (
        <div>
            {badge && <Badge color="primary" style={{cursor:"pointer"}} onClick={this.toggle.bind(this)}>{label ||"More.."}</Badge>}
            {(button || !badge) && !isOpen && <Button size="sm" color="primary" style={{cursor:"pointer"}} onClick={this.toggle.bind(this)}>
                {label ||"More.."}
                </Button>}
            {isOpen && !badge && <Badge style={{cursor:"pointer"}} size="sm" color="secondary" onClick={this.toggle.bind(this)}>Hide</Badge>}
            <Collapse isOpen={this.state.isOpen}>
                {children}
            </Collapse>
        </div>);
    }
}
