import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import ArticleList from "./ArticleList";
import {OrderInfo} from "../Order/SelectArticles";
import {DrawingsFileList} from "./FileListComponents";
import {CommentPanel} from "./CommentComponents";

export default class SendCustomerDrawing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comments: props.variables.comments ?? [],
        };
    }
    render()
    {
        const { articles, orderId,autoDrawArticles } = this.props.variables;

        return <div>
            <OrderInfo orderId={orderId}/>
            <h3>Articles</h3>
            <ArticleList articles={articles} orderId={orderId}/>
            {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
                <ArticleList articles={autoDrawArticles} orderId={orderId}/>
            </>}

            <CommentPanel comments={this.state.comments}
                          setComments={(newComments) => {
                              this.setState({comments: newComments});
                          }}/>
            <hr/>
           <DrawingsFileList orderId={orderId}/>
            <Row className="justify-content-between">
                <Col sm={2}>
                    <Button onClick={() => this.props.toggle()}>
                        Cancel
                    </Button>
                </Col>
                <Col sm={2}>
                    <Button color="success" onClick={() => this.props.onSubmit({
                        comments: {
                            value: JSON.stringify(this.state.comments),
                            type: "json"
                        }
                    })}>
                        Complete task
                    </Button>
                </Col>
            </Row>

        </div>
    }
}

