import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {getGridBooleanOperators, GridFilterOperator} from "@mui/x-data-grid";
import {
    FilterOperatorDate,
    FilterOperatorNumber,
    FilterOperatorSingleSelect,
    FilterOperatorString
} from "./FilterOperatorDefinitions";
import {camelToTitle} from "../../UTIL";

export type ColumnDefinition = GridColDef & {
    customFilterOperators?: GridFilterOperator[]
    searchable?: boolean
    customFilterField?: string
    customSearchField?: string
    customSortField?: string
    parseFilterValue?: (value: string) => any
}

export const createColumns = (columns: ColumnDefinition[], columnsWidth: any = {}, columnOrdering: string[]) =>
{
    const indexMap = new Map<string, number>();
    columnOrdering.forEach((field, index) => indexMap.set(field, index));

    return columns.map((column) =>
    {
        let filterOperators: GridFilterOperator[] | null
        if (column.customFilterOperators)
        {
            filterOperators = column.customFilterOperators
        } else
        {
            switch (column.type)
            {
                case 'date':
                    filterOperators = FilterOperatorDate
                    break;
                case 'singleSelect':
                    filterOperators = FilterOperatorSingleSelect
                    break;
                case 'number':
                    filterOperators = FilterOperatorNumber
                    break;
                case 'boolean':
                    filterOperators = getGridBooleanOperators()
                    break;
                default:
                    filterOperators = FilterOperatorString
                    break;
            }
        }

        return {
            ...column,
            headerName: column.headerName ?? camelToTitle(column.field),
            width: columnsWidth[column.field] ?? column.width ?? 150,
            filterOperators: filterOperators
        }
    }).sort((a, b) =>
    {
        const aIndex = indexMap.get(a.field);
        const bIndex = indexMap.get(b.field);
        if (aIndex === undefined || bIndex === undefined)
        {
            return 0;
        } else
        {
            return aIndex - bIndex;
        }
    })
}