import _, {omit} from "lodash";
import React from "react";
import gql from "graphql-tag";
import {format} from "date-fns";
import {useMutation} from "@apollo/client";
import StdQuery from "../../common/StdQuery";
import EditableDetailRenderer from "../../common/EditableDetailRenderer";
import {mapSelectValue, transformSelectValue} from "../lib/UTIL";


const formQuery = gql`
    query
    {
        suppliers(pagination: {pageIndex: 0,pageSize: 1000},ordering:{id:"name",desc:false},filtering:[] )
        {
            list
            {
                id
                name
            }
        }

        termsOfDelivery
        {
            name:label
            id:value
        }
        modeOfDelivery
        {
            name:label
            id:value
        }
        termsOfPayments
        {
            name:label
            id:value
        }

    }
`

export default ({purchaseOrder, id}) =>
{
    const [update] = useMutation(gql`mutation($id:ID!,$po:PurchaseOrderInputType!){
        updatePurchaseOrder(purchaseOrder: $po,id:$id)
        {
            id
        }
    }`, {refetchQueries: ["getInventoryPurchaseOrder"]});

    return <StdQuery query={formQuery} options={null} variables={null}>
        {data =>
            <EditableDetailRenderer extra={null} open={null} onSubmit={async ({object}) =>
            {
                await update({
                    variables: {
                        po: omit(object, ['status']),
                        id
                    }
                });
            }} formDefinition={[
                {
                    name: "supplierId",
                    required: true,
                    type: "select",
                    label: "supplier",
                    options: data.suppliers.list,
                    transformValue: ({object}) =>
                    {
                        const value = object['supplier']
                        return {...value, isInventory: true}
                    }
                },
                {
                    name: "currency", required: true, type: "currencyPicker"
                },
                {
                    name: "termsOfDelivery",
                    label: "Terms of Delivery",
                    type: "select",
                    transformValue: transformSelectValue,
                    mapValueFn: mapSelectValue,
                    options: data.termsOfDelivery,
                },
                {
                    name: "modeOfDelivery",
                    type: "select",
                    transformValue: transformSelectValue,
                    mapValueFn: mapSelectValue,
                    options: data.modeOfDelivery,
                },
                {
                    name: "dateOfDispatch", type: "date",
                },
                {
                    name: "status", disabled: true
                },
                {
                    name: "termsOfPayment", type: "select",
                    transformValue: transformSelectValue,
                    mapValueFn: mapSelectValue,
                    options: data.termsOfPayments
                },
                {
                    name: "supplierRef"
                },
                {
                    name: "pdfText", type: "textarea"
                }

            ]} object={_.omit({
                ...purchaseOrder,
                createdAt: format(new Date(purchaseOrder.createdAt), "yyyy-MM-dd HH:mm"),
            }, ["id", "articleRows"])}/>
        }
    </StdQuery>
}
