import React, {useEffect, useState} from "react";
import gql from "graphql-tag";
import {useApolloClient} from "@apollo/client";
import Logger from "./Logger";
import EventEmitter from "./common/EventEmitter";
const ADMIN_GROUP = "admin-group";
export const ECONOMY_GROUP = "economy";
const LOGISTICS_GROUP = "logistics";


const groupQuery = gql`
    query getGroups
    {
        groups
    }
`;

class Auth {

    /**
     * Authenticate a user. Save a token string in Local Storage
     *
     * @param {string} token
     */
    static authenticateUser(token) {
        localStorage.setItem('token', token);
    }

    /**
     * Check if a user is authenticated - check if a token is saved in Local Storage
     *
     * @returns {boolean}
     */
    static isUserAuthenticated() {
        return localStorage.getItem('token') !== null;
    }

    /**
     * Deauthenticate a user. Remove a token from Local Storage.
     *
     */
    static deauthenticateUser() {
        EventEmitter.emit("logout");
        console.log(`emitting logout`);
        localStorage.removeItem('token');
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getToken() {
        return localStorage.getItem('token');
    }

    static setTenant(tenant)
    {
        return localStorage.setItem("tenant",tenant);
    }

    static getTenant()
    {
        return localStorage.getItem("tenant");
    }

    static setSearchAllTenant(searchAllTenant)
    {
        return localStorage.setItem("searchAllTenant",searchAllTenant);
    }

    static getSearchAllTenant()
    {

        return localStorage.getItem("searchAllTenant");
    }

    static setUsername(username)
    {
        return localStorage.setItem("username",username);
    }

    static getUsername()
    {
        return localStorage.getItem("username");
    }

    static setGroups(groups)
    {
        return localStorage.setItem("groups",groups);
    }

    static getGroups()
    {
        const groups = localStorage.getItem("groups");
        if(!groups)
        {
            return "";
        }
        return groups;
    }

}

export const IsLogistics = ({children,altRender})=>
{
    return <UserGroupHasAccess children={children} group={LOGISTICS_GROUP}/>
};

export const IsEconomy = ({children,altRender})=>
{
    return <UserGroupHasAccess children={children} group={ECONOMY_GROUP}/>
};
export const IsAdmin = ({children})=>
{
    return <UserGroupHasAccess children={children} group={ADMIN_GROUP}/>
};
//fetches groups from either local storage or through a query.
const fetchGroups = async(setGroups,client)=>
{
    const res = await client.query({query:groupQuery,fetchPolicy:"cache-first"});
    if(res.data.groups)
    {
        localStorage.setItem("groups",res.data.groups);
        setGroups(res.data.groups);
    }
    else
    {
        Logger.error(`Failed to fetch user's groups`,{user:Auth.getUsername(),errors:res.errors});
    }
};

const UserGroupHasAccess = ({children,group})=>
{
    const [groups,setGroups] = useState([]);
    const client = useApolloClient();
    useEffect(()=>
    {
        fetchGroups(setGroups,client);
    },[client]);

    if((!groups || groups.indexOf(group)=== -1) && groups.indexOf(ADMIN_GROUP) === -1)
    {
        return null;
    }
    else {
        return children;
    }

};

export default Auth;
