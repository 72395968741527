import React, {Component} from 'react';
import {Col} from 'reactstrap';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import CreateContactMutation from './graphql/CreateContact.graphql';
import Auth from "../Auth";
import FormRenderer from "../common/FormRenderer";


export const ContactFormDefinition = (contact)=>
[
    {name:"name",required:true},
    {name:"title"},
    {name:"description",type:"textarea"},
    {name:"email",type:"email"},
    {name:"companyId",label:"Select company",
        required:true,
        type:"search",
        filter:searchObj=>searchObj.type === "company",
        startValue:contact?{value:contact.company.id,label:contact.company.name}:null
    },
    {name:"buysOnline",type:"checkbox"},
    {name:"mobilePhone"},
    {name:"workPhone"},
    {name:"department"},
    {name:"assigneeId",label:"Select assignee",type:"userPicker"},
    {name:"isActive", type: "checkbox"},
];

class CreateContact extends Component
{


    render()
    {
        const {companyId,companyName} = this.props.match.params;

        let company;
        if(companyId)
        {
            company = {company:{id:parseInt(companyId),name:decodeURIComponent(companyName)}};
        }
        console.log(company);
        return <Col sm={6}>
            <FormRenderer onSubmit={this.createContact} object={{companyId:parseInt(companyId)}}
                          formDefinition={ContactFormDefinition(company)}/>
        </Col>

    }
    createContact = async (contactForm)=>
    {
        const contact = contactForm.object;
        contact.tenantId = Auth.getTenant();
        const data = (await this.props.createContact({
            variables:{contact}
        })).data;
        this.props.history.push(`/contactDetail/${data.createContact.id}`);
    }



}


export default graphql(CreateContactMutation,{name:"createContact",options:{refetchQueries:["getCompany"]}})(withRouter(CreateContact));
