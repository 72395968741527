import {ModuleObject} from "../ShpaeObject"
import {Dispatch, SetStateAction, useCallback, useMemo, useState} from "react"
import {State} from "../ModuleRampPage";

type Props = {
    setModels: Dispatch<SetStateAction<ModuleObject[]>>
}

type RecordState = {
    stepIndex: number
    stepRecord: ModuleObject[][]
}

const useStepRecordHook = (props: Props) =>
{
    const {setModels} = props

    const [recordState, setRecordState] = useState<RecordState>({stepIndex: 0, stepRecord: []})

    const canUndo = useMemo(() =>
    {
        return recordState.stepIndex > 0
    }, [recordState.stepIndex])

    const canRedo = useMemo(() =>
    {
        return recordState.stepIndex < recordState.stepRecord.length - 1
    }, [recordState])

    const undo = useCallback(() =>
    {
        if (!canUndo)
        {
            return false
        }

        setRecordState(prevState =>
        {
            const {stepIndex, stepRecord} = prevState
            const newStepIndex = stepIndex - 1
            const modules = stepRecord[newStepIndex]
            setModels(modules)
            return {stepIndex: newStepIndex, stepRecord}
        })

        return true
    }, [setModels, canUndo])

    const redo = useCallback(() =>
    {
        if (!canRedo)
        {
            return false
        }

        setRecordState(prevState =>
        {
            const {stepIndex, stepRecord} = prevState
            const newStepIndex = stepIndex + 1
            const modules = stepRecord[newStepIndex]
            setModels(modules)
            return {stepIndex: newStepIndex, stepRecord}
        })
        return true
    }, [canRedo, setModels])

    const newStep = useCallback((modules: ModuleObject[]) =>
    {
        setRecordState(prevState =>
        {
            const {stepIndex, stepRecord} = prevState
            const newStepRecord = stepRecord.slice(0, stepIndex + 1)
            newStepRecord.push(modules)
            return {stepIndex: newStepRecord.length - 1, stepRecord: newStepRecord}
        })

        setModels(modules)
        State.getInstance().models = modules
    }, [setModels])

    return {
        undo, redo, newStep,
        canUndo, canRedo
    }
}

export default useStepRecordHook