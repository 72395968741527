import React, {memo, useCallback, useMemo, useState} from "react";
import {
    CreateSeparateKickPlateMutation,
    SeparateKickPlateFragmentFragment,
} from "../../generated/graphql";
import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";
import Pen from "../../common/icons/Pen";
import Trash from "../../common/icons/Trash";

const SeparateKickPlateModal = memo(({separateKickPlate, isOpen, toggle, onUpdateClick}:
                                         {
                                             separateKickPlate: SeparateKickPlateFragmentFragment,
                                             isOpen: boolean, toggle: () => void
                                             onUpdateClick: (separateKickPlate: SeparateKickPlateFragmentFragment) => void
                                         }) =>
{
    const [separateKickPlateData, setSeparateKickPlateData] = useState(separateKickPlate)

    const onChange = useCallback((key: string, value: string) =>
    {
        setSeparateKickPlateData(prevState => ({...prevState, [key]: value}))
    }, [])

    const onUpdate = useCallback(() =>
    {
        onUpdateClick(separateKickPlateData)
        toggle()
    }, [onUpdateClick, separateKickPlateData, toggle])

    const separateKickPlateFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "length",
                type: "number",
                min: 250,
                max: 3000,
                step: 10,
                hint: '(250~3000mm)'
            },
            {
                name: "quantity", type: "number",
                min: 1,
                max: 250,
                hint: '(1~250)'
            },
        ]
    }, [])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader isOpen={isOpen} toggle={toggle}>Edit Separate Kick Plate</ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <NewFormRenderer
                        formDefinition={separateKickPlateFormDefinition}
                        object={separateKickPlateData}
                        onChange={onChange}/>
                </Col>
            </Row>

        </ModalBody>
        <ModalFooter>
            <Button color="success" onClick={onUpdate}>Update</Button>
        </ModalFooter>
    </Modal>
})


const SeparateKickPlateRow = memo(({separateKickPlate, onEditClick, onDeleteClick}: {
    separateKickPlate: SeparateKickPlateFragmentFragment,
    onEditClick: (id: string) => void, onDeleteClick: (id: string) => void
}) =>
{
    const textAlignRight: any = {textAlign: "right"}

    return <tr key={`separateKickPlate${separateKickPlate.id}`}>
        <td style={textAlignRight}>{separateKickPlate.length}</td>
        <td style={textAlignRight}>{separateKickPlate.quantity}</td>
        <td>
            <span className="link" onClick={() => onEditClick(separateKickPlate.id)}>
                <Pen/>
            </span>
            <span className="link" style={{marginLeft: "12px"}}
                  onClick={() => onDeleteClick(separateKickPlate.id)}>
                <Trash/>
            </span>
        </td>
    </tr>
})

const StraightStairSeparateKickPlatePanel = memo(({
                                                      separateKickPlates,
                                                      addSeparateKickPlate,
                                                      deleteSeparateKickPlate,
                                                      updateSeparateKickPlate
                                                  }:
                                                      {
                                                          separateKickPlates: SeparateKickPlateFragmentFragment[],
                                                          addSeparateKickPlate: () => Promise<CreateSeparateKickPlateMutation | null | undefined>,
                                                          deleteSeparateKickPlate: (id: string) => void,
                                                          updateSeparateKickPlate: (separateKickPlate: SeparateKickPlateFragmentFragment) => void
                                                      }) =>
{
    const [separateKickPlate, setSeparateKickPlate] = useState<SeparateKickPlateFragmentFragment | null>(null)

    const toggleSeparateKickPlateModal = useCallback(() => setSeparateKickPlate(null), [])

    const onEditClick = useCallback((id) =>
    {
        const separateKickPlate = separateKickPlates.find(e => e.id === id)
        if (separateKickPlate)
        {
            setSeparateKickPlate(separateKickPlate)
        }
    }, [separateKickPlates])

    const handleDeleteClick = useCallback((id: string) =>
    {
        if (window.confirm(`Are you sure you want to delete separate kick plate?`))
        {
            deleteSeparateKickPlate(id)
        }
    }, [deleteSeparateKickPlate])

    const handleAddClick = useCallback(async () =>
    {
        const response = await addSeparateKickPlate()
        if (response?.createStraightStairSeparateKickPlate)
        {
            setSeparateKickPlate(response.createStraightStairSeparateKickPlate)
        }
    }, [addSeparateKickPlate])

    return <>

        <h3>Separate Kick Plates</h3>

        {
            separateKickPlates.length > 0
                ? <Table striped responsive bordered>
                    <thead>
                    <tr>
                        <th>Length</th>
                        <th>Quantity</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        separateKickPlates.map((separateKickPlate) =>
                        {
                            return <SeparateKickPlateRow key={`separateKickPlate${separateKickPlate.id}`}
                                                         separateKickPlate={separateKickPlate}
                                                         onEditClick={onEditClick}
                                                         onDeleteClick={handleDeleteClick}/>
                        })
                    }
                    </tbody>
                </Table>
                : <p>No separate kick plates</p>
        }

        <Button color="primary" style={{marginTop: "4px"}} onClick={handleAddClick}>Add separateKickPlate</Button>

        {separateKickPlate &&
            <SeparateKickPlateModal separateKickPlate={separateKickPlate} isOpen={true}
                                    toggle={toggleSeparateKickPlateModal} onUpdateClick={updateSeparateKickPlate}/>}
    </>
})

export default StraightStairSeparateKickPlatePanel