import React, {Component} from 'react';
import gql from 'graphql-tag';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import ProjectForm from './ProjectForm';
import {flowRight as compose} from 'lodash';
import UpdateProjectMutation from './graphql/UpdateProject.graphql';

class UpdateContactPage extends Component
{

    render()
    {
        const data = this.props.data;
        if(data.loading)
        {
            return (<p>Loading..</p>);
        }
        else if(data.error)
        {
            return (<p>Error..</p>);
        }
        let project = data.project;
        return (<ProjectForm project={project} onSubmit={(project) =>this.updateProject(project)}/>);
    }
    async updateProject(projectForm)
    {
        console.log(projectForm);
        console.log(await this.props.updateProject({
            variables:{id:parseInt(this.props.match.params.id),project:projectForm.project}
            //,companies:_.map(projectForm.companies,comp => comp.value)
        }));
        //extract id and go to detailview.
        this.props.history.push(`/projectDetail/${this.props.match.params.id}`);
    }



}

const getProject = gql`
query getProject($id:ID!)
{
    project(id:$id)
    {
        id
        name
        description
#        companies 
#        {
#          value:id
#          label:name
#        }

    }
}`;
export default compose(graphql(UpdateProjectMutation, {name: "updateProject"}), graphql(getProject, {
    options:
        (props) => {
            return {variables: {id: parseInt(props.match.params.id)}};
        }
}))(withRouter(UpdateContactPage));

