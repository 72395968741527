import React from "react";
import {find} from "lodash";
import {Badge} from "reactstrap";
import {MultiSelectFilterOperators} from "../MultiSelectInput";

type StatusType = {
    id: string
    name: string
    color: string
}

const ColumnsStatus = (list: StatusType[]) =>
{
    return {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: list.map((e) =>
        {
            return {value: e.id, label: e.name}
        }),
        renderCell: (params) =>
        {
            const status = find(list, s => s.id === params.value);
            return status ? <Badge size='lg' style={{backgroundColor: status.color}}>{status.name}</Badge> : null
        },
        customFilterOperators: MultiSelectFilterOperators(list)
    }
}

export default ColumnsStatus