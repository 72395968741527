import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {withApollo} from '@apollo/client/react/hoc'
import Picker from './common/Picker';
import SearchQuery from './common/graphql/FreeTextSearchQuery.graphql';
import logger from './Logger';
import {downloadFile} from "./UTIL";


class FreeTextSearch extends Component {
    onChange(selected)
    {
        if(selected)
        {
            switch(selected.type)
            {
                case "company":
                    this.props.history.push(`/companyDetail/${selected.value}`);
                    break;
                case "contact":
                    this.props.history.push(`/contactDetail/${selected.value}`);
                    break;
                case "project":
                    this.props.history.push(`/projectDetail/${selected.value}`);
                    break;
                case "order":
                    this.props.history.push(`/orderDetail/${selected.value}`);
                    break;
                case "purchaseOrder":
                    this.props.history.push(`/purchaseOrderDetail/${selected.value}`);
                    break;
                case "articleRow":
                    this.props.history.push(`/orderDetail/${selected.value}`);
                    break;
                case "file":
                    downloadFile(selected.value,selected.label);
                    break;
                case "quote":
                    this.props.history.push(`/quoteDetail/${selected.value}`);
                    break;
                default:
                    logger.error("Free text search: Dont know this type..." + selected.type);
                    break;
            }
        }

    }

    render()
    {
        console.log("this.props.searchAllTenant from FreeTextSearch", this.props.searchAllTenant);
        return (
        <React.Fragment>
            <div style={{maxWidth:"250px"}}>
                <Picker filter={searchObj=>searchObj.type !== "project"}
                        resetInput={true}
                        query={SearchQuery}
                        getSearchAllTenant={this.props.getSearchAllTenant}
                        onChange={this.onChange.bind(this)}/>
            </div>

        </React.Fragment>

        );
    }
}

export default withApollo(withRouter(FreeTextSearch));
