import {PackageItemsCreator} from "../ArticleRowWithPackageQuantity";
import React, {useCallback, useEffect, useState} from "react";
import {ArticleRowFragmentFragment} from "../../generated/graphql";
import CellArticleRow from "../../common/data-grid/cell/CellArticleRow";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {Link} from "react-router-dom";

const TABLE_KEY = "TABLE_PACKAGE_ITEMS"

const PackageItemsDataGrid = (props: { packageItemsCreator: PackageItemsCreator[] }) =>
{
    const {packageItemsCreator} = props

    const [data, setData] = useState<(ArticleRowFragmentFragment & {
        orderId: string
        orderNumber: string
    })[]>([])

    useEffect(() =>
    {
        const result = packageItemsCreator.flatMap((e) =>
        {
            const orderId = e.orderId
            const orderNumber = e.orderNumber
            return e.items.map((item) =>
            {
                return {
                    ...item,
                    orderId: orderId,
                    orderNumber: orderNumber
                }
            })
        })

        setData(result)
    }, [packageItemsCreator])

    const getColumns = useCallback(() =>
    {
        return [
            {
                headerName: 'Art no',
                field: 'article',
                filterable: false,
                renderCell: (params) =>
                {
                    return <CellArticleRow articleRowId={params.id} article={params.value}
                                           stockArticle={params.row.stockArticle}/>
                }
            },
            {
                field: "name",
                headerName: "Name"
            },
            {
                field: "packagedQuantity",
                headerName: "Package quantity",
                type: 'number',

            },
            {
                field: "order",
                headerName: "Order",
                renderCell: (params) =>
                {
                    const {orderId, orderNumber} = params.row
                    return <Link to={`/orderDetail/${orderId}`}>{orderNumber}</Link>
                }
            },
            ColumnId()
        ]
    }, [])

    return <NonPaginatedDataGrid
        definition={{
            tableKey: TABLE_KEY,
            pageSize: 10,
            columns: getColumns(),
            initState: {
                columns: {
                    columnVisibilityModel: {
                        id: false
                    }
                }
            }
        }}
        data={data}
    />
}

export default PackageItemsDataGrid