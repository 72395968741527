export type SupplierEvaluationQuestion = {
    header: string
    type: "radioWithDate" | "radio" | "text" | "radioWithoutNotApplicable"
    questions: { key: string, text: string }[]
}

export const GeneralSupplierEvaluationQuestions: SupplierEvaluationQuestion[] = [
    {
        header: "1. Supplier's Quality and Environmental Work",
        type: "radioWithDate",
        questions: [
            {
                key: "quality_management_iso_9001",
                text: "Does the company have a certified quality management system according to ISO 9001?",
            },
            {
                key: "active_quality_work",
                text: "Does the company engage in active quality work?",
            },
            {
                key: "environmental_management_iso_14001",
                text: "Does the company have a certified environmental management system according to ISO 14001?",
            },
            {
                key: "active_environmental_work",
                text: "Does the company engage in active environmental work?",
            },
            {
                key: "ce_mark_authorization",
                text: "Does the company have the right to CE mark its products?",
            },
            {
                key: "quality_controls_fpc_systems",
                text: "Does the company have quality controls, FPC systems?",
            }
        ],
    },
    {
        header: "2a. Previous Supplier",
        type: "radio",
        questions: [
            {
                key: "service_product_delivery",
                text: "Has the supplier been able to deliver the ordered service/product?",
            },
            {
                key: "timely_deliveries",
                text: "Have the deliveries been made on time?",
            },
            {
                key: "correct_delivery_location",
                text: "Have the deliveries been made to the right place?",
            },
            {
                key: "met_quality_requirements",
                text: "Has the service/product met the set quality requirements?",
            },
            {
                key: "environmental_consideration_work",
                text: "Has the company carried out its work with consideration of environmental requirements?",
            }
        ],
    },
    {
        header: "2b. New Supplier",
        type: "radio",
        questions: [
            {
                key: "goods_services_meet_requirements",
                text: "Does the company have goods/services that meet the set requirements?",
            },
            {
                key: "can_meet_delivery_requirements",
                text: "Can the company meet the set delivery requirements?",
            },
            {
                key: "service_good_meets_quality",
                text: "Does the service/good meet the set quality requirements?",
            },
            {
                key: "own_environmental_routines",
                text: "Does the company have its own environmental routines/environmentally declared products?",
            }
        ]
    },
    {
        header: "3. Other Information",
        type: "text",
        questions: [
            {
                key: "miscellaneous_info",
                text: "Please provide any other relevant information here",
            }
        ]
    },
    {
        header: "4. Overall Assessment",
        type: "radioWithoutNotApplicable",
        questions: [
            {
                key: "overall_supplier_evaluation",
                text: "Please provide an overall assessment of the supplier",
            }
        ]
    }
];


export const AssembleSupplierEvaluationQuestions: SupplierEvaluationQuestion[] = [
    {
        header: "1. Supplier's Quality and Environmental Work",
        type: "radioWithDate",
        questions: [
            {
                key: "iso_9001_2015_certification",
                text: "Does the company have a certified quality management system according to ISO 9001:2015?",
            },
            {
                key: "iso_14001_2015_certification",
                text: "Does the company have a certified environmental management system according to ISO 14001:2015?",
            },
            {
                key: "iso_3834_certification",
                text: "Is the company quality assured according to ISO 3834-2 or 3834-3?",
            },
            {
                key: "certified_1090_2",
                text: "Is the company certified according to 1090-2?",
            },
            {
                key: "f_tax_certificate",
                text: "Does the company have a F-tax certificate?",
            },
            {
                key: "apply_ido6",
                text: "Does the company apply IDO6?",
            },
            {
                key: "valid_liability_insurance",
                text: "Does the company have valid insurance including liability insurance?",
            },
            {
                key: "safe_construction_training",
                text: "Has the company undergone Safe Construction Training?",
            },
            {
                key: "permission_hot_work",
                text: "Does the company have permission to perform hot work?",
            }
        ]
    },
    {
        header: "2a. Previous Supplier",
        type: "radio",
        questions: [
            {
                key: "service_delivery_compliance",
                text: "Has the supplier been able to deliver the ordered service?",
            },
            {
                key: "assembly_timeliness",
                text: "Has the assembly been done on time?",
            },
            {
                key: "assembly_location_accuracy",
                text: "Has the assembly been done at the right place?",
            },
            {
                key: "service_quality_compliance",
                text: "Has the service met the set quality requirements?",
            },
            {
                key: "work_environmental_compliance",
                text: "Has the company performed its work with consideration of environmental requirements?",
            }
        ]
    },
    {
        header: "2b. New Supplier",
        type: "radio",
        questions: [
            {
                key: "services_meet_requirements",
                text: "Does the company have services that meet the set requirements?",
            },
            {
                key: "delivery_requirements_compliance",
                text: "Can the company meet the set delivery requirements?",
            },
            {
                key: "service_quality_requirements",
                text: "Does the service meet the set quality requirements?",
            },
            {
                key: "environmental_routines",
                text: "Does the company have its own environmental routines?",
            }
        ]
    },
    {
        header: "3. Other Information",
        type: "text",
        questions: [
            {
                key: "miscellaneous_info",
                text: "Please provide any other relevant information here",
            }
        ]
    },
    {
        header: "4. Overall Assessment",
        type: "radioWithoutNotApplicable",
        questions: [
            {
                key: "overall_supplier_evaluation2",
                text: "Please provide an overall assessment of the supplier",
            }
        ]
    }
]
