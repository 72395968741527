import React, {Component} from 'react';
import {Button, ButtonGroup, Col, Modal, ModalBody} from "reactstrap";
import * as WorkflowForms from '../workflow-forms';
import PropTypes from 'prop-types';
import { Mutation, Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import SubmitFormMutation from './graphql/SubmitTaskForm.graphql';
import BPMDiagram from "./BPMDiagram";
import GetStartFormKey from './graphql/GetStartFormKey.graphql';
import StartProcessInstanceMutation from './graphql/StartProcessInstance.graphql';
import TaskFragment from '../new-task/graphql/TaskFragment.graphql';
import gql from "graphql-tag";
import StdQuery from "./StdQuery";
import logger from "../Logger";
import Auth from "../Auth";
import UnclaimMutation from '../new-task/graphql/UnclaimTask.graphql';
import DefaultWorkflowForm from "./DefaultWorkflowForm";
import ReassignTaskButton from "./ReassignTaskButton";
import Draggable from "react-draggable";

const updateTaskMutation = gql`
mutation updateTask($id:String!,$variables:JSON,$name:String,$description:String,$due:String)
{
    updateTask(id:$id,variables:$variables,description:$description,name:$name,due:$due)
    {
        id
        due
        name
        description
    }
}`;

const getTaskQuery = gql`
query task($id:String!)
{
    task(id:$id)
    {
        ...TaskFragment
        unclaimable
    }
}
${TaskFragment}`;

class GenericWorkflowForm extends Component
{
    constructor()
    {
        super();
        this.taskModalBody = this.taskModalBody.bind(this);
        this.startInstanceModalBody = this.startInstanceModalBody.bind(this);
        this.state = {
            submitting: false
        };
    }

    updateTask = (id)=>
    {
        return async (variables,toggle=true)=>
        {
            if(this.state.submitting) {
                console.error('caught double submit');
                return;
            }
            this.setState({submitting: true});
            try{
                await this.props.updateTask({
                    variables:
                    {
                        id,
                        name:variables["name"],
                        due:variables["due"],
                        description:variables["description"],
                        variables: variables || {}
                    },
                    refetchQueries: ["AllTasks", 'tasks','getOrder']
                });
            }
            catch(e)
            {
                alert(`Something went wrong updating task. ${e.message}`);
                logger.error(`${Auth.getUsername()} Failed to update task id:${id} ${e.message}`,{msg:e.message,stack:e.stack});
            }
            if(toggle)
            {
                this.props.toggle();
            }
            this.setState({submitting: false});
        }
    };

    taskModalBody(task)
    {
       return <Mutation mutation={SubmitFormMutation} refetchQueries={()=>['AllTasks']} >
            {submitForm=> {
                const {processDefinitionKey, formKey, id} = task;
                let Form;
                // const task = this.props.task;
                if (formKey)
                {
                    //const Directory = WorkflowForms[processDefinitionKey][formKey];
                    Form = WorkflowForms[processDefinitionKey][formKey];
                    // if(!Directory)
                    // {
                    //     throw Error(`Cant find directory by key: ${processDefinitionKey} used for locating workflow form.`);
                    // }


                }
                else
                {
                    Form = (props) => <DefaultWorkflowForm {...props}/>

                }
                return <ModalBody className="no-drag">
                    {task.processInstanceId && <BPMDiagram width={750} activityIds={task.activityIds}
                                                                   processInstanceId={task.processInstanceId}/>}
                    <Form toggle={this.props.toggle} variables={task.variables} task={task} onSubmit={async variables => {
                        if(this.state.submitting) {
                            console.error('caught double submit');
                            return;
                        }
                        this.setState({submitting: true});
                        this.props.toggle();
                        try{
                            await submitForm({
                                variables:
                                {
                                    processInstanceId: id,
                                    variables: variables || {}
                                },
                                refetchQueries: ["AllTasks", 'tasks','getOrder','getPurchaseOrder']
                            });
                        }
                        catch(e)
                        {
                            alert(`Something went wrong submitting task. ${e.message}`);
                            logger.error(`${Auth.getUsername()} Failed to submit task name:${task.name} id:${task.id} ${e.message}`,{msg:e.message,stack:e.stack});
                        }
                        this.setState({submitting: false});
                    }} update={this.updateTask(id)}/>
                </ModalBody>
            }}
       </Mutation>
    }

    startInstanceModalBody()
    {
        const {processDefinitionKey} = this.props;
        return <Query query={GetStartFormKey} variables={{processDefinitionKey: processDefinitionKey}}>
            {({data,loading,error}) =>
            {
                if(loading)
                {
                    return `Loading..`;
                }
                else if(error)
                {
                    return `Failed to load start form key ${error.toString()}`;
                }
                let Form;
                if (data.getStartFormKey)
                {
                    Form = WorkflowForms[processDefinitionKey][data.getStartFormKey];
                }
                return <Mutation mutation={StartProcessInstanceMutation}>
                    {(startProcessInstance)=>
                    {
                        return <ModalBody>
                            <Form variables={this.props.variables} onSubmit={async variables => {
                                await startProcessInstance({
                                    variables:
                                    {
                                        variables: variables || {},
                                        processDefinitionKey
                                    },
                                    refetchQueries: ["AllTasks", 'tasks', 'getOrder','getPurchaseOrder']
                                });
                                this.props.toggle();
                            }}/>
                        </ModalBody>
                    }}
                </Mutation>
            }}
        </Query>
    }

    unclaimButton(task)
    {
        if(task.unclaimable)
        {
            return <Mutation mutation={UnclaimMutation}>
                {unclaim =>
                <Button color="warning" onClick={async ()=>
                {
                    if(window.confirm(`Are you sure you wish to unclaim task ${task.name}?`))
                    {
                        await unclaim({
                            variables:{
                                id:task.id
                            },
                            refetchQueries:["AllTasks",'tasks', 'getOrder']
                        });
                        this.props.toggle();
                    }
                }
                }>
                    Unclaim
                </Button>
                }
            </Mutation>
        }
        else
        {
            return null;
        }
    }

    unclaimTask(task)
    {
        if(task.unclaimable != null )
        {
            return this.unclaimButton(task);
        }
        else
        {
            return <StdQuery query={getTaskQuery} variables={{id:task.id}}>
                {data=>
                {
                    const task = data.task;
                    return this.unclaimButton(task);
                }}
            </StdQuery>
        }
    }


    render()
    {
        const task = this.props.task;
        if(!task)
        {
            return null;
        }
        return <Draggable cancel=".no-drag">
            <Modal size='lg' isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <div className="modal-header">
                    <Col sm={7}>
                        <h5 className="modal-title">
                            {`Task: ${task.name}`}
                        </h5>
                    </Col>
                    <Col sm={{offset:1,size:3}}>
                        <ButtonGroup>
                            {task.assignee === Auth.getUsername() && <ReassignTaskButton task={task} toggle={() => this.props.toggle()}/>}
                            {this.unclaimTask(task)}
                        </ButtonGroup>
                    </Col>
                    <Button onClick={()=>this.props.toggle()} type="button" className="close" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </Button>
                </div>
                {this.taskModalBody(task)}
            </Modal>

        </Draggable>
    }
}

GenericWorkflowForm.propTypes =
{
    task:PropTypes.object,
    processDefinitionKey:PropTypes.string,
    toggle:PropTypes.func.isRequired,
    isOpen:PropTypes.bool.isRequired,
    variables:PropTypes.object,
};

export default graphql(updateTaskMutation,{name:"updateTask"})(GenericWorkflowForm)
