import React, {useImperativeHandle} from "react";
import {Autocomplete, TextField} from "@mui/material";


function MultiSelectInput(list: { id: string, name: string }[], label = "status")
{
    return function StatusMultiSelect(props)
    {
        const {item, applyValue, focusElementRef} = props;
        const refStatus: React.Ref<any> = React.useRef(null);

        useImperativeHandle(focusElementRef, () => ({
            focus: () =>
            {
                refStatus.current
                    .querySelector(`input[value="${item.value}"]`)
            }
        }));

        const handleOnChange = (event, values) =>
        {
            const status = values.map((e) => e.id)
            applyValue({...item, value: status});
        };

        return (
            <Autocomplete
                ref={refStatus}
                multiple
                id="status"
                options={list}
                getOptionLabel={(option) => option?.name}
                filterSelectedOptions
                onChange={handleOnChange}
                value={item.value?.map((e) => list.find((status) => status.id === e))}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={label}
                        placeholder={label}
                    />
                )}/>
        );
    }
}

export const MultiSelectFilterOperators = (list, label = "status") => [{
    label: 'in',
    value: 'in',
    getApplyFilterFn: (filterItem) =>
    {
        if (!filterItem.field || !filterItem.value || !filterItem.operator)
        {
            return null;
        }

        return (params): boolean =>
        {
            return filterItem.value.includes(params.value)
        };
    },
    InputComponent: MultiSelectInput(list, label)
}]
