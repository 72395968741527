import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import PropTypes from 'prop-types';

class ModalForm extends Component
{
    toggle()
    {
        this.setState({
            modal: !this.state.modal
        });
        if(this.props.onToggle)
        {
            this.props.onToggle();
        }

    }

    constructor()
    {
        super();
        this.state = {
            modal: false,
        };
        this.toggle = this.toggle.bind(this);
    }


    modal()
    {
        return <Modal size={this.props.size} isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>{this.props.title}</ModalHeader>
            <ModalBody>
                {this.props.children(this.toggle)}
            </ModalBody>
        </Modal>
    }

    render()
    {
        return <React.Fragment>
            {this.props.button && this.props.button(this.toggle)}
            {!this.props.button && <Button {...this.props.buttonProps} onClick={this.toggle}>{this.props.activationButtonText}</Button>}
            {this.modal.bind(this)()}
        </React.Fragment>
    }
}

ModalForm.propTypes = {
    children: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    activationButtonText:PropTypes.string,
    button:PropTypes.func,
    onToggle:PropTypes.func,
    size:PropTypes.string,
    noFooter:PropTypes.bool,
    buttonProps:PropTypes.object
};

export default ModalForm;
