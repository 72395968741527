import gql from 'graphql-tag';
import React, { useCallback, useState} from 'react';
import {
    Button,
    Card, CardBody,
    CardHeader,
    Col,
    Form,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
    Table
} from 'reactstrap';
import ModalForm from "./ModalForm";
import ReactDropZone from 'react-dropzone';
import {selectRenderer} from "./FormRenderer";
import {useUploadFiles} from "../UTIL";

export const DrawingsCategoryType = {
    id:"1",
    name:"Drawings"
};

export const ProductionDrawingsCategoryType = {
    id:"8",
    name:"Production Drawings"
};

export const CustomerDocCategoryType = {
    id:"2",
    name:"Customer documentation"
};

const CategoryUploadInfoTable = () => {
    return <Card style={{margin: "16px 0"}}>
        <CardHeader style={{fontSize: "20px"}}>
            What to upload
        </CardHeader>
        <CardBody>
            <Table size="sm">
                <thead>
                <tr>
                    <th>

                    </th>
                    <th>
                        Description
                    </th>
                    <th>
                        Responsible role(s)
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td style={{fontWeight: 600}}>
                        Quote
                    </td>
                    <td>
                        Quote, drawings from customer (all types of files, dw, pdf etc.), autodrawings, information and
                        specifications from customer.
                    </td>
                    <td>
                        Sales
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        Construction
                    </td>
                    <td>
                        Customer drawings, revisions and revision requirements
                    </td>
                    <td>
                        Sales, Construction
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        Signed order confirmation
                    </td>
                    <td>
                        Customer signed order confirmation and associated drawing.
                    </td>
                    <td>
                        Sales
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        Production
                    </td>
                    <td>
                        Drawings for production
                    </td>
                    <td>
                        Construction
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        Economy
                    </td>
                    <td>
                        Order and PO
                    </td>
                    <td>
                        Sales
                    </td>
                </tr>
                <tr>
                    <td style={{fontWeight: 600}}>
                        Quality documents
                    </td>
                    <td>
                        DOP
                    </td>
                    <td>
                        Logistics
                    </td>
                </tr>
                </tbody>
            </Table>
        </CardBody>
    </Card>
}

const CategoryModal = (props) => {
    const {toggle, isOpen, fileCategory, onSubmit} = props
    const [selectedFileCategory, setSelectedFileCategory] = useState(fileCategory || null);

    return <Modal toggle={toggle} isOpen={isOpen}>
        <ModalHeader toggle={toggle}>File Category Selection</ModalHeader>
        <ModalBody>
            <Label>File category</Label>
            <small>(Sets the category for all uploaded files)</small>
            {selectRenderer({query: gql`query{fileCategories{id name}}`, required: true},
                selectedFileCategory, val => setSelectedFileCategory(val))}
            <br/>
            <Button disabled={!selectedFileCategory}
                onClick={() => {
                onSubmit(selectedFileCategory)
                toggle()
            }}>Submit</Button>
            <CategoryUploadInfoTable/>
        </ModalBody>
    </Modal>
}

export default ({fileCategory,toggle,onSubmit,orderId,quoteId,supplierId,noModal,noCategory=false})=>
{
    const [files,setFiles] = useState([]);
    const [loading,setLoading] = useState(false);
    const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)

    const toggleCategoryModal = useCallback(()=> {
        setIsOpenCategoryModal(prevState => !prevState)
    },[setIsOpenCategoryModal])

    const uploadFiles = useUploadFiles();

    const submit = useCallback(async(selectedFileCategory)=>
    {
        setLoading(true);
        const uploadedFiles = await uploadFiles(
            files,
            {
                quoteId,
                orderId,
                supplierId,
                fileCategoryId:selectedFileCategory?.id
            });
        setLoading(false);
        setFiles([]);
        // setSelectedFileCategory(null);
        onSubmit && onSubmit(uploadedFiles);
    },[uploadFiles,setLoading,onSubmit,orderId,quoteId,supplierId,files]);

    const form = (toggle) => <Form onSubmit={async (event) => {
        event.preventDefault();
        await submit(null);
        toggle && toggle();
    }}>
        <ul>
            {files.map(file => {
                return (<li key={file.name}>{file.name}</li>)
            })}
        </ul>

        <Row>
            <Col md={5}>
                <ReactDropZone
                    style={{
                        width: '100%', height: '100px', border: '1px dashed black',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}
                    onDrop={(accepted) => {
                        setFiles(files => files.concat(accepted));
                    }}>
                    <h5>Drop your files here or click me.</h5>
                </ReactDropZone>
            </Col>
        </Row>
        <br/>

        {!loading && <Button
            onClick={noCategory ? () => {
            } : toggleCategoryModal}
            type={noCategory ? "submit" : "button"}
            disabled={files.length === 0}>
            Submit
        </Button>}

        {loading && <Spinner color="warning"/>}

        <CategoryModal isOpen={isOpenCategoryModal} toggle={toggleCategoryModal}
                       selectedFileCategory={fileCategory || null}
                       onSubmit={async (selectedFileCategory) => {
                           await submit(selectedFileCategory)
                           toggle && toggle();
                       }}/>
    </Form>;

    if(noModal)
    {
        return form(toggle);
    }
    else
    {
        return <ModalForm onToggle={()=>setFiles([])} title='Upload files'
                          activationButtonText='Upload files'>
            {(toggle)=>form(toggle)}
        </ModalForm>;
    }
}


