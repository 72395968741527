import React from 'react';
import {Link} from 'react-router-dom';
import {PurchaseOrderStatus} from "../UTIL";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnTenantId from "../common/data-grid/columns/ColumnTenantId";
import ColumnSupplierName from "../common/data-grid/columns/ColumnSupplierName";
import ColumnAssignee from "../common/data-grid/columns/ColumnAssignee";
import ColumnStatus from "../common/data-grid/columns/ColumnStatus";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";


const columns = (showTenant) => {
    return [
        ColumnTenantId(),
        {
            field: 'number',
            headerName: 'Number',
            renderCell: (params) => {
                const {id, tenantId, number} = params.row
                const text = showTenant ? `${tenantId} - ${number}` : `${number}`

                return <Link to={`/purchaseOrderDetail/${id}`}>{text}</Link>
            }
        },
        ColumnSupplierName(),
        ColumnAssignee(),
        ColumnStatus(PurchaseOrderStatus),
        ColumnDate('receivedDate', 'Received shipment'),
        ColumnDate('deliveryDate', 'Delivery date'),
        ColumnId(),
    ]
}

const columnVisibilityModel = {
    columnVisibilityModel: {
        id: false,
        tenantId: false,
    }
}

const TABLE_KEY = "PURCHASE_ORDER_SUB_PANEL"

const defaultSorting = {sortModel: [{field: "deliveryDate", sort: "desc"}]}

export default ({purchaseOrders, showTenant, noSupplier}) => {
    if (noSupplier) {
        columnVisibilityModel.columnVisibilityModel['supplier.name'] = false;
    }

    return (
        <NonPaginatedDataGrid definition={{
            tableKey: TABLE_KEY,
            columns: columns(showTenant),
            initState: {
                columns: columnVisibilityModel,
                sorting: defaultSorting,
            }
        }} data={purchaseOrders}/>
    )
}
