import React, {useEffect, useState} from 'react';
import {useMutation} from '@apollo/client';
import { Table} from "reactstrap";
import StdQuery from "../common/StdQuery";
import gql from "graphql-tag";
import Trash from "../common/icons/Trash";
import FormRenderer from "../common/FormRenderer";
import config from '../config';
import _, {map} from 'lodash';
import ModalForm from "../common/ModalForm";
import Pen from "../common/icons/Pen";

const deleteTemplateMutation = gql`
    mutation deleteTemplate($id:ID!)
    {
        deleteTemplate(id:$id)
    }
`;

const getTemplates = gql`
    query getTemplates
    {
        templates
        {
            id
            name
            category
            tenantId
            createdAt
            file
            {
                id
                filename
                downloadUrl
            }
        }
    }
`;

const updateTemplateMutation = gql`
mutation updateTemplate($id:ID!,$template:TemplateInputType!)
{
    updateTemplate(id:$id,template:$template)
    {
        id
    }
}`;

const createTemplateMutation = gql`
mutation createTemplate($template:TemplateInputType!)
{
    createTemplate(template:$template)
    {
        id
        name
        category
        tenantId
        createdAt
        file
        {
            filename
            id
        }
    }
}`;

export const TemplateCategories = [
    {id:"quote",name:"Quote"},
    {id:"order",name:"Order"}
    ];

export default ()=>
{
    const [deleteTemplate] = useMutation(deleteTemplateMutation,{refetchQueries:["getTemplates"]});
    const [updateTemplate] = useMutation(updateTemplateMutation,{refetchQueries:["getTemplates"]});
    const [createTemplate] = useMutation(createTemplateMutation,{refetchQueries:["getTemplates"]});
    const [tenants,setTenants] = useState([]);
    useEffect(() =>
    {
        fetch(config.BACKEND_URI + "tenants").then(res => res.json()).then(
        tenants => setTenants(map(tenants, tenant => ({id: tenant.name, name: tenant.name}))));
    }, []);
    return <React.Fragment>
        <ModalForm title={"Create new template"} activationButtonText={"Create new Template"}>
            {toggle=>
            <FormRenderer formDefinition={[
                {name:"fileId",label:"Upload a template",type:"file",required:true},
                {name:"name",required:true},
                {name:"category",type:"select",options:TemplateCategories,required:true},
                {name:"tenantId",type:"select",options:tenants}

            ]}
                          onSubmit={async({object})=>
            {
                console.log(await createTemplate({variables:
                    {
                        template:object
                    }}));
                toggle();
            }} />}
        </ModalForm>

        <StdQuery query={getTemplates}>
            {data =>
            {
                if(data.templates && data.templates.length > 0)
                {

                    return <Table bordered striped className={"-highlight"}>
                        <thead>
                           <tr>
                               <th>
                                   Name
                               </th>
                               <th>
                                   Category
                               </th>
                               <th>
                                   Tenant
                               </th>
                               <th>
                                   Created At
                               </th>
                               <th>Actions</th>
                           </tr>
                        </thead>
                        <tbody>
                        {_.map(data.templates,template=>
                        <tr key={template.id}>
                            <td>
                                <a href={template.file.downloadUrl}>{template.name}</a>
                            </td>
                            <td>
                                {template.category}
                            </td>
                            <td>
                                {template.tenantId}
                            </td>
                            <td>
                                {template.createdAt}
                            </td>
                            <td>
                            <span style={{display:"inline"}} className="link" onClick={async () =>
                            {
                                if (window.confirm(`Are you sure you want to delete ${template.name}?`))
                                {
                                    await deleteTemplate({variables: {id: template.id}});
                                }
                            }}>
                                    <Trash/>
                            </span>
                            <ModalForm title={"Edit template"}
                                       button={toggle=><span style={{display:"inline"}} className="link" onClick={() => toggle()}>
                                    <Pen/>
                            </span>}>
                                {toggle=>
                                <FormRenderer object={template} formDefinition={[
                                    {name:"fileId",label:"Upload a template",type:"file"},
                                    {name:"name",required:true},
                                    {name:"category",type:"select",options:TemplateCategories,required:true},
                                    {name:"tenantId",type:"select",options:tenants}

                                ]}
                                              onSubmit={async({object})=>
                                              {
                                                  console.log(object);
                                                  console.log(await updateTemplate({variables:
                                                      {
                                                          id:template.id,
                                                          template:object
                                                      }}));
                                                  toggle();
                                              }} />}
                            </ModalForm>

                            </td>
                        </tr>
                        )}
                        </tbody>
                    </Table>
                }
                else
                {
                    return `Could not find any templates..`;
                }
            }
            }
        </StdQuery>
    </React.Fragment>
}
