import {Alert, Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import React, {useCallback, useState} from "react";


type OnClick = (locale: string) => void

export default ({
                    hideWarning,
                    toggle,
                    isOpen,
                    onClick
                }: { hideWarning: boolean, toggle: () => void, isOpen: boolean, onClick: OnClick }) =>
{
    const [selection, setSelection] = useState({locale: 'sv'});

    const handleSelection = useCallback((category, value) =>
    {
        setSelection(prevState => ({...prevState, [category]: value}))
    }, [setSelection])

    const handleDownloadClick = useCallback(async () =>
    {
        onClick(selection.locale)
        toggle();
    }, [onClick, toggle, selection.locale])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Download CE Document</ModalHeader>
        <ModalBody>
            {
                !hideWarning && <Alert color="warning">Please set the weldability of the article first</Alert>
            }
            <FormGroup>
                <Label for="locale">Locale</Label>
                <Input type="select" name="locale" id="locale"
                       onChange={(e) => handleSelection('locale', e.target.value)}>
                    <option value="sv">sv</option>
                    <option value="en">en</option>
                </Input>
            </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleDownloadClick} disabled={!hideWarning}>Download</Button>
        </ModalFooter>
    </Modal>
}