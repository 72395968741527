import React, {Component} from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';

export default class PurchaseOrderSidebar extends Component
{
    render()
    {
        return (
            <Nav pills vertical>
                <NavItem>
                    <NavLink to="/createPurchaseOrder" className="nav-link">Create Purchase Order</NavLink>
                </NavItem>
            </Nav>);
    }
}



