import {ModuleObject, Node} from "../ShpaeObject";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import ModuleTemplate, {childSafePole} from "../ModuleTemplate";
import {Axis, CreateBox, Tools} from "@babylonjs/core";
import {RampEdge} from "./Ramp";
import {getAngleBetweenVectorsByAxisY} from "../utils";
import {MaterialName} from "../Materials";
import {Scene} from "@babylonjs/core/scene";

export class KickPlate extends ModuleObject {
    startNode:Node|undefined;
    endNode:Node|undefined;

    constructor(moduleTemplate:ModuleTemplate, scene) {
        super(moduleTemplate, scene);
        this.init();
    }

    build(){
        const {length, width, height, slopeDegree} = this.moduleTemplate;
        const {poleSpacing} = childSafePole;
        const nodeAmount = Math.round(length / poleSpacing);
        this.vector = new Vector3(0,0, length);

        const plate = CreateBox(`kickPlate-${this.id}`,{width, height, depth:length}, this.scene);
        plate.translate(this.origin, 1);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];
        this.meshes.push(plate);
        plate.translate(new Vector3(width/2, height/2,length/2), 1);
        this.rotate(Axis.X, -slopeDegree);

        // step length is half-length between two nodes, 1 step to first node from start
        // | - o - - o - - o - |
        const halfScaler = new Vector3(nodeAmount*2, nodeAmount*2, nodeAmount*2);
        const stepVector = this.vector.divide(halfScaler);

        let pointer = this.origin.clone();
        for(let i=0; i<nodeAmount; i++){
            pointer = pointer.add(stepVector);
            const newNode = new Node(this, pointer.clone(),()=>true, this.scene);
            // newNode.nodeAttachableTypes = [NodeAttachableType.ramp, NodeAttachableType.pole];
            this.children.push(newNode);
            this.nodes.push(newNode);
            pointer = pointer.add(stepVector);
        }
        this.startNode = this.nodes[0];
        this.endNode = this.nodes[this.nodes.length-1];
    }

    attachTo(attachNode:Node, fromNode:Node){
        const rampEdge = attachNode.parent;
        if(rampEdge instanceof RampEdge && fromNode.parent instanceof KickPlate){
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector.negate(), rampEdge.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
            this.vector = rampEdge.vector;
        }
    }

    clone(scene:Scene){
        const cloned = new KickPlate(this.moduleTemplate, scene);
        super.clone(scene);
        return cloned;
    }

}