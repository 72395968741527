import React, {useCallback, useMemo} from 'react';
import {downloadFile, FORMAT_DATE_TIME, formatToSwedishTime} from "../../UTIL";
import gql from "graphql-tag";
import {graphql} from '@apollo/client/react/hoc'
import Trash from "../icons/Trash";
import ColumnId from "../data-grid/columns/ColumnId";
import ColumnCreatedAt from "../data-grid/columns/ColumnCreatedAt";
import NonPaginatedDataGrid from "../data-grid/NonPaginatedDataGrid";
import {useGetFileCategoriesQuery} from "../../generated/graphql";
import {MultiSelectFilterOperators} from "../data-grid/MultiSelectInput";

const KEY_TABLE = "TABLE_FILES"

const deleteFileMutation = gql`
    mutation deleteFile($id:ID!)
    {
        deleteFile(id:$id)
    }
`;


const FilesSubpanel = (props) => {
    const files = props.files;

    const {data} = useGetFileCategoriesQuery()

    const deleteFile = useCallback(async (file) => {
        if (window.confirm(`Are you sure you want to remove file ${file.filename}?`)) {
            await props.deleteFile({variables: {id: file.id}, refetchQueries: ["getOrder", "quote", "getSupplier"]});
        }
    }, [props])

    const getColumns = useMemo(() => {
        return [
            {
                field: 'filename',
                headerName: 'Filename',
                width: 400,
                renderCell: (params) => {
                    const value = params.value
                    return <span className='link' onClick={async () => {
                        await downloadFile(params.id, value);
                    }}>{value}</span>
                }
            },
            {
                field: 'fileCategory.name',
                headerName: 'File Category',
                type: "singleSelect",
                customFilterOperators: MultiSelectFilterOperators(data?.fileCategories
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    ?.map((e) => ({id: e.name, name: e.name})) ?? [],
                    "Category")
            },
            {
                field: 'creator.displayName',
                headerName: 'Creator',
            },
            ColumnCreatedAt((value) => formatToSwedishTime(value, FORMAT_DATE_TIME)),
            {
                field: 'remove',
                headerName: 'Remove',
                filterable: false,
                sortable: false,
                width: "80",
                renderCell: (params) => {
                    return <span className='link'
                                 onClick={() => deleteFile(params.row)}><Trash/></span>
                }
            },
            ColumnId()
        ]
    }, [data, deleteFile])

    const buttons = useMemo(() => {
        const list = ['Construction', 'Economy', 'Production', 'Quote']
        return list.map((e) => (
            {
                name: e,
                filters: {
                    items: [{
                        field: 'fileCategory.name',
                        value: [e],
                        operator: 'in'
                    }]
                }
            }
        ))
    }, [])

    return <div style={{margin: "16px 0"}}>
        <NonPaginatedDataGrid
        definition={{
            tableKey: KEY_TABLE,
            columns: getColumns,
            initState: {
                sorting: {
                    sortModel: [
                        {field: 'fileCategory.name', sort: 'asc'},
                        {field: 'createdAt', sort: 'asc'},
                    ]
                },
                columns: {columnVisibilityModel: {id: false}},
            },
            buttons: props.noCategory ? null : buttons
        }}
        data={files}/>
    </div>
}

export default graphql(deleteFileMutation, {name: "deleteFile"})(FilesSubpanel);
