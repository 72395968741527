import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {getPackageColumns} from "../PackageListPage";
import React from "react";
import PackageExpandedPanel, {calculatePackageExpandedHeight} from "./PackageExpandedPanel";


const TABLE_KEY = "SHIPMENT_PACKAGES"

export default (props)=> {
    const {packages} = props

    return <div>
        <NonPaginatedDataGrid
            {...props}
            definition={{
                tableKey: TABLE_KEY,
                disableTooBar: true,
                columns: getPackageColumns(),
                initState: {columns: {columnVisibilityModel: {id: false}}}
            }}
            data={packages ?? []}
            getDetailPanelContent={({ row }) => <PackageExpandedPanel packageInfo={row}/>}
            getDetailPanelHeight={calculatePackageExpandedHeight}
        />
    </div>
}