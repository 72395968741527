/**
 * Created by magnus on 11/16/15.
 */
//angle2 = anslutningsvinkel
const AnslAngle = {
    600: {
        13: {
            "stepsPrRot":13.33,
            "effectiveAngle": 27,
            "angle1": 15,
            "angle2": 15,
            halfStepAngle:18,
            "cageAngle": -11,
            ccMeasure:270,
            stepDepth:163

        }
    },
    700: {
        14: {
            "stepsPrRot":14,
            "effectiveAngle": 25.71,
            "angle1": 15.43,
            "angle2": 12.86,
            halfStepAngle:15.43,
            "cageAngle": -10.71,
            ccMeasure:300,
            stepDepth:200
        },
        16: {
            "stepsPrRot":15.56,
            "effectiveAngle": 23.14,
            "angle1": 12.86,
            "angle2": 12.86,
            halfStepAngle:15.43,
            "cageAngle": -9.43,
            ccMeasure:270,
            stepDepth:180
        },
    },
    800: {
        15: {
            "stepsPrRot":14.55,
            "effectiveAngle": 24.75,
            "angle1": 13.5,
            "angle2": 13.5,
            halfStepAngle:13.5,
            "cageAngle": -10.5,
            ccMeasure:330,
            stepDepth:236
        },
        16: {
            "stepsPrRot":16,
            "effectiveAngle": 22.5,
            "angle1": 13.5,
            "angle2": 12.375,
            halfStepAngle:13.5,
            "cageAngle": -9.37,
            ccMeasure:300,
            stepDepth:215
        },
        18: {
            "stepsPrRot":17.78,
            "effectiveAngle": 20.25,
            "angle1": 11.35,
            "angle2": 11.35,
            halfStepAngle:13.5,
            "cageAngle": -8.25,
            ccMeasure:270,
            stepDepth:193
        }
    },

    900: {
        15: {
            "stepsPrRot":15,
            "effectiveAngle": 24,
            "angle1": 12,
            "angle2": 12,
            halfStepAngle:12,
            "cageAngle": -10.33,
            ccMeasure:360,
            stepDepth:270
        },
        16: {
            "stepsPrRot":16.36,
            "effectiveAngle": 22,
            "angle1": 12,
            "angle2": 12,
            halfStepAngle:12,
            "cageAngle": -9.33,
            ccMeasure:330,
            stepDepth:248
        },
        18: {
            "stepsPrRot":18,
            "effectiveAngle": 20,
            "angle1": 12,
            "angle2": 10,
            halfStepAngle:12,
            "cageAngle": -8.33,
            ccMeasure:300,
            stepDepth:226
        },
        20: {
            "stepsPrRot":20,
            "effectiveAngle": 18,
            "angle1": 10,
            "angle2": 10,
            halfStepAngle:12,
            "cageAngle": -7.33,
            ccMeasure:270,
            stepDepth:203
        }

    },
    1000: {
        16: {
            "stepsPrRot":16.67,
            "effectiveAngle": 21.6,
            "angle1": 10.8,
            "angle2": 10.8,
            halfStepAngle:10.8,
            "cageAngle": -9.3,
            ccMeasure:360,
            stepDepth:281
        },
        18: {
            "stepsPrRot":18.18,
            "effectiveAngle": 19.8,
            "angle1": 10.8,
            "angle2": 10.8,
            halfStepAngle:10.8,
            "cageAngle": -8.4,
            ccMeasure:330,
            stepDepth:258
        },
        20: {
            "stepsPrRot":20,
            "effectiveAngle": 18,
            "angle1": 10.8,
            "angle2": 9,
            halfStepAngle:10.8,
            "cageAngle": -7.5,
            ccMeasure:300,
            stepDepth:235
        },
        22: {
            "stepsPrRot":22.22,
            "effectiveAngle": 16.2,
            "angle1": 9,
            "angle2": 9,
            halfStepAngle:10.8,
            "cageAngle": -6.6,
            ccMeasure:270,
            stepDepth:211
        }
    },
    1100: {
        18: {
            "stepsPrRot":18.5,
            "effectiveAngle": 19.459,
            "angle1": 9.7295,
            "angle2": 9.7295,
            halfStepAngle:9.7295,
            "cageAngle": -8.38,
            ccMeasure:360,
            stepDepth:287
        },
        20: {
            "stepsPrRot":20.182,
            "effectiveAngle": 17.837,
            "angle1": 9.7295,
            "angle2": 9.7295,
            halfStepAngle:9.7295,
            "cageAngle": -7.57,
            ccMeasure:330,
            stepDepth:264
        },
        22: {
            "stepsPrRot":22.201,
            "effectiveAngle": 16.216,
            "angle1": 9.7295,
            "angle2": 8.1075,
            halfStepAngle:9.7295,
            "cageAngle": -6.76,
            ccMeasure:300,
            stepDepth:240
        },
        24: {
            "stepsPrRot":24.667,
            "effectiveAngle": 14.594,
            "angle1": 8.1075,
            "angle2": 8.1075,
            halfStepAngle:9.7295,
            "cageAngle": -5.95,
            ccMeasure:270,
            stepDepth:216
        }
    },
    1200: {
        20: {
            "stepsPrRot":20.33,
            "effectiveAngle": 17.71,
            "angle1": 8.85,
            "angle2": 8.85,
            halfStepAngle:8.85,
            "cageAngle": -7.62,
            ccMeasure:360,
            stepDepth:292
        },
        22: {
            "stepsPrRot":22.18,
            "effectiveAngle": 16.23,
            "angle1": 8.85,
            "angle2": 8.85,
            halfStepAngle:8.85,
            "cageAngle": -6.89,
            ccMeasure:330,
            stepDepth:268
        },
        24: {
            "stepsPrRot":24.4,
            "effectiveAngle": 14.75,
            "angle1": 8.85,
            "angle2": 7.38,
            halfStepAngle:8.85,
            "cageAngle": -6.15,
            ccMeasure:300,
            stepDepth:244
        },
        27: {
            "stepsPrRot":27.11,
            "effectiveAngle": 13.28,
            "angle1": 7.38,
            "angle2": 7.38,
            halfStepAngle:8.85,
            "cageAngle": -5.41,
            ccMeasure:270,
            stepDepth:220
        }
    },
    1300: {
        22: {
            "stepsPrRot":21.991,
            "effectiveAngle": 16.37,
            "angle1": 8.185,
            "angle2": 8.185,
            halfStepAngle:8.185,
            "cageAngle": -7.05,
            ccMeasure:360,
            stepDepth:299
        },
        24: {
            "stepsPrRot":23.99,
            "effectiveAngle": 15.006,
            "angle1": 8.185,
            "angle2": 8.185,
            halfStepAngle:8.185,
            "cageAngle": -6.37,
            ccMeasure:330,
            stepDepth:274
        },
        26: {
            "stepsPrRot":26.389,
            "effectiveAngle": 13.642,
            "angle1": 8.185,
            "angle2": 6.821,
            halfStepAngle:8.185,
            "cageAngle": -5.68,
            ccMeasure:300,
            stepDepth:249
        },
        29: {
            "stepsPrRot":29.322,
            "effectiveAngle": 12.278,
            "angle1": 6.821,
            "angle2": 6.821,
            halfStepAngle:8.185,
            "cageAngle": -5,
            ccMeasure:270,
            stepDepth:225
        },
    }
};

export default AnslAngle;
