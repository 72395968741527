import React, {useCallback, useState} from 'react';
import {camundaDateFix} from "../../UTIL";
import {OrderInfo} from "./SelectArticles";
import { Button, Col, Row, Spinner} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";

export default ({variables,onSubmit:onInvoiceSubmit,toggle})=> {
    const {orderId, deliveryDate} = variables;
    const [internalInvoiceData, setInternalInvoiceData] = useState({
        "internalInvoiceDate": deliveryDate,
        "internalInvoiceNumber": null
    });
    const [loading, setLoading] = useState(false);

    const onInternalInvoiceDataChange = useCallback((fieldName:string, value)=>{
        const newData = {...internalInvoiceData};
        newData[fieldName] = value;
        setInternalInvoiceData(newData);
    },[internalInvoiceData]);
    const submit = useCallback(async(e)=>{
        e.preventDefault();
        setLoading(true);
        await onInvoiceSubmit({
            internalInvoiceDate: {type: "Date", value: camundaDateFix(internalInvoiceData.internalInvoiceDate)},
            internalInvoiceNumber: {type:"String",value: internalInvoiceData.internalInvoiceNumber}
        });
        setLoading(false);
    }, [ internalInvoiceData, onInvoiceSubmit]);

    return <form onSubmit={submit}>
        <OrderInfo orderId={orderId} order={undefined}/>
        <h2>Input data for internal invoice</h2>
        <NewFormRenderer
            formDefinition={[
            {name: "internalInvoiceDate", type: "date", required: true},
            {name: "internalInvoiceNumber", type: "text", required: true},
        ]}
            columns={undefined}
            object={internalInvoiceData}
            onChange={onInternalInvoiceDataChange}
            customSubmit
        />
        {/*<TaskFooter toggle={()=>toggle()} submit={e=>submitBoth(e)} loading={loading} />*/}
        <Row>
            <Col sm={"9"}>
                <Button onClick={toggle}>Close</Button>
            </Col>
            <Col sm={"3"}>
                <Button color="success" type={"submit"} disabled={loading} onSubmit={()=>{}}>
                    Complete task {loading && <Spinner size={"sm"}/> }
                </Button>
            </Col>
        </Row>

    </form>
}
