import React, {useEffect, useState} from 'react';
import gql from "graphql-tag";
import {stairSchema} from "../common/lib/GenerateDrawingSchema";
import {Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row, Spinner} from "reactstrap";
import {useStdMutation} from "./lib/Util";
import Collapser from "../common/Collapser";
import ShortenText from "../common/ShortenText";

export default  ({setSpiralStair,unsavedChanges,spiralStair,segments,articleRowId,spiralStairId})=>
{
    const [queueName,setQueueName] = useState("generate-queue");
    const [revisionTag,setRevisionTag] = useState(spiralStair.revisionTag ?? "");
    // const [drawingInformation,setDrawingInformation] = useState(spiralStair.drawingInformation||"");
    const [clicked,setClicked] = useState(false);
    const [generate,{loading,error,data}] = useStdMutation("Create drawing request",gql`
        mutation($id:ID!,$arId:ID!,$queue:String,$revisionTag:String)
        {
            createGenerateMessage(spiralStairId:$id,articleRowId: $arId,queueName: $queue,revisionTag: $revisionTag)
        }`);
    const [errors,setErrors] = useState([]);
    useEffect(()=>
    {
        const fn = async()=>
        {
            try
            {
                await stairSchema.validate({...spiralStair,segments},{abortEarly:false});
                setErrors([]);
            }
            catch(e)
            {
                setErrors(e.errors);
            }

        }
        fn();
    },[spiralStair,segments]);
    const showButton = !clicked && !unsavedChanges && errors.length ===0;
    return <Card>
        <CardHeader>
            Generate Drawing
        </CardHeader>
        <CardBody>
            <Row>
                <Col sm={7}>
                    <FormGroup>
                        <Label>
                            Drawing information
                            <br/>
                            <small>(this shows up on the automatically generated drawing as is)</small>
                        </Label>
                        <Input type={"textarea"} value={spiralStair.drawingInformation || ""}
                               onChange={e=>
                               {
                                   const val = e.target.value;
                                   setSpiralStair(spr=>({...spr,drawingInformation:val}))
                               }}/>
                    </FormGroup>
                    <FormGroup>
                        <Label>
                            Revision tag
                            <br/>
                            <small>
                                (for some what revision the drawing is. e.g. enter "A" for "Revision A" or 1 for "Revision 1".)
                            </small>
                        </Label>
                        <Input type={"text"} value={revisionTag} onChange={e => {
                            const value = e.target.value;
                            setSpiralStair(spr => ({...spr, revisionTag: value}))
                            setRevisionTag(value)
                        }}/>
                    </FormGroup>

                    {showButton && <Row>
                        <Col sm={5}>
                            <Button color={"info"} size={"sm"} onClick={async()=>
                            {
                                setClicked(true);
                                await generate({variables:{id:spiralStairId,arId:articleRowId,queue:queueName,revisionTag}});
                            }}>
                                Submit
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <select  value={queueName} onChange={e=>setQueueName(e.target.value)}>
                                    <option>
                                        generate-queue
                                    </option>
                                    <option>
                                        generate-queue-dev
                                    </option>
                                    <option>
                                        generate-queue-dev-2
                                    </option>
                                    <option>
                                        generate-queue-dev-3
                                    </option>

                                </select>
                            </FormGroup>
                        </Col>
                    </Row>}
                    {loading && <Spinner color={"warning"}/>}
                    {data && <small style={{color:"green"}}>Drawing has been requested, please wait for notification email.</small>}
                    {error && <small style={{color:"red"}}>{error.message}</small>}
                    {unsavedChanges && <small style={{color:"red"}}>Save before generating drawing</small>}
                    {errors.length > 0 && <p style={{fontSize:"12px",color:"red"}}>Fix validation errors before generating drawing</p>}
                </Col>

                <Col sm={4}>

                    <Collapser label={"Show errors.."} open={segments.length === 1}>
                        <ul className={"validation-list"}>
                            {errors.map((message,index) =>
                                <li key={index} style={{marginBottom:"10px", borderBottom:"2px solid #ddd"}}>
                                    <ShortenText text={message} lengthToCollapse={10}/>
                                </li>
                            )}
                        </ul>
                    </Collapser>

                </Col>
            </Row>

        </CardBody>
    </Card>
};
