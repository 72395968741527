import React from 'react';
import {useMutation} from '@apollo/client';
import FormRenderer from "./FormRenderer";
import CreateTask from '../new-task/graphql/CreateNewTask.graphql';
import {Modal, ModalBody, ModalHeader} from "reactstrap";

export default (props)=>
{
    const {variables,isOpen,toggle,refetchQueries} = props;
    const [createTask] = useMutation(CreateTask, {refetchQueries: refetchQueries});

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
            Create task
        </ModalHeader>
        <ModalBody>
            <FormRenderer formDefinition={[
                {name: "name", required: true},
                {name: "description", type: "textarea"},
                {name: "due", label: "Due Date", type: "datetime", defaultValue: new Date()},
            ]} onSubmit={async ({object}) => {
                await createTask({
                    variables:
                    {
                        task:
                        {
                            name:object.name,
                            description:object.description,
                            due:object.due,
                            variables:
                            {
                                ...variables
                            }
                        }
                    }
                });
                toggle();
            }}/>
        </ModalBody>
    </Modal>
};
