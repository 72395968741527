import React, {memo} from "react";

const ColumnQuantity = memo(({value}: { value: number }) =>
{
    return <>
        {value.toLocaleString('en-EN',
            {minimumFractionDigits: 0, maximumFractionDigits: 3})}
    </>
})

export default ColumnQuantity;