import {
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarFilterButton, GridToolbarQuickFilter
} from "@mui/x-data-grid";
import React from "react";

export default () =>
{
    return (
        <GridToolbarContainer>
            <GridToolbarColumnsButton/>
            <GridToolbarFilterButton/>
            <GridToolbarDensitySelector/>
            <GridToolbarQuickFilter debounceMs={500}/>
        </GridToolbarContainer>
    );
}
