import React from 'react';
import { Button, ButtonGroup, Col, Row} from "reactstrap";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {useMutation} from '@apollo/client';
import _ from 'lodash';
import CreatePurchaseOrdersMutation from '../../order/graphql/CreatePurchaseOrders.graphql';
import StdQuery from "../../common/StdQuery";
import gql from "graphql-tag";
import ClickOnceButton from "../../common/lib/ClickOnceButton";

const orderQuery = gql`
    query getOrder($id:ID!)
    {
        order(id:$id)
        {
            id
            number
            purchaseOrders
            {
                id
            }
        }
    }
`;

const createPurchaseOrders = async (createPurchaseOrders,orderId)=>
{
    try
    {
        const res = await createPurchaseOrders({
            variables:{orderId:parseInt(orderId)},
        });
        if(res.errors)
        {
            throw new Error(`Failure ${_.map(res.errors,e=>e.message)}`);
        }
        NotificationPopup.success(`Created Purchase Orders`);
    }
    catch(e)
    {
        NotificationPopup.error(`Failed to create Purchase Orders ${e.message}`);
    }
};

export default ({variables,toggle,onSubmit})=>
{
    const {orderId} = variables;
    const [createPOs] = useMutation(CreatePurchaseOrdersMutation);
    return <div>
        <p>
            Create all the purchase orders that this order rely on.
            <br/>
            You can add articles and Purchase Orders later if needed.
            <br/>
            The Purchase Orders' process/workflow is used to determine when this order can be delivered.
            <br/>
            In the case where Purchase Orders are sent directly from the supplier to the customer
            please make sure that you mark that Purchase Order as delivered when the supplier notifies you.
        </p>
        <Row className="justify-content-between">
            <Col sm={2}>
                <Button onClick={() => toggle()}>
                    Cancel
                </Button>
            </Col>
            <StdQuery query={orderQuery} variables={{id: orderId}}>
                {data =>
                {
                    if (data && data.order)
                    {
                        if (data.order.purchaseOrders && data.order.purchaseOrders.length > 0)
                        {
                            return <Col sm={10}>
                                <ButtonGroup>
                                    <span style={{border: "3px dashed red", padding: "2px",marginRight:"1em"}}>Purchase orders already exist. Not creating any Purchase Orders. Create manually if you need more POs.</span>
                                    <Button color="success" onClick={() =>
                                    {
                                        onSubmit();
                                        toggle();
                                    }}>
                                        Complete task
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        } else
                        {
                            return <Col sm={4}>
                                <ButtonGroup>
                                    <ClickOnceButton color="success" onClick={async () =>
                                    {
                                        if(window.confirm("Are you sure you want to create purchase orders?\nHave you checked so that the articles are correct?"))
                                        {
                                            await createPurchaseOrders(createPOs, orderId)
                                            onSubmit();
                                        }
                                        toggle();
                                    }}>
                                        Create purchase orders and complete task
                                    </ClickOnceButton>
                                </ButtonGroup>
                            </Col>
                        }
                    } else
                    {
                        return `Failed to find order`;
                    }
                }}
            </StdQuery>
        </Row>


    </div>
};



