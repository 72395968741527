import React from 'react';
import './loadingPlaceHolderStyle.css';

const LoadingPlaceHolder = () => {
    return (
        <div className='container'>
            <div className='centerBlock'>
                <div className='animateStair stair1 outer'><div className='inner'/></div>
                <div className='animateStair stair2 outer'><div className='inner'/></div>
                <div className='animateStair stair3 outer'><div className='inner'/></div>
                <div className='animateStair stair4 outer'><div className='inner'/></div>
            </div>
            Loading...
        </div>
    )
}

export default LoadingPlaceHolder;
