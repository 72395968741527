import {useStdMutation} from "../../spiralStair/lib/Util";
import {Button} from "reactstrap";
import React, {useCallback, useEffect, useState} from "react";
import {withRouter} from "react-router";
import ModalForm from "../ModalForm";
import _ from "lodash";
// @ts-ignore
import QuickCreateArticle from "./graphql/CreateArticleRow.graphql";
// @ts-ignore
import CreateSpiralStairMutation from "./graphql/CreateSpiralStairMutation.graphql";
// @ts-ignore
import QuickCreateModuleRampMutation from "./graphql/QuickCreateModuleRamp.graphql";

import {
    useGetSupplierByProductTypeIdQueryLazyQuery,
    useProductTypesQuery
} from "../../generated/graphql";
import NewFormRenderer from "../NewFormRenderer";
import config, {environment} from "../../config";


export default withRouter(({orderId, quoteId, history})=> {
    const [createArticle] = useStdMutation("Quick Create Article", QuickCreateArticle, {refetchQueries:["quote","getOrder"]});
    const onSubmit = useCallback(async ({productTypeId, supplierId}) =>{
        // @ts-ignore
        const res = await createArticle({
            variables: {
                orderId,
                quoteId,
                productTypeId,
                supplierId,
            }
        });
        if(res.data)
        {
            const articleRow = res.data.quickCreateArticle;
            history.push(`/ArticleDetail/${articleRow.article.id}/${articleRow.id}`);
        }
    }, [createArticle, orderId, quoteId, history]);

    return <ModalForm size="lg" title={"Article"} noFooter activationButtonText={"New Article"} buttonProps={{outline: true}}>
        {toggle => {
            return <SupplierSelector onSubmit={onSubmit} toggle={toggle} productTypeId={undefined} skipSupplier={!orderId}/>
        }}
    </ModalForm>

});

export const CreateSpiralStair = withRouter(({orderId, quoteId, history}) =>
{
    const [createRow] = useStdMutation("Create spiral stair", CreateSpiralStairMutation, {refetchQueries: ["quote", "getOrder"]});
    return <Button className={"primary-button-outline"} outline={true} onClick={async () =>
    {
        // @ts-ignore
        const res = await createRow({variables: {orderId, quoteId}});
        if (res.data)
        {
            const {spiralStair, articleRow} = res.data.quickCreateSpiralStair;
            history.push(`/spiralStairDetail/${spiralStair.id}/${articleRow.id}`);
        }
    }}>
        Spiral stair
    </Button>;
});

export const SupplierSelector = ({toggle, onSubmit, productTypeId, skipSupplier}) => {
    const [getSupplier] = useGetSupplierByProductTypeIdQueryLazyQuery();
    const {data: productTypesData} = useProductTypesQuery({variables:{filtered:true}});
    const [formDefinition, setFormDefinition] = useState<any>([]);
    const [supplierData, setSupplierData] = useState<any>([]);
    const [form, setForm] = useState<any>([]);

    useEffect(() => {
        const initForm = {
            productTypeId: productTypeId || null,
            supplierId: null,
        };
        setForm(initForm);
        const getSupplierFn = async () => {
            const res = await getSupplier({variables:{id:productTypeId}});
            setSupplierData(res.data);
        }
        if(productTypeId){
            getSupplierFn().then();
        }
    }, [getSupplier, setSupplierData, productTypeId]);

    useEffect(() => {
        if(skipSupplier){
            console.log("skipSupplier")
            setFormDefinition ([
                {name:"productTypeId", required:true, type:"select", options:_.get(productTypesData,"productTypes")},
            ]);
        }else if(productTypeId){
            setFormDefinition ([
                {name:"supplierId",required:true,type:"select", options:_.get(supplierData,"productType.suppliers")},
            ]);
        }else{
            setFormDefinition ([
                {name:"productTypeId", required:true, type:"select", options:_.get(productTypesData,"productTypes")},
                {name:"supplierId",required:true,type:"select", options:_.get(supplierData,"productType.suppliers")},
            ]);
        }
    }, [productTypesData, supplierData, skipSupplier, productTypeId]);

    return (
        <div>
            <p>Select supplier. Keep in mind this will be used to generate EAN Article Number.</p>
            <p>Later you can edit the article as usual.</p>
            <NewFormRenderer
                object={form}
                columns={2}
                formDefinition={formDefinition}
                onChange={async (key, val)=>{
                    if(key==="productTypeId"){
                        const res = await getSupplier({variables:{id:val}});
                        setSupplierData(res.data);
                        setForm({supplierId:null, productTypeId:val});
                    }else if(key==="supplierId"){
                        setForm({...form, supplierId:val});
                    }
                }}
                customSubmit={<Button color="success" style={{float:"right"}} onClick={()=>{
                    onSubmit({productTypeId:form.productTypeId, supplierId:form.supplierId});
                    toggle();
                }}>Create</Button>}
            />
        </div>
    );
}

export const QuickCreateModuleRamp = withRouter(({quoteId, orderId, history})=>
{
    const [quickCreate] = useStdMutation("Create module ramp",QuickCreateModuleRampMutation,{
        variables:{quoteId,orderId},
        refetchQueries:["quote","getOrder"]
    });

    if (config.environment === environment.PRODUCTION)
    {
        return null
    }

    return <Button outline={true} onClick={async()=>
    {
        // @ts-ignore
        const res = await quickCreate();
        if(res.data)
        {
            const articleRow = res.data.quickCreateModuleRamp;
            history.push(`/moduleRampDetail/${articleRow.article.moduleRamp.id}/${articleRow.id}`);
        }
    }}>
        Module ramp
    </Button>
});

