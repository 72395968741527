import React, {useState} from 'react';
import Pen from "../common/icons/Pen";
import gql from "graphql-tag";
import {map, last, uniq, sortBy,identity} from 'lodash';
import {Button} from "reactstrap";
import {useStdMutation} from "./lib/Util";
import {usePriceListVersionsQuery} from "../generated/graphql";


const mutation = gql`mutation($id:ID!,$spiralStair:SpiralStairInputType!)
{
    updateSpiralStair(id:$id,spiralStair: $spiralStair)
    {
        id
    }
}`

export default ({spiralStairId,priceListId})=>
{
    const {data,loading,error} = usePriceListVersionsQuery();
    const [update] = useStdMutation("Set pricelist",mutation,{refetchQueries:["spiralStairDetail", "getPrice"]});
    const [editing,setEditing] = useState(false);
    const [selectedVersion,setSelectedVersion] = useState(priceListId);
    if(loading)return <>...</>
    else if(error)return <>error..</>
    const latestVersion = last(sortBy(data?.priceListVersions,identity)) as number;
    const newerExists =  latestVersion > selectedVersion;

    if(!editing)
    {
        return (<p>
            {newerExists && <p style={{color:"red"}}>A newer list exists. New Version: {latestVersion}</p>}
            Pricelist version: {priceListId}
            <span className={"link"} onClick={()=>setEditing(true)}><Pen/></span>
        </p>)
    }
    else
    {
        return (<div>
            Pricelist version: {selectedVersion} →
            <select value={selectedVersion} onChange={e=>setSelectedVersion(e.target.value)}>
                {map(uniq(data?.priceListVersions?.concat([parseInt(priceListId)])),i=><option key={i} value={i}>{i}</option>)}
            </select>
            <Button style={{marginLeft:"8px"}} size={'sm'} color={"success"} onClick={async()=>
            {
                await update({variables:{
                        id:spiralStairId,
                        spiralStair:{priceListId:selectedVersion}
                }});
                setEditing(false);
            }}>Save</Button>
        </div>)
    }

}
