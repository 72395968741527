import CellArticleRow from "../../common/data-grid/cell/CellArticleRow";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import React, {useCallback, useEffect, useImperativeHandle, useState} from "react";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import {PackageItemFragmentFragment, useGetOrderLazyQuery} from "../../generated/graphql";
import {ok} from "assert";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import NewFormRenderer from "../../common/NewFormRenderer";
import {PackageItemsCreator, ArticleRowWithPackageQuantity} from "../ArticleRowWithPackageQuantity";
import Collapser from "../../common/Collapser";
import PackageItemsDataGrid from "./PackageItemsNonPaginatedDataGrid";

const TABLE_KEY = "PACKAGE_ARTICLE_ROWS"

type AddArticleRowForPackagePanelProps = {
    packageItems: PackageItemFragmentFragment[] | undefined | null
    orderId?: string,
    defaultSelectedArticleRowIdList?: string[]
    openSearchOrder?: boolean
}

const AddArticleRowForPackagePanel = React.forwardRef((props: AddArticleRowForPackagePanelProps, ref) =>
{
    const {packageItems, orderId, defaultSelectedArticleRowIdList, openSearchOrder} = props

    const [packageItemsCreator, setPackageItemsCreator] = useState<PackageItemsCreator[]>([])
    const [searchOrder, setSearchOrder] = useState<any>({orderId: orderId})
    const [order, setOrder] = useState<any>()
    const [ordersCache] = useState<any[]>([])
    const [articleRowsWithPackageQuantity, setArticleRowsWithPackageQuantity] = useState<ArticleRowWithPackageQuantity[]>([])
    const [selectedArticleRowIdList, setSelectedArticleRowIdList] = useState<GridRowSelectionModel>([])

    const [getOrderQuery, {loading}] = useGetOrderLazyQuery()

    const onSearchOrderChange = useCallback(async (key, value) =>
    {
        setSearchOrder({...searchOrder, [key]: value})
    }, [setSearchOrder, searchOrder])

    const getOrder = useCallback(async (orderId) =>
    {
        if (orderId == null)
        {
            return
        }
        const {data} = await getOrderQuery({variables: {id: orderId}})
        const order = data?.order
        if (order == null)
        {
            return
        }

        const selectedPackageItems = packageItemsCreator?.find((e) => e.orderId === order.id)?.items

        const newArticleRows = order.articleRows?.map((articleRow) =>
        {
            const selectedItem = selectedPackageItems?.find((item) => item.id === articleRow!.id)
            const selectedItemQuantity = selectedItem?.packagedQuantity

            const totalQuantity = articleRow!.quantity
            const packagedQuantity = selectedItem ? selectedItemQuantity : totalQuantity

            return {
                ...articleRow!,
                packagedQuantity,
            }
        }) ?? []
        const newOrder = {
            ...order,
            articleRows: newArticleRows,
        }

        ordersCache.push(newOrder)
        setOrder(newOrder)
    }, [getOrderQuery, ordersCache, packageItemsCreator])

    const getPackageItemsInput = useCallback(() =>
    {
        return packageItemsCreator.flatMap((e) => e.items)
            .map((e) => ({
                articleRowId: e.id,
                quantity: e.packagedQuantity
            }))
    }, [packageItemsCreator])

    const handleOnChange = useCallback((selectedArticleRowIdList, articleRowsWithPackageQuantity) =>
    {
        if (!order || !order.id || !order.number)
        {
            return;
        }

        const items = selectedArticleRowIdList?.map((e) =>
        {
            const found = articleRowsWithPackageQuantity.find((articlePackaging) => articlePackaging.id === e)
            ok(found, `Failed to find articleRow by id ${e}`)
            return found
        })

        const data = {
            orderId: order.id,
            orderNumber: order.number,
            items: items
        }

        const index = packageItemsCreator.findIndex((e) => e.orderId === data.orderId)
        const copy = [...packageItemsCreator]

        if (index === -1)
        {
            copy.push(data)
        } else
        {
            copy[index] = data
        }
        setPackageItemsCreator(copy)
    }, [order, packageItemsCreator, setPackageItemsCreator])

    const onPackageQuantityChange = useCallback((articleRowId, quantity: number) =>
    {
        const copy = [...articleRowsWithPackageQuantity]
        const data = copy.find((e) => e.id === articleRowId)
        if (data)
        {
            data.packagedQuantity = quantity
        }
        setArticleRowsWithPackageQuantity(copy)
        handleOnChange(selectedArticleRowIdList, copy)
    }, [articleRowsWithPackageQuantity, setArticleRowsWithPackageQuantity, handleOnChange, selectedArticleRowIdList])

    const onRowCheckChange = useCallback((newRowSelectionModel) =>
    {
        setSelectedArticleRowIdList(newRowSelectionModel)
        handleOnChange(newRowSelectionModel, articleRowsWithPackageQuantity)
    }, [setSelectedArticleRowIdList, handleOnChange, articleRowsWithPackageQuantity])

    const getColumns = useCallback((onPackageQuantityChange: (articleRowId: string, quantity: number) => void) =>
    {
        return [
            {
                headerName: 'Art no',
                field: 'article',
                filterable: false,
                renderCell: (params) =>
                {
                    return <CellArticleRow articleRowId={params.id} article={params.value}
                                           stockArticle={params.row.stockArticle}/>
                }
            },
            {
                field: "name",
                headerName: "Name"
            },
            {
                field: "quantity",
                headerName: "Total quantity",
                type: 'number'
            },
            {
                field: "packagedQuantity",
                headerName: "Package quantity",
                type: 'number',
                renderCell: (params) =>
                {
                    const value = params.value
                    const max = params.row.quantity
                    return <span>
                    <input type="number" defaultValue={value} min={0} max={max}
                           style={{width: "60px"}}
                           onChange={(e) =>
                           {
                               const quantity = Math.min(parseInt(e.target.value), max)
                               onPackageQuantityChange(params.id, quantity)
                           }}/>
                </span>
                }
            },
            ColumnId()
        ]
    }, [])

    useEffect(() =>
        {
            if (order == null)
            {
                return
            }
            const articleRows = order.articleRows
            setArticleRowsWithPackageQuantity(articleRows)

            const current = packageItemsCreator.find((e) => e.orderId === order.id)
            const selectedPackageItems = current?.items

            if (orderId && order.id === orderId && !current && defaultSelectedArticleRowIdList)
            {
                setPackageItemsCreator([{
                    orderId: order.id,
                    orderNumber: order.number,
                    items: articleRows.filter((e) => defaultSelectedArticleRowIdList.includes(e.id))
                }])
            } else
            {
                setSelectedArticleRowIdList(selectedPackageItems?.map((e) => e.id!) ?? [])
            }
        }, [order, packageItemsCreator, defaultSelectedArticleRowIdList, orderId]
    )

    useEffect(() =>
    {
        if (!packageItems)
        {
            return
        }
        const groupedPackageItems = packageItems.reduce((grouped, packageItem) =>
        {
            const articleRow = packageItem.articleRow
            if (!articleRow)
            {
                return grouped
            }

            const orderId = articleRow.orderId;
            if (!orderId)
            {
                return grouped
            }
            if (!grouped[orderId])
            {
                grouped[orderId] = [];
            }
            grouped[orderId].push(packageItem);
            return grouped;
        }, {});
        const packageItemsCreatorList: PackageItemsCreator[] = Object.keys(groupedPackageItems).map(orderId =>
        {
            const packageItemsForOrderId = groupedPackageItems[orderId];

            const firstPackageItem = packageItemsForOrderId[0]; // Assuming there's at least one packageItem
            const orderNumber = firstPackageItem.articleRow.order.number;

            const items: ArticleRowWithPackageQuantity[] = packageItemsForOrderId.map(packageItem =>
            {
                return {
                    ...packageItem.articleRow,
                    packagedQuantity: packageItem.quantity
                };
            });

            return {
                orderId,
                orderNumber,
                items
            };
        });

        setPackageItemsCreator(packageItemsCreatorList)
    }, [packageItems])

    useEffect(() =>
    {
        if (searchOrder.orderId)
        {
            const order = ordersCache.find((e) => e.id === searchOrder.orderId)
            if (order)
            {
                setOrder(order)
            } else
            {
                getOrder(searchOrder.orderId).then()
            }
        }
    }, [searchOrder.orderId, getOrder, ordersCache])

    useImperativeHandle(ref, () => ({getPackageItemsInput}))

    return <div>
        <Collapser label={"Show search order"} open={openSearchOrder ?? true}>
            <h4 style={{marginTop: "16px"}}>Search order</h4>

            <NewFormRenderer
                formDefinition={[{
                    name: "orderId",
                    label: "Order",
                    type: "search",
                    getSearchAllTenant: () => 'true',
                    filter: obj => obj.type === "order",
                }]}
                object={searchOrder}
                columns={3}
                onChange={onSearchOrderChange}/>

            <NonPaginatedDataGrid
                checkboxSelection
                disableRowSelectionOnClick
                loading={loading}
                definition={{
                    tableKey: TABLE_KEY,
                    pageSize: 10,
                    columns: getColumns(onPackageQuantityChange),
                    initState: {
                        columns: {
                            columnVisibilityModel: {
                                id: false
                            }
                        }
                    }
                }}
                rowSelectionModel={selectedArticleRowIdList}
                onRowSelectionModelChange={onRowCheckChange}
                data={articleRowsWithPackageQuantity}/>
        </Collapser>

        <hr/>
        <h4>Package Items</h4>
        <PackageItemsDataGrid packageItemsCreator={packageItemsCreator}/>
        <hr/>
    </div>
})

export default AddArticleRowForPackagePanel