import React, {Component} from 'react';
import {Button, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {withApollo} from '@apollo/client/react/hoc'

class ProjectForm extends Component
{


    constructor(data)
    {
        super();

        if(data.project)
        {
            const project = data.project;
            this.state = {
                project: {name:project.name,description:project.description},
                // companies:project.companies
            };
        }
        else
        {
            this.state =
                {
                    project:
                    {
                        name: "",
                        description: "",
                    },
                    // companies: null
                };
        }
    }

    submit(event)
    {
        event.preventDefault();
        this.props.onSubmit(this.state);
    }

    onChange( name, e) {
        this.setState({"project": { ...this.state.project, [name]:e.target.value } });
    }

    render()
    {
        //companySelectOptions(this.props.client);
        return (
            <Form onSubmit={(event) => this.submit(event)} className="col-sm-3">

                <FormGroup>
                    <Label>Name <small style={{color:"grey"}}>(required)</small></Label>
                    <Input required type="text" onChange={this.onChange.bind(this,'name')}
                           value={this.state.project.name}/>
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Input type="textarea" onChange={this.onChange.bind(this,'description')}
                           value={this.state.project.description}/>
                </FormGroup>
                {/*<Row>*/}
                    {/*<Col sm={4}>*/}
                        {/*<Select.Async autoload={false} value={this.state.companies}*/}
                                      {/*onChange={(selected) => {console.log(selected); this.setState({companies:selected})}}*/}
                                      {/*loadOptions={getOptions}*/}
                                      {/*multi={true}*/}
                        {/*/>*/}
                    {/*</Col>*/}
                {/*</Row>*/}
                <Row>
                    <Button type="submit">Save</Button>
                </Row>

            </Form>
        )
    }
}



export default withApollo(ProjectForm);
