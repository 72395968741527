import React, {useCallback, useMemo, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem, NavLink,
    Row, Spinner,
    TabContent, TabPane
} from 'reactstrap';
import {useMutation} from '@apollo/client';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import GetSupplier from './graphql/GetSupplier.graphql';
import DetailRenderer from "../common/DetailRenderer";
import CreateSupplierRef from './graphql/CreateSupplierRef.graphql';
import RemoveSupplierRef from './graphql/RemoveSupplierRef.graphql';
import ModalForm from "../common/ModalForm";
import FormRenderer from "../common/FormRenderer";
import Trash from "../common/icons/Trash";
import Collapser from "../common/Collapser";
import PurchaseOrderSubPanel from "../purchaseOrder/PurchaseOrderSubPanel";
import _ from 'lodash';
import StdQuery from "../common/StdQuery";
import UpdateSupplierRef from './graphql/UpdateSupplierRef.graphql';
import gql from "graphql-tag";
import P from "../common/Paragraph";
import {VismaSupplierClasses} from "./SupplierEditPage";
import UploadFile from "../common/UploadFile";
import FilesSubpanel from "../common/subpanels/FilesSubpanel";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import SupplierEvaluationPanel from "./evaluation/SupplierEvaluationPanel";
import {IsAdmin} from "../Auth";
import {useRemoveSupplierMutation} from "../generated/graphql";
import {useMutationHandler} from "../custom-hook/useMutationHandler";

const TABLE_KEY = "TABLE_KEY_SUPPLIER_REF"

const getPOs = gql`
query getPurchaseOrders($id:ID!)
{
    supplier(id:$id)
    {
        id
        purchaseOrders
        {
            id
            number
            createdAt
            assignee
            {
                username
                displayName
            }
            status
            receivedDate
            updatedAt
            deliveryDate
            tenantId
        }
    }
}
`;

const SupplierRefFormDef = [
    {name:'name',required:true},
    {name:"email"},
    {name:"workPhone"},
    {name:"mobilePhone"}
];


export default withRouter(({match, history})=>
{
    const [activeTab, setActiveTab] = useState('default');
    const [createSupplierRef] = useMutation(CreateSupplierRef,{refetchQueries:['getSupplier']});
    const [removeSupplierRef] = useMutation(RemoveSupplierRef,{refetchQueries:['getSupplier']});
    const [updateSupplierRef] = useMutation(UpdateSupplierRef,{refetchQueries:['getSupplier']});
    const {executeMutation: removeSupplier, loading} = useMutationHandler(useRemoveSupplierMutation)

    const [supplierRef,setSupplierRef] = useState(null);

    const columns = useMemo(()=> {
        return [
            {field:'name',
                renderCell: (params)=> {
                    return <span className="link" onClick={()=>setSupplierRef(params.row)}>{params.value}</span>;
                }
            },
            {
                field:"email",
                renderCell: (params)=> <a href={`mailto:${params.value}`}>{params.value}</a>
            },
            {field: "workPhone"},
            {field: "mobilePhone"},
            ColumnCreatedAt(),
            {
                field: "remove",
                renderCell:(params)=>{
                    return <p className='link' onClick={()=>
                    {
                        if(window.confirm(`Are you sure you want to remove this supplier ref?`))
                        {
                            removeSupplierRef({
                                variables:{
                                    id:params.id
                                },
                                refetchQueries:['getSupplier']
                            });
                        }
                    }}><Trash/></p>
                }
            },
            ColumnId(),
        ]
    },[removeSupplierRef])

    const onDeleteClick = useCallback(async (supplierId) => {
        if (window.confirm(`Are you sure you want to remove this supplier?`)) {
            await removeSupplier({variables: {id: supplierId}})
        }
    }, [removeSupplier])

    const renderRemoveButton = (supplierId) => {
        return <IsAdmin>
            {
                loading ? <Spinner/>
                    : <Button style={{marginLeft: "4px"}}
                              onClick={() => onDeleteClick(supplierId)}>
                        Remove
                    </Button>
            }
        </IsAdmin>
    }

    return <StdQuery query={GetSupplier} variables={{id:parseInt(match.params.id)}}>
        {(data)=>{
            if(data.supplier == null)
            {
                return (<p>Not found :(</p>);
            }
            const supplier = data.supplier;
            const eanValue = supplier.eanValue.toString().padStart(2,'0');
            return (
                <div>
                    <Nav tabs fill>
                        <NavItem style={{maxWidth:"300px"}}>
                            <NavLink active={activeTab === 'default'} onClick={() => {
                                setActiveTab('default');
                            }}>
                                <h4>{supplier.name}</h4>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{maxWidth:"300px"}}>
                            <NavLink active={activeTab === 'files'} onClick={() => {
                                setActiveTab('files');
                            }}>
                                <h4>Files</h4>
                            </NavLink>
                        </NavItem>
                        <NavItem style={{maxWidth:"300px"}}>
                            <NavLink active={activeTab === 'evaluation'} onClick={() => {
                                setActiveTab('evaluation');
                            }}>
                                <h4>Evaluation</h4>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} style={{paddingTop:"35px"}}>
                        <TabPane tabId="default">
                            <Row>
                                <Col sm={6} lg={4}>
                                    <DetailRenderer object={{eanValue,..._.omit(supplier,['eanValue','name','supplierRefs','purchaseOrders','id'
                                            ,'supplierStreet','supplierCity','supplierCountry','supplierPostalCode','vismaSupplierClass'])
                                        ,vismaSupplierClass:_.get(_.find(VismaSupplierClasses,({id})=>id===supplier.vismaSupplierClass),"name",""),
                                    }}/>
                                </Col>
                                <Col sm={6} lg={4}>
                                    <Link style={{marginRight: "4px"}} to={`/supplierEdit/${supplier.id}`}>
                                        <Button color="secondary">Edit</Button>
                                    </Link>
                                    <ModalForm activationButtonText='Add supplier ref' title='Add supplier ref'>
                                        {(toggle)=>
                                        {
                                            return <FormRenderer formDefinition={SupplierRefFormDef} onSubmit={async ({object}) =>
                                            {
                                                await createSupplierRef({
                                                    variables:{
                                                        supplierRef:{
                                                            ...object,
                                                            supplierId:supplier.id
                                                        }
                                                    },
                                                    refetchQueries:['getSupplier']
                                                });
                                                toggle();
                                            }}/>
                                        }}
                                    </ModalForm>
                                    {renderRemoveButton(supplier.id)}
                                    <Card>
                                        <CardHeader>
                                            Supplier address
                                        </CardHeader>
                                        <CardBody>
                                            <P>{supplier.supplierStreet}</P>
                                            <P>{supplier.supplierCity} {supplier.postalCode}</P>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                            <hr/>
                            <Modal isOpen={supplierRef!=null} toggle={()=>setSupplierRef(null)}>
                                <ModalHeader toggle={()=>setSupplierRef(null)}>
                                    Edit {supplierRef && supplierRef.name}
                                </ModalHeader>
                                <ModalBody>
                                    <FormRenderer object={supplierRef} formDefinition={SupplierRefFormDef}
                                                  onSubmit={({object}) =>
                                                  {
                                                      updateSupplierRef({variables:{id:supplierRef.id,supplierRef:object}});
                                                      setSupplierRef(null);
                                                  }}/>
                                </ModalBody>
                            </Modal>
                            <h4>Supplier Refs</h4>
                            <NonPaginatedDataGrid definition={{
                                tableKey: TABLE_KEY,
                                columns: columns,
                                initState: {
                                    sorting: {sortModel: [{field: 'name', sort: "asc"}]}
                                }
                            }} data={supplier.supplierRefs}/>
                            <hr/>
                            <Collapser label={"Show purchase orders"} open>
                                <h4>Purchase Orders</h4>
                                <StdQuery query={getPOs} variables={{id:supplier.id}}>
                                    {({supplier})=>
                                        <PurchaseOrderSubPanel noSupplier showTenant purchaseOrders={supplier.purchaseOrders}/>
                                    }
                                </StdQuery>

                            </Collapser>
                        </TabPane>

                        <TabPane tabId="files">
                            <UploadFile noCategory noModal supplierId={supplier.id}/>
                            <FilesSubpanel noCategory files={supplier.files}/>
                        </TabPane>
                        <TabPane tabId="evaluation">
                            <SupplierEvaluationPanel supplierId={supplier.id}/>
                        </TabPane>
                    </TabContent>
            </div>)
        }}
    </StdQuery>
});
