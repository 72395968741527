import {Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import React, {useEffect} from "react";
import CreditRatingInfoBox from "../company/CreditRatingInfoBox";
import {useLazyQuery, useMutation} from '@apollo/client';
import ConvertToOrderMutation from './graphql/ConvertToOrder.graphql';
import gql from "graphql-tag";
import {withRouter} from "react-router";
import NotificationPopup from "../common/lib/NotificationPopup";
import logger from '../Logger';
import Auth from "../Auth";
import {Link} from "react-router-dom";
import DefaultQueryHandler from "../common/DefaultQueryHandler";

const convertToOrderAction = async(convertToOrder,history,quoteId)=>
{

    if(window.confirm(`Are you sure you want to convert this quote to an order?`))
    {
        try{
            const res = await convertToOrder({variables:
                {
                    quoteId:quoteId
                }});
            if(res.errors || !res.data.convertToOrder.id)
            {
                NotificationPopup.error(`Something went wrong when trying to convert to order ${res.errors.toString()}`);
                logger.error(`${Auth.getUsername()} failed to convert to order`,{errors:res.errors});
            }
            else
            {
                history.push(`/orderDetail/${res.data.convertToOrder.id}`);
            }
        }
        catch(e)
        {
            NotificationPopup.error(`Something went wrong when trying to convert to order. ${e.message}`);
            logger.error(`${Auth.getUsername()} failed to convert to order`,{msg:e.message,stack:e.stack});
        }


    }
};

const getCompany = gql`
    query company($id:ID!)
    {
        company(id:$id)
        {
            id
            name
            organisationNumber
            creditRating
            creditRatingComment
            creditRatingDate
            creditRatingLimitCurrency
        }
    }
`;


export default withRouter(({isOpen,quote,toggle,history})=>
{
    const [convertToOrder] = useMutation(ConvertToOrderMutation);
    const [fetchCompany,res] = useLazyQuery(getCompany);

    useEffect(()=>
    {
        if(quote.company)
        {
            fetchCompany({variables:{id:quote.company.id}});
        }

    },[fetchCompany,quote])
    console.log(res);
    const {data} = res;
    return <DefaultQueryHandler res={res}>
        {data && <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Convert to Order
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <CreditRatingInfoBox company={data.company}/>
                    </Col>
                </Row>
                <br/>
            </ModalBody>
            <ModalFooter>
                {!data.company.organisationNumber && <p style={{color:"red"}}>
                    Quote's company "<Link to={`/companyDetail/${data.company.id}`}>
                    {data.company.name}
                </Link>" is missing organisation number.

                </p>}
                {data.company.organisationNumber && <Button color={"success"} onClick={() => convertToOrderAction(convertToOrder, history, quote.id)}>
                    Convert to Order
                </Button>}

            </ModalFooter>
        </Modal>}
        <span></span>
    </DefaultQueryHandler>
})
