import React, {useState} from 'react';
import gql from "graphql-tag";
import FormRenderer from "../common/FormRenderer";
import {Button, Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";
import config from '../config';
import TemplateAdministration from "./TemplateAdministration";
import {useMutation} from "@apollo/client";
import NotificationPopup from "../common/lib/NotificationPopup";
import Auth from "../Auth";
import Logger from "../Logger";

const restartOrderWorkflow = gql`
    mutation restartOrderWorkflow($id:ID!)
    {
        restartOrderWorkflow(id:$id)
        {
            id
            status
            name
        }
    }
`

const restartPoWF = gql`
 mutation restartPOWF($id:ID!)
 {
     restartPurchaseOrderWorkflow(id:$id)
     {
         id
         status
     }
 }`


export default ()=>
{
    const [restartOrder,{loading:orderLoading}] = useMutation(restartOrderWorkflow);
    const [restartPO,{loading:poLoading}] = useMutation(restartPoWF);
    const [ldapLoading,setLdapLoading] = useState(false);

    return <div>
        <h2>Admin page</h2>
        <Row>
            <Col sm={6}>
                <Card>
                    <CardHeader>
                        <h2>Templates</h2>
                    </CardHeader>
                    <CardBody>
                        <TemplateAdministration />
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Row>
            <Col sm={3}>
                <Card>
                    <CardHeader>
                        <h2>LDAP Sync</h2>
                    </CardHeader>
                    <CardBody>
                        {!ldapLoading && <Button color="primary" onClick={async()=>
                        {
                            if(window.confirm(`Are you sure you wish to perform a LDAP Sync?`))
                            {
                                setLdapLoading(true);
                                try
                                {
                                    await fetch(config.BACKEND_URI + "ldapSync");
                                }
                                catch(e)
                                {
                                    NotificationPopup.error(`Failed to perform LDAP Sync. ${e.message}`);
                                    Logger.error(`Failed to perform LDAP Sync`,{user:Auth.getUsername()})
                                }
                                finally
                                {
                                    setLdapLoading(false);
                                }
                            }
                        }}>
                            LDAP Sync
                        </Button>}
                        {ldapLoading && <Spinner color="warning"/>}
                    </CardBody>
                </Card>

            </Col>
        </Row>
        <Row>
            <Col sm={6}>
                <Card>
                    <CardHeader>
                        <h2>Restart ORDER workflow</h2>
                    </CardHeader>
                    <CardBody>
                        {orderLoading && <Spinner color={"warning"}/>}
                        {!orderLoading && <FormRenderer submitButtonText={"Restart workflow"}
                                      formDefinition={[{name:"id",label:"Id of order",required:true,type:"number"}]}
                                      onSubmit={async(form)=>
                                      {
                                          try
                                          {
                                              await restartOrder({variables:{id:form.object.id}})
                                              NotificationPopup.success(`Restarted workflow`);
                                          }
                                          catch(e)
                                          {
                                              NotificationPopup.error(`Failed to restart workflow ${e.message}`);
                                          }
                                      }}/>}
                    </CardBody>
                </Card>
            </Col>
        </Row>

        <Row>
            <Col sm={6}>
                <Card>
                    <CardHeader>
                        <h2>Restart PURCHASE ORDER workflow</h2>
                    </CardHeader>
                    <CardBody>
                        <p>Scheduler tasks are removed when restarting workflow
                        </p>
                        {poLoading && <Spinner color={"warning"}/>}
                        {!poLoading && <FormRenderer submitButtonText={"Restart PO workflow"}
                                      formDefinition={[{name:"id",label:"Id of PO",required:true,type:"number"}]}
                                      onSubmit={async(form)=>
                                      {
                                          try
                                          {
                                              await restartPO({variables:{id:form.object.id}})
                                              NotificationPopup.success(`Restarted workflow`);
                                          }
                                          catch(e)
                                          {
                                              NotificationPopup.error(`Failed to restart workflow ${e.message}`);
                                          }
                                      }}/>}
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>
}
