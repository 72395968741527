import React, {Component} from 'react';
import {Nav} from 'reactstrap';

export default class DefaultSidebar extends Component {
    render()
    {
        return (
            <Nav pills vertical>
                {/*<NavItem>*/}
                    {/*<Link to="/" className="nav-link">My tasks (Not implemented)</Link>*/}
                {/*</NavItem>*/}
            </Nav>);
    }
}



