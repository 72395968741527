import StdQuery from "../common/StdQuery";
import gql from "graphql-tag";
import {Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import moment from "moment";

const timeSpentQuery = gql`
query articleRows($articleIds:[ID!])
    {
        articleRows(articleIds:$articleIds)
        {            
            id         
            schedulerTasks
            {
                duration
                finishedAt
                startedAt
                id
                taskType
                {
                    id
                    name
                    description
                }                        
            }                            
        }
    }
`;

const START_OF_DAY_HOUR = 7;
const END_OF_DAY_HOUR = 16; 

function dateDiff(start, end) {
    let startMoment = moment(start);
    let endMoment = moment(end);
    let diffInMinutes = 0;
    let dailyDiff = 0;
    while(!startMoment.isSame(endMoment, 'day')) {
        dailyDiff = startMoment.clone().hour(END_OF_DAY_HOUR).minute(0).second(0).diff(startMoment, 'minutes');        
        if(dailyDiff > 0) {
            diffInMinutes += dailyDiff;
        }
        if(startMoment.isoWeekday() === 5) { //it's friday, jump to monday morning
            startMoment.hour(START_OF_DAY_HOUR).minute(0).second(0).add(3, 'days');
        } else {
            startMoment.hour(START_OF_DAY_HOUR).minute(0).second(0).add(1, 'days');
        }
    }
    dailyDiff = endMoment.diff(startMoment, 'minutes');
    if(dailyDiff > 0) {
        diffInMinutes += dailyDiff;
    }
    return diffInMinutes;
}

export default ({spiralStairId,open,toggle})=>
{
    return <Modal isOpen={open} toggle={toggle}>
        <ModalHeader toggle={toggle}>Time spent producing spiral stair</ModalHeader>
        <ModalBody>
            <StdQuery query={timeSpentQuery} variables={{articleIds:[spiralStairId]}}>
                {(data) =>
                {
                    return <Table>
                        <thead>
                        <tr>
                            <th>Task</th>
                            <th>Duration</th>
                            <th>Estimate</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.articleRows[0].schedulerTasks.map(({id,duration,finishedAt,startedAt,taskType})=>
                            <tr key={id}>
                                <td>{taskType.name}</td>
                                <td>{dateDiff(startedAt,finishedAt)} mins</td>
                                <td>{duration} mins</td>
                            </tr>)}
                        </tbody>
                    </Table>                       
                }}
            </StdQuery>
        </ModalBody>
    </Modal>
}