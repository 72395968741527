import React, {useState} from 'react';
import {Row, Navbar, Nav, NavItem, NavbarToggler, Collapse, NavLink, NavbarBrand} from 'reactstrap';
import {Link} from 'react-router-dom';
import {InventoryRoute} from "../InventoryRouters";
import config, {environment} from "../../config";

const InventoryLabel = () => {
    let text = ""
    if (config.environment === environment.LOCAL) {
        text = "DEBUG"
    } else if (config.environment === environment.DEVELOP) {
        text = "DEVELOP"
    } else {
        text = ""
    }

    return <NavbarBrand style={{color: "white"}} href={"/"}>{text}</NavbarBrand>
}

export default () => {
    const items = [
        {
            name: "Articles",
            to: InventoryRoute.ARTICLES
        },
        {
            name: "Orders",
            to: InventoryRoute.ORDERS
        },
        {
            name: "Purchase Orders",
            to: InventoryRoute.PURCHASE_ORDERS
        },
        {
            name: "Suppliers",
            to: InventoryRoute.SUPPLIERS
        },
        {
            name: "Reports",
            to: InventoryRoute.REPORTS
        },
        {
            name: "Stocktaking",
            to: InventoryRoute.STOCK_TAKING
        },
        {
            name: "Receiving Article",
            to: InventoryRoute.CODE_SCANNER
        },
        {
            name: "Scheduler",
            to: InventoryRoute.SCHEDULER
        }
    ]
    const [navbarOpen, setNavbar] = useState(false);
    const toggleNavbar = () => setNavbar(i => !i);

    return <div>
        <Row>
            <Navbar className={"inventoryNavBar navbar-light"} expand={"md"}>
                <InventoryLabel/>
                <NavbarToggler onClick={toggleNavbar}/>
                <Collapse isOpen={navbarOpen} navbar>
                    <Nav navbar onSelect={toggleNavbar}>
                        {items.map(({name, to}) => <NavItem key={name}>
                            <NavLink>
                                <Link to={to}>
                                    {name}
                                </Link>
                            </NavLink>
                        </NavItem>)}
                    </Nav>
                </Collapse>
            </Navbar>
        </Row>
    </div>
}

