import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CreateStraightStairSupportMutation,
    StraightStairSupportFragmentFragment,
    useCreateStraightStairSupportMutation,
    useDeleteStraightStairSupportMutation
} from "../../generated/graphql";
import {StraightStairHookProps} from "../StraightStairV2Types";
import {useCallback} from "react";

const useSupportHook = (props: StraightStairHookProps) =>
{
    const {straightStairV2, setStraightStairV2} = props;

    const {
        executeMutation: createSupportMutation,
        loading: addSupportLoading
    } = useMutationHandler(useCreateStraightStairSupportMutation)

    const {
        executeMutation: deleteSupportMutation,
        loading: deleteSupportLoading
    } = useMutationHandler(useDeleteStraightStairSupportMutation)

    const setStraightStairSupport = useCallback((onUpdate: (newSupports: StraightStairSupportFragmentFragment[]) => StraightStairSupportFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, supports: onUpdate([...prevState.supports])}
        })
    }, [setStraightStairV2])

    const updateSupport = useCallback((support: StraightStairSupportFragmentFragment) =>
    {
        setStraightStairSupport((newSupports) =>
        {
            const index = newSupports.findIndex(e => e.id === support.id);
            if (index === -1)
            {
                return newSupports;
            }
            newSupports[index] = support;

            return newSupports;
        })
    }, [setStraightStairSupport])

    const onCreateSupportSuccess = useCallback((data: CreateStraightStairSupportMutation | null) =>
    {
        const support = data?.createStraightStairSupport
        if (support)
        {
            setStraightStairSupport((newSupports) =>
            {
                newSupports.push(support)
                return newSupports;
            })
        }
    }, [setStraightStairSupport])

    const addSupport = useCallback(async () =>
    {
        if (!straightStairV2)
        {
            return
        }

        return await createSupportMutation({variables: {straightStairV2Id: straightStairV2.id}},
            {onSuccess: onCreateSupportSuccess})
    }, [createSupportMutation, onCreateSupportSuccess, straightStairV2])

    const onDeleteSupportSuccess = useCallback((id: string) =>
    {
        setStraightStairSupport((newSupports) =>
        {
            return newSupports.filter((e) => e.id !== id)
        })
    }, [setStraightStairSupport])

    const deleteSupport = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }
        await deleteSupportMutation({variables: {id: id}},
            {onSuccess: () => onDeleteSupportSuccess(id)})
    }, [straightStairV2, deleteSupportMutation, onDeleteSupportSuccess])


    return {
        addSupport, deleteSupport, updateSupport,
        addSupportLoading, deleteSupportLoading,
    }
}

export default useSupportHook;