import React, {Component} from 'react';
import { graphql } from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';
import ContactUpdateMutation from './graphql/UpdateContact.graphql';
import GetContactQuery from './graphql/GetContact.graphql';
import StdQuery from "../common/StdQuery";
import FormRenderer from "../common/FormRenderer";
import {ContactFormDefinition} from "./CreateContactPage";
import {Col} from "reactstrap";

class ContactEditPage extends Component
{

    render()
    {
        return <StdQuery query={GetContactQuery} variables={{id:parseInt(this.props.match.params.id)}}>
            {data=>
            {
                const {contact} = data;
                return <Col sm={6}>
                    <FormRenderer onSubmit={this.updateContact} object={contact}
                                  formDefinition={ContactFormDefinition(contact)}/>
                </Col>
            }}
        </StdQuery>
    }
     updateContact = async(contactForm)=>
    {
        const id = parseInt(this.props.match.params.id);
        await this.props.updateContact({
            variables:{
                contact:contactForm.object,
                id
            }
        });
        this.props.history.push(`/contactDetail/${id}`);
    }



}


export default graphql(ContactUpdateMutation,
{name: "updateContact"})(withRouter(ContactEditPage));

