import {Spinner} from "reactstrap";
import NotificationPopup from "./lib/NotificationPopup";
import React from "react";

type QueryResultProps = {
    loading: boolean
    error?: Error | null
    children: React.ReactNode
    customError?: React.ReactNode
    isHideLoading?: boolean
};

const QueryResult: React.FC<QueryResultProps> = ({loading, error, customError, isHideLoading, children}) =>
{
    if (loading)
    {
        if (isHideLoading)
        {
            return null;
        }
        return <Spinner color="warning"/>
    }

    if (error)
    {
        if (customError)
        {
            return <>{customError}</>
        }
        const message = error.message
        NotificationPopup.error(`${message}`)
        return <p style={{color: "red"}}>Error: {message}</p>
    }

    return <>{children}</>
};

export default QueryResult;