import React from 'react';

export default () => {
    return <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} width={"25px"} height={"25px"}>
        <path style={{fill:"#9E5A23"}}
              d="M264.828,361.931h-256l45.021-124.469l52.083-60.91l17.655-26.483L256,203.034L264.828,361.931z"/>
        <path style={{fill:"#844414"}}
              d="M211.862,361.931c0-29.131-23.834-52.966-52.966-52.966s-52.966,23.835-52.966,52.966  c0,29.131,23.834,52.966,52.966,52.966S211.862,391.062,211.862,361.931"/>
        <g>
            <path style={{fill:"#CE8D5B"}}
                  d="M105.931,361.931c0-29.131-23.834-52.966-52.966-52.966S0,332.8,0,361.931   c0,29.131,23.834,52.966,52.966,52.966S105.931,391.062,105.931,361.931"/>
            <path style={{fill:"#CE8D5B"}}
                  d="M150.069,256c0-29.131-23.834-52.966-52.966-52.966S44.138,226.869,44.138,256   c0,29.131,23.834,52.966,52.966,52.966S150.069,285.131,150.069,256"/>
        </g>
        <g>
            <path style={{fill:"#844414"}}
                  d="M79.448,264.828c-5.297,0-8.828-3.531-8.828-8.828c0-15.007,11.476-26.483,26.483-26.483   c5.297,0,8.828,3.531,8.828,8.828s-3.531,8.828-8.828,8.828s-8.828,3.531-8.828,8.828   C88.276,261.297,84.745,264.828,79.448,264.828"/>
            <path style={{fill:"#844414"}}
                  d="M35.31,370.759c-5.297,0-8.828-3.531-8.828-8.828c0-15.007,11.476-26.483,26.483-26.483   c5.297,0,8.828,3.531,8.828,8.828c0,5.297-3.531,8.828-8.828,8.828s-8.828,3.531-8.828,8.828   C44.138,367.228,40.607,370.759,35.31,370.759"/>
        </g>
        <path style={{fill:"#CE8D5B"}}
              d="M141.241,370.759c-5.297,0-8.828-3.531-8.828-8.828c0-15.007,11.476-26.483,26.483-26.483  c5.297,0,8.828,3.531,8.828,8.828c0,5.297-3.531,8.828-8.828,8.828s-8.828,3.531-8.828,8.828  C150.069,367.228,146.538,370.759,141.241,370.759"/>
        <path style={{fill:"#9E5A23"}}
              d="M353.103,97.103H158.897c29.131,0,52.966,23.834,52.966,52.966s-23.834,52.966-52.966,52.966h194.207  c29.131,0,52.966-23.834,52.966-52.966S382.234,97.103,353.103,97.103"/>
        <path style={{fill:"#844414"}}
              d="M397.241,203.034H203.034C232.166,203.034,256,226.869,256,256c0,29.131-23.834,52.966-52.966,52.966  h194.207c29.131,0,52.966-23.835,52.966-52.966S426.372,203.034,397.241,203.034"/>
        <g>
            <path style={{fill:"#9E5A23"}}
                  d="M459.034,308.966H264.828c29.131,0,52.966,23.835,52.966,52.966   c0,29.131-23.834,52.966-52.966,52.966h194.207c29.131,0,52.966-23.834,52.966-52.966C512,332.8,488.165,308.966,459.034,308.966"/>
            <path style={{fill:"#9E5A23"}}
                  d="M105.931,361.931c0,29.131-23.834,52.966-52.966,52.966h105.931   C129.766,414.897,105.931,391.062,105.931,361.931"/>
            <path style={{fill:"#9E5A23"}}
                  d="M211.862,361.931c0,29.131-23.834,52.966-52.966,52.966h105.931   C235.697,414.897,211.862,391.062,211.862,361.931"/>
        </g>
        <g>
            <path style={{fill:"#844414"}}
                  d="M264.828,141.241h-55.614c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h55.614   c5.297,0,8.828,3.531,8.828,8.828C273.655,137.71,270.124,141.241,264.828,141.241"/>
            <path style={{fill:"#844414"}}
                  d="M247.172,176.552h-37.959c-5.297,0-8.828-3.531-8.828-8.828s3.531-8.828,8.828-8.828h37.959   c5.297,0,8.828,3.531,8.828,8.828S252.469,176.552,247.172,176.552"/>
            <path style={{fill:"#844414"}}
                  d="M344.276,176.552H291.31c-5.297,0-8.828-3.531-8.828-8.828s3.531-8.828,8.828-8.828h52.966   c5.297,0,8.828,3.531,8.828,8.828S349.572,176.552,344.276,176.552"/>
            <path style={{fill:"#844414"}}
                  d="M388.414,141.241h-44.138c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h44.138   c5.297,0,8.828,3.531,8.828,8.828C397.241,137.71,393.71,141.241,388.414,141.241"/>
            <path style={{fill:"#844414"}}
                  d="M308.966,141.241h-8.828c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h8.828   c5.297,0,8.828,3.531,8.828,8.828C317.793,137.71,314.262,141.241,308.966,141.241"/>
            <path style={{fill:"#844414"}}
                  d="M388.414,176.552h-8.828c-5.297,0-8.828-3.531-8.828-8.828s3.531-8.828,8.828-8.828h8.828   c5.297,0,8.828,3.531,8.828,8.828S393.71,176.552,388.414,176.552"/>
        </g>
        <g>
            <path style={{fill:"#9E5A23"}}
                  d="M308.966,247.172h-55.614c-5.297,0-8.828-3.531-8.828-8.828s3.531-8.828,8.828-8.828h55.614   c5.297,0,8.828,3.531,8.828,8.828S314.262,247.172,308.966,247.172"/>
            <path style={{fill:"#9E5A23"}}
                  d="M291.31,282.483h-37.959c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h37.959   c5.297,0,8.828,3.531,8.828,8.828C300.138,278.952,296.607,282.483,291.31,282.483"/>
            <path style={{fill:"#9E5A23"}}
                  d="M388.414,282.483h-52.966c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h52.966   c5.297,0,8.828,3.531,8.828,8.828C397.241,278.952,393.71,282.483,388.414,282.483"/>
            <path style={{fill:"#9E5A23"}}
                  d="M432.552,247.172h-44.138c-5.297,0-8.828-3.531-8.828-8.828s3.531-8.828,8.828-8.828h44.138   c5.297,0,8.828,3.531,8.828,8.828S437.848,247.172,432.552,247.172"/>
            <path style={{fill:"#9E5A23"}}
                  d="M353.103,247.172h-8.828c-5.297,0-8.828-3.531-8.828-8.828s3.531-8.828,8.828-8.828h8.828   c5.297,0,8.828,3.531,8.828,8.828S358.4,247.172,353.103,247.172"/>
            <path style={{fill:"#9E5A23"}}
                  d="M432.552,282.483h-8.828c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h8.828   c5.297,0,8.828,3.531,8.828,8.828C441.379,278.952,437.848,282.483,432.552,282.483"/>
        </g>
        <g>
            <path style={{fill:"#844414"}}
                  d="M370.759,353.103h-55.614c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h55.614   c5.297,0,8.828,3.531,8.828,8.828C379.586,349.572,376.055,353.103,370.759,353.103"/>
            <path style={{fill:"#844414"}}
                  d="M353.103,388.414h-37.959c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h37.959   c5.297,0,8.828,3.531,8.828,8.828C361.931,384.883,358.4,388.414,353.103,388.414"/>
            <path style={{fill:"#844414"}}
                  d="M450.207,388.414h-52.966c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h52.966   c5.297,0,8.828,3.531,8.828,8.828C459.034,384.883,455.503,388.414,450.207,388.414"/>
            <path style={{fill:"#844414"}}
                  d="M494.345,353.103h-44.138c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h44.138   c5.297,0,8.828,3.531,8.828,8.828C503.172,349.572,499.641,353.103,494.345,353.103"/>
            <path style={{fill:"#844414"}}
                  d="M414.897,353.103h-8.828c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h8.828   c5.297,0,8.828,3.531,8.828,8.828C423.724,349.572,420.193,353.103,414.897,353.103"/>
            <path style={{fill:"#844414"}}
                  d="M494.345,388.414h-8.828c-5.297,0-8.828-3.531-8.828-8.828c0-5.297,3.531-8.828,8.828-8.828h8.828   c5.297,0,8.828,3.531,8.828,8.828C503.172,384.883,499.641,388.414,494.345,388.414"/>
        </g>
        <path style={{fill:"#CE8D5B"}}
              d="M211.862,150.069c0-29.131-23.834-52.966-52.966-52.966s-52.966,23.834-52.966,52.966  s23.834,52.966,52.966,52.966S211.862,179.2,211.862,150.069"/>
        <path style={{fill:"#844414"}}
              d="M141.241,158.897c-5.297,0-8.828-3.531-8.828-8.828c0-15.007,11.476-26.483,26.483-26.483  c5.297,0,8.828,3.531,8.828,8.828c0,5.297-3.531,8.828-8.828,8.828s-8.828,3.531-8.828,8.828  C150.069,155.366,146.538,158.897,141.241,158.897"/>
        <g>
            <path style={{fill:"#CE8D5B"}}
                  d="M256,256c0-29.131-23.834-52.966-52.966-52.966S150.069,226.869,150.069,256   c0,29.131,23.834,52.966,52.966,52.966S256,285.131,256,256"/>
            <path style={{fill:"#CE8D5B"}}
                  d="M317.793,361.931c0-29.131-23.834-52.966-52.966-52.966c-29.131,0-52.966,23.835-52.966,52.966   c0,29.131,23.835,52.966,52.966,52.966C293.959,414.897,317.793,391.062,317.793,361.931"/>
        </g>
        <path style={{fill:"#9E5A23"}}
              d="M185.379,264.828c-5.297,0-8.828-3.531-8.828-8.828c0-15.007,11.476-26.483,26.483-26.483  c5.297,0,8.828,3.531,8.828,8.828s-3.531,8.828-8.828,8.828s-8.828,3.531-8.828,8.828  C194.207,261.297,190.676,264.828,185.379,264.828"/>
        <path style={{fill:"#844414"}}
              d="M247.172,370.759c-5.297,0-8.828-3.531-8.828-8.828c0-15.007,11.476-26.483,26.483-26.483  c5.297,0,8.828,3.531,8.828,8.828c0,5.297-3.531,8.828-8.828,8.828c-5.297,0-8.828,3.531-8.828,8.828  C256,367.228,252.469,370.759,247.172,370.759"/>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
        <g>
        </g>
    </svg>
}