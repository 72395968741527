import {RailingType, StraightStairSegmentFragmentFragment, StringerMaterial} from "../generated/graphql";
import {STRAIGHT_STAIR_TAB_SEGMENT} from "./StraightStairConstants";
import {PlatformRailingVo} from "./StraightStairV2Types";

export const getSegmentId = (id: string) =>
{
    return `${STRAIGHT_STAIR_TAB_SEGMENT}${id}`
}

export const convertRailingTypeToLabel = (type: string) =>
{
    switch (type.toLowerCase())
    {
        case RailingType.PlsSimple.toLowerCase():
            return "PLS Simple"
        case RailingType.PlsStd.toLowerCase():
            return "PLS STD"
        case RailingType.RodSimple.toLowerCase():
            return "Rod Simple"
        case RailingType.StdTube.toLowerCase():
            return "STU tube"
        default:
            throw Error(`unknown railing type ${type}`)
    }
}

export const calculateT1Width = (stairStringerMaterial: StringerMaterial, stepsWidth: number) =>
{
    let h: number
    switch (stairStringerMaterial) // Stringer_calculator_Data: X22~X33
    {
        case StringerMaterial.Pls150x10:
        case StringerMaterial.Pls200x10:
        case StringerMaterial.Pls250x10:
            h = 10
            break;
        case StringerMaterial.Pls150x8:
        case StringerMaterial.Pls200x8:
        case StringerMaterial.Pls250x8:
            h = 8
            break;
        case StringerMaterial.Pls200x12:
        case StringerMaterial.Pls300x12:
            h = 12
            break;
        case StringerMaterial.Upe140:
            h = 65
            break;
        case StringerMaterial.Upe160:
            h = 70
            break;
        case StringerMaterial.Upe180:
            h = 75
            break;
        case StringerMaterial.Upe200:
            h = 80
            break;
    }
    return 2 * h + stepsWidth
}

export const getRecommendedPlatformRailingLength = (side: string,
                                                    segment: StraightStairSegmentFragmentFragment,
                                                    platformRailing: PlatformRailingVo,
                                                    t1Width: number) =>
{
    if (!platformRailing.checked)
    {
        return 0;
    }
    let TB2 = platformRailing.length
    const CB6 = segment.platformSCP1
    const CB7 = segment.platformSCP2
    const TB10 = t1Width

    const TB8 = (side === "L1" || side === "L2")
        ? segment.platformLength
        : segment.platformWidth

    if (CB6 === side || CB7 === side)
    {
        if (TB2 >= (TB8 - TB10) || (TB8 - TB10) > 150)
        {
            if ((TB8 - TB10) > 0)
            {
                TB2 = TB8 - TB10;
            } else
            {
                TB2 = 0;
            }
        }
    } else
    {
        TB2 = TB8;
        if (TB2 < 150)
        {
            TB2 = 0;
        }
        if (TB2 > TB8)
        {
            TB2 = TB8;
        }
    }
    if (TB2 < 150)
    {
        TB2 = 0;
    }

    return TB2
}