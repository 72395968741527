import {
    Bom, BomCategory,
    SpiralStair,
    UpdateSpiralStairStatusMutation,
    useGetSpiralStairBomLazyQuery
} from "../../generated/graphql";
import {
    Badge,
    Button,
    ButtonGroup,
    Card, CardBody,
    CardHeader,
    Col, Input,
    Row,
    Spinner
} from "reactstrap";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import Picker from "../../common/Picker";
import gql from "graphql-tag";
import BillOfMaterialsSection from "./BillOfMaterialsSection";
import {ok} from "assert";
import {camelToTitle} from "../../UTIL";


const pickerQuery = gql`
    query($term:String!)
    {
        Inventory_searchArticle(term: $term){
            id:artNo
            name
            category
        }
    }`;

const BomRow = ({row,setRow,deleteRow,isEditable})=>
{
    const handleOnDelete = useCallback(() =>
    {
        const isAddRow = !row.artNo
        if (isAddRow || window.confirm(`Are you sure you want to delete ${row.artNo}-${row.name}?`))
        {
            deleteRow()
        }
    }, [deleteRow, row])

    return <Row style={{"margin": "10px 0"}} key={row?.artNo}>
        <Col xs={9}>
            <Picker startValue={row?.artNo?{label:row.artNo +" - " +row.name}:""}
                    types={["stockArticle"]}
                    mapFn={({name, id, category}) => ({
                        value: id,
                        name: name,
                        category: category,
                        artNo: id,
                        label: `${id} - ${name}`
                    })}
                    onChange={(selected) =>
                    {
                        setRow({...row, artNo: selected.artNo, name: selected.name, category: selected.category})
                    }}
                    query={pickerQuery}
                    isDisabled={!isEditable}
            />
        </Col>
        <Col xs={2}>
            <Input type={"number"} style={{"width":"100%"}} value={row?.quantity} onChange={e=>setRow({...row,quantity:e.target.value})}
                    disabled={!isEditable}/>
            {row?.unit}
        </Col>
        <Col xs={1}>
            <Button color={"danger"} onClick={handleOnDelete} disabled={!isEditable}>X</Button>
        </Col>
    </Row>
}

export const BomListPanel = (props: { bomList: Bom[], setBomList: any, isEditable: boolean, onRemoveBomClick: (bom: Bom) => void }) =>
{
    const {bomList, setBomList, isEditable, onRemoveBomClick} = props
    const addArticle = useCallback(() =>
    {
        setBomList(bomList.concat([{
            __typename: "Bom",
            artNo: null,
            name: null,
            quantity: 0,
            unit: null,
        }]));
    }, [setBomList, bomList]);

    const data = useMemo(() =>
    {
        if (!bomList)
        {
            return null
        }

        const categoryIndexMap = {}
        const groupList: { category: string, items: Bom[] }[] = Object.values(BomCategory)
            .sort()
            .map((e, index) =>
            {
                categoryIndexMap[e] = index
                return {category: e, items: []}
            })
        const noCategoryItems: Bom[] = []

        bomList.forEach((e) =>
        {
            if (e.category && categoryIndexMap[e.category] != null)
            {
                const index = categoryIndexMap[e.category]
                groupList[index].items.push(e)
                return
            }

            noCategoryItems.push(e)
        })

        if (noCategoryItems.length > 0)
        {
            groupList.push({category: "noCategory", items: noCategoryItems})
        }
        return groupList
    }, [bomList])

    const setRow = useCallback((bom) =>
    {
        const newBomList = bomList.filter((e) => e.artNo)
        const index = newBomList.findIndex((e) => e.artNo === bom.artNo)
        if (index !== -1)
        {
            newBomList[index] = bom
        } else
        {
            newBomList.push(bom)
        }

        setBomList(newBomList)
    }, [setBomList, bomList])

    return <div>
        {
            data && data.map((e,index) =>
            {
                return <Card style={{margin: "0", borderColor: "transparent"}} key={`bomCategory${index}`}>
                    <CardHeader style={{fontWeight: "bold"}}>
                        {camelToTitle(e.category)}
                        <Badge pill style={{marginLeft: "4px"}}>{e.items.length}</Badge>
                    </CardHeader>
                    <CardBody style={{padding: "0"}}>
                        {
                            e.items.map((bom, index) =>
                            {
                                return <BomRow key={index} row={bom}
                                               setRow={setRow}
                                               deleteRow={() => onRemoveBomClick(bom)}
                                               isEditable={isEditable}/>

                            })}
                    </CardBody>
                </Card>
            })
        }
        <Row style={{"margin": "10px 0"}}>
            <Col xs={12}>
                <Button onClick={addArticle} disabled={!isEditable}>Add</Button>
            </Col>
        </Row>
    </div>
}

export default ({spiralStairsData,setSpiralStairsData, isEditable, toggle}:
                    { spiralStairsData: (SpiralStair & { articleRowId: string })[], setSpiralStairsData, isEditable:boolean, toggle: ()=> void})=>
{
    const [targetIndex, setTargetIndex] = useState(0);
    const currentItem = spiralStairsData[targetIndex]

    const [getBomsQuery , {loading}] = useGetSpiralStairBomLazyQuery()
    const [boms, setBoms] = useState<any>()

    useEffect(()=> {
        const fetch = async () =>
        {
            setBoms(null)
            const {orderId, articleId} = currentItem
            const response = await getBomsQuery({
                variables: {
                    orderId: orderId!,
                    articleId: articleId
                }
            })
            setBoms(response.data?.getSpiralStairBom ?? [])
        }
        fetch().then()
    }, [targetIndex, currentItem, setBoms, getBomsQuery])

    const onUpdateStatusSuccess = useCallback((response: UpdateSpiralStairStatusMutation) =>
    {
        const data = response.updateSpiralStairStatus
        ok(data, `Miss response data.`)
        const newSpiralStairs = [...spiralStairsData]
        const foundSpiralStair = newSpiralStairs.find((e) => e.articleId === data.id)
        if (foundSpiralStair)
        {
            foundSpiralStair.status = data.status
            foundSpiralStair.statusUpdatedBy = data.statusUpdatedBy
            foundSpiralStair.statusUpdatedAt = data.statusUpdatedAt
        }
        setSpiralStairsData(newSpiralStairs)
    }, [spiralStairsData, setSpiralStairsData])

    return <>
            <ButtonGroup>
            {
                spiralStairsData.map((e,index)=>{
                    return (
                        <Button key={e.id} size="sm" outline={targetIndex!==index} onClick={() => {setTargetIndex(index);}}>
                            Article #{e.artNo}
                        </Button>);
                })
            }
            </ButtonGroup>
        {
            boms == null || loading ? <Spinner/>
                : <BillOfMaterialsSection orderId={currentItem.orderId!} articleId={currentItem.articleId} articleRowId={currentItem.articleRowId}
                                          boms={boms} spiralStairStatus={currentItem.status}
                                          isEditable={isEditable}
                                          artNo={currentItem.artNo ?? ""} onUpdateStatusSuccess={onUpdateStatusSuccess}
                                          toggle={toggle}
                                          statusUpdatedBy={currentItem.statusUpdatedBy}
                                          statusUpdatedAt={currentItem.statusUpdatedAt}
                                          freeText={currentItem.freeText}
                                          onUpdateFreeTextSuccess={(freeText)=> currentItem.freeText = freeText}/>
        }
    </>;
}



