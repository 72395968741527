import NotificationPopup from "../common/lib/NotificationPopup";
import {ApolloError, FetchResult} from "@apollo/client";
import {useCallback} from "react";
import logger from "../Logger";

type ExecuteMutation<TData, TVariables> = (variables?: TVariables,
                                           context?: {
                                               onSuccess?: (data: TData | null) => void,
                                               onError?: (error: any) => boolean
                                           }
) => Promise<TData | null | undefined>

type UseMutationHookResult<TData, TVariables> =
    {
        (options?: any): [
            (variables?: TVariables) => Promise<FetchResult<TData>>,
            {
                data?: TData | null | undefined
                loading: boolean
                error?: ApolloError | undefined
            }
        ]
    }

type UseMutationHandlerResult<TData, TVariables> =
    {
        executeMutation: ExecuteMutation<TData, TVariables>
        data: TData | null | undefined
        loading: boolean
        error: Error | undefined
    }

export const useMutationHandler = <TData, TVariables>(
    useMutationHook: UseMutationHookResult<TData, TVariables>,
    options = {}
): UseMutationHandlerResult<TData, TVariables> =>
{
    const [executeMutationFunction, {data, loading, error}] = useMutationHook(options)

    const executeMutation: ExecuteMutation<TData, TVariables> = useCallback(async (variables, context) =>
    {
        try
        {
            const response = await executeMutationFunction(variables)
            const data = response?.data
            if (context?.onSuccess && data)
            {
                context.onSuccess(data)
            }
            return data
        } catch (e: any)
        {
            if (context?.onError)
            {
                const isHandled = context.onError(e)
                if (isHandled)
                {
                    return
                }
            }

            logger.error(`Execute mutation "${useMutationHook?.name}" failed.`, e)
            NotificationPopup.error(`${e?.message}`)
        }
    }, [executeMutationFunction, useMutationHook])

    return {executeMutation, data, loading, error}
}
