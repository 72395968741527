import React, {Component} from 'react';
import {Badge} from "reactstrap";
import {withApollo} from '@apollo/client/react/hoc'
import gql from "graphql-tag";
import EventEmitter from '../common/EventEmitter';
import Auth from "../Auth";

const query = gql`
    query hasNewTasks
    {
        hasNewTasks
    }
`;

class NewTasksIcon extends Component
{
    constructor()
    {
        super();
        this.state = {
            newTaskCount:0
        };
    }

    componentWillUnmount()
    {
        clearInterval(this.timer);
    }

    componentDidMount()
    {
        this.checkForNewTasks();
        this.timer = setInterval(this.checkForNewTasks,180000);

        EventEmitter.on(`clearNewTasks`,()=>
        {
            this.setState({newTaskCount:0});
        })
    }

    checkForNewTasks = async()=>
    {
        //don't check while task list is being viewed.
        if(Auth.isUserAuthenticated() && window.location.pathname.indexOf('/tasks') === -1 && !window.document.hidden)
        {
            try {
                const res = await this.props.client.query(
                {
                    query
                });
                this.setState({newTaskCount:res.data.hasNewTasks});
            } catch(e) {
                console.log(e);
            }
        }
    };

    render()
    {
        if(this.state.newTaskCount > 0)
        {
            return <Badge color="danger">
                {this.state.newTaskCount}
            </Badge>
        }
        else
        {
            return null;
        }

    }


}

export default withApollo(NewTasksIcon)



