import _, {map} from "lodash";
import {ArticleProductType} from "./ProductType.enum";
import React from "react";
import {useMutation} from "@apollo/client";
import gql from "graphql-tag";
import {Row, Col} from "reactstrap";
import EditableDetailRenderer from "../../common/EditableDetailRenderer";
import NotificationPopup from "../../common/lib/NotificationPopup";
import CreateStockTransaction from "./CreateStockTransaction";
import StockTransactionList from "./StockTransactionList";
import {InventoryArticleCategoryType} from "../../generated/inventoryGraphql";


export default ({article, isEditable}) =>
{
    const [update] = useMutation(gql`mutation($id:ID!,$article:ArticleInputType!)
    {
        updateArticle(id:$id,article: $article)
        {
            id
        }
    }`, {refetchQueries: ["getInventoryArticle"]});

    return <Row>
        <Col lg={4}>
            <EditableDetailRenderer
                extra={null}
                open={null}
                object={{...article, lengthMeasure: article.length}}
                columns={3}
                isEditable={isEditable}
                onSubmit={async ({object}) =>
                {
                    try
                    {

                        await update({
                            variables: {
                                id: article.id,
                                // @ts-ignore
                                article: {
                                    ..._.omit(object, ["lengthMeasure"]),
                                    length: object.lengthMeasure
                                }
                            }
                        });
                        NotificationPopup.success(`Updated article`);
                    } catch (e: any)
                    {
                        NotificationPopup.error(`Failed to update article: ${e.message}`);
                    }
                }}
                formDefinition={[
                    {name: "name"},
                    {name: "artNo", disabled: true},
                    {name: "description"},

                    {name: "height", type: "number"},
                    {name: "lengthMeasure", label: "Length", type: "number"},
                    {name: "width", type: "number"},
                    {name: "sellOnline", type: "checkbox"},

                    {name: "lowAlert", type: "number"},
                    {name: "isInventory", type: "checkbox"},

                    {hideField: () => true, notEditable: true},
                    {hideField: () => true, notEditable: true},
                    {hideField: () => true, notEditable: true},

                    {name: "price", type: "currency"},
                    {name: "currency", type: "currencyPicker"},
                    {hideField: () => true, notEditable: true},

                    {hideField: () => true, notEditable: true},
                    {hideField: () => true, notEditable: true},
                    {hideField: () => true, notEditable: true},

                    {name: "internalPrice", type: "currency"},
                    {name: "internalCurrency", type: "currencyPicker"},
                    {hideField: () => true, notEditable: true},

                    {name: "purchasePrice", type: "currency"},
                    {name: "purchaseCurrency", type: "currencyPicker"},
                    {hideField: () => true, notEditable: true},
                    {
                        name: "productType", type: "select", options:
                            map(ArticleProductType, (type, key) => ({id: key, name: key}))
                    },
                    {
                        name: "category", type: "select", options:
                            map(InventoryArticleCategoryType, (type, key) => ({id: type, name: key}))
                    }
                ]}

            />
        </Col>
        <Col lg={8}>
            <h2>Total Quantity: {article.quantity}</h2>
            <CreateStockTransaction article={article} />
            <StockTransactionList isEditable={isEditable}
                                  data={article.stockTransactions.sort((a, b) =>
                                  {
                                      const dateA = new Date(a.transactedAt).getTime();
                                      const dateB = new Date(b.transactedAt).getTime();
                                      return dateB - dateA;
                                  })}
            />
        </Col>
    </Row>
}
