import React, {Component} from 'react';
import {Spinner} from "reactstrap";

class Dynamic extends Component {
    constructor(props) {
        super(props);
        this.state = { module: null };
    }
    componentDidMount() {
        this.props.load()
        .then((component) => {
            this.setState(() => ({
                module: component.default ? component.default : component
            }))
        })
    }
    render() {
        const { module: Component } = this.state;
        return(
        <div>
            {Component && <Component {...this.props} />}
            {!Component && <Spinner color={"warning"} />}
        </div>
        )
    }
}

export default Dynamic;
