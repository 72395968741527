import React, {memo, useCallback, useMemo, useState} from "react";
import {Button, ButtonGroup, Modal, ModalBody, ModalHeader} from "reactstrap";
import {Link} from "react-router-dom";
import {saveAs} from 'file-saver';
import {format} from 'date-fns';
import Create from "./Create";
import Auth from "../../Auth";
import config from "../../config";
import {withRouter} from "react-router";
import {InventoryRoute} from "../InventoryRouters";
import {
    ArticlesV2Document,
    InventoryArticleCategoryType} from "../../generated/inventoryGraphql";
import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import {ArticleProductType} from "./ProductType.enum";
import ColumnQuantity from "../components/data-grid/ColumnQuantity";

// If changing this value, also change the backend code in articles.service.ts
const LOW_ALERT_PERCENTAGE = 20;
const KEY_TABLE = "TABLE_INVENTORY_ARTICLE"

const LowAlertText = memo(({value, color}) => {
    return <>
        <div style={{color: color, textAlign: "right", textDecoration: "underline"}}>
            {value.toLocaleString('en-EN',
                {minimumFractionDigits: 0, maximumFractionDigits: 3})}
        </div>
    </>
})

const InventoryArticleListPage = (props) => {
    const [open, setOpen] = useState(false);
    const [lowAlert, setLowAlert] = useState(false);
    const toggle = () => setOpen(e => !e);

    const columns = useMemo(() => {
        return [
            {
                field: 'artNo',
                headerName: 'Article No.',
                searchable: true,
                renderCell: (params) => {
                    return <Link to={`${InventoryRoute.ARTICLE_DETAIL}/${params.id}`}>{params.value}</Link>;
                },
            },
            {
                field: 'name',
                searchable: true,
            },
            {
                field: 'productType',
                type: 'singleSelect',
                valueGetter: (params) => {
                    const value = params.value
                    return value ? ArticleProductType[value] : null
                },
                valueOptions: Object.entries(ArticleProductType).map(([key, value]) => ({value: value, label: key}))
            },
            {
                field: 'category',
                type: 'singleSelect',
                valueOptions: Object.entries(InventoryArticleCategoryType).map(([key, value]) => ({
                    value: value,
                    label: key
                }))
            },
            {
                field: 'quantity',
                headerName: 'Physical Inventory',
                type: 'number',
                filterable: false,
                sortable: false,
                renderCell: (params) => <ColumnQuantity value={params.value}/>
            },
            {
                field: 'inventoryInTransit',
                headerName: 'Inventory in Transit(Ordered quantity (PO))',
                type: 'number',
                filterable: false,
                sortable: false,
                renderCell: (params) => <ColumnQuantity value={params.value}/>
            },
            {
                field: 'reservedQuantitySum',
                headerName: 'Customer Reserved(The quantity reserved by current orders)',
                type: 'number',
                filterable: false,
                sortable: false,
                renderCell: (params) => <ColumnQuantity value={params.value}/>
            },
            {
                field: 'inventoryAfterShipping',
                headerName: 'Inventory After Shipping(Physical Inventory - Customer Reserved)',
                type: 'number',
                filterable: false,
                sortable: false,
                valueGetter: (params) => {
                    const reservedQuantitySum = params.row.reservedQuantitySum ?? 0
                    const quantity = params.row.quantity ?? 0
                    return quantity - reservedQuantitySum
                },
                renderCell: (params) => <ColumnQuantity value={params.value}/>
            },
            {
                field: 'lowAlert',
                headerName: 'Low alert(Physical Inventory + Inventory in Transit - Customer Reserved)',
                type: 'number',
                filterable: false,
                sortable: false,
                renderCell: (params) => {
                    const original = params.row
                    const value = params.value
                    const currentQuantity = original.quantity - original.reservedQuantitySum + original.inventoryInTransit;
                    if (value > currentQuantity) {
                        return <LowAlertText color={"red"} value={value}/>
                    } else if (Math.round(value * (1 + LOW_ALERT_PERCENTAGE * 0.01)) > currentQuantity) {
                        return <LowAlertText color={"orange"} value={value}/>
                    } else {
                        return <LowAlertText color={"green"} value={value}/>
                    }
                }
            },
            ColumnDate("noStock", "Out of stock on(This date does not account for Inventory in Transit)"),
            ColumnId()
        ]
    }, [])

    const onLowAlertCheckClick = useCallback(() => setLowAlert(prevState => !prevState), [])

    return <div>
        <Modal isOpen={open} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Create article
            </ModalHeader>
            <ModalBody>
                <Create {...props} toggle={toggle}/>
            </ModalBody>
        </Modal>
        <ButtonGroup style={{marginBottom: "8px"}}>
            <Button onClick={() => setOpen(true)}>Create Article</Button>
            <Button color={"info"} onClick={async () => {
                const token = Auth.getToken();
                const res = await fetch(`${config.INVENTORY_URI}articles/barcodes`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const blob = await res.blob();
                saveAs(blob, `inventory-barcodes-${format(new Date(), "yyyy-MM-dd")}.pdf`);
            }}>
                Barcodes
            </Button>
            <Button onClick={onLowAlertCheckClick}
                    color='primary'
                    outline={!lowAlert}>Low alert</Button>
        </ButtonGroup>

        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
                initState: {
                    sorting: {sortModel: [{field: 'artNo', sort: 'asc'}]}
                }
            }}
            query={ArticlesV2Document}
            queryVariables={{lowAlert: lowAlert}}/>
    </div>
}

export default withRouter(InventoryArticleListPage)
