import {ModuleObject, Node} from "../ShpaeObject";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {Axis, CreateBox, Tools} from "@babylonjs/core";
import ModuleTemplate, {scaleRatio} from "../ModuleTemplate";
import {MaterialName} from "../Materials";
import {nodeOfRampForPole} from "../NodeFilterFunctions";
import {RampEdge} from "./Ramp";
import {getAngleBetweenVectorsByAxisY} from "../utils";
import {Scene} from "@babylonjs/core/scene";

export class WoodPole extends ModuleObject
{
    vector: Vector3 = Axis.Y.clone();
    bottomNode: Node | undefined;
    slopeDegree = 0;

    constructor(moduleTemplate: ModuleTemplate, scene)
    {
        super(moduleTemplate, scene);
        this.init();
    }

    build()
    {
        const {width, height, length, otherProps} = this.moduleTemplate;
        const {offsetMM} = otherProps;
        const box = CreateBox(`mesh-industrialPole-${this.id}`, {width, height, depth: length}, this.scene);
        console.log("build pole", this.moduleTemplate)
        box.translate(this.origin, 1);
        box.translate(new Vector3(-offsetMM * scaleRatio, height / 2, 0), 1);
        this.meshes.push(box);
        this.material = this.scene.materialStore[MaterialName.metalMaterial];

        this.vector = new Vector3(0, 0, 1);
        this.bottomNode = new Node(this, this.origin.clone(), nodeOfRampForPole);
        this.children.push(this.bottomNode);
        this.nodes.push(this.bottomNode);
    }

    attachTo(attachNode: Node, fromNode: Node)
    {
        const rampEdge = attachNode.parent as RampEdge;
        if (rampEdge && fromNode.parent instanceof WoodPole)
        {
            const angle = getAngleBetweenVectorsByAxisY(fromNode.parent.vector, rampEdge.vector);
            this.rotate(Axis.Y, Tools.ToDegrees(angle), attachNode.origin);
            this.moduleTemplate.slopeDegree = rampEdge.slopeDegree;
        }
    }

    clone(scene: Scene)
    {
        return new WoodPole(this.moduleTemplate, scene);
    }
}