import React from 'react';

import {selectRenderer} from "../common/FormRenderer";
import GetTermsOfDeliveryQuery from '../common/graphql/GetTermsOfDelivery.graphql';
import GetTermsOfPayment from '../common/graphql/GetTermsOfPayment.graphql';
import GetModeOfDelivery from '../common/graphql/GetModeOfDelivery.graphql';
import query from '../supplier/graphql/GetSuppliersV2.graphql';
import {Link} from "react-router-dom";
import _ from 'lodash';
import gql from "graphql-tag";
import StdQuery from "../common/StdQuery";
import {FORMAT_DATE, formatToSwedishTime} from "../UTIL";

const GetSupplierRefs = gql`
    query getSupplierRefs($id:ID!)
    {
        supplier(id:$id)
        {
            id
            supplierRefs
            {
                id
                name
                createdAt
            }
        }
    }
`;

export const PurchaseOrderCreateFormDef = [
    {name:"supplierId",required:true,label:"Supplier",type:'select',query:query},
    {name:"supplierRefId",required:true,type:'custom',input:function(model,onChange)
        {
            if(model.supplierId)
            {
                return <StdQuery query={GetSupplierRefs} variables={{id:model.supplierId}}>
                    {(data)=>
                    {
                        return selectRenderer({required:true,name:'supplierRefId',options:data.supplier.supplierRefs},
                        model.supplierRefId,onChange);
                    }}
                </StdQuery>

            }
            else
            {
                return <p>..</p>;
            }

        }},
    {name:'orderId',type:"search",types:["order"],
        required:true,
        hint:<small>Enter order number</small>
    },
    {name:"currency",required:true,type:'currencyPicker'},
];

export default (purchaseOrder)=>
{
    const locale = purchaseOrder?purchaseOrder.supplier.locale:null;
    let hint = '';
    let startValue = null;
    if(purchaseOrder && purchaseOrder.order)
    {
        hint = <p style={{color:"grey"}}>
            Language: {locale||"none"} (Change language and default values <Link to={`/supplierEdit/${purchaseOrder.supplier.id}`}>Here</Link>)
        </p>;
        startValue = {label:purchaseOrder.order.name,value:purchaseOrder.order.id};
    }
    return [
        {name:"termsOfDeliveryId",label:"Terms of delivery",
            hint,
            type:"select",query:GetTermsOfDeliveryQuery,required:true,
            defaultValue:_.get(purchaseOrder,'supplier.defaultTermsOfDelivery.id'),
            variables:{
                locale:locale
            }},
        {name:"termsOfPaymentId",label:"Terms of payment",
            type:"select",query:GetTermsOfPayment,required:true,
            defaultValue:_.get(purchaseOrder,'supplier.defaultTermsOfPayment.id'),
            variables:{
                locale:locale
            }},
        {name:"modeOfDeliveryId",label:"Mode of delivery",
            type:"select",query:GetModeOfDelivery,required:true,
            defaultValue:_.get(purchaseOrder,'supplier.defaultModeOfDelivery.id'),
            variables:{
                locale:locale
            }},
        {name:"description",label:"Description",type:"textarea"},
        {name:"assigneeId",label:"Assignee",type:"userPicker"},
        {name:"supplierId",required:true,label:"Supplier",type:'select',query:query},
        {name:"supplierRefId",required:true,type:'custom',input:function(model,onChange)
            {
                if(model.supplierId)
                {
                    return <StdQuery query={GetSupplierRefs} variables={{id:model.supplierId}}>
                        {(data)=>
                        {
                            return selectRenderer({required:true,name:'supplierRefId',options:data.supplier.supplierRefs},
                            model.supplierRefId,onChange);
                        }}
                    </StdQuery>

                }
                else
                {
                    return <p>..</p>;
                }

            }},
        {name:'deliveryTitle'},
        {name:'deliveryStreet'},
        {name:'deliveryCountry'},
        {name:'deliveryCity'},
        {name:'deliveryPostalCode',pattern:"[0-9 ]+"},
        {name:'deliveryFreeText'},
        {
            label: "Preferred Delivery Date",
            name: 'deliveryDate',
            type: "date",
            required: true,
            transformValue: ({value}) => formatToSwedishTime(value, FORMAT_DATE),
        },
        {name:"currency",type:"currencyPicker",required:true},
        {name:'orderId',type:"search",filter:(obj)=>
            {
                return obj.type === "order";
            },
            required:true,
            hint:<p>Enter order number</p>,
            startValue,},
        { name:"invoiceNumber",disabled:true},
        {name: "receivedDate", disabled: true, transformValue: ({value}) => formatToSwedishTime(value, FORMAT_DATE)},
        { name: "markReceivedUserId", label: "markReceivedUser", disabled: true,
            defaultValue:_.get(purchaseOrder,'markReceivedUser.displayName')
        },
    ]

};
