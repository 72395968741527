import {ShipmentStatusExt} from "../model/ShipmentStatusExt";
import {Badge} from "reactstrap";
import React from "react";
import {ShipmentStatus} from "../../generated/graphql";


export default (props: { status: ShipmentStatus }) =>
{
    const {status} = props
    return <Badge style={{backgroundColor: ShipmentStatusExt.color(status)}}>{ShipmentStatusExt.label(status)}</Badge>
}