import {MultiSelectFilterOperators} from "../MultiSelectInput";
import {PrioritizedProject} from "../../../generated/graphql";
import {Badge} from "reactstrap";
import React from "react";


const ColumnPrioritizedProject = () =>
{
    return {
        field: 'prioritizedProject',
        type: 'singleSelect',
        customFilterOperators: MultiSelectFilterOperators(Object.entries(PrioritizedProject)
                .map(([key, value]) => ({id: value, name: key})),
            "Priority"),
        renderCell: (params) =>
        {
            const value = params.value
            if (value === PrioritizedProject.High)
            {
                return <Badge color="success">High</Badge>
            }
            return null
        }
    }
}

export default ColumnPrioritizedProject