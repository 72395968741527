import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
    OrderWithDeliveryInfoFragmentFragment,
    PackageFragmentFragment,
    useCreateShipmentMutation
} from "../generated/graphql";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import NewFormRenderer from "../common/NewFormRenderer";
import Auth from "../Auth";
import {ok} from "assert";
import NotificationPopup from "../common/lib/NotificationPopup";
import SelectPackagesForShipmentPanel from "./component/SelectPackagesForShipmentPanel";
import {PaymentInstructionTypes} from "./model/PaymentInstructionType";
import {TransportServiceTypes} from "./model/TransportServiceTypes";
import {DEFAULT_CONSIGNOR_ACCOUNT} from "./model/ConsignorAccountList";
import PackageDataGrid from "../package/component/PackageNonPaginatedDataGrid";
import {isProd} from "../workflow-forms/DrawJob/util";
import {shipmentFormDefinitions} from "./model/ShipmentFormDefinitions";

type ShipmentNumberAndConsigneeRef = { number: string, consigneesReference: string }

export const WarningTermsOfDelivery = ["Mottagarfrakt", "EXW"]

const ShipmentNumberModal = ({isOpen, toggle, handleOnCreate, packages}: {
    isOpen: boolean,
    toggle: () => void,
    handleOnCreate: (data: ShipmentNumberAndConsigneeRef) => Promise<void>,
    packages: PackageFragmentFragment[]
}) =>
{
    const [data, setData] = useState<ShipmentNumberAndConsigneeRef>({
        number: '',
        consigneesReference: ''
    })

    const [isLoading, setIsLoading] = useState(false)

    const formDefinition = useMemo(() =>
    {
        return [{
            name: "number",
            label: "Shippers ref",
            required: true,
            type: "text",
        },
            {
                name: "consigneesReference",
                type: "text",
            },]
    }, [])

    const onChange = useCallback((key, value) =>
    {
        setData(prevState => ({...prevState, [key]: value}))
    }, [])

    useEffect(() =>
    {
        if (!packages)
        {
            return
        }
        const items = packages.flatMap((e) => e.packageItems)

        const orderMap = new Map<string, { order: OrderWithDeliveryInfoFragmentFragment, artNoSet: Set<string> }>()
        items.forEach((e) =>
        {
            const order = e?.articleRow?.order
            const orderNumber = order?.number
            if (!order || !orderNumber)
            {
                return
            }

            if (!orderMap.has(orderNumber))
            {
                orderMap.set(orderNumber, {order: order, artNoSet: new Set()})
            }

            const stockArticleNo = e?.articleRow?.stockArticle?.artNo
            const artNo = e?.articleRow?.article?.artNo

            const number = stockArticleNo ? stockArticleNo : artNo
            if (number)
            {
                const set = orderMap.get(orderNumber)?.artNoSet
                if (!set?.has(number))
                {
                    set?.add(number)
                }
            }
        })

        const numbers: string[] = [];
        const consigneesReferences: string[] = []

        orderMap.forEach((value) =>
        {
            const order = value.order
            const artNoSet = value.artNoSet
            const artNoArray = Array.from(artNoSet);
            numbers.push(`${order.assignee?.displayName} / ${order.number} ${artNoArray.join(' ')}`)
            consigneesReferences.push(`${order.contact?.name ?? ''}`)
        })

        setData({
            number: numbers.join(', '),
            consigneesReference: consigneesReferences.join(', ')
        })

    }, [packages]);

    const onCreateClick = useCallback(async () =>
    {
        setIsLoading(true)
        await handleOnCreate(data)
        setIsLoading(false)
        toggle()
    }, [data, handleOnCreate, toggle])

    return <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create shipment</ModalHeader>
        <ModalBody>
            <NewFormRenderer
                formDefinition={formDefinition}
                object={data}
                onChange={onChange}/>

            <h4>Packages</h4>
            <PackageDataGrid packages={packages ?? []}/>
        </ModalBody>
        <ModalFooter>
            <Button color='success' onClick={onCreateClick} disabled={isLoading}>
                Submit
                {isLoading && <Spinner color='warning'/>}
            </Button>
        </ModalFooter>
    </Modal>
}

export default (props) =>
{
    const [isOpen, setIsOpen] = useState(false)

    const toggle = useCallback(() =>
    {
        setIsOpen(prevState => !prevState)
    }, [])

    const [createdShipment, setCreatedShipment] = useState<any>(
        {
            template: isProd ? "Inrikes Eurostair AB Säffle" : "Package",
            transportService: TransportServiceTypes[0].id,
            paymentInstruction: PaymentInstructionTypes.consignor,
            pickupDate: new Date(),
            pickupEarliest: "07:00",
            pickupLatest: "16:00",
            paymentAccount: isProd ? DEFAULT_CONSIGNOR_ACCOUNT : null
        }
    )

    const packageRef = useRef<any>()

    const [createShipmentMutation] = useCreateShipmentMutation()

    const formDefinition = useMemo(() =>
    {
        return shipmentFormDefinitions(true)
    }, [])

    const onChange = useCallback(async (key, value) =>
    {
        setCreatedShipment({...createdShipment, [key]: value})
    }, [setCreatedShipment, createdShipment])

    const isCreateButtonDisabled = useCallback(() =>
    {
        const required = formDefinition.filter((e) => e.required)
        const keys = Object.keys(createdShipment)

        return !required.every((e) => keys.includes(e.name))
    }, [createdShipment, formDefinition])

    const createShipment = useCallback(async (numberAndConsigneeRef: ShipmentNumberAndConsigneeRef) =>
    {
        try
        {
            const packages = packageRef.current?.getSelectedPackageList()
            const warningPackages = packages?.filter((e) =>
            {
                return e.packageItems?.some((item) =>
                {
                    const name = item.articleRow?.order?.termsOfDelivery?.name
                    return name && WarningTermsOfDelivery.includes(name)
                }) ?? false
            })

            if (warningPackages && warningPackages.length > 0)
            {
                const marks = warningPackages.map((e, index) => `${e.mark}${index !== warningPackages.length - 1 ? ', ' : ''}`)
                if (!window.confirm(`The delivery terms for the following packages(${marks}) are EXW or Mottagarfrakt. Are you sure you want to create a shipment?`))
                {
                    return
                }
            }

            const packageIds = packageRef.current?.getSelectedPackageIdList()

            const response = await createShipmentMutation({
                variables: {
                    shipment: {
                        ...createdShipment,
                        ...numberAndConsigneeRef,
                        tenantId: Auth.getTenant(),
                        packageIds: packageIds,
                    }
                }
            })
            ok(!response.errors, `${response.errors}`)

            const id = response.data?.createShipment?.id
            NotificationPopup.success(`Create shipment success`)
            props.history.push(`/shipment-detail/${id}`)
        } catch (e)
        {
            NotificationPopup.error(`${e}`)
        }
    }, [createdShipment, createShipmentMutation, props.history])

    return <div>
        <NewFormRenderer
            formDefinition={formDefinition}
            object={createdShipment}
            columns={3}
            onChange={onChange}/>

        <h5 style={{marginTop: "16px"}}>Select packages</h5>

        <SelectPackagesForShipmentPanel ref={packageRef}/>

        <Button color="primary"
                style={{marginTop: "16px"}}
                disabled={isCreateButtonDisabled()}
                onClick={toggle}>Create shipment</Button>

        <ShipmentNumberModal isOpen={isOpen} toggle={toggle} handleOnCreate={createShipment}
                             packages={packageRef.current?.getSelectedPackageList()}/>
    </div>
}
