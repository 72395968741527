import React, {memo, useCallback, useMemo, useState} from "react";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Row,
    Spinner
} from "reactstrap";
import {
    usePriceListVersionsForStraightStairQuery
} from "../../generated/graphql";
import {last, sortBy} from "lodash";
import {labelize, mapSeries} from "../../UTIL";
import config from "../../config";
import Auth from "../../Auth";
import {ok} from "assert";
import * as XLSX from 'xlsx';


enum FileType
{
    straightStairData = "straightStairData",
    railing = "railing",
    extraRailing = "extraRailing",
    platform = "platform",
    step = "step",
    stepType = "stepType",
    support = "support",
    stringer = "stringer"
}

const getHeaders = (type: FileType) =>
{

    switch (type)
    {
        case FileType.straightStairData:
            return ["WSK1", "Bolted railing Price per poles", "Bieżnik T1 Cena", "Poles 70 Cena", "Poles 80 Cena", "Poles 100 Cena", "CM ASN", "CM PAINT"]
        case FileType.railing:
            return ["LC", "SR", "HDG", "RAL", "STDR1", "STDR2", "STDR3", "STDR4", "Ext1", "Ext2", "HDRL", "IND", "Ext300waga", "Ext300", "Cena", "Waga"]
        case FileType.extraRailing:
            return ["LC", "HDG", "RAL", "STDR1", "STDR2", "STDR3", "STDR4", "IND", "Kick", "Cena", "Waga", "Cena2", "Waga2", "HAND PR", "HAND W"]
        case FileType.platform:
            return ["plate 0mm", "Krata", "Zbrojenie", "HDG", "RAL", "Zint", "Dług", "Szer", "CenaPLS150x8", "WagaPLS150x8", "CenaPLS150x10", "WagaPLS150x10", "CenaPLS200x8", "WagaPLS200x8", "CenaPLS200x10", "WagaPLS200x10", "CenaPLS200x12", "WagaPLS200x12", "CenaPLS250x8", "WagaPLS250x8", "CenaPLS250x10", "WagaPLS250x10", "CenaPLS300x12", "WagaPLS300x12", "CenaUPE140", "WagaUPE140", "CenaUPE160", "WagaUPE160", "CenaUPE180", "WagaUPE180", "CenaUPE200", "WagaUPE200"]
        case FileType.step:
            return ["dług", "szer", "Cena NE", "plate mm", "Krata", "Zbrojenie", "HDG", "RAL", "ASN", "close", "Kickplate", "Waga"]
        case FileType.stepType:
            return ["Type", "Tmp1", "Tmp2", "Tmp3"]
        case FileType.support:
            return ["L", "HDG", "RAL", "Cena 70", "Cena 80", "Cena 100", "Waga 70", "Waga 80", "Waga 100"]
        case FileType.stringer:
            return ["przekrój", "HDG", "RAL", "Cena", "Waga", "LC"]
    }
}

const getFileName = (type: FileType) =>
{
    switch (type)
    {
        case FileType.straightStairData:
            return "StraightStairData.csv"
        case FileType.railing:
            return "RailingData.csv"
        case FileType.extraRailing:
            return "ExtraRailingData.csv"
        case FileType.platform:
            return "PlatformData.csv"
        case FileType.step:
            return "StepData.csv"
        case FileType.stepType:
            return "StepTypeData.csv"
        case FileType.support:
            return "SupportData.csv"
        case FileType.stringer:
            return "StringerData.csv"
    }
}

const DownloadExampleCard = memo(({downloadFile}: { downloadFile: (type: FileType) => Promise<void> }) =>
{
    return <Card>
        <CardHeader> Download example </CardHeader>
        <CardBody>
            {
                Object.values(FileType).map((e) =>
                {
                    return <Button key={`btnDownload${e}`} style={{marginBottom: "20px", display: "block"}}
                                   color="primary"
                                   onClick={() => downloadFile(FileType[e])}>
                        {labelize(e)}
                    </Button>
                })
            }
        </CardBody>
    </Card>
})

const UploadCsvCard = memo(({loading, handleSubmit}:
                                {
                                    loading: boolean,
                                    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
                                }) =>
{
    return <Card>
        <CardHeader>Upload csv files</CardHeader>

        <CardBody>
            <Form onSubmit={handleSubmit}>
                {
                    Object.values(FileType).map((e) =>
                    {
                        return <FormGroup key={e}>
                            <Label for={e}>{labelize(e)}</Label>
                            <Input type="file" name={e} id={e} accept=".csv"/>
                        </FormGroup>
                    })
                }
                {loading ? <>
                        <Spinner color={"warning"}/>
                        <span>The file upload will take a few minutes. Please wait.</span>
                    </>
                    : <Button type="submit" color={"success"}>Upload csv</Button>
                }
            </Form>
        </CardBody>
    </Card>
})

const StraightStairPricingPage = () =>
{
    const [loading, setLoading] = useState(false)
    const {data: versionList} = usePriceListVersionsForStraightStairQuery()
    const [duplicateErrorMessage, setDuplicateErrorMessage] = useState<string[]>([])

    const latestVersion = useMemo(() =>
    {
        return last(sortBy(versionList?.priceListVersionsForStraightStair)) ?? 0;
    }, [versionList])

    const downloadFile = useCallback(async (type: FileType) =>
    {
        const fileName = getFileName(type)
        const link = document.createElement('a');
        link.href = `files/${fileName}`
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    }, []);

    const readFileAsText = useCallback((file: File) =>
    {
        return new Promise((resolve, reject) =>
        {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(new Error(`Failed to read file: ${file.name}`));
            reader.readAsText(file, "UTF-8");
        });
    }, [])

    const getCheckColumns = useCallback((expectedHeaders: string[]): number[] =>
    {
        const columns: number[] = []
        expectedHeaders.forEach((e, i) =>
        {
            const header = e.toLowerCase()

            if (!(header.includes('waga') || header.includes('cena') ||
                header.includes('hand pr') || header.includes('hand w')))
            {
                columns.push(i)
            }
        })
        return columns
    }, [])

    const checkHasDuplicates = useCallback((data: any, columns: number[]): boolean =>
    {
        const seen = new Map<string, number>();
        const duplicates = new Map<string, number[]>();

        for (let i = 1; i < data.length; i++)
        {
            const key = columns.map(column => data[i][column]).join(',');
            const index = i +1
            if (seen.has(key))
            {
                if (!duplicates.has(key))
                {
                    duplicates.set(key, [seen.get(key)!]);
                }
                duplicates.get(key)!.push(index);
            } else
            {
                seen.set(key, index);
            }
        }

        if (duplicates.size > 0)
        {
            console.log(`duplicates.size ${duplicates.size}`)
            const errorMessages: string[] = [];

            duplicates.forEach((indexes, key) =>
            {
                const message = `key "${key}" at indexes: ${indexes.join(', ')}.`;
                errorMessages.push(message);
            });
            setDuplicateErrorMessage(errorMessages)
            return true
        }
        setDuplicateErrorMessage([])
        return false
    }, [])

    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) =>
    {
        event.preventDefault();

        try
        {
            setLoading(true)
            const formData = new FormData(event.currentTarget);
            const formEntries = Array.from(formData.entries())
            const hasFiles = formEntries.some(([_, value]) =>
            {
                return value instanceof File && value.size > 0
            })
            ok(hasFiles, `Please select files`)

            await mapSeries(formEntries, async ([key, value]) =>
            {
                if (value instanceof File && value.size > 0)
                {
                    const expectedHeaders = getHeaders(key)

                    const data = await readFileAsText(value);
                    const workbook = XLSX.read(data, {type: "binary"});
                    const sheetName = workbook.SheetNames[0];
                    const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});

                    const headers: any = jsonData[0]
                    const isMatch = expectedHeaders.every((header, index) => header === headers[index]);
                    ok(isMatch, `The header format does not match the expected format. Field: ${key},  File: ${value.name}`)

                    const columns = getCheckColumns(expectedHeaders)
                    const hasDuplicate = checkHasDuplicates(jsonData, columns);
                    ok(!hasDuplicate, `Each row must be unique. Field: ${key},  File: ${value.name}`)

                    const newFile = new File([value], `${key}.csv`, {
                        type: value.type,
                    });

                    formData.set(key, newFile);
                }
            })

            const res = await fetch(
                `${config.BACKEND_URI}upload/straight-stair-csv-files`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `bearer ${Auth.getToken()}`,
                    },
                    body: formData,
                });

            setLoading(false)
            if (res.ok)
            {
                NotificationPopup.success(`Successful upload of straight stair excel file`);
                return
            }
            const json = await res.json();
            NotificationPopup.error(`Failed to  straight stair excel file. ${res.status} ${json?.err}`);
        } catch (e: any)
        {
            setLoading(false)
            NotificationPopup.error(`${e?.message}`)
        }
    }, [readFileAsText, checkHasDuplicates, getCheckColumns])


    return <div>
        <h3>Straight stair Ver. {latestVersion}</h3>

        <Row>
            <Col sm={4}>
                <UploadCsvCard loading={loading} handleSubmit={handleSubmit}/>
            </Col>
            <Col sm={4}>
                <DownloadExampleCard downloadFile={downloadFile}/>
            </Col>
        </Row>
        {
            duplicateErrorMessage.length > 0 && <Row>
                <Col style={{color: 'red'}}>

                    <h3>Error: duplicates have been found</h3>
                    {
                        duplicateErrorMessage.map((e) => <p>{e}</p>)
                    }
                </Col>
            </Row>
        }
    </div>
}

export default StraightStairPricingPage