import React, {useCallback, useEffect, useState} from 'react';
import {TableComponent} from "./PaginatedTable";
import _ from 'lodash';


//TODO: add handling of filters here?
export default ({data,columns})=>
{
    const [tableData,setTableData] = useState([]);
    const [pageCount,setPageCount] = useState(0);
    const fetchData = useCallback(({pageSize, pageIndex,sortBy})=>
    {
        let sorted = data;
        if(sortBy && sortBy.length === 1)
        {
            sorted = _.sortBy(data,[(row)=>
            {
                return _.get(row,sortBy[0].id);
            }]);
            if(sortBy[0].desc)
            {
                sorted = sorted.reverse();
            }
        }
        setTableData(_.take(_.drop(sorted,pageSize * pageIndex),pageSize));
        setPageCount(Math.ceil(sorted.length/pageSize));


    },[setTableData,setPageCount,data]);

    useEffect(()=>
    {
        fetchData({pageIndex:0,pageSize:20});
    },[fetchData])

    return <TableComponent pageCount={pageCount} data={tableData} fetchData={fetchData} loading={false} columns={columns} />
}
