import {Badge, Modal, ModalBody, ModalHeader} from "reactstrap";
import ControlSpiralStairBom from "./ControlSpiralStairBom";
import React, {useCallback, useState} from "react";
import {Order, SpiralStair} from "../../generated/graphql";

type SpiralStairData =(SpiralStair & { articleRowId: string })

export default ({order, isOpen, toggle}: {order:Order, isOpen:boolean, toggle:()=>void})=>{
    const initSpiralStairs = useCallback(() =>
    {
        return order.articleRows?.flatMap((e) =>
            {
                const spiralStair = e?.article?.spiralStair
                if (!spiralStair)
                {
                    return null
                }
                return {
                    ...e?.article?.spiralStair,
                    artNo: e.article?.artNo,
                    articleId: e.article?.id,
                    orderId: order.id,
                    articleRowId: e.id
                }
            })?.filter((e) => e != null) as SpiralStairData[]
            ?? []
    }, [order])

    const [spiralStairs, setSpiralStairs] = useState<SpiralStairData[]>(initSpiralStairs())

    const isEditable = order.status !== "delivered"

    return (
        <>
            <Modal size="md" style={{maxWidth:"800px"}} isOpen={isOpen} toggle={toggle} backdrop={false}>
                <ModalHeader toggle={toggle}>
                    Bill of Material {order.number}
                </ModalHeader>
                <ModalBody>
                    {spiralStairs.length === 0
                        ? <Badge color="info">No spiral stairs</Badge>
                        : <ControlSpiralStairBom spiralStairsData={spiralStairs}
                                                 setSpiralStairsData={setSpiralStairs}
                                                 isEditable={isEditable}
                                                 toggle={toggle}/>
                    }
                </ModalBody>
            </Modal>
        </>
    );
}
