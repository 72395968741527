import React from 'react';
import {withApollo} from '@apollo/client/react/hoc'
import {Link} from 'react-router-dom';
import ColumnId from "../common/data-grid/columns/ColumnId";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {ProjectsV2Document} from "../generated/graphql";

const columns = () => {
    return [
        {
            field: 'name',
            headerName: 'Name',
            searchable: true,
            width: 250,
            renderCell: (params) => {
                return <Link to={`/projectDetail/${params.id}`}>{params.value}</Link>
            }
        },
        ColumnCreatedAt(),
        ColumnId(),
    ]
}

const KEY_TABLE = "PROJECT_LIST"

const defaultSort = {sortModel: [{field: 'name', sort: 'asc'}]}

const columnVisibilityModel = {columnVisibilityModel: {id: false}}

const ProjectListPage = () => {
    return (
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns(),
                initState: {
                    sorting: defaultSort,
                    columns: columnVisibilityModel,
                },
            }}
            query={ProjectsV2Document}
            filterTenantId={true}/>
    )
}

export default withApollo(ProjectListPage);
