import React, {Component} from 'react';
import gql from "graphql-tag";
import StdQuery from "../common/StdQuery";
import {Card, CardBody, Col, Table, UncontrolledTooltip} from "reactstrap";
import _, {filter, get} from "lodash";
import Collapser from "../common/Collapser";
import {graphql} from "@apollo/client/react/hoc";
import {articleDetailLink} from "../workflow-forms/DrawJob/ArticleList";
import ClaimTaskMutation from '../new-task/graphql/ClaimTask.graphql';
import GetTaskQuery from '../new-task/graphql/GetTask.graphql'
import GenericWorkflowForm from "../common/GenericWorkflowForm";
import Auth from "../Auth";
import {Link} from "react-router-dom";
import Wood from "../common/icons/Wood";
import PaintBucket from "../common/icons/PaintBucket";
import Dot from "../common/icons/Dot";
import Up from "../common/icons/Up";
import DoubleUp from "../common/icons/DoubleUp";
import {SurfaceTreatmentType} from "../generated/graphql";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";

const TABLE_KEY = "TABLE_DRAW_JOB"

const query = gql`
    query tasks($processDefinitionKey:String!)
    {
        tasks(processDefinitionKey:$processDefinitionKey)
        {
            id
            taskDefinitionKey
            activityIds
            name
            createdAt
            variables
            assignee
            order{
                number
                id
                preferredDeliveryDate
                assignee
                {
                    displayName
                    username
                }

                company
                {
                    id
                    name
                }
                project{
                    id
                    name
                }
                prioritisation {
                    id
                    name
                    level
                }
                articleRows {
                    id
                    article {
                        id
                        artNo
                        spiralStair {
                            id
                            stepType
                            segments {
                                id
                            }
                            surfaceTreatmentOverview {
                                type
                            }
                        }
                    }
                }
            }
        }
    }
`;


class DrawJobOverviewPage extends Component {

    constructor() {
        super();
        this.claimTask = this.claimTask.bind(this);
        this.state = {
            selectedTask: null,
        };
    }

    styleTh =  {whiteSpace: 'nowrap'}

    async claimTask(task) {
        if (window.confirm(`Are you sure you wish to assign the task: "${task.name}" to you?`)) {
            await this.props.claimTask({
                variables: {
                    id: task.id,
                },
                refetchQueries: ["tasks"]
            });
            this.setState({ selectedTask: task });
        }

    }

    tableDefinition = [
        {
            field: 'claimTask',
            headerName: 'Claim task',
            filterable: false,
            sortable: false,
            width:100,
            renderCell: (params) => {
                const task = params.row
                if (!task.assignee) {
                    return <span className='link' onClick={() => this.claimTask(task)}>
                                    Claim task
                                </span>
                }
                if (task.assignee === Auth.getUsername()) {
                    return <span className="link" onClick={() => this.setState({selectedTask: task})}>
                                    Open task
                                </span>
                }
                return <span>Claimed</span>
            },
        },
        {
            field: 'order.number',
            headerName: 'Order No.',
            width:100,
            renderCell: (params) => {
                const task = params.row
                if (task.variables && task.variables.orderId) {
                    return <Link to={`/orderDetail/${task.variables.orderId}`}>
                        {task.order.number}
                    </Link>
                }
                return task.order.number
            },
        },
        {
            field: 'order.prioritisation',
            headerName: 'Prioritisation',
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const prioritisation = params.value
                if (prioritisation) {
                    return <>
                        {(() => {
                            switch (prioritisation.level) {
                                case 0:
                                    return <Dot/>;
                                case 1:
                                    return <Up/>;
                                case 2:
                                    return <DoubleUp/>;
                                default:
                                    return null;
                            }
                        })()}
                        {' '}
                        {prioritisation.name}
                    </>
                }
                return null
            },
        },
        {
            field: 'order.assignee.displayName',
            headerName: 'Order Assignee',
        },
        {
            field: 'order.company.name',
            headerName: 'Company name',
            renderCell: (params) => <strong>{params.value}</strong>
        },
        {
            field: 'order.project.name',
            headerName: 'Project name',
            renderCell: (params) => <p style={{fontStyle: "italic"}}>{params.value}</p>
        },
        ColumnCreatedAt(),
        {
            field: 'articles',
            headerName: 'Articles',
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const task = params.row
                return <div>
                    {
                        task.variables.articles.concat(task.variables.autoDrawArticles || [])
                            .map(a => {
                                const article = task.order?.articleRows?.find((e) => e?.article?.id === a.id)?.article
                                if (!article) {
                                    return null
                                }

                                const sectionCount = _.get(article, "spiralStair.segments.length");
                                const isAuto = _.some(task.variables.autoDrawArticles, a => a.id === article.id);
                                const hasWoodSteps = _.get(article, "spiralStair.stepType") === "oak30mm" || _.get(article, "spiralStair.innerRailingType") === "oak";
                                const woodId = `wood-${article.id}`;
                                const powderId = `powderCoat-${article.id}`;
                                const surfaceTreatment = article.spiralStair?.surfaceTreatmentOverview;
                                let hasPowderCoating = false;
                                if (surfaceTreatment?.type) {
                                    hasPowderCoating = [SurfaceTreatmentType.Pulverlack, SurfaceTreatmentType.VfzLack].includes(surfaceTreatment.type);
                                }

                                return (
                                    <p key={article.id} style={{margin: 0, whiteSpace: 'nowrap'}}>
                                        {articleDetailLink(article, article.artNo)}
                                        {isAuto && <span style={{fontSize: "20px", color: "green"}}>A</span>}
                                        {sectionCount != null && <small>({sectionCount} sections)</small>}
                                        {hasWoodSteps && (
                                            <>
                                                <UncontrolledTooltip target={woodId}>Wooden steps</UncontrolledTooltip>
                                                <span id={woodId}
                                                      style={{textDecoration: "underline", fontSize: "5px"}}><Wood/></span>
                                            </>
                                        )}
                                        {hasPowderCoating && (
                                            <>
                                                <UncontrolledTooltip target={powderId}>Powder Coated</UncontrolledTooltip>
                                                <span id={powderId}
                                                      style={{textDecoration: "underline", fontSize: "9px"}}><PaintBucket/></span>
                                            </>
                                        )}
                                    </p>
                                );
                            })
                    }
                </div>
            }
        },
        ColumnDate("order.preferredDeliveryDate", "Preferred delivery date"),
        {
            field: 'assignee',
            headerName: 'Task Assignee',
        },
        {
            field: 'variables.drafter',
            headerName: 'Drafter'
        },
    ]

    taskCardBody = (tasks, collapsed) => {
        return <Collapser open={!collapsed}>
            <NonPaginatedDataGrid definition={{
                tableKey: TABLE_KEY,
                columns: this.tableDefinition,
                pageSize: 100,
                initState: {
                    sorting: {sortModel: [{field: "createdAt", sort: "asc"}]}
                }
            }} data={tasks}/>
        </Collapser>
    };

    tasksCard(label,tasks,collapsed)
    {
        return <Card key={label} style={{border:"0",marginBottom:"15px"}} >
            {/*<CardTitle></CardTitle>*/}
            <CardBody style={{padding:"0"}}>
                <h4>{label}</h4>
                {this.taskCardBody(tasks || [],collapsed)}
            </CardBody>
        </Card>
    }


    render()
    {
        const userTasks = [
            {key: "UploadCustomerDrawings", label: "Draw Jobs"},
            {key:"ProvideMoreInformation",label:"Seller provides more information"},
            {key:"ReviewMoreInformation",label:"Engineer reviews provided information"},
            {key: "ReviseDrawing", label: "Customer waiting for new drawings"},
            {key: "SendCustomerDrawing", label: "Send Customer Drawing",collapsed:true},
            {key: "CustomerResponse", label: "Waiting for customer's approval",collapsed:true},
            {key: "UploadProductionDrawing", label: "Waiting for production drawing"},
            {key: "ReviewDrawings", label: "Review drawings"},
            {key: "ProvideInformationForRevise", label: "Seller provides more information for revise"},
        ];
        const sectionCount = tasks=>_.sumBy(_.flatMap(tasks,"variables.articles")
        .concat(_.flatMap(tasks,"variables.autoDrawArticles")),article=>
        _.get(article,"spiralStair.segments.length")||0);
        const task = this.state.selectedTask;
        return (
        <div>
            {task && <StdQuery noLoadingRender query={GetTaskQuery} variables={{id:get(task,"id")}}>
                {data =>
                {
                    return <GenericWorkflowForm isOpen={true} task={data.task} toggle={()=>this.setState({selectedTask:null})} />;
                }}
            </StdQuery>}
            <StdQuery  query={query} variables={{processDefinitionKey:"DrawJob"}}>
                {data=>
                {
                    const groupedTasks = _.groupBy(data.tasks,task =>task.taskDefinitionKey);
                    return <div>
                        <Col sm={12}>
                            <Table bordered>
                                <thead>
                                <tr>
                                    {_.map(userTasks,({key,label}) =>
                                    {
                                        if(key === "UploadCustomerDrawings")
                                        {
                                            return <React.Fragment>
                                                <th key={key}>Unassigned {label}</th>
                                                <th key={key}>Assigned {label}</th>
                                            </React.Fragment>
                                        }
                                        return <th key={key}>{label}</th>
                                    }
                                    )}
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {_.map(userTasks,({key,label}) =>
                                    {
                                        const tasks = groupedTasks[key] || [];
                                        if(key === "UploadCustomerDrawings")
                                        {
                                            const assignedTasks = filter(tasks,t=>t.assignee);
                                            const unAssignedTasks = filter(tasks,t=>!t.assignee);

                                            return <React.Fragment key={key}>
                                                <td>
                                                    <p>{`${unAssignedTasks.length} draw jobs. With
                                    ${_.sumBy(unAssignedTasks,'variables.articles.length')} articles.
                                    (${_.sumBy(unAssignedTasks,"variables.autoDrawArticles.length") || 0} auto)
                                     Sections: ${sectionCount(unAssignedTasks)}`}</p>
                                                </td>
                                                <td>
                                                    <p>{`${assignedTasks.length} draw jobs. With
                                    ${_.sumBy(assignedTasks,'variables.articles.length')} articles.
                                    (${_.sumBy(assignedTasks,"variables.autoDrawArticles.length") || 0} auto)
                                     Sections: ${sectionCount(assignedTasks)}`}</p>
                                                </td>
                                            </React.Fragment>
                                        }
                                        return <td key={key}>
                                            <p>{`${tasks.length} draw jobs. With
                                    ${_.sumBy(tasks,'variables.articles.length')} articles.
                                    (${_.sumBy(tasks,"variables.autoDrawArticles.length") || 0} auto) 
                                    Sections: ${sectionCount(tasks)}`}
                                            </p>
                                        </td>
                                    })}
                                </tr>
                                </tbody>
                            </Table>
                        </Col>


                        <p>{`${data.tasks.length} draw jobs. With
                                    ${_.sumBy(data.tasks,'variables.articles.length') + 
                        _.sumBy(data.tasks,'variables.autoDrawArticles.length')} articles. Sections: ${sectionCount(data.tasks)}`}</p>
                        {_.map(userTasks,obj =>
                        {
                            const key = obj.key;
                            const label = obj.label;
                            const tasks = groupedTasks[key];
                            if(key === "UploadCustomerDrawings")
                            {
                                return <React.Fragment key={key}>
                                    {this.tasksCard(`Unassigned ${label}`,filter(tasks,t=>!t.assignee))}
                                    {this.tasksCard(`Assigned ${label}`,filter(tasks,t=>t.assignee))}
                                </React.Fragment>
                            }
                            else
                            {
                                return this.tasksCard(label,tasks,obj.collapsed);
                            }


                        })}
                    </div>



                }}
            </StdQuery>
        </div>
        );
    }
}

export default graphql(ClaimTaskMutation, {name: "claimTask"})(DrawJobOverviewPage);
