import React from "react";
import {PackageItemFragmentFragment} from "../../generated/graphql";
import {Table} from "reactstrap";
import CellArticleRow from "../../common/data-grid/cell/CellArticleRow";
import {Link} from "react-router-dom";

export const calculatePackageExpandedHeight = ({row}): number =>
{
    const packageItemsLength = row.packageItems?.length ?? 0
    if (packageItemsLength === 0)
    {
        return 150
    }

    return 100 + (packageItemsLength) * 65
}

export const PackageExpandedPanel = (props) =>
{
    const {packageInfo} = props
    return <div style={{padding: "20px"}}>
        <h5>Items</h5>
        <PackageItemsTable packageItems={packageInfo.packageItems}/>
    </div>
}


const PackageItemsTable = (props: { packageItems?: PackageItemFragmentFragment[] | null }) =>
{
    const {packageItems} = props

    return <Table style={{maxWidth: "400px"}}>
        <thead>
        <tr>
            <th>Art no</th>
            <th>name</th>
            <th>quantity</th>
            <th>order</th>
        </tr>
        </thead>

        <tbody>
        {
            packageItems?.map((e, index) =>
            {
                const {articleRow, quantity} = e
                return <tr key={index}>
                    <td>
                        <CellArticleRow articleRowId={articleRow?.id} article={articleRow?.article}
                                        stockArticle={articleRow?.stockArticle}/>
                    </td>
                    <td>{articleRow?.name ?? "-"}</td>
                    <td style={{textAlign: "right"}}>{quantity}</td>
                    <td>
                        {
                            articleRow?.order ?
                                <Link to={`/orderDetail/${articleRow.order.id}`}>{articleRow.order?.number}</Link>
                                : "-"
                        }
                    </td>
                </tr>
            })
        }
        </tbody>
    </Table>
}


export default PackageExpandedPanel