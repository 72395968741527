import gql from 'graphql-tag';
import GetTermsOfDeliveryQuery from '../common/graphql/GetTermsOfDelivery.graphql';
import GetTermsOfPayment from '../common/graphql/GetTermsOfPayment.graphql';
import GetModeOfDelivery from '../common/graphql/GetModeOfDelivery.graphql';
import {TenantData} from "../common/lib/TenantData";
import Auth from "../Auth";
import _ from 'lodash';
import React from "react";

const ClassificationsQuery = gql`
    query {
        classifications
        {
            id
            name
        }
    }
`;
const CompanyTypeQuery = gql`

    query {
        companyTypes
        {
            id
            name
        }
    }
`;
const ProductInterestsQuery = gql`

    query {
        productInterests
        {
            id
            name
        }
    }
`;

const IndustriesQuery = gql`

    query {
        industries
        {
            id
            name
        }
    }
`;
export default (company)=>
{
    const defaultLocale = _.get(TenantData,`${Auth.getTenant()}.defaultLocale`);
    const organisationNumberRequired = Auth.getTenant() !== 'ESDE';
    const locale = company?company.locale:defaultLocale;
    return [
        {name:"name",required:true},
        {name:"website"},
        {name:"defaultModeOfDeliveryId",label:`Default Mode of delivery`,
            type:"select",query:GetModeOfDelivery,
            variables:{locale}},

        {name:"organisationNumber",required:organisationNumberRequired,pattern:"[A-Za-z0-9]+",hint:<small><br/>No special characters or spaces</small>},
        {name:"phone"},
        {name:"defaultTermsOfDeliveryId",label:`Default Terms of delivery`,
            type:"select",query:GetTermsOfDeliveryQuery,
            variables:{locale}},

        {name:"locale",label:"Language",type:"localePicker",defaultValue:defaultLocale},
        {name:"email",type:"email"},
        {name:"defaultTermsOfPaymentId",label:`Default Terms of payment`,
            type:"select",query:GetTermsOfPayment,
            variables:{locale}},

        {name:"companyTypeId",label:"Company type",type:'select',query:CompanyTypeQuery},
        {name:"assigneeId",label:"Assignee",type:'userPicker'},
        {name:"classificationId",type:'select',query:ClassificationsQuery},

        {name:"industryId",label:"Industry",type:'select',query:IndustriesQuery},
        {name:"productInterests",label:"Product interests",type:'select',multi:true,query:ProductInterestsQuery},
        {name:"description",type:"textarea"},
        {name:"customerReferenceNumberRequired", type:"checkbox"}
    ];
}
