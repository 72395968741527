import {Link} from "react-router-dom";
import {UncontrolledTooltip} from "reactstrap";
import React from "react";
import AlertIcon from "../../icons/AlertIcon";
import Stair from "../../icons/Stair";


const SupplierMissingAlert = (props) => {
    const {articleId} = props
    return <span id={"alert" + articleId}>
        <AlertIcon fill={"#FFA500"}/>
        <UncontrolledTooltip target={"alert" + articleId}>Missing supplier</UncontrolledTooltip>
      </span>
}

export default (props) =>
{
    const {articleRowId, article, stockArticle} = props
     let value = article?.artNo

    let stockArticleArtNo = stockArticle?.artNo
    if (stockArticleArtNo) {
        return stockArticleArtNo;
    }
    if (value == null) {
        if (!article) {
            return "";
        } else if (article.spiralStair) {
            value = "View spiral stair";
        } else if (article.straightStair || article.straightStairV2) {
            value = `View straight stair`;
        } else if (article.moduleRamp) {
            value = `View module ramp`;
        } else {
            return <>
                <Link to={`/articleDetail/${article.id}/${articleRowId}`}>View Article</Link>
                {(article.supplier || article.artNo) ? null :
                    <SupplierMissingAlert articleId={article.id}/>}
            </>
        }
    }
    if (article && article.spiralStair) {

        const id = `spiralStair${article.id}`;
        return <span>
                            <img src="/favicon.png" height={20}/>
                            <UncontrolledTooltip target={id} placement="right">View Spiral Stair</UncontrolledTooltip>
                            <Link id={id}
                                  to={`/spiralStairDetail/${article.spiralStair.id}/${articleRowId}`}>{value}</Link>
            {!article.supplier && !article.artNo && <SupplierMissingAlert articleId={article.id}/>}
                            </span>
    } else if (article && article.straightStairV2) {
        return <span className="link">
            <Stair fill={"currentcolor"}/>
            <Link to={`/straightStairV2Detail/${article.straightStairV2.articleId}/${articleRowId}`}>
                {value}
            </Link>
            {!article.supplier && !article.artNo && <SupplierMissingAlert articleId={article.id}/>}
        </span>
    } else if (article && article.straightStair) {
        return <span>
                            <Link to={`/straightStairDetail/${article.straightStair.id}/${articleRowId}`}>
                                {value}
                            </Link>
            {!article.supplier && !article.artNo && <SupplierMissingAlert articleId={article.id}/>}
                        </span>
    } else if (article && article.moduleRamp) {
        return <span>
                            <img src="/rampIcon.png" height={20}/>
                            <Link to={`/moduleRampDetail/${article.moduleRamp.id}/${articleRowId}`}>
                                {value}
                            </Link>
            {!article.supplier && !article.artNo && <SupplierMissingAlert articleId={article.id}/>}
                        </span>
    }
    else if (article) {
        return <>
            <Link to={`/articleDetail/${article.id}/${articleRowId}`}>{value}</Link>
            {!article.supplier && !article.artNo && <SupplierMissingAlert articleId={article.id}/>}
        </>
    } else {
        return value;
    }
}