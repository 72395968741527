import {Badge} from "reactstrap";

export enum SurfaceTreatmentTypeColor{
    default = "#333",
    vfz = "#c29621",
    vfz_lack = "#9ad641",
    pulverlack = "#419cd6",
}

export enum SurfaceTreatmentType
{
    vfz = "vfz",
    vfz_lack = "vfz_lack",
    pulverlack = "pulverlack",
    vfz_fe_zn = "vfz_fe_zn",
    obehandlad = "obehandlad",
}

export const getSurfaceTreatmentType = (spiralStair: { surfaceTreatmentOverview, platingType }) =>
{
    const isCheckedPC = spiralStair.surfaceTreatmentOverview.isCheckedPC;
    if (spiralStair.platingType && isCheckedPC)
    {
        return SurfaceTreatmentType.vfz_lack;
    } else if (spiralStair.platingType)
    {
        return SurfaceTreatmentType[spiralStair.platingType];
    } else if (isCheckedPC)
    {
        return SurfaceTreatmentType.pulverlack;
    } else
    {
        return SurfaceTreatmentType.obehandlad;
    }
}

export default ({spiralStair}: { spiralStair: { surfaceTreatmentOverview, platingType } })=>{
    if(!spiralStair) return null;

    const surfaceTreatmentValue = getSurfaceTreatmentType(spiralStair)

    if (Object.keys(SurfaceTreatmentTypeColor).includes(surfaceTreatmentValue))
    {
        return <Badge style={{"backgroundColor": SurfaceTreatmentTypeColor[surfaceTreatmentValue]}}>{surfaceTreatmentValue}</Badge>
    }
    else
    {
        return <Badge style={{"backgroundColor": SurfaceTreatmentTypeColor.default}}>{surfaceTreatmentValue}</Badge>
    }
}