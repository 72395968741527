import React, {useState} from 'react';
import {Button, ButtonGroup, Col, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import QuestionMark from "../icons/QuestionMark";

export default ({submit,save,toggle,submitLabel}:{submit:()=>void,save?:()=>void,toggle:()=>void,submitLabel?:string})=>
{
    const [loading,setLoading] = useState(false);
    return <React.Fragment>
        <hr/>
        <Row className="justify-content-between">
            <Col sm={2}>
                <Button onClick={()=>toggle()}>
                    Cancel
                </Button>
            </Col>
            <Col sm={4}>
                <ButtonGroup>
                    {save && <Button color="primary" onClick={save}>
                        Save
                        <span id="save-info">
                                <QuestionMark/>
                            </span>
                        <UncontrolledTooltip target="save-info" >
                            Save data in task without completing the task.
                        </UncontrolledTooltip>
                    </Button>}

                    {!loading && <Button color="success" onClick={async()=>
                    {
                        setLoading(true);
                        await submit();
                        setLoading(false);
                    }}>
                        {submitLabel || "Complete task"}
                    </Button>}
                    {loading && <Spinner color={'warning'}/>}
                </ButtonGroup>
            </Col>
        </Row>
    </React.Fragment>
}
