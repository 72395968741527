import {Spinner} from "reactstrap";


export default ({res,children})=>
{
    const {loading,error} = res;
    if(loading)
    {
        return <Spinner color="warning"/>
    }
    else if(error) {
        return <p style={{color: "red"}}>
            Encountered an error.
            <br/>
            {error.message}
        </p>
    }
    if(typeof(children) === 'function')
    {
        return children()
    }
    else {
        return children;
    }

}
