import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Html5Qrcode, Html5QrcodeScannerState, Html5QrcodeSupportedFormats} from "html5-qrcode";
import {withRouter} from "react-router";

const qrcodeRegionId = "html5qr-code-full-region";

/**
 * @param pageActive    If this scanner in wrap inside a tab, it should be active when tab is selected.
 * @param scannerActive Is scanner temporary stop or not.
 */
const CodeScanner = (props) =>
{
    const {pageActive, scannerActive, scanSuccessCallback, scanErrorCallback} = props
    const history = props.history
    const divElement = useRef<HTMLDivElement | null>(null);
    const [html5QrcodeScanner, setHtml5QrcodeScanner] = useState<Html5Qrcode | null>(null);

    const startScan = useCallback(() =>
    {
        if (!divElement.current) return;

        const divWidth = divElement.current.clientWidth;
        const width = divWidth - 50;
        const height = width * 0.625;
        const config = {
            formatsToSupport: [Html5QrcodeSupportedFormats.PDF_417],
            fps: 10,
            qrbox: {width, height},
            disableFlip: false,
        };
        html5QrcodeScanner?.start({facingMode: "environment"}, config, scanSuccessCallback, scanErrorCallback);
    }, [html5QrcodeScanner, scanSuccessCallback, scanErrorCallback]);

    const stopScan = useCallback(() =>
    {
        if (html5QrcodeScanner?.getState() !== Html5QrcodeScannerState.SCANNING) return;
        html5QrcodeScanner?.stop();
    }, [html5QrcodeScanner]);

    useEffect(() =>
    {
        setHtml5QrcodeScanner(new Html5Qrcode(qrcodeRegionId));
    }, [setHtml5QrcodeScanner]);

    useEffect(() =>
    {
        const stopListen = history.listen(() =>
        {
            stopScan();
            stopListen();
        });
    }, [history, stopScan]);

    useEffect(() =>
    {
        if (pageActive && scannerActive)
        {
            startScan();
        } else
        {
            stopScan();
        }
    }, [pageActive, scannerActive, startScan, stopScan]);


    return <div id={qrcodeRegionId} style={{width: "30%"}} ref={divElement}/>
}

export default withRouter(CodeScanner)