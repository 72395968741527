import React, {useCallback, useState} from 'react';
import {Button, Card, CardBody, CardHeader, Col, Input, Row} from "reactstrap";
import {IsEconomy} from "../Auth";
import {useMutation} from "@apollo/client";
import UpdateCreditLimitMutation from "./graphql/UpdateCreditLimit.graphql";
import Select from "react-select";
import {FORMAT_DATE, formatCurrency, formatToSwedishTime} from "../UTIL";

export default ({company})=>
{
    const {
        id,
        creditRatingLimit,
        creditRatingLimitCurrency,
        creditRatingLimitUpdateAt,
        creditRatingLimitNote,
    } = company;
    const [isEditing, setIsEditing] = useState(false);
    const [editingLimit, setEditingLimit] = useState(parseFloat(creditRatingLimit));
    const [editingCurrency, setEditingCurrency] = useState(({id:creditRatingLimitCurrency,name:creditRatingLimitCurrency}));
    const [editingNote, setEditingNote] = useState(creditRatingLimitNote);
    const [updateCreditLimit] = useMutation(UpdateCreditLimitMutation, {
        refetchQueries: ["getCompany"]
    });

    const submit = useCallback(async ()=>{
        console.log("editingCurrency", editingCurrency)
        await updateCreditLimit({
            variables:{
                id,
                creditLimit: editingLimit,
                currency: editingCurrency.id,
                note: editingNote,
            }
        });
        setIsEditing(false);
    }, [updateCreditLimit, id, editingLimit, editingCurrency, editingNote, setIsEditing]);

    console.log("creditRatingLimit", creditRatingLimit)
    return <Card>
        <CardHeader>
            <Row>
                <Col><h5>Credit Insurance</h5></Col>
                <Col>
                    {!isEditing &&
                        <IsEconomy>
                            <Button size={"sm"} color='info' onClick={()=>setIsEditing(true)}>Edit Credit Limit</Button>
                        </IsEconomy>
                    }
                    {isEditing && <>
                        <Button size={"sm"} color='secondary' onClick={()=>setIsEditing(false)}>Cancel editing</Button>
                        <Button size={"sm"} color='success' onClick={()=>submit()}>Save</Button>
                    </>}
                </Col>
            </Row>
        </CardHeader>
        <CardBody>
            {!isEditing && <>
                {creditRatingLimit !== undefined && creditRatingLimit !== null ? <>
                    Limit: {formatCurrency(creditRatingLimit)} {creditRatingLimitCurrency}<br/>
                    Updated at: {formatToSwedishTime(creditRatingLimitUpdateAt, FORMAT_DATE)}<br/>
                    Note: {creditRatingLimitNote}
                </> : <>
                    Limit unset
                </>}
            </>}

            {isEditing && <>
                Limit: <Input type={"number"} value={editingLimit} onChange={(e)=>setEditingLimit(parseFloat(e.target.value))}/>
                <small>
                    {formatCurrency(editingLimit)} {editingCurrency?.id ?? ""}
                </small>
                <br/>
                Currency: <Select
                    getOptionLabel={({name}) => name}
                    getOptionValue={({id}) => id}
                    required={true}
                    options={["SEK","NOK","DKK","EUR"].map(c=>({id:c,name:c}))}
                    value={editingCurrency}
                    onChange={(e)=>setEditingCurrency(e)}
                />
                Note: <Input type={"text"} value={editingNote} onChange={(e)=>setEditingNote(e.target.value)}/>
            </>}
        </CardBody>
    </Card>
}
