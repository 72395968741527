import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Row, Spinner} from "reactstrap";

import {Prompt} from "react-router";
import _ from 'lodash';
import {
    useArticleRowLazyQuery,
    useStraightStairLazyQuery,
    useUpdateArticleRowMutation, useUpdateStraightStairMutation
} from "../generated/graphql";
import StraightStairEditCard from "./StraightStairEditCard";
import BackButtons from "../common/BackButtons";
import ArticleRowEditCard from "../common/article/ArticleRowEditCard";

export default ({match}) => {
    const {id} = match.params;
    const articleRowId = match.params.articleRowId;
    const [straightStairOld, setStraightStairOld] = useState<any>(null);
    const [articleRowRes, setArticleRowRes] = useState<any>(null);
    const [straightStairState, setStraightStairState] = useState<any>(null);
    const [articleRowState, setArticleRowState] = useState<any>(null);
    const [getStraightStair] = useStraightStairLazyQuery();
    const [getArticleRow] = useArticleRowLazyQuery();
    const [updateStraightStair] = useUpdateStraightStairMutation({refetchQueries:["straightStair"]})
    const [updateArticleRow] = useUpdateArticleRowMutation({refetchQueries:["articleRow"]});
    const [saveLoading, setSaveLoading] = useState(false);
    const unsavedChanges = straightStairState &&
        (
            !isEqual(straightStairState, straightStairOld) ||
            (articleRowState && !isEqual(articleRowState, articleRowRes.data?.articleRow))
        );

    useEffect(()=>{
        if(saveLoading) return;

        const asyncFn = async () => {
            const straightStairRes = await getStraightStair({variables:{ id }});
            const straightStair:any = {...straightStairRes.data?.straightStair};
            if(!straightStair) return;
            straightStair.supplierId = straightStair.supplier?.id;
            setStraightStairOld(straightStair);
            setStraightStairState(straightStair);
            if(articleRowId){
                const articleRowRes = await getArticleRow({variables:{ articleRowId }});
                setArticleRowRes(articleRowRes);
                setArticleRowState(articleRowRes?.data?.articleRow);
            }
        }
        asyncFn().then();

    }, [getStraightStair, getArticleRow, setStraightStairOld, setArticleRowRes, saveLoading, articleRowId, id]);

    const save = useCallback(async () => {
        setSaveLoading(true);
        const columns = [
            "name", "quantity", "unit", "price", "supplierId",
            "height", "stepWidth","stepDepth", "stepType", "blank", "handRailType",
            "surfaceTreatment", "platformS1", "platformS2", "inPrice", "inPriceCurrency", "weldability"
        ];
        await updateStraightStair({variables:{id, stair:_.pickBy(straightStairState, function(value, key) {
                    return columns.includes(key);
                })
            }});
        await updateArticleRow({variables:{id:articleRowId, articleRow:_.pickBy(articleRowState, function(value, key) {
                    return columns.includes(key);
                })
            }});

        setSaveLoading(false);
    }, [setSaveLoading, updateStraightStair, updateArticleRow, straightStairState, articleRowState, articleRowId, id]);

    if(!straightStairState || (articleRowId && !articleRowState))
    {
        return <Spinner color={"warning"} />
    }
    const {
        name:articleName,
        artNo,
    } = straightStairState;

    return <>
        <Prompt when={unsavedChanges} message={`You have unchanged changes, are you sure you want to leave?`} />
        <Row>
            <Col sm={1}>
                <BackButtons articleRowId={articleRowId} />
            </Col>
            <Col sm={5}>
                {articleRowId && <h2>{articleRowState.name} {artNo}</h2>}
                {!articleRowId && <h2>{articleName} {artNo}</h2>}
            </Col>
        </Row>
        {articleRowId && <Row style={{marginBottom:"20px"}}>
            <Col sm={6}>
                <ArticleRowEditCard articleRow={articleRowState} onChange={(key, value)=>{
                    const newArticleRowState = {...articleRowState};
                    newArticleRowState[key] = value;
                    setArticleRowState(newArticleRowState);
                }}/>
            </Col>
        </Row>
        }
        <Row style={{marginBottom:"20px"}}>
            <Col sm={6}>
                <StraightStairEditCard isOrder={!!articleRowState.orderId} article={straightStairState}
                                       hasPurchaseOrder={(articleRowState?.order?.purchaseOrders?.length ?? 0) > 0}
                                       onChange={(key, value)=>{
                    const newStraightStairState = {...straightStairState};
                    newStraightStairState[key] = value;
                    setStraightStairState(newStraightStairState);
                }}/>
            </Col>
        </Row>
        {!saveLoading && <Button size={"sm"} color={"success"} onClick={()=>save()}>
            Save
        </Button>}
        {saveLoading && <Spinner color={"warning"} />}
        <p>Unsaved changes: {unsavedChanges?<strong style={{color:"red"}}>Yes</strong>:"No"}</p>
    </>
};

const isEqual = (state,object)=>
{
    for(let key in state)
    {
        if(key !== "__typename"&& state.hasOwnProperty(key) && !_.isEqual(state[key],object[key]))
        {
            return false;
        }
    }
    return true;
};
