import {Spinner} from "reactstrap";
import React from "react";

const LoadingView = () =>
{
    return <div style={{position: "fixed", top: "50%", left: "50%", zIndex: 9999}}>
        <Spinner color='warning'/>
    </div>
}

export default LoadingView