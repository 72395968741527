import {Node} from "./ShpaeObject";
import {Ramp, RampEdge, StartingRamp, TrianglePlatform} from "./Module/Ramp";
import {IndustrialPole, IndustrialRailing, IndustrialRailingCurvePiece} from "./Module/IndustrialPole";
import {ChildSafeSection} from "./Module/ChildSafeSection";
import {WoodPole} from "./Module/WoodPole";
import {instanceOf} from "./utils";
import {RampToPlatform} from "./Module/RampToPlatform";
import {KickPlate} from "./Module/KickPlate";

export const nodeForRampEdge = (node:Node) => {
    const {slopeDegree} = node.parent as RampEdge;

    if(node.attach.length===0){
        if (Math.abs(slopeDegree) > 0.1)
        {
            return instanceOf(node.ancestor, [IndustrialPole, ChildSafeSection, WoodPole, KickPlate]);
        } else
        {
            return instanceOf(node.ancestor, [Ramp, StartingRamp, TrianglePlatform,
                IndustrialPole, ChildSafeSection, WoodPole, RampToPlatform, KickPlate]);
        }
    }else{
        return false;
    }
}

export const nodeForIndustrialRailing = (node:Node):boolean => {
    return node.parent instanceof IndustrialPole &&
        node.parent.railingNode === node &&
        node.parent.railingNode.attach.length<2;
}

export const nodeRailingForIndustrialPole = (node:Node):boolean => {
    return node.parent instanceof IndustrialRailing || node.parent instanceof IndustrialRailingCurvePiece;
}

export const nodeOfChildSafeSectionForRailingParts = (node:Node):boolean => {
    return node.parent instanceof ChildSafeSection && node.attach.length===0;
}

export const nodeOfRampForPole = (node:Node):boolean => {
    // check if the node is child of ramp AND not occupied by other pole or ramp
    if (!(node.parent instanceof RampEdge)) return false;

    const isOccupied = node.attach.some(attachNode=> {
        return (
            attachNode.ancestor instanceof IndustrialPole ||
            attachNode.ancestor instanceof ChildSafeSection ||
            attachNode.parent instanceof RampEdge ||
            attachNode.ancestor instanceof WoodPole
        );
    });

    return !isOccupied;
}

export const nodeOfRampForKickPlate = (node:Node):boolean => {
    // check if the node is child of ramp AND not occupied by other pole or ramp
    if (!(node.parent instanceof RampEdge)) return false;

    const isOccupied = node.attach.some(attachNode=> {
        return (
            attachNode.parent instanceof RampEdge ||
            attachNode.parent instanceof KickPlate
        );
    });

    return !isOccupied;
}