

export enum StatusEnum
{
    created="created",
    finished="finished"
}


export interface StatusValueList
{
    value:StatusEnum;
    label:string;
    variant:string;
}

export const POStatuses:Record<StatusEnum,StatusValueList> = {
    [StatusEnum.created]:{
        value:StatusEnum.created,
        label:"Created",
        variant:"success"
    },
    [StatusEnum.finished]:
    {
        value:StatusEnum.finished,
        label:"Finished",
        variant:"warning"
    }
}
