import React, {Component} from 'react';
import {Badge} from "reactstrap";
import {withApollo} from '@apollo/client/react/hoc'
import gql from "graphql-tag";

const query = gql`
    query hasPastDueTasks
    {
        hasPastDueTasks
    }
`;

class PastDueTasksIcon extends Component
{
    constructor()
    {
        super();
        this.state = {
            pastDueTaskCount:0
        };
    }

    componentWillUnmount()
    {
        clearInterval(this.timer);
    }

    componentDidMount()
    {
        this.checkForPastDueTasks();
        this.timer = setInterval(this.checkForPastDueTasks,180000);
    }

    checkForPastDueTasks = async()=>
    {
        if (!window.document.hidden)
        {
            try
            {
                const res = await this.props.client.query(
                {
                    query: query
                });
                this.setState({pastDueTaskCount: res.data.hasPastDueTasks});
            } catch (e)
            {
                console.log(e);
            }
        }
    }


    render()
    {
        if(this.state.pastDueTaskCount > 0)
        {
            return <Badge color="info" title="Tasks past due">
                ⏰ {this.state.pastDueTaskCount}
            </Badge>
        }
        else
        {
            return null;
        }

    }


}

export default withApollo(PastDueTasksIcon)



