import { memo, useCallback, useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import CreatePackagePage from "../CreatePackagePage";

type Props = {
    orderId: string,
    articleRowIds?: string[],
    openSearchOrder: boolean
}


const CreatePackageModalPanel = (props: Props) => {
    const { orderId, articleRowIds, openSearchOrder } = props

    const [open, setOpen] = useState(false)
    const [isCreateAnother, setIsCreateAnother] = useState<boolean>(false)
    const [isReopen, setIsReopen] = useState<boolean>(false)
    const [createPackageKey, setCreatePackageKey] = useState(0)

    const toggle = useCallback(() => {
        setOpen(prevState => !prevState)
    }, [])

    const handleIsCreateAnother = useCallback((e) => {
        setIsCreateAnother(e.target.checked)
    }, [])

    const handleOnSuccess = useCallback(() => {
        if (isCreateAnother) {
            setIsReopen(true)
        }
        setOpen(false)
    }, [isCreateAnother])

    useEffect(() => {
        if (!open && isReopen) {
            setIsReopen(false)
            setOpen(true)
            setCreatePackageKey(prevKey => prevKey + 1);
        }
    }, [open, isReopen]);

    return <>
        <Button onClick={toggle} color={"info"} size={"sm"}>Create Package</Button>
        <Modal toggle={toggle} isOpen={open} size="lg">
            <ModalHeader toggle={toggle}>Create Package</ModalHeader>
            <ModalBody>
                <CreatePackagePage key={createPackageKey}
                    orderId={orderId}
                    defaultSelectedArticleRowIdList={articleRowIds}
                    openSearchOrder={openSearchOrder}
                    customOnCreateSuccess={handleOnSuccess} />
                <FormGroup check inline style={{ margin: "12px 8px" }}>
                    <Input type="checkbox" className='big-checkbox' checked={isCreateAnother}
                        onChange={handleIsCreateAnother} />
                    <Label check style={{ marginLeft: "4px" }}>
                        Create Another
                    </Label>
                </FormGroup>
            </ModalBody>
        </Modal>
    </>
}


export default memo(CreatePackageModalPanel)