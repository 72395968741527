import React, {useMemo} from 'react';
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {SchedulerFinishedTasksV2Document} from "../generated/graphql";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnId from "../common/data-grid/columns/ColumnId";
import {Link} from "react-router-dom";
import {formatToSwedishTime} from "../UTIL";

const KEY_TABLE = "FINISHED_TASKS"

const FinishedTasksTableDefaultSort = {sortModel: [{field: 'finishedAt', sort: 'desc'}]}

export default () => {
    const columns = useMemo(() => {
        return [
            {
                field: 'articleRow.name',
                headerName: 'Name',
                searchable: true,
            },
            {
                field: 'articleRow.article.artNo',
                headerName: 'Article no.',
                searchable: true
            },
            {
                field: 'order.number',
                headerName: 'Order no.',
                customFilterField: 'orders.number',
                customSortField: "orders.number",
                customSearchField: 'orders.number',
                searchable: true,
                renderCell: (params) => {
                    return <Link to={`/orderDetail/${params.row.order.id}`}>
                        {params.value}
                    </Link>
                },
            },
            {
                field: 'resource.name',
                headerName: 'Resource name',
                customSortField: "schedulerResource.name",
                customSearchField: "schedulerResource.name",
                customFilterField: "schedulerResource.name",
                searchable: true,
            },
            ColumnDate('startedAt', 'Started At', (value) => formatToSwedishTime(value)),
            ColumnDate('finishedAt', 'Finished At', (value) => formatToSwedishTime(value)),
            {
                field: 'spentTime',
                headerName: 'Spent time',
                type: "number",
                filterable: false,
                sortable: false,
                width: 100,
            },
            {
                field: 'duration',
                headerName: 'Estimated time',
                type: "number",
                width: 100,
            },
            ColumnId(),
        ]
    }, [])

    return <div>
        <PaginatedDataGrid definition={{
            tableKey: KEY_TABLE,
            columns: columns,
            initState: {
                sorting: FinishedTasksTableDefaultSort,
            }
        }} query={SchedulerFinishedTasksV2Document}/>
    </div>
}
