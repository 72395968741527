import React, { Component } from 'react';
import { Query } from '@apollo/client/react/components';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import GetSupplier from './graphql/GetSupplier.graphql';
import FormRenderer from "../common/FormRenderer";
import UpdateSupplier from './graphql/UpdateSupplier.graphql';
import SupplierFragment from './graphql/SupplierFragment.graphql';
import ProductTypesQuery from '../common/graphql/ProductTypes.graphql';
import _ from 'lodash';
import { filteredFieldNamesFromFragment } from "../UTIL";
import GetTermsOfDeliveryQuery from '../common/graphql/GetTermsOfDelivery.graphql';
import GetTermsOfPayment from '../common/graphql/GetTermsOfPayment.graphql';
import GetModeOfDelivery from '../common/graphql/GetModeOfDelivery.graphql';


export const VismaSupplierClasses = [
    {
        id: "10",
        name: "10 - Swedish suppliers"
    },
    {
        id: "20",
        name: "20 - EU suppliers"
    },
    {
        id: "30",
        name: "30 - Non-EU suppliers"
    },
]

export const SupplierFormDef = (supplier) => {
    const simpleSupplier = _.filter(filteredFieldNamesFromFragment(SupplierFragment), (val) => {
        return ["id", "createdAt", "updatedAt", 'name', 'productTypes', 'locale', 'supplierRefs', 'eanValue', 'vismaSupplierClass', 'currency'].indexOf(val) === -1;
    });
    const simpleDef = _.map(simpleSupplier, (val => ({ name: val })));
    const locale = supplier ? supplier.locale : null;
    return [
        { name: 'name', required: true },
        { name: 'eanValue', required: true },
        { name: 'productTypes', type: 'select', multi: true, query: ProductTypesQuery },
        { name: 'locale', label: "Language", type: 'localePicker' },
        {
            name: "defaultTermsOfDeliveryId", label: `Default Terms of delivery`,
            type: "select", query: GetTermsOfDeliveryQuery,
            variables: { locale }
        },
        {
            name: "defaultTermsOfPaymentId", label: `Default Terms of payment`,
            type: "select", query: GetTermsOfPayment,
            variables: { locale }
        },
        {
            name: "defaultModeOfDeliveryId", label: `Default Mode of delivery`,
            type: "select", query: GetModeOfDelivery,
            variables: { locale }
        },
        { name: "vismaSupplierClass", type: "select", options: VismaSupplierClasses },
        { name: "currency", required: false, type: 'currencyPicker' },
    ].concat(simpleDef)
};



class SupplierEditPage extends Component {



    async updateSupplier(form) {
        console.log(form.object);
        console.log(await this.props.createSupplier({
            variables: { id: parseInt(this.props.match.params.id), supplier: form.object }
        }));
        //extract id and go to detailview.
        this.props.history.push(`/supplierDetail/${this.props.match.params.id}`);
    }

    render() {
        return <Query query={GetSupplier} variables={{ id: parseInt(this.props.match.params.id) }}>
            {({ loading, error, data }) => {
                if (loading) return `Loading..`;
                else if (error) return `Error: ${error}`;
                else if (data.supplier === null) return (<p>Not found :(</p>);
                else {

                    return <FormRenderer columns={3} onSubmit={this.updateSupplier.bind(this)}
                        object={data.supplier} formDefinition={SupplierFormDef(data.supplier)} />;
                }
            }}
        </Query>;
    }



}

export default graphql(UpdateSupplier, { name: "createSupplier" })(withRouter(SupplierEditPage));

