import React, {useCallback, useState} from 'react';
import UploadFile, {
    CustomerDocCategoryType,
    DrawingsCategoryType,
    ProductionDrawingsCategoryType
} from "../../common/UploadFile";
import {filter, map} from 'lodash';
import FileList from "../../common/FileList";
import ArticleList from "./ArticleList";
import TaskFooter from "../../common/task-workflow/TaskFooter";
import StdQuery from "../../common/StdQuery";
import getFilesQuery from './graphql/GetFiles.graphql';
import {OrderInfo} from "../Order/SelectArticles";
import {Button} from "reactstrap";
import {CommentPanel} from "./CommentComponents";

const submit = (onSubmit,files, comments)=>
{
    const submit = ()=>
    {
        onSubmit({
            customerDrawings: {
                value: JSON.stringify(map(files)),
                type: "json",
            },
            needMoreInformation: {
                value: false,
                type: "Boolean",
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            },
        })
    };
    if(files.length > 0)
    {
        submit();
    }
    else
    {
        if(window.confirm(`You have not uploaded any drawings. Are you sure you want to proceed?`))
        {
            submit();
        }
    }
};

export default ({update,onSubmit,variables,toggle})=>
{
    const [files,setFiles] = useState(variables.customerDrawings || []);
    const [comments, setComments] = useState(variables.comments ?? [])

    const {articles,comment,orderId,autoDrawArticles} = variables;

    const handleRequestMoreInformationClick = useCallback(() => {
        if(window.confirm(`Please confirm if more information is needed (if comments are to be added, please add them first`)){
            onSubmit({
                comments: {
                    value: JSON.stringify(comments),
                    type: "json"
                },
                needMoreInformation: {
                    value: true,
                    type: "Boolean"
                }
            })
        }
    }, [onSubmit, comments])

    return <div>
        <OrderInfo orderId={orderId}/>
        {comment && <div>
            <h2>Comment from customer</h2>
            <p>
                {comment}
            </p>
        </div>}
        <h3>Articles</h3>
        <ArticleList articles={articles} orderId={orderId}/>
        {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
            <ArticleList articles={autoDrawArticles} orderId={orderId}/>
        </>}
        <StdQuery query={getFilesQuery} variables={{orderId}}>
            {data => {
                const drawings = filter(data.files, file => file.fileCategory.id === DrawingsCategoryType.id || file.fileCategory.id === ProductionDrawingsCategoryType.id);
                const customerDocumentation = filter(data.files, file => file.fileCategory.id === CustomerDocCategoryType.id);
                return <React.Fragment>
                    <h3>Customer documentation files</h3>
                    <FileList files={customerDocumentation}/>
                    <hr/>
                    <h3>Uploaded drawings on order</h3>
                    <FileList files={drawings}
                              onDelete={() => {
                              }}/>
                </React.Fragment>
            }
            }
        </StdQuery>
        <hr/>
        <h3>Uploaded files</h3>
        <FileList files={files}
                  onDelete={(deletedFile) => {
                      setFiles(filter(files, file => file.id !== deletedFile.id));
                      update({
                          customerDrawings: {
                              value: JSON.stringify(map(files)),
                              type: "json",
                          }
                      }, false);
                  }
                  }/>
        <UploadFile fileCategory={DrawingsCategoryType}
                    onSubmit={files => setFiles(oldFiles => oldFiles.concat(files))}/>
        <hr/>

        {/*<CommentTable comments={comments}/>*/}
        {/*<AddCommentModal open={addCommentModal}*/}
        {/*                 toggle={toggleAddCommentModal}*/}
        {/*                 submit={handleAddComment}*/}
        {/*                 comments={comments}/>*/}
        <CommentPanel comments={comments}
                      setComments={(newComments) => {
                          setComments(newComments)
                      }}/>

        {/*<Button color={'danger'} onClick={handleRequestMoreInformationClick}>Request more information</Button>*/}
        <div style={{marginTop: "16px"}}>
            <Button color={'danger'} onClick={handleRequestMoreInformationClick}>
                Request more information
            </Button>
        </div>

        <TaskFooter toggle={toggle} save={() => {
            update({
                customerDrawings: {
                    value: JSON.stringify(map(files)),
                    type: "json",
                },
                comments: {
                    value: JSON.stringify(comments),
                    type: "json"
                },
            }, true);
        }} submit={() => submit(onSubmit, files, comments)}/>
    </div>;
}
