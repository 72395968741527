import React, {memo, useCallback, useMemo} from "react";
import {AsnEnabledFunc} from "../StraightStairV2Types";
import {enumToOptions} from "../../UTILmore";
import {
    PreferedMaterialType,
    RailingStandardType,
    RailingType,
    StepType, StraightStairSurfaceTreatmentFragmentFragment, StraightStairV2FragmentFragment,
    StringerMaterial
} from "../../generated/graphql";
import {Alert, Button, Col, Row} from "reactstrap";
import NewFormRenderer from "../../common/NewFormRenderer";
import {deflectionOptions, maxLoadOptions} from "../StraightStairOptions";
import {convertRailingTypeToLabel} from "../StraightStairUtils";
import {PATH_STRAIGHT_STAIR_IMAGE} from "../StraightStairConstants";

type Props = {
    straightStairV2: StraightStairV2FragmentFragment,
    onChange: (key: string, value: string) => void,
    toggleSurfaceTreatmentModal: () => void,
    isAsnEnabled: AsnEnabledFunc,
    surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[]
    stringerOptions: StringerMaterial[]
}

const SurfaceTreatmentSummary = ({surfaceTreatments}: { surfaceTreatments: StraightStairSurfaceTreatmentFragmentFragment[] }) =>
{
    const getText = useCallback((isPowderCoating: boolean) =>
    {
        const count = surfaceTreatments.filter((e) => isPowderCoating ? e.isCheckedPC : e.isCheckedPl)
            .length

        const text = isPowderCoating ? "powder coated" : "galvanized"

        if (count === 0)
        {
            return `None ${text}.`
        }

        if (count === surfaceTreatments.length)
        {
            return `All parts ${text}.`
        }

        return `${count} parts ${text}.`
    }, [surfaceTreatments])

    const textStyle = {fontSize: "18px"}
    return <>
        <Alert color="warning" style={{marginTop: "8px", maxWidth: "300px"}}>
            <li style={textStyle}>{getText(false)}</li>
            <li style={textStyle}>{getText(true)}</li>
        </Alert>
    </>
}

const StairTabPane = memo(({
                               straightStairV2, onChange,
                               toggleSurfaceTreatmentModal, isAsnEnabled, surfaceTreatments,
                               stringerOptions
                           }: Props) =>
{
    const stairFormDefinition = useMemo(() =>
    {
        const asnEnable = isAsnEnabled(straightStairV2.stepType)
        return [
            {
                name: "stepType",
                type: "select",
                options: enumToOptions(StepType)
            },
            {
                name: "maxLoad",
                type: "select",
                options: maxLoadOptions
            },
            {
                name: "deflection",
                type: "select",
                options: deflectionOptions
            },
            {
                name: "preferedMaterial",
                type: "select",
                options: enumToOptions(PreferedMaterialType, (value) => `${value.toUpperCase()}`),
            },
            {
                name: "stringerMaterial",
                type: "select",
                disabled: straightStairV2.isMinStringerMaterial,
                options: stringerOptions.map((e) => ({id: e, name: `${e.toUpperCase()}`})),
            },
            {name: "blank", type: "hidden", hideField: (_) => true},
            {name: "description", type: "textarea"},
            {
                name: "isMinStringerMaterial",
                label: "Auto min set stringer material",
                type: "checkbox",
            },
            {name: "blank", type: "hidden", hideField: (_) => true},
            {name: "kickplate", type: "checkbox"},
            {name: "close", type: "checkbox", disabled: asnEnable},
            {name: "asn",label:'ASN', type: "checkbox", disabled: !asnEnable},
        ]
    }, [straightStairV2 , isAsnEnabled, stringerOptions])

    const railingFormDefinition = useMemo(() =>
    {
        return [
            {
                name: "railingStandard",
                type: "select",
                options: enumToOptions(RailingStandardType)
            },
            {
                name: "railingType",
                type: "select",
                options: enumToOptions(RailingType, (value) => convertRailingTypeToLabel(value)),
                hideField: () => straightStairV2.railingStandard !== RailingStandardType.Childsafe
            },
        ]
    }, [straightStairV2.railingStandard])

    const getPreferedMaterialImage = (preferedMaterial: PreferedMaterialType) =>
    {
        let fileName: string
        switch (preferedMaterial)
        {
            case PreferedMaterialType.Pls:
                fileName = 'ParPLS'
                break;
            case PreferedMaterialType.Upe:
                fileName = 'ParUPE'
                break;
        }
        return `${PATH_STRAIGHT_STAIR_IMAGE}/ADJ/${fileName}.JPG`
    }

    const getStepImage = (stair: StraightStairV2FragmentFragment) =>
    {
        let mat: string
        switch (stair.stepType)
        {
            case StepType.PlateForConcrete:
                mat = "B"
                break;
            case StepType.PrepForCarpet:
            case StepType.PrepForTerrazzo:
            case StepType.PrepForTiles:
            case StepType.PrepForWood:
                mat = "P"
                break;
            case StepType.StandardGrate:
                mat = "G"
                break;
            case StepType.Tearplate:
                mat = "T"
                break;
            case StepType.TightMeshGrate:
                mat = "GT"
                break;
        }

        const asn = stair.asn ? 1 : 0
        const kick = stair.kickplate ? 1 : 0
        return `${PATH_STRAIGHT_STAIR_IMAGE}/Steps/${mat}-${kick}-${asn}.jpg`
    }

    return <>
        <Row>
            <Col sm={9} xl={5}>
                <NewFormRenderer
                    columns={3}
                    formDefinition={stairFormDefinition}
                    object={straightStairV2}
                    onChange={onChange}/>
                <Button color={"primary"} style={{marginTop: "16px"}}
                        onClick={toggleSurfaceTreatmentModal}>
                    Surface treatment
                </Button>
                <SurfaceTreatmentSummary surfaceTreatments={surfaceTreatments}/>
            </Col>

            <Col sm={3} xl={6}>
                <Row>
                    <Col xl={6} style={{marginBottom: "8px"}}>
                        <p style={{margin: 0}}>Step</p>
                        <img src={getStepImage(straightStairV2)} alt="step"
                             style={{width: "100%"}}/>
                    </Col>

                    <Col xl={6}>
                        <p style={{margin: 0}}>Prefered Material</p>
                        <img src={getPreferedMaterialImage(straightStairV2.preferedMaterial)} alt="preferedMaterial"
                             style={{width: "100%"}}/>
                    </Col>
                </Row>
            </Col>

        </Row>
        <Row style={{marginTop: "24px", marginBottom: '24px'}}>
            <Col>
                <h3>Railing</h3>
                <NewFormRenderer
                    columns={3}
                    formDefinition={railingFormDefinition}
                    object={straightStairV2}
                    onChange={onChange}/>
            </Col>
        </Row>
    </>
})

export default StairTabPane
