import React from 'react';

export default () =>
{
    return <svg version="1.1" id="layer1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" x="0px" y="0px"
                viewBox="10 10 20 20" >
<g>
	<g>
		<g>
			<g>
				<path fill="#231815" d="M27,26.4c-0.4,0-0.7-0.1-1-0.4l-1.6-1.4H15c-1.9,0-3.5-1.6-3.5-3.5v-4c0-1.9,1.6-3.5,3.5-3.5h10
					c1.9,0,3.5,1.6,3.5,3.5v7.8C28.5,25.7,27.8,26.4,27,26.4z M15,14.6c-1.4,0-2.5,1.1-2.5,2.5v4c0,1.4,1.1,2.5,2.5,2.5h9.6
					c0.1,0,0.2,0,0.3,0.1l1.8,1.6c0.3,0.3,0.8,0.1,0.8-0.4v-7.8c0-1.4-1.1-2.5-2.5-2.5H15z"/>
			</g>
            <g>
				<path fill="#231815" d="M16,20c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0
					c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5C16.4,19.9,16.2,20,16,20z"/>
			</g>
            <g>
				<path fill="#231815" d="M20,20c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0
					c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5C20.4,19.9,20.2,20,20,20z"/>
			</g>
            <g>
				<path fill="#231815" d="M24,20c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.2,0.1-0.4,0.2-0.5c0.3-0.3,0.8-0.3,1.1,0
					c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2-0.1,0.4-0.2,0.5C24.4,19.9,24.2,20,24,20z"/>
			</g>
		</g>
	</g>
</g>
</svg>

}

