import {
    Axis,
    Color3,
    CreateLines,
    CreatePlane,
    DynamicTexture,
    Mesh,
    Quaternion,
    Space,
    StandardMaterial,
    Tools
} from "@babylonjs/core";
import {Vector3} from "@babylonjs/core/Maths/math.vector";
import {setPickedModuleData, State} from "./ModuleRampPage";
import {ModuleObject} from "./ShpaeObject";
import SceneManager, {SceneName} from "./SceneManager";

export const createAxis = (scene,size) => {
    const makeTextPlane = function(text, color, size) {
        const dynamicTexture = new DynamicTexture("DynamicTexture", 50, scene, true);
        dynamicTexture.hasAlpha = true;
        dynamicTexture.drawText(text, 5, 40, "bold 36px Arial", color , "transparent", true);
        const plane = CreatePlane("TextPlane", {size,}, scene);
        const material = new StandardMaterial(`TextPlaneMaterial-${text}`, scene);
        material.backFaceCulling = false;
        material.specularColor = new Color3(0, 0, 0);
        material.diffuseTexture = dynamicTexture;
        plane.material = material;
        return plane;
    };

    const axisX = CreateLines("axisX", {points:[
            Vector3.Zero(), new Vector3(size, 0, 0), new Vector3(size * 0.95, 0.05 * size, 0),
            new Vector3(size, 0, 0), new Vector3(size * 0.95, -0.05 * size, 0)
        ]}, scene);
    axisX.color = new Color3(1, 0, 0);
    const xChar = makeTextPlane("X", "red", size / 10);
    xChar.position = new Vector3(0.9 * size, -0.05 * size, 0);
    axisX.addChild(xChar);
    const axisY = CreateLines("axisY", {points:[
            Vector3.Zero(), new Vector3(0, size, 0), new Vector3( -0.05 * size, size * 0.95, 0),
            new Vector3(0, size, 0), new Vector3( 0.05 * size, size * 0.95, 0)
        ]}, scene);
    axisY.color = new Color3(0, 1, 0);
    const yChar = makeTextPlane("Y", "green", size / 10);
    yChar.position = new Vector3(0, 0.9 * size, -0.05 * size);
    axisY.addChild(yChar);
    const axisZ = CreateLines("axisZ", {points:[
            Vector3.Zero(), new Vector3(0, 0, size), new Vector3( 0 , -0.05 * size, size * 0.95),
            new Vector3(0, 0, size), new Vector3( 0, 0.05 * size, size * 0.95)
        ]}, scene);
    axisZ.color = new Color3(0, 0, 1);
    const zChar = makeTextPlane("Z", "blue", size / 10);
    zChar.position = new Vector3(0, 0.05 * size, 0.9 * size);
    axisZ.addChild(zChar);
    const threeAxis = new Mesh("axis");
    threeAxis.addChild(axisX);
    threeAxis.addChild(axisY);
    threeAxis.addChild(axisZ);
    return threeAxis;
}

export const createMeasurement = ( start:Vector3, end:Vector3, textSize:number, up:Vector3=Vector3.Up(), scene=SceneManager.getInstance()[SceneName.main]) => {
    const width = Vector3.Distance(start, end);
    const height = textSize*0.05;
    const widthPx = width*50;
    const heightPx = height*50;

    const root = new Mesh("measurement", scene);
    const linePane = CreatePlane(`measurement-line`, {width, height}, scene);
    linePane.position = new Vector3(0,height*0.6,0);
    root.addChild(linePane);

    const textureRuler = new DynamicTexture("dynamic-texture", {width:widthPx, height:heightPx}, scene);
    const rulerContext = textureRuler.getContext();
    const material = new StandardMaterial("material", scene);

    textureRuler.hasAlpha = true;
    material.diffuseTexture = textureRuler;
    material.useAlphaFromDiffuseTexture = true;
    linePane.material = material;

    const lineY = textSize/5;
    rulerContext.font = `${textSize}px Arial`;
    rulerContext.fillStyle = "black";
    rulerContext.lineWidth = textSize/10;
    rulerContext.beginPath();
    rulerContext.moveTo(0, heightPx);
    rulerContext.lineTo(0, heightPx-lineY*2);
    rulerContext.stroke();
    rulerContext.closePath();

    rulerContext.beginPath();
    rulerContext.moveTo(0, heightPx-lineY);
    rulerContext.lineTo(widthPx, heightPx-lineY);
    rulerContext.stroke();
    rulerContext.closePath();

    rulerContext.beginPath();
    rulerContext.moveTo(widthPx, heightPx);
    rulerContext.lineTo(widthPx, heightPx-lineY*2);
    rulerContext.stroke();
    rulerContext.closePath();
    textureRuler.update();

    const text = `${(Math.round(width * 10) / 100).toFixed(2)} m`;
    const textWidthPx = rulerContext.measureText(text).width;
    const textWidth = textWidthPx/50;

    const textPane = CreatePlane(`measurement-text`, {width:textWidth, height}, scene);
    textPane.position = new Vector3(0,height*0.6,0);
    root.addChild(textPane);

    const textureText = new DynamicTexture("dynamic-texture", {width:textWidthPx*1.1, height:heightPx}, scene);
    const textContext = textureText.getContext();
    const textMaterial = new StandardMaterial("material", scene);
    textureText.hasAlpha = true;
    textContext.font = `${textSize}px Arial`;
    textContext.fillStyle = "black";
    textContext.fillText(text, 0, heightPx - lineY*3 );
    textureText.update();
    textMaterial.diffuseTexture = textureText;
    textMaterial.useAlphaFromDiffuseTexture = true;
    textPane.material = textMaterial;

    root.position = Vector3.Center(start, end);
    const planeNormal = Vector3.Backward();
    const planeVector = new Vector3(-1,0,0);

    // rotate to face up
    const rotateAxis = Vector3.Cross(planeNormal, up);
    const degree = Tools.ToDegrees(Vector3.GetAngleBetweenVectors(planeNormal, up, rotateAxis));
    root.rotate(rotateAxis, Tools.ToRadians(degree), Space.WORLD);
    const qua = Quaternion.RotationAxis(rotateAxis, Tools.ToRadians(degree) );
    const planeVectorAfterRotate = planeVector.rotateByQuaternionToRef(qua, new Vector3())

    // rotate to same as start-end vector
    const vector = end.subtract(start);
    const rotateAxis2 = Vector3.Cross(vector, planeVector);
    const degree2 = Tools.ToDegrees(Vector3.GetAngleBetweenVectors(vector, planeVectorAfterRotate, rotateAxis2));
    root.rotate(up, Tools.ToRadians(degree2), Space.WORLD);
}

export const getAngleBetweenVectorsByAxisY = (v1:Vector3, v2:Vector3) => {
    const v1WithoutY = new Vector3(v1.x, 0, v1.z);
    const v2WithoutY = new Vector3(v2.x, 0, v2.z);
    return Vector3.GetAngleBetweenVectors(v1WithoutY, v2WithoutY, Axis.Y);
}

export const degreeToQuadrant = (degree:number) => {
    const positiveMod = ((degree % 360) + 360) % 360;
    return Math.floor(positiveMod / 90) + 1;
}

export const isNodesOnSameVector = (n1:Vector3, n2:Vector3, vector:Vector3) => {
    const distanceVec = n1.subtract(n2);
    if(Vector3.Distance(n1, n2)<0.1){
        return true;
    }

    let scale = 1;
    if(Math.abs(vector.x) > 0.01){
        scale = distanceVec.x/vector.x;
        if(Math.abs(vector.y*scale - distanceVec.y) < 0.1 && Math.abs(vector.z*scale - distanceVec.z) < 0.1){
            return true;
        }
    } else if(Math.abs(vector.y) > 0.01){
        if(Math.abs(distanceVec.x) > 0.01 ) {
            return false;
        }
        scale = distanceVec.y/vector.y;
        if(Math.abs(vector.z*scale - distanceVec.z) < 0.1){
            return true;
        }
    } else if(Math.abs(vector.z) > 0.01) {
        if(Math.abs(distanceVec.x) > 0.01 || Math.abs(distanceVec.y) > 0.01 ) {
            return false;
        }
        if(Math.abs(distanceVec.z) > 0.01) return true;
    }

    return false;
}

export const isNodesCanJointWithDegreeAnd2Length = (n1:Vector3, n2:Vector3, degree:number, length:number) => {
    if (length===0 || Vector3.Distance(n1, n2) < 0.1) return false;

    return Math.abs(Math.cos(Tools.ToRadians(degree/2)) * Math.abs(length) - Vector3.Distance(n1, n2)/2) < 0.02;
}

export const instanceOf = (test, typesArray:any[]) => {
    let isType = false;
    typesArray.forEach((T)=>{
        if(test instanceof T){
            isType = true;
        }
    });
    return isType;
}

export const camelCaseToNormalString = (camelString:string)=>{
    const result = camelString.replace(/([A-Z])/g, " $1");

    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const makeCurve = (degree, curveRadius, height=0) => {
    const path:Vector3[] = [];
    const rad = 180/Math.PI;
    for (let i =0; i < degree; i ++ ) {
        path.push( new Vector3(curveRadius * Math.sin(i/rad), i*height/degree, curveRadius * Math.cos(i/rad)) );
    }
    return path;
};

export const setPickedModule = (module:ModuleObject|null)=>{
    const stateInstance = State.getInstance();
    if(stateInstance.pickedModule){
        stateInstance.pickedModule.eventBoxes.forEach(eventBox=>{
            eventBox.outlineColor = new Color3(245/255, 159/255, 36/255);
            eventBox.isPickable = true;
            eventBox.renderOutline = false;
        });
        stateInstance.pickedModule.isPicked = false;
    }

    setPickedModuleData(module?.moduleTemplate);
    stateInstance.pickedModule = module;
}