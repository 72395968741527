import React, {Component} from 'react';
import {Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import FormRenderer, {Input} from "./FormRenderer";
import {graphql,withApollo} from '@apollo/client/react/hoc'
import searchArticleWithInventory from './graphql/searchArticleWithInventory.graphql';
import CreateArticleRowMutation from './graphql/CreateArticleRow.graphql';
import PropTypes from 'prop-types';
import Picker from "./Picker";
import GetStockArticle from "./graphql/GetStockArticle.graphql";
import StdQuery from "./StdQuery";
import DetailRenderer from "./DetailRenderer";

const articleLabel = (article)=>`${article.name} - ${article.artNo ?? ''}`;
const ArticleRowDef = [
    {
        name: "quantity",
        type: "number",
        required: true,
        min: 1,
        step:"any"
    }
];
const StockArticleRowDef = [
    {
        name: "quantity",
        type: "number",
        required: true,
        min: 1,
        step:"any"
    },
    {
        name: "discount",
        type: "number",
        min: 0,
        max: 100,
    }
];

class CreateArticleRow extends Component
{
    toggle()
    {
        this.setState({
            modal:!this.state.modal,
            isFromInventory:null,
            selectedArticleId:null,
        });
    }
    constructor(props)
    {
        super(props);
        this.state = {
            modal:false,
            createdArticle:null,
            isFromInventory:null,
            selectedArticleId:null,
            createAnother: false,
            uniqueId: Math.random(),
        };

        this.toggle = this.toggle.bind(this);
    }

    async save(form)
    {
        try
        {
            form.object.orderId = this.props.orderId;
            form.object.purchaseOrderId = this.props.purchaseOrderId;
            form.object.quoteId = this.props.quoteId;

            if(this.state.isFromInventory){
                form.object.stockArticleId = this.state.selectedArticleId;
            }else{
                form.object.articleId = this.state.selectedArticleId;
            }
            await this.props.createArticleRow({refetchQueries:["getOrder", "getPurchaseOrder", "quote"],
                variables:{articleRow:form.object}});
            this.toggle();

            if(this.state.createAnother){
                this.setState(
                    {uniqueId:Math.random()}, //get a new key to make Picker reload
                    ()=>{
                        this.toggle();
                    });
            }
        }
        catch(e)
        {
            //TODO:Show notification
            alert(e.message);
            console.error(e);
        }

    }

    setArticle(selected){
        this.setState({
            isFromInventory: selected.fromInventory,
            selectedArticleId: selected.value,
            article: selected
        });
    }

    modal()
    {
        return <Modal isOpen={this.state.modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>Find article</ModalHeader>
            <ModalBody>
                {this.state.isFromInventory===false &&
                    <div>
                        <p>Selected article: {this.state.article.label}</p>
                        <FormRenderer onSubmit={this.save.bind(this)} formDefinition={ArticleRowDef}/>
                    </div>
                }
                {this.state.isFromInventory===null &&
                <div key={this.state.uniqueId}>
                    <Picker query={searchArticleWithInventory}
                            mapFn={article=> ({
                                value:article.id,
                                label:articleLabel(article),
                                fromInventory:article.fromInventory
                            })}
                            onChange={selected=>this.setArticle(selected)} />
                </div>
                }
                {this.state.isFromInventory && <div style={{marginTop:"7px"}}>
                    <StdQuery query={GetStockArticle} variables={{id:this.state.selectedArticleId}} >
                        {data =>{
                            if(!data.stockArticle)
                            {
                                return "Failed to fetch this stock article :(";
                            }
                            else
                            {
                                const { artNo, price, internalPrice, internalCurrency, currency, quantity, reservedQuantitySum } = data.stockArticle;
                                let totalQuantity = quantity;
                                let availableQuantity = totalQuantity - reservedQuantitySum;
                                return <DetailRenderer hideUpdateInfo columns={2} object={{
                                    artNo, totalQuantity, reservedQuantitySum, availableQuantity,
                                    inPrice: `${internalPrice} ${internalCurrency}`,
                                    sellingPrice: `${price} ${currency}`,
                                }} />
                            }
                        }}
                    </StdQuery>

                    <FormRenderer onSubmit={this.save.bind(this)} formDefinition={StockArticleRowDef}/>
                </div>}


            </ModalBody>
            <ModalFooter>
                <Col sm={8}>
                    <Input className='big-checkbox' type={'checkbox'} checked={this.state.createAnother}
                           onChange={e=>{this.setState({createAnother: e.target.checked}) }}/><Label>Pick Another</Label>
                </Col>
                <Col sm={3}>
                    <Button style={{"float":"right"}} color="secondary" onClick={this.toggle}>Cancel</Button>
                </Col>
            </ModalFooter>
        </Modal>
    }
    render()
    {
        return <>
            <Button color={"light"} className={"primary-button"} onClick={this.toggle}>Find Article</Button>
            {this.modal.bind(this)()}
        </>
    }
}
CreateArticleRow.propTypes = {
    orderId:PropTypes.string,
    purchaseOrderId:PropTypes.string,
    quoteId: PropTypes.string,
};
export default graphql(CreateArticleRowMutation, {name: "createArticleRow"})(withApollo(CreateArticleRow));
