import React, {useMemo, useState} from 'react';
import gql from "graphql-tag";
import {Link} from "react-router-dom";
import {useMutation} from "@apollo/client";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import FormRenderer from "../../common/FormRenderer";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {withRouter} from "react-router";
import {InventoryRoute} from "../InventoryRouters";
import PaginatedDataGrid from "../../common/data-grid/PaginatedDataGrid";
import {GetSuppliersV2Document} from "../../generated/inventoryGraphql";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";

const KEY_TABLE = "TABLE_INVENTORY_SUPPLIERS"

const createMutation = gql`
    mutation($supplier:SupplierInputType!)
    {
        createSupplier(supplier: $supplier)
        {
            id
        }
    }`

const GetTermsOfPayments = gql`
    query
    {
        termsOfPayments
        {
            value
            label
        }
    }
`
const GetTermsOfDeliveryQuery = gql`
    query
    {
        termsOfDelivery
        {
            value
            label
        }
    }
`
const GetModeOfDelivery = gql`
    query
    {
        modeOfDelivery
        {
            value
            label
        }
    }
`

export const SupplierFormDef = () => {
    return [
        {name: "name", required: true},
        {name: "street"},
        {name: "city"},
        {name: "postalCode"},
        {name: "country"},
        {name: "phone"},
        {name: "organisationNumber"},
        {name: "VATNumber"},
        {
            name: "defaultTermsOfPayment", type: "select", query: GetTermsOfPayments,
            mapFn: e => ({id: e.value, name: e.label})
        },
        {
            name: "defaultTermsOfDelivery", type: "select", query: GetTermsOfDeliveryQuery,
            mapFn: e => ({id: e.value, name: e.label})
        },
        {
            name: "defaultModeOfDelivery", type: "select", query: GetModeOfDelivery,
            mapFn: e => ({id: e.value, name: e.label})
        },
        {name: "defaultSupplierRef"},
        {name: "currency", type: "currencyPicker"}
    ];
}


const InventorySupplierListPage = () => {
    const [open, setOpen] = useState(false);
    const [createSupplier] = useMutation(createMutation, {refetchQueries: ["getSuppliers"]});
    const toggle = () => setOpen(o => !o);

    const columns = useMemo(() =>
    {
        return [
            {
                field: 'name',
                width: 300,
                searchable: true,
                renderCell: (params) =>
                {
                    return <Link to={`${InventoryRoute.SUPPLIER_DETAIL}/${params.id}`}>{params.value}</Link>;
                }
            },

            ColumnCreatedAt(),
        ]
    }, [])

    return <div>
        <Modal isOpen={open} toggle={toggle} size={"lg"}>
            <ModalHeader toggle={toggle}>
                Create Supplier
            </ModalHeader>
            <ModalBody>
                <FormRenderer columns={2} onSubmit={async ({object}) => {
                    toggle();
                    try {
                        await createSupplier({variables: {supplier: object}});
                        NotificationPopup.success(`Created supplier ${object.name}`);
                        document.dispatchEvent(new Event(`refetch-data`));
                    } catch (e) {
                        NotificationPopup.error(`Failed to create supplier. ${e.message}`);
                    }
                }} formDefinition={SupplierFormDef()}/>
            </ModalBody>
        </Modal>
        <Button onClick={() => setOpen(true)}>Create Supplier</Button>
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
            }}
            query={GetSuppliersV2Document}/>
    </div>

}

export default withRouter(InventorySupplierListPage)