import ColumnId from "../common/data-grid/columns/ColumnId";
import React from "react";
import {Link} from "react-router-dom";
import PaginatedDataGrid from "../common/data-grid/PaginatedDataGrid";
import {GetPackagesDocument} from "../generated/graphql";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import PackageExpandedPanel, {calculatePackageExpandedHeight} from "./component/PackageExpandedPanel";
import ColumnPackageType from "../common/data-grid/columns/ColumnPackageType";

export const getPackageColumns = () =>
{
    return [
        {
            field: "mark",
            headerName: "Shipping mark",
            searchable: true,
            renderCell: (params) =>
            {
                return <Link to={`/package-detail/${params.id}`}>{params.value ?? "-"}</Link>
            }
        },
        {
            field: "shipment",
            headerName: "Shipment",
            renderCell: (params) =>
            {
                const shipment = params.value
                return shipment ? <Link to={`/shipment-detail/${shipment.id}`}>{shipment.number}</Link>
                    : "-"
            }
        },
        ColumnPackageType(),
        {field: 'quantity', type: 'number', width: 100},
        {field: 'weight', type: 'number', width: 100},
        {field: 'length', type: 'number', width: 100},
        {field: 'width', type: 'number', width: 100},
        {field: 'height', type: 'number', width: 100},
        {field: "description"},
        ColumnDate("createdAt", "Created at"),
        ColumnId(),
    ]
}

const columnVisibilityModel = {
    columnVisibilityModel: {
        id: false
    }
}

const sortModel = {
    sortModel:
        [{field: 'createdAt', sort: 'desc'}]
}

const KEY_TABLE = "PACKAGE_LIST"

export default () =>
{
    return <div>
        <PaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: getPackageColumns(),
                initState: {
                    sorting: sortModel,
                    columns: columnVisibilityModel
                }
            }}
            query={GetPackagesDocument}
            getDetailPanelContent={({row}) => <PackageExpandedPanel packageInfo={row}/>}
            getDetailPanelHeight={calculatePackageExpandedHeight}
        />
    </div>
}
