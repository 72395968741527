import React from 'react';
import {Card, CardBody, CardHeader, Row, Col, Alert, Modal, ModalHeader, ModalBody, Table} from 'reactstrap';
import {FORMAT_DATE, FORMAT_TIME, formatToSwedishTime} from "../../UTIL";


export default (props) =>
{
    const {result, toggle, isOpen} = props

    return <Modal toggle={toggle} isOpen={isOpen} size="xl">
        <ModalHeader toggle={toggle}>{result.errors ? "Save Failed" : "Save Success"}</ModalHeader>
        <ModalBody>
            {result.errors?.map((error) => (
                <Alert color="danger" key={error.code}>{error.description}</Alert>
            ))}

            {result.consignments?.map((consignment) => (
                <Card key={consignment.attributes.consignmentId} className="mb-3">
                    <CardHeader>
                        <h3>Order No: {consignment.attributes.orderNo}</h3>
                        <h3>Consignment No: {consignment.attributes.consignmentNo}</h3>
                        <h3>Consignment ID: {consignment.attributes.consignmentId}</h3>
                    </CardHeader>
                    <CardBody>
                        <h5>Parts</h5>
                        <Row>
                            {consignment.Part.map((part, index) => (
                                <Col key={index}>
                                    <h6>{part.attributes.role}</h6>
                                    <PartTable part={part} index={index}/>
                                </Col>
                            ))}
                        </Row>

                        <h5>Goods Items:</h5>

                        <GoodsItemTable goodsItem={consignment.GoodsItem}/>

                        <div>
                            <h5>Transport Product</h5>
                            <p>Code: {consignment.TransportProduct.code}</p>

                            <h6>TransportProduct - AddService:</h6>
                            <ul>
                                {consignment.TransportProduct.AddService.filter(Boolean).map((service, i) =>
                                    <li key={i}>{service.attributes.value}</li>
                                )}
                            </ul>

                            <p>Pickup Date: {formatToSwedishTime(consignment.TransportProduct.Pickup.date, FORMAT_DATE)}</p>
                            <p>Pickup earliest: {formatToSwedishTime(consignment.TransportProduct.Pickup.earliest, FORMAT_TIME)}</p>
                            <p>Pickup latest: {formatToSwedishTime(consignment.TransportProduct.Pickup.latest, FORMAT_TIME)}</p>
                        </div>
                    </CardBody>
                </Card>
            ))}
        </ModalBody>
    </Modal>
}

const GoodsItemTable = (props) =>
{
    const {goodsItem} = props
    return <Table>
        <thead>
        <tr>
            <th>No of Packages</th>
            <th>Weight(kg)</th>
            <th>Volume(m3)</th>
            <th>Length(cm)</th>
            <th>Width</th>
            <th>Height</th>
            <th>Package Type</th>
            <th>Tag</th>
            <th>Description</th>

        </tr>
        </thead>
        <tbody>
        {goodsItem.map((item, i) => (
            <tr key={i}>
                <td>{item.noOfPackages}</td>
                <td>{item.weight}</td>
                <td>{item.volume}</td>
                <td>{item.length}</td>
                <td>{item.width}</td>
                <td>{item.height}</td>
                <td>{item.packageType}</td>
                <td>{item.tag}</td>
                <td>{item.description}</td>
            </tr>
        ))}
        </tbody>
    </Table>

}

const PartTable = (props) =>
{
    const {part, index} = props
    return <Table striped key={index}>
        <thead>
        <tr>
            <th>Type</th>
            <th>Content</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Name</td>
            <td>{part.Address.name}</td>
        </tr>
        <tr>
            <td>Address</td>
            <td>{part.Address.address}</td>
        </tr>
        <tr>
            <td>Post Code</td>
            <td>{part.Address.postcode}</td>
        </tr>
        <tr>
            <td>City</td>
            <td>{part.Address.city}</td>
        </tr>
        <tr>
            <td>Country Code</td>
            <td>{part.Address.countrycode}</td>
        </tr>
        <tr>
            <td>Contact Person</td>
            <td>{part.Communication?.contactPerson}</td>
        </tr>
        <tr>
            <td>Phone</td>
            <td>{part.Communication?.phone}</td>
        </tr>
        <tr>
            <td>Mobile</td>
            <td>{part.Communication?.mobile?.$value}</td>
        </tr>
        <tr>
            <td>Email</td>
            <td>{part.Communication?.email?.$value}</td>
        </tr>
        <tr>
            <td>Reference</td>
            <td>{part.Reference?.reference}</td>
        </tr>
        </tbody>
    </Table>
}