import {Link} from "react-router-dom";
import React from "react";

const ColumnContactName = () =>
{
    return {
        field: 'name',
        headerName: 'Name',
        searchable: true,
        renderCell: (params) =>
        {
            return <Link to={`/contactDetail/${params.id}`}>{params.value}</Link>
        }
    }
}
export default ColumnContactName