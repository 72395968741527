import React, {useMemo} from "react";
import {Col} from "reactstrap";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import ColumnId from "../../common/data-grid/columns/ColumnId";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";

const KEY_TABLE = "TABLE_INVENTORY_ARTICLE_RESERVE_QUANTITIES"

export default ({data}) =>
{
    const columns = useMemo(() =>
    {
        return [
            {field: "orderNumber"},
            {field: "quantity", type: "number"},
            ColumnDate("preferredDeliveryDate", "Preferred delivery date"),
            ColumnId()
        ]
    }, [])

    return <Col lg={6}>
        <NonPaginatedDataGrid
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
                pageSize: 20,
            }}
            data={data}/>
    </Col>
}
