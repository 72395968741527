import React from 'react';

export default () =>
{
    return <svg width="24px" height="24px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" fill="white" fill-opacity="0.01"/>
        <path d="M13 30L25 18L37 30" stroke="#FFA500" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

}

