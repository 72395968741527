import React, { Component  } from 'react';
import { graphql,withApollo } from '@apollo/client/react/hoc';
import gql from "graphql-tag";
import GenericWorkflowForm from "../common/GenericWorkflowForm";
import ModalForm from "../common/ModalForm";
import FormRenderer from "../common/FormRenderer";
import CreateTaskMutation from './graphql/CreateNewTask.graphql';
import ClaimTaskMutation from './graphql/ClaimTask.graphql';
import {camundaDateFix,  } from "../UTIL";
import Auth from "../Auth";
import { Badge} from "reactstrap";
import StdQuery from "../common/StdQuery";
import GetTaskQuery from './graphql/GetTask.graphql'
import { flowRight as compose, get } from 'lodash';
import query from './graphql/GetTasks.graphql';
import EventEmitter from '../common/EventEmitter';
import logger from '../Logger';
import 'moment/locale/sv';
import 'moment/locale/nb';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { Col, Row } from "reactstrap";
import P from '../common/Paragraph';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../common/data-grid/columns/ColumnCreatedAt";
import ColumnId from "../common/data-grid/columns/ColumnId";
import {Box} from "@mui/material";
import {FilterOperatorStringWithEmpty} from "../common/data-grid/FilterOperatorDefinitions";

const TABLE_KEY = "TABLE_TASK"

const filterAssignedToMe = {field: "assignee", operator: "equals", value: Auth.getUsername()}

const OrderDescription = ({name,task,onClick}) =>
{
    if (!task.order) {
        return null;
    }

    return <div>
        <Row>
            <Col>
                <P style={{ fontSize: "1em" }}>
                    {task.assignee !== Auth.getUsername() && name}
                    {task.assignee === Auth.getUsername() &&
                        <span onClick={() => onClick(task)} className="link">
                            {_.truncate(name, { length: 50 })}
                        </span>}
                </P>
            </Col>
        </Row>
        <Row>
            <Col>
                <P>
                    <strong>Order number:</strong> {task.order.tenantId} - {task.order.number}
                </P>
            </Col>
        </Row>
    </div>
}

const FollowupDescription = ({name,task,onClick})=>
{
    return <div>
        <Row>
            <Col>
                <P style={{ fontSize: "1em" }}>
                        <span onClick={() => onClick()} className="link">
                            {task?.variables?.subject ?? name ?? ""}
                        </span>
                </P>
            </Col>
        </Row>
        <Row>
            <Col>
                <P>
                    <strong>Quote number:</strong> {task?.quote?.number}
                </P>
            </Col>
        </Row>
    </div>
}

const GenericTaskDescription = ({name,task,onClick})=>
{
    return <div>
        <Row>
            <Col >
                <P>
                    <span
                        style={{ fontSize: "1em" }}
                        className="link"
                        onClick={() => onClick()}>{name}
                    </span>
                </P>
            </Col>
        </Row>
        <Row>
            <Col>
                <P>
                    {!task.processDefinitionName && task.variables.quoteId && task.quote &&
                    <React.Fragment><strong>Quote number:</strong> {task.quote.number}</React.Fragment>
                    }
                    {!task.processDefinitionName && task.variables.orderId && task.order &&
                    <React.Fragment><strong>Order number:</strong> {task.order.number}</React.Fragment>
                    }
                    {!task.processDefinitionName && task.variables.companyId && task.company &&
                    <React.Fragment><strong>Company:</strong> {task.company.name}</React.Fragment>
                    }
                </P>
            </Col>
        </Row>
    </div>
}

class TaskListPage extends Component {

    constructor() {
        super();
        this.state = {
            editing: false,
            currentTask: null,
            filtered: [{ id: "assignee", value: Auth.getUsername() }],
            newTasks: []
        };
        this.toggle = this.toggle.bind(this);
        this.createNewTask = this.createNewTask.bind(this);
        this.claimTask = this.claimTask.bind(this);
    }

    componentDidMount() {
        this.clearNewTasks();
    }
    componentWillUnmount() {
        //clear the tasks again to make sure that any new tasks created have been seen.
        this.clearNewTasks();
    }

    async clearNewTasks() {
        const res = await this.props.client.mutate(
            {
                mutation: gql`mutation { clearNewTasks }`,
            });
        if(res && res.data) {
            this.setState({ newTasks: res.data.clearNewTasks });
            EventEmitter.emit(`clearNewTasks`);
        }
    }

    toggle() {
        this.setState({ editing: !this.state.editing, currentTask: null });
    }

    async createNewTask(task, toggle) {
        toggle();
        const { name, due, description, orderId, quoteId, companyId } = task;
        await this.props.createTask({
            variables: {
                task:
                {
                    name, description, due: camundaDateFix(due),
                    variables:
                    {
                        orderId:
                        {
                            value: orderId,
                            type: "Integer"
                        },
                        quoteId:
                        {
                            value: quoteId,
                            type: "Integer"
                        },
                        companyId:
                        {
                            value: companyId,
                            type: "Integer"
                        }
                    }
                }
            },
            refetchQueries: ["AllTasks"]
        });
    }

    async claimTask(task) {
        if (window.confirm(`Are you sure you wish to assign the task: "${task.name}" to yourself?`)) {
            await this.props.claimTask({
                variables: {
                    id: task.id,
                },
                refetchQueries: ["AllTasks"]
            });
            this.setState({ editing: true, currentTask: task });
        }

    }

    mainRender(tasks) {
        const task = this.state.currentTask;
        return <div>
            <ModalForm title='Create new task' activationButtonText='Create new task'>
                {toggle =>
                    <FormRenderer formDefinition={[
                        { name: "name", required: true },
                        { name: "description", type: "textarea" },
                        { name: "due", label: "Due Date", type: "datetime", defaultValue: new Date() },
                        { type: "infotext", key: "infotext1", value: "Please select only 1 of the optional values below" },
                        {
                            name: 'companyId', type: "search",
                            hint: <p>Enter company name</p>,
                            filter: obj => obj.type === "company",
                        },
                        {
                            name: 'quoteId', type: "search",
                            hint: <p>Enter quote number</p>,
                            filter: obj => obj.type === "quote",
                        },
                        {
                            name: 'orderId', type: "search",
                            hint: <p>Enter order number</p>,
                            filter: obj => obj.type === "order",
                        }
                    ]}
                    onSubmit={form => this.createNewTask(form.object, toggle)} />
                }
            </ModalForm>
            <br />
            {task && <StdQuery noLoadingRender query={GetTaskQuery} variables={{ id: get(task, "id") }}>
                {data => {
                    return <GenericWorkflowForm isOpen={this.state.editing} task={data.task} toggle={this.toggle} />
                }}
            </StdQuery>}
            <Box
                sx={{
                    width: '100%',
                    '& .UploadCustomerDrawings': {
                        backgroundColor: '#E6E6FA',
                    },
                    '& .ProvideMoreInformation': {
                        backgroundColor: '#FFC0CB',
                    },
                    '& .ReviewMoreInformation': {
                        backgroundColor: '#F0FFF0',
                    },
                    '& .ReviseDrawing': {
                        backgroundColor: '#FAFAD2',
                    },
                    '& .SendCustomerDrawing': {
                        backgroundColor: '#F5DEB3',
                    },
                    '& .CustomerResponse': {
                        backgroundColor: '#FFE4E1',
                    },
                    '& .UploadProductionDrawing': {
                        backgroundColor: '#54FF9F',
                    },
                    '& .ReviewDrawings': {
                        backgroundColor: '#FF82AB',
                    },
                }}>
                <NonPaginatedDataGrid
                    definition={{
                        tableKey: TABLE_KEY,
                        columns: [
                            {
                                field: "assignee",
                                customFilterOperators: FilterOperatorStringWithEmpty,
                                renderCell: (params) => {
                                    const value = params.value
                                    const original = params.row
                                    return <>
                                        {!value && <span className='link' onClick={() => this.claimTask(original)}>
                                    Claim task
                                </span>}
                                        {original.assignee && value}
                                        &nbsp;&nbsp;
                                        {this.state.newTasks.indexOf(original.id) !== -1 &&
                                            <Badge pill style={{fontSize: "0.9em"}} color="primary">New</Badge>}
                                    </>

                                }
                            },
                            {
                                field: "name", headerName: "Description", width: 300,
                                renderCell: (params) => {
                                    const original = params.row
                                    const value = params.value

                                    const processDefinitionName = original.processDefinitionName

                                    if (processDefinitionName === "Order" || processDefinitionName === "Draw job" ||
                                        processDefinitionName === "ESPR Purchase Order" || processDefinitionName === "Purchase Order Workflow") {
                                        return <OrderDescription
                                            onClick={() => this.setState({editing: true, currentTask: original})}
                                            name={value}
                                            task={original}
                                        />
                                    } else if (processDefinitionName === "QuoteFollowup") {
                                        return <FollowupDescription task={original} onClick={() =>
                                            this.setState({editing: true, currentTask: original})}
                                                                    name={value}/>
                                    } else {
                                        return <GenericTaskDescription onClick={() =>
                                            this.setState({editing: true, currentTask: original})} name={value}
                                                                       task={original}/>
                                    }
                                }
                            },
                            {
                                field: 'company.name',
                                headerName: 'Company name',
                                filterable: false,
                                valueGetter: ({row}) => {
                                    const d = row
                                    if (d.order && d.order.company) {
                                        return d.order.company.name;
                                    } else if (d.quote && d.quote.company) {
                                        return d.quote.company.name;
                                    } else if (d.company) {
                                        return d.company.name;
                                    }
                                },
                            },
                            {
                                field: 'contacts',
                                filterable: false,
                                sortable: false,
                                valueGetter: ({row}) => {
                                    const d = row
                                    if (d.order && d.order.contact) {
                                        return {id: d.order.contact.id, name: d.order.contact.name};
                                    }
                                    if (d.quote && d.quote.contact) {
                                        return {id: d.quote.contact.id, name: d.quote.contact.name};
                                    }
                                    if (d.purchaseOrder?.order?.contact) {
                                        return {
                                            id: d.purchaseOrder.order.contact.id,
                                            name: d.purchaseOrder.order.contact.name
                                        };
                                    }
                                    return null
                                },
                                renderCell: (params) => {
                                    const value = params.value
                                    if (value) {
                                        return <div key={value.id}><Link
                                            to={`/contactDetail/${value.id}`}>{value.name}</Link></div>
                                    }
                                    return null
                                }
                            },
                            {
                                field: 'project.name',
                                headerName: 'Project name',
                                filterable: false,
                                sortable: false,
                                valueGetter: ({row}) => {
                                    const d = row
                                    if (d.order && d.order.project) {
                                        return d.order.project.name;
                                    } else if (d.quote && d.quote.project) {
                                        return d.quote.project.name;
                                    }
                                },
                                renderCell: (params) => {
                                    const original = params.row
                                    if (original.order && original.order.project) {
                                        return <React.Fragment>{original.order.project.name}</React.Fragment>
                                    } else if (original.quote && original.quote.project) {
                                        return <React.Fragment>{original.quote.project.name}</React.Fragment>
                                    }
                                    return null;
                                }
                            },
                            ColumnDate('due', 'Due date'),
                            {
                                field: 'processDefinitionName',
                                headerName: "Name of workflow",
                            },
                            ColumnCreatedAt(),
                            ColumnId(),
                        ],
                        initState: {
                            sorting: {sortModel: [{field: "due", sort: "desc"}]},
                            filter: {filterModel: {items: [filterAssignedToMe]}}
                        },
                        pageSize: 20,
                        buttons: [{
                            name: 'Assigned to me',
                            filters: {items: [filterAssignedToMe]}
                        },
                            {
                                name: 'Pooled tasks',
                                filters: {items: [{field: "assignee", operator: "isEmpty"}]}
                            }
                        ]
                    }}
                    data={tasks}
                    getCellClassName={(params) => {
                        const original = params.row
                        if (original && original.activityIds && original.activityIds.length > 0 && original.processDefinitionKey && original.processDefinitionKey === 'DrawJob') {
                            return original.activityIds[0]
                        }
                    }}
                />
            </Box>
        </div>
    }


    render() {
        const { loading, error, tasks } = this.props.data;
        if (loading) {
            return null;
        }
        else if (error) {
            if (error.networkError && error.networkError.statusCode !== 401) {
                logger.error(`Failed to fetch tasks ${error.message}`, { error });
            }
            return <p>Something went wrong when fetching tasks.</p>;
        }
        else if (tasks) {
            return this.mainRender(tasks);
        }


    }
}



export default compose(
    graphql(ClaimTaskMutation, { name: "claimTask" }),
    graphql(query),
    graphql(CreateTaskMutation, { name: "createTask" }))(withApollo(TaskListPage));
