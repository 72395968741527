import {DoubleRailingAndExtendedEnabledFunc, StraightStairHookProps} from "../StraightStairV2Types";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CopyStraightStairSegmentMutation,
    CreateStraightStairSegmentMutation, PlatformType,
    StepType,
    StraightStairSegmentFragmentFragment, useCalculateNumberOfStepsLazyQuery,
    useCalculateSegmentGeometryLazyQuery,
    useCopyStraightStairSegmentMutation,
    useCreateStraightStairSegmentMutation,
    useDeleteStraightStairSegmentMutation
} from "../../generated/graphql";
import {useCallback, useMemo} from "react";
import {calculateT1Width, getRecommendedPlatformRailingLength, getSegmentId} from "../StraightStairUtils";
import {STRAIGHT_STAIR_TAB_STAIR} from "../StraightStairConstants";
import {getStringerMaterialOptions} from "../StraightStairOptions";
import {debounce} from "lodash";

type Props = StraightStairHookProps & {
    updateActiveTab:  (tab: string) => void
}

const useSegmentHook = (props: Props) =>
{
    const {straightStairV2, setStraightStairV2, updateActiveTab} = props;
    const [calculateSegmentGeometryQuery] = useCalculateSegmentGeometryLazyQuery()

    const {
        executeMutation: createSegmentMutation,
        loading: createSegmentLoading
    } = useMutationHandler(useCreateStraightStairSegmentMutation)

    const {
        executeMutation: deleteSegmentMutation,
        loading: deleteSegmentLoading
    } = useMutationHandler(useDeleteStraightStairSegmentMutation)

    const {
        executeMutation: copySegmentMutation,
        loading: copySegmentLoading
    } = useMutationHandler(useCopyStraightStairSegmentMutation)

    const [calculateNumberOfStepsQuery, {loading: calculateNumberOfStepsLoading}] = useCalculateNumberOfStepsLazyQuery()

    const setStraightStairSegments = useCallback((onUpdate: (newSegments: StraightStairSegmentFragmentFragment[]) => StraightStairSegmentFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, segments: onUpdate([...prevState.segments])}
        })
    }, [setStraightStairV2])

    const onAddSegmentSuccess = useCallback((data: CreateStraightStairSegmentMutation | null) =>
    {
        const segment = data?.createStraightStairSegment
        if (segment)
        {
            setStraightStairSegments((newSegments) =>
            {
                newSegments.push(segment)
                return newSegments
            })

            updateActiveTab(getSegmentId(segment.id))
        }
    }, [setStraightStairSegments, updateActiveTab])

    const addSegment = useCallback(async () =>
    {
        if (!straightStairV2)
        {
            return
        }

        await createSegmentMutation({variables: {straightStairV2Id: straightStairV2.id}},
            {onSuccess: onAddSegmentSuccess})
    }, [straightStairV2, createSegmentMutation, onAddSegmentSuccess])

    const onDeleteSegmentSuccess = useCallback((id: string) =>
    {
        setStraightStairSegments((newSegments) =>
        {
            return newSegments.filter((e) => e.id !== id)
        })

        updateActiveTab(STRAIGHT_STAIR_TAB_STAIR)
    }, [setStraightStairSegments, updateActiveTab])

    const deleteSegment = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }
        await deleteSegmentMutation({variables: {id: id}},
            {onSuccess: () => onDeleteSegmentSuccess(id)})
    }, [deleteSegmentMutation, onDeleteSegmentSuccess, straightStairV2])

    const onCopySegmentSuccess = useCallback((data: CopyStraightStairSegmentMutation | null) =>
    {
        const segment = data?.copyStraightStairSegment
        if (segment)
        {
            setStraightStairSegments((newSegments) =>
            {
                newSegments.push(segment)
                return newSegments
            })

            updateActiveTab(getSegmentId(segment.id))
        }
    }, [setStraightStairSegments, updateActiveTab])

    const copySegment = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }

        await copySegmentMutation({variables: {id: id}},
            {onSuccess: onCopySegmentSuccess})
    }, [straightStairV2, copySegmentMutation, onCopySegmentSuccess])

    const updateSegmentGeometry = useCallback(async (segment: {
        id: string,
        additionalStep: boolean,
        numberOfSteps: number,
        overlap: number,
        stairHeight: number,
        stepDepth: number,
        stepWidth: number
    }) =>
    {
        const {additionalStep, numberOfSteps, overlap, stairHeight, stepDepth, stepWidth} = segment
        if (!numberOfSteps || !overlap || !stairHeight || !stepDepth || !stepWidth)
        {
            return
        }

        const response = await calculateSegmentGeometryQuery({
            variables: {
                segment: {additionalStep, numberOfSteps, overlap, stairHeight, stepDepth, stepWidth}
            }
        })

        if (!response.data?.calculateSegmentGeometry)
        {
            return
        }

        setStraightStairSegments((newSegments) =>
        {
            const index = newSegments.findIndex(e => e.id === segment.id);
            if (index === -1)
            {
                return newSegments;
            }

            const updatedSegment = {...newSegments[index]};
            updatedSegment.geometry = response.data?.calculateSegmentGeometry
            newSegments[index] = updatedSegment;
            return newSegments;
        })
    }, [calculateSegmentGeometryQuery, setStraightStairSegments])

    const debounceUpdateSegmentGeometry = useMemo(() => debounce(updateSegmentGeometry, 500), [updateSegmentGeometry]);

    const isDoubleRailingAndExtendedEnabled: DoubleRailingAndExtendedEnabledFunc = useCallback((options) =>
    {
        const {railingSide, wallHandrail, extraHandrail} = options
        if (railingSide === 0 && wallHandrail === 0)
        {
            return false
        }
        if (railingSide > 0 && wallHandrail === 0 && !extraHandrail)
        {
            return false
        }
        return true
    }, [])

    const updateSegment = useCallback((id: string, key: string, value: string | number) =>
    {
        if (!straightStairV2)
        {
            return
        }

        setStraightStairSegments((newSegments) =>
        {
            const index = newSegments.findIndex(e => e.id === id);
            if (index === -1)
            {
                return newSegments;
            }

            const updatedSegment = {...newSegments[index]};
            updatedSegment[key] = value;
            if (key === "platformIsMinFrameMaterial" || key === "platformMaterialType")
            {
                updatedSegment.platformFrameMaterial = getStringerMaterialOptions(updatedSegment.platformMaterialType)[0]
            }
            if (key === "railingSide")
            {
                if (value === 0)
                {
                    updatedSegment.extraHandrail = false
                }
            }
            if (key === "platformIntegrated")
            {
                if (updatedSegment.platformLength > 2000)
                {
                    updatedSegment.platformLength = 2000
                }
                updatedSegment.platformWidth = updatedSegment.stepWidth

                switch (straightStairV2.stepType)
                {
                    case StepType.StandardGrate:
                        if (updatedSegment.platformWidth < 1190)
                        {
                            updatedSegment.platformType = PlatformType.StandardGrate30
                        } else
                        {
                            updatedSegment.platformType = PlatformType.StandardGrate40
                        }
                        break;
                    case StepType.TightMeshGrate:
                        updatedSegment.platformType = PlatformType.StandardGrate40
                        break;
                    case StepType.PlateForConcrete:
                        updatedSegment.platformType = PlatformType.TideMeshGrate
                        break;
                    case StepType.PrepForCarpet:
                        updatedSegment.platformType = PlatformType.PlateForConcrete
                        break;
                    case StepType.PrepForWood:
                        updatedSegment.platformType = PlatformType.PrepForCarpet
                        break;
                    case StepType.PrepForTiles:
                        updatedSegment.platformType = PlatformType.PrepForWood
                        break;
                    case StepType.PrepForTerrazzo:
                        updatedSegment.platformType = PlatformType.PrepForTiles
                        break;
                    case StepType.Tearplate:
                        updatedSegment.platformType = PlatformType.PrepForTerrazzo
                        break;

                }
                updatedSegment.platformMaxLoad = straightStairV2.maxLoad

                updatedSegment.platformMaterialType = straightStairV2.preferedMaterial
                updatedSegment.platformFrameMaterial = straightStairV2.stringerMaterial
                updatedSegment.platformSCP1 = "W1"
                updatedSegment.platformSCP2 = "W2"
            }

            const isDoubleRailingAndExtendedEnable = isDoubleRailingAndExtendedEnabled(updatedSegment)
            if (!isDoubleRailingAndExtendedEnable)
            {
                updatedSegment.doubleRailing = false
                updatedSegment.railingExtended = false
            }

            newSegments[index] = updatedSegment;

            const updateGeometryKeys = ["additionalStep", "numberOfSteps", "overlap", "stairHeight", "stepDepth", "stepWidth",]
            if (updateGeometryKeys.includes(key))
            {
                debounceUpdateSegmentGeometry(updatedSegment)
            }

            return newSegments;
        })
    }, [debounceUpdateSegmentGeometry, isDoubleRailingAndExtendedEnabled, setStraightStairSegments, straightStairV2])

    const onPlatformRailingChange = useCallback((segmentId: string, platformRailingId: string, key: string, value: string | boolean | number) =>
    {
        setStraightStairSegments((newSegments) =>
        {
            const index = newSegments.findIndex(e => e.id === segmentId);
            if (index === -1)
            {
                return newSegments;
            }
            const segment = {...newSegments[index]};

            const railingKey = Object.keys(segment.platformRailings)
                .find(key => segment.platformRailings[key].id === platformRailingId)
            if (!railingKey)
            {
                return newSegments
            }
            const platformRailing = {...segment.platformRailings[railingKey]}
            platformRailing[key] = value
            if (key === "checked" && value)
            {
                const stringerMaterial = straightStairV2?.stringerMaterial
                if (stringerMaterial)
                {
                    platformRailing.length = getRecommendedPlatformRailingLength(railingKey,
                        segment, platformRailing, calculateT1Width(stringerMaterial, segment.stepWidth)
                    )
                }
            }

            segment.platformRailings[railingKey] = platformRailing
            newSegments[index] = segment;
            return newSegments;
        })
    }, [straightStairV2?.stringerMaterial, setStraightStairSegments])

    const calculateNumberOfSteps = useCallback(async (segmentId: string) =>
    {
        const segment = straightStairV2?.segments?.find((e) => e.id === segmentId)
        if (!segment)
        {
            return
        }

        const {stairHeight, stepDepth, overlap} = segment
        const response = await calculateNumberOfStepsQuery({variables: {stairHeight, stepDepth, overlap}})
        const newNumberOfSteps = response.data?.calculateNumberOfSteps

        if (newNumberOfSteps)
        {
            updateSegment(segmentId, "numberOfSteps", newNumberOfSteps)
        }
    }, [straightStairV2, calculateNumberOfStepsQuery, updateSegment])

    return {
        createSegmentLoading, deleteSegmentLoading, copySegmentLoading, calculateNumberOfStepsLoading,
        addSegment, deleteSegment, updateSegment, copySegment,
        onPlatformRailingChange, calculateNumberOfSteps,
        isDoubleRailingAndExtendedEnabled,
    }
}

export default useSegmentHook;