import React from "react";

export default (props) =>
{
    const fill = props.fill ? props.fill : "";

    return <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path fill={fill} d="M18 6h-3v3h-3v3H9v3H6v3H3v3h18V3h-3zm1-2h1v16H4v-1h3v-3h3v-3h3v-3h3V7h3z"/>
        <path fill="none" d="M0 0h24v24H0z"/>
    </svg>

}