import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import React, {useState} from 'react';
import {capitalizeFirstLetter, labelize} from "../UTIL";
import {pickBy} from 'lodash';
import UpdateCompanyMutation from './graphql/UpdateCompany.graphql';
import {useStdMutation} from "../spiralStair/lib/Util";
import NotificationPopup from "../common/lib/NotificationPopup";

const BillingAddition = ({company,onChange,viewOnly})=>
{
    if(viewOnly)
    {
        return <p>{company["billingEmail"] || "" }</p>;
    }
    else
    {
        return <FormGroup key="billingEmail">
            <Label>Billing Email</Label>
            <Input type="email"
                   value={company["billingEmail"] || ""}
                   onChange={(e) => onChange("billingEmail", e) }/>
        </FormGroup>
    }
};

const DeliveryTitle = ({company, onChange, viewOnly}) => {
    if (viewOnly) {
        return <React.Fragment>
            <p>{company["deliveryTitle"] || ""}</p>
        </React.Fragment>
    } else {
        return <React.Fragment key="deliveryTitle">
            <FormGroup>
                <Label>Delivery Title</Label>
                <Input disabled={viewOnly} type="text"
                       value={company["deliveryTitle"] || ""}
                       onChange={(e) => onChange("deliveryTitle", e)}/>
            </FormGroup>
        </React.Fragment>
    }
}

const DeliveryAddition = ({company, onChange, viewOnly}) => {
    if (viewOnly) {
        return <React.Fragment>
            <p>{company["deliveryContactName"] || ""}</p>
            <p>{company["deliveryContactPhone"] || ""}</p>
            <p>{company["deliveryFreeText"] || ""}</p>
        </React.Fragment>
    } else {
        return <React.Fragment key="deliveryTitle-deliveryFreeText">
            <FormGroup>
                <Label>Delivery Contact Name</Label>
                <Input disabled={viewOnly} type="text"
                       value={company["deliveryContactName"] || ""}
                       onChange={(e) => onChange("deliveryContactName", e)}/>
            </FormGroup>
            <FormGroup>
                <Label>Delivery Contact Phone</Label>
                <Input disabled={viewOnly} type="text"
                       value={company["deliveryContactPhone"] || ""}
                       onChange={(e) => onChange("deliveryContactPhone", e)}/>
            </FormGroup>
            <FormGroup>
                <Label>Delivery Free Text</Label>
                <Input disabled={viewOnly} type="textarea"
                       value={company["deliveryFreeText"] || ""}
                       onChange={(e) => onChange("deliveryFreeText", e)}/>
            </FormGroup>
        </React.Fragment>
    }
};

const content = (company,onChange,viewOnly) => {
    return ["billing","visiting","delivery"].map((type, index) =>
    (<TabPane key={index} tabId={index + ""}>
        <Row>
            <Col sm="12">
                {type === "delivery" && <DeliveryTitle onChange={onChange} company={company} viewOnly={viewOnly} /> }

                {["Street","Street2", "PostalCode", "City", "Country"].map(fieldType =>
                {
                    if(fieldType === "Street2" && type !== "billing")
                    {
                        return null;
                    }
                    if(viewOnly)
                    {
                        if(fieldType === "City")
                        {
                            return null;
                        }
                        else if(fieldType ==="PostalCode")
                        {
                            return <p key={fieldType}>
                                {company[type + fieldType]} {company[type + "City"]}
                            </p>;
                        }
                        return <p key={fieldType}>{company[type + fieldType] || ""}</p>
                    }
                    else
                    {
                        return <FormGroup key={fieldType}>
                            <Label>{capitalizeFirstLetter(type)} {labelize(fieldType)}</Label>
                            <Input  type="text" pattern={fieldType === "PostalCode"?"[0-9 ]+":""}
                                   value={company[type + fieldType] || ""}
                                   onChange={(e) => onChange(type + fieldType, e) }/>
                            {fieldType === "PostalCode" && company[type + fieldType] && !(/^[0-9 ]+$/.test(company[type + fieldType])) &&
                            <span style={{color:"red"}}>
                                Postcode can only contain digits and spaces.
                            </span>}
                        </FormGroup>
                    }
                })}
                {type === "billing" && <BillingAddition onChange={onChange} company={company} viewOnly={viewOnly} />}
                {type === "delivery" && <DeliveryAddition onChange={onChange} company={company} viewOnly={viewOnly} /> }
            </Col>
        </Row>
    </TabPane>))
};

export default (props)=>
{
    const [activeTab,setTab] = useState("0");
    const [editMode,setEditMode] = useState(false);
    const [saveCompany] = useStdMutation("Update company",UpdateCompanyMutation,{refetchQueries:["getCompany"]});
    const propCompany = pickBy(props.company,(value,key)=>
    {
        return ["billing","visiting","delivery"].some(part=>key.indexOf(part)!==-1)
    });
    const [company,setCompany] = useState(propCompany);
    const onChange = (key,e)=>
    {
        setCompany({...company,[key]:e.target.value});
    };

    return <Card>
        <CardHeader>
            <Nav tabs card>
                {["billing","visiting","delivery"].map((type,index) =>
                (
                <NavItem key={index}>
                    <NavLink
                    className={activeTab === index + ""?"active":""}
                    onClick={() => { setTab(index + ""); }}>
                        {capitalizeFirstLetter(type)}
                    </NavLink>
                </NavItem>
                ))}
                <NavItem>
                    {!editMode && <Button size={"sm"} color="primary" onClick={()=>setEditMode(true)}>
                        Edit
                    </Button>}
                    {editMode && <React.Fragment>
                        <Button color="success" onClick={async()=>
                        {
                            if(company.billingPostalCode && !/^[0-9 ]+$/.test(company.billingPostalCode))
                            {
                                NotificationPopup.error(`Invalid billing postalcode`);
                                return;
                            }
                            if(company.visitingPostalCode && !/^[0-9 ]+$/.test(company.visitingPostalCode))
                            {
                                NotificationPopup.error(`Invalid Visiting postalcode`);
                                return;
                            }
                            if(company.deliveryPostalCode && !/^[0-9 ]+$/.test(company.deliveryPostalCode))
                            {
                                NotificationPopup.error(`Invalid Delivery postalcode`);
                                return;
                            }
                            await saveCompany({variables:{
                                    id:props.company.id,
                                    company
                                }});
                            setEditMode(false);
                        }}>
                            Save
                        </Button>
                        <Button color="secondary" onClick={async()=>
                        {
                            setCompany(propCompany);
                            setEditMode(false);
                        }}>
                            Cancel
                        </Button>
                    </React.Fragment>}
                </NavItem>
            </Nav>
        </CardHeader>
        <CardBody>
            <div>
                <TabContent activeTab={activeTab}>
                    {content(company,onChange,!editMode)}
                </TabContent>
            </div>
        </CardBody>
    </Card>
};
