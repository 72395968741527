import {useUsersQuery} from "../../../generated/graphql";

const NAME_ASSIGNEE = 'Assignee'
const FIELD_ASSIGNEE = 'assignee.username'
const FIELD_ID_ASSIGNEE = 'assigneeId'

const ColumnAssignee = (field?: string, headerName?: string, filterField?: string) =>
{
    const {data} = useUsersQuery();

    return {
        field: field ?? FIELD_ASSIGNEE,
        headerName: headerName ?? NAME_ASSIGNEE,
        customFilterField: filterField ?? FIELD_ID_ASSIGNEE,
        type: 'singleSelect',
        valueOptions: data?.users?.map((e) =>
        {
            return {value: e?.username, label: e?.displayName}
        }) ?? [],
        searchable: true,
        customSearchField: filterField ?? FIELD_ID_ASSIGNEE,
        customSortField: filterField ?? FIELD_ID_ASSIGNEE,
    }
}
export default ColumnAssignee