import {StraightStairHookProps} from "../StraightStairV2Types";
import {useMutationHandler} from "../../custom-hook/useMutationHandler";
import {
    CreateStraightStairExtraRailingMutation,
    StraightStairExtraRailingFragmentFragment,
    useCreateStraightStairExtraRailingMutation,
    useDeleteStraightStairExtraRailingMutation
} from "../../generated/graphql";
import {useCallback} from "react";


const useExtraRailingHook = (props: StraightStairHookProps) =>
{
    const {straightStairV2, setStraightStairV2} = props;

    const {
        executeMutation: createExtraRailingMutation,
        loading: addExtraRailingLoading
    } = useMutationHandler(useCreateStraightStairExtraRailingMutation)

    const {
        executeMutation: deleteExtraRailingMutation,
        loading: deleteExtraRailingLoading
    } = useMutationHandler(useDeleteStraightStairExtraRailingMutation)

    const setStraightStairExtraRailing = useCallback((onUpdate: (newExtraRailings: StraightStairExtraRailingFragmentFragment[]) => StraightStairExtraRailingFragmentFragment[]) =>
    {
        setStraightStairV2(prevState =>
        {
            if (!prevState)
            {
                return null
            }
            return {...prevState, extraRailings: onUpdate([...prevState.extraRailings])}
        })
    }, [setStraightStairV2])

    const updateExtraRailing = useCallback((extraRailing: StraightStairExtraRailingFragmentFragment) =>
    {
        setStraightStairExtraRailing(newExtraRailings =>
        {
            const index = newExtraRailings.findIndex(e => e.id === extraRailing.id);
            if (index === -1)
            {
                return newExtraRailings;
            }

            newExtraRailings[index] = extraRailing;
            return newExtraRailings;
        })
    }, [setStraightStairExtraRailing])

    const onCreateExtraRailingSuccess = useCallback((data: CreateStraightStairExtraRailingMutation | null) =>
    {
        const extraRailing = data?.createStraightStairExtraRailing
        if (extraRailing)
        {
            setStraightStairExtraRailing(newExtraRailings =>
            {
                newExtraRailings.push(extraRailing)
                return newExtraRailings;
            })
        }
    }, [setStraightStairExtraRailing])

    const addExtraRailing = useCallback(async () =>
    {
        if (!straightStairV2)
        {
            return
        }

        return await createExtraRailingMutation({variables: {straightStairV2Id: straightStairV2.id}},
            {onSuccess: onCreateExtraRailingSuccess})
    }, [createExtraRailingMutation, onCreateExtraRailingSuccess, straightStairV2])

    const onDeleteExtraRailingSuccess = useCallback((id: string) =>
    {
        setStraightStairExtraRailing(newExtraRailings =>
        {
            return newExtraRailings.filter((e) => e.id !== id)
        })
    }, [setStraightStairExtraRailing])

    const deleteExtraRailing = useCallback(async (id: string) =>
    {
        if (!straightStairV2)
        {
            return
        }
        await deleteExtraRailingMutation({variables: {id: id}},
            {onSuccess: () => onDeleteExtraRailingSuccess(id)})
    }, [straightStairV2, deleteExtraRailingMutation, onDeleteExtraRailingSuccess])

    return {
        addExtraRailingLoading, deleteExtraRailingLoading,
        addExtraRailing, deleteExtraRailing, updateExtraRailing,
    }
}

export default useExtraRailingHook;