import React, {useCallback, useState} from "react";
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, Table} from "reactstrap";
import Auth from "../../Auth";
import {formatToSwedishTime} from "../../UTIL";
import Collapser from "../../common/Collapser";

type Comment = {
    comment: string
    user: string
    createdAt: Date
}

export const CommentPanel = ({comments, oldComment, setComments}:
                                 {
                                     comments?: Comment[],
                                     setComments:any,
                                     oldComment?: string
                                 }) =>
{

    const [addCommentModal, setAddCommentModal] = useState(false)

    const toggleAddCommentModal = useCallback(() =>
    {
        setAddCommentModal(value => !value)
    }, [setAddCommentModal])

    const handleAddComment = useCallback((newComments) =>
    {
        setComments(newComments)
        toggleAddCommentModal()
    }, [setComments, toggleAddCommentModal])

    return <>
        <CommentTable comments={comments} requiresMoreInformationComment={oldComment}/>
        <Button onClick={toggleAddCommentModal}>Add comment</Button>
        <AddCommentModal open={addCommentModal}
                         toggle={toggleAddCommentModal}
                         comments={comments}
                         submit={handleAddComment}/>

    </>
}


export const AddCommentSection = ({
                                      submit,
                                      comments,
                                      btnText
                                  }: { submit: (newComments) => void, comments?: Comment[], btnText?: string }) =>
{
    const [comment, setComment] = useState("");
    const handleOnSubmit = useCallback(() =>
    {
        const newComment = {
            comment: comment,
            user: Auth.getUsername() ?? '',
            createdAt: new Date()
        }

        const newComments = comments ?? []
        newComments?.push(newComment)

        setComment("")

        submit(newComments)
    }, [comment, submit, comments]);

    return <div>
        <FormGroup>
            <Label>Comment</Label>
            <Input type="textarea" value={comment} onChange={e => setComment(e.target.value)}/>
        </FormGroup>

        <Row style={{marginTop: "20px", justifyContent: "space-between"}}>
            <Col>
            </Col>
            <Col sm={3}>
                <Button color={"success"} onClick={() => handleOnSubmit()}>
                    {btnText == null ? "Submit" : btnText}
                </Button>
            </Col>
        </Row>
    </div>
}

export const AddCommentModal = ({submit, open, toggle, comments, title}:
                                    {
                                        submit: (newComments) => void, open: boolean, toggle: any,
                                        comments?: Comment[], title?: String
                                    }) =>
{
    return <Modal isOpen={open}>
        <ModalHeader toggle={toggle}>
            {title ?? `Add Comment`}
        </ModalHeader>
        <ModalBody>
            <AddCommentSection comments={comments} submit={submit}/>
        </ModalBody>
    </Modal>
}

export const CommentTable = ({comments, requiresMoreInformationComment}:
                                 { comments?: Comment[], requiresMoreInformationComment?: string }) =>
{
    return <Collapser label={'Comments'} open >
        <h3>Comments</h3>
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>Comment</th>
                <th>Commenter</th>
                <th>Created at</th>
            </tr>
            </thead>
            <tbody>
            {
                requiresMoreInformationComment && <tr>
                    <td>(Old) {requiresMoreInformationComment}</td>
                </tr>
            }

            {
                comments?.map((e, index) =>
                {
                    return <tr key={index}>
                        <td>{e.comment}</td>
                        <td>{e.user}</td>
                        <td>{formatToSwedishTime(e.createdAt)}</td>
                    </tr>
                })
            }
            </tbody>
        </Table>
    </Collapser>
}

