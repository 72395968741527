export const TenantData = {
    "ESSE":
    {
        defaultCurrency:"SEK",
        defaultUnit:"st",
        defaultLocale:"sv"
    },
    "ESNO":
    {
        defaultCurrency:"NOK",
        defaultUnit:"stk",
        defaultLocale:"no"
    },
    "ESDK":
    {
        defaultCurrency:"DKK",
        defaultUnit:"stk",
        defaultLocale:"en"
    },
    "ESDE":
    {

        defaultCurrency:"EUR",
        defaultUnit:"pcs",
        defaultLocale:"en"
    },
    "EXPORT":
    {
        defaultCurrency:"EUR",
        defaultUnit:"pcs",
        defaultLocale:"en"
    }
};
