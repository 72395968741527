import {Link} from "react-router-dom";
import React from "react";

const ColumnOrderNumber = () =>
{
    return {
        field: 'number',
        headerName: 'Number',
        searchable: true,
        width: 90,
        renderCell: (params) =>
        {
            return <Link to={`/orderDetail/${params.id}`}
                         style={params.row.status === "cancelled" ? {
                             textDecoration: "line-through",
                             color: "red"
                         } : null}>
                {params.value}
            </Link>
        },
    }
}

export default ColumnOrderNumber
