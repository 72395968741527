import React from 'react';

export default () =>
{
    return <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24">
        <g id="Icons" style={{opacity: 0.75}}>
            <g id="clear">
                <path id="circle_with_strike" style={{fillRule: "evenodd", clipRule: "evenodd"}}
                      d="M11.999,5.022c-3.853,0-6.977,3.124-6.977,6.978    c0,3.853,3.124,6.978,6.977,6.978c3.854,0,6.979-3.125,6.979-6.978C18.978,8.146,15.853,5.022,11.999,5.022z M6.886,12    c0-1.092,0.572-3.25,0.93-2.929l7.113,7.113c0.488,0.525-1.837,0.931-2.93,0.931C9.174,17.114,6.886,14.824,6.886,12z     M16.184,14.929L9.07,7.816c-0.445-0.483,1.837-0.931,2.929-0.931c2.827,0,5.115,2.289,5.115,5.114    C17.114,13.092,16.75,15.542,16.184,14.929z"/>
            </g>
        </g>
        <g id="Guides" style={{display: "none"}}>
        </g>
    </svg>
}