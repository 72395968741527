import React, {useMemo} from 'react';
import gql from 'graphql-tag';
import {SupplierFormDef} from "./List";
import {Link} from "react-router-dom";
import {Badge, Col, Row} from "reactstrap";
import {useMutation} from "@apollo/client";
import StdQuery from "../../common/StdQuery";
import EditableDetailRenderer from "../../common/EditableDetailRenderer";
import NotificationPopup from "../../common/lib/NotificationPopup";
import {withRouter} from "react-router";
import {POStatuses} from "../purchase-order/Status";
import {InventoryRoute} from "../InventoryRouters";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";

const KEY_TABLE = "TABLE_INVENTORY_SUPPLIER_PURCHASE_ORDER"

const supplierQuery = gql`
    query getSupplier($id:ID!)
    {
        supplier(id:$id)
        {
            id
            VATNumber
            city
            country
            createdAt
            currency
            name
            organisationNumber
            phone
            postalCode
            defaultModeOfDelivery
            defaultTermsOfDelivery
            defaultTermsOfPayment
            defaultSupplierRef
            purchaseOrders
            {
                id
                createdAt
                creator
                dateOfDispatch
                status
            }
            street

        }
    }
`;

const PurchaseOrdersTableGrid = ({purchaseOrders}) => {
    const columns = useMemo(() => {
        return [
            {
                headerName: "Number",
                field: "id",
                renderCell: (params) => <Link to={`${InventoryRoute.PURCHASE_DETAIL}/${params.id}`}>{params.id}</Link>
            },
            {
                field: "status",
                renderCell: (params) => {
                    const status = POStatuses[params.value];
                    return <Badge color={status.variant}>{status.label}</Badge>;
                }
            },
            ColumnDate('dateOfDispatch', 'Date Of Dispatch'),
            {field: "creator"},
            ColumnCreatedAt()
        ];
    }, [])

    return <>
        <h3>Purchase orders</h3>
        <NonPaginatedDataGrid
            style={{marginTop: "20px"}}
            definition={{
                tableKey: KEY_TABLE,
                columns: columns,
                pageSize: 20,
                disableTooBar: true,
                initState: {
                    columns: {
                        columnVisibilityModel: {id: true}
                    }
                }
            }}
            data={purchaseOrders}/>
    </>
};

const InventorySupplierDetailPage = (props) => {
    const {params} = props.match;
    const [update] = useMutation(gql`
        mutation($id:ID!,$supplier:SupplierInputType!)
        {
            updateSupplier(id:$id,supplier:$supplier)
            {
                id
            }
        }`, {refetchQueries: ["getSupplier"]})
    return <StdQuery query={supplierQuery} variables={{id: parseInt(params.id)}}>
        {({supplier}) => {
            if (!supplier) {
                return <p style={{color: "red"}}>Find no supplier with id: {params.id}</p>;
            }

            return <div>
                <h1>{supplier.name}</h1>
                <Row>
                    <Col lg={7}>
                        <EditableDetailRenderer onSubmit={async ({object}) => {
                            try {
                                await update({
                                    variables: {
                                        id: supplier.id,
                                        supplier: object
                                    }
                                })
                                NotificationPopup.success(`Updated supplier`);
                            } catch (e) {
                                NotificationPopup.error(`Failed to update supplier ${e.message}`);
                            }
                        }} object={supplier} formDefinition={SupplierFormDef()}/>
                        <br/>
                        <PurchaseOrdersTableGrid purchaseOrders={supplier.purchaseOrders.sort((a, b) => b.id - a.id)}/>
                    </Col>
                    <Col>
                    </Col>
                </Row>
            </div>
        }

        }

    </StdQuery>
}

export default withRouter(InventorySupplierDetailPage)