import {Card, CardBody, CardHeader} from "reactstrap";
import Paragraph from "../common/Paragraph";
import React, {useEffect, useState} from "react";
import {cageValidation, calcStepHeight, railLength, totalHeight} from "./lib/Util";
import {object,number,array} from 'yup';
import Collapser from "../common/Collapser";

//General limitations that are imposed by e.g. production, regulations or ease-of-use.


export const GeneralLimitationsSpiralSegmentSchema = object().shape({
    stepHeight:number().min(160).max(220),
})


export const GeneralLimitationsSpiralStairSchema = object().shape({
    segments:array().of(GeneralLimitationsSpiralSegmentSchema)

})
export default ({spiralStair,segments})=>
{
    const [errors,setErrors] = useState([]);
    useEffect(()=>
    {
        const fn = async()=>
        {
            try
            {
                await GeneralLimitationsSpiralStairSchema.validate({segments:
                    segments.map(segment=>({stepHeight:calcStepHeight(segment)}))}
                    ,{abortEarly:false});
                setErrors([]);
            }
            catch(e)
            {
                setErrors(e.errors);
            }
        }
        fn();
    },[setErrors,spiralStair,segments]);

    return <Card>
        <CardHeader>
            <Paragraph style={{fontSize:"18px"}}>
                Info
            </Paragraph>
        </CardHeader>
        <CardBody>
            <p>Rail length: {railLength(segments) || "N/A"} mm</p>
            <p>Total Height: {totalHeight(segments)|| "N/A"} mm</p>
            <Collapser label={"Show validation errors"} open>
                <ul className={"validation-list"}>
                    {errors.map(e =>
                    <li key={e}>
                        {e}
                    </li>
                    )}
                    <li>{cageValidation(spiralStair,segments)}</li>
                </ul>
            </Collapser>

        </CardBody>
    </Card>
}
