import React from 'react';
import { Input, Label} from "reactstrap";
import {formatCurrency} from "../UTIL";
import {useGetPrioritisationQuery} from "../generated/graphql";
import Select from "../common/FixRequiredSelect";

export default ({articleRow, onChange}) =>
{
    const {data} = useGetPrioritisationQuery();
    const {name, price, prioritisation} = articleRow;

    return <>
        <Label>Name</Label>
        <Input type="text" value={name} onChange={e =>onChange(prev=>({...prev,name:e.target.value}))}/>
        <Label>Prioritisation</Label>
        <Select
            getOptionLabel={({name}) => name}
            getOptionValue={({id}) => id}
            options={data?.prioritisation}
            value={prioritisation}
            onChange={e => {
                return onChange(prev=>({...prev, prioritisation:e, prioritisationId:parseInt(e.id)}));
            } }
        />
        <Label>Price</Label>
        <Input type="number" value={price} onChange={e =>onChange(prev=>({...prev,price:parseFloat(e.target.value)}))}/>
        <small><p>{formatCurrency(price)}</p></small>
      </>
}
