import React from 'react';
import StdQuery from "./StdQuery";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";
import gql from "graphql-tag";

const GetArticleRow = gql`
    query articleRow($id:ID!)
    {
        articleRow(id:$id)
        {
            id
            quoteId
            orderId
            purchaseOrderId
        }
    }
`;
export default ({articleRowId, style={} })=>
{
    if(articleRowId)
    {
        return <StdQuery noLoadingRender query={GetArticleRow} variables={{id:articleRowId}}>
            {data=>
            {
                if(data.articleRow)
                {
                    const {quoteId,orderId,purchaseOrderId} = data.articleRow;
                    return <React.Fragment>
                        {orderId && <Link to={`/orderDetail/${orderId}`}>
                            <Button style={style} size="sm" color="warning">Go back to order</Button>
                        </Link> }
                        {quoteId && <Link to={`/quoteDetail/${quoteId}`}>
                            <Button style={style} size="sm" color="warning">Go back to quote</Button>
                        </Link> }
                        {purchaseOrderId && <Link to={`/purchaseOrderDetail/${purchaseOrderId}`}>
                            <Button style={style} size="sm" color="warning">Go back to PO</Button>
                        </Link>}
                    </React.Fragment>
                }
                else
                {
                    return null;
                }

            }

            }
        </StdQuery>
    }
    else
    {
        return null;
    }

};
