import React, {Component} from 'react';
import {Label} from "reactstrap";
import {FORMAT_DATE, formatToSwedishTime} from "../UTIL";


export default class CreateUpdateInfo extends Component{

    constructor()
    {
        super();
        this.state = { isOpen:false};
    }

    toggle()
    {
        this.setState({isOpen:!this.state.isOpen});
    }

    render()
    {
        const obj = this.props.object;

        return (
        <div>
                <Label>Created at</Label>
                <p>{formatToSwedishTime(obj.createdAt, FORMAT_DATE)}</p>
                <Label>Created by</Label>
                <p>{obj.creator?.displayName ?? obj.creator ?? "Missing"}</p>
                <Label>Updated at</Label>
                <p>{formatToSwedishTime(obj.updatedAt, FORMAT_DATE)}</p>
        </div>);
    }
}
