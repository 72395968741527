type PackageType = {
    name: string,
    id: string
}

export const PackageTypes: PackageType[] = [
    { id: "BG", name: "Bag(BG)" },
    { id: "CS", name: "Case(CS)" },
    { id: "EP", name: "EUR pallet(EP)" },
    { id: "OP", name: "One-way pallet(OP)" },
    { id: "XP", name: "Other pallets(XP)" },
    { id: "PC", name: "Parcel(PC)" },
    { id: "PI", name: "Pipe(PI)" },
    { id: "NE", name: "Unpacked or unpackaged(NE)" }
]
