import {
    StraightStairPresetType,
    useGetSupplierByProductTypeIdQueryQuery, useQuickCreateStraightStairMutation,
    useQuickCreateStraightStairV2Mutation
} from "../../generated/graphql";
import React, {useCallback, useMemo, useState} from "react";
import ModalForm from "../ModalForm";
import config, {environment} from "../../config";
import {withRouter} from "react-router";
import {SupplierSelector} from "./CreateArticle";
import _ from "lodash";
import NewFormRenderer from "../NewFormRenderer";
import {Button} from "reactstrap";

const STRAIGHT_STAIR_PRODUCT_TYPE_ID = "2";

export const CreateStraightStair = withRouter(({orderId, history}) =>
{
    const [createStraightStair] = useQuickCreateStraightStairMutation({refetchQueries: ["quote", "getOrder"]})

    const onSubmit = useCallback(async ({supplierId}) =>
    {
        const variables = {orderId, supplierId}
        const res = await createStraightStair({variables: variables});
        if (res.data)
        {
            const articleRow = res.data.quickCreateStraightStair;
            history.push(`/straightStairDetail/${articleRow?.article?.id}/${articleRow?.id}`);
        }
    }, [orderId, history, createStraightStair]);
    return <ModalForm size="lg" title={"Straight stair"} noFooter activationButtonText={"Straight stair"}
                      buttonProps={{outline: true}}>
        {toggle =>
        {
            return <SupplierSelector productTypeId={STRAIGHT_STAIR_PRODUCT_TYPE_ID} onSubmit={onSubmit} toggle={toggle}
                                     skipSupplier={false}/>
        }}
    </ModalForm>
});

export const QuickCreateStraightStair = withRouter(({quoteId, orderId, history}) =>
{
    const [quickCreate] = useQuickCreateStraightStairMutation({refetchQueries: ["quote", "getOrder"]})
    const onSubmit = useCallback(async ({supplierId}) =>
    {
        const variables = {quoteId, orderId, supplierId}
        const res = await quickCreate({variables: variables});
        if (res.data)
        {
            const articleRow = res.data.quickCreateStraightStair;
            history.push(`/straightStairDetail/${articleRow?.article?.id}/${articleRow?.id}`);
        }
    }, [quoteId, orderId, history, quickCreate]);

    return <ModalForm size="lg" title={"Straight stair"} noFooter activationButtonText={"Straight stair"}
                      buttonProps={{outline: true}}>
        {toggle =>
        {
            return <SupplierSelector productTypeId={STRAIGHT_STAIR_PRODUCT_TYPE_ID} onSubmit={onSubmit} toggle={toggle}
                                     skipSupplier={false}/>
        }}
    </ModalForm>
});


const CreateStraightStairV2Modal = ({toggle, onSubmit,}) =>
{
    const {data: supplierData} = useGetSupplierByProductTypeIdQueryQuery({variables: {id: STRAIGHT_STAIR_PRODUCT_TYPE_ID}});

    const [form, setForm] = useState({});

    const onChange = useCallback((key, value) =>
    {
        setForm(prev => ({...prev, [key]: value}));
    }, [])

    const formDefinition = useMemo(() =>
    {
        return [
            {
                name: "supplierId",
                required: true,
                type: "select",
                options: _.get(supplierData, "productType.suppliers")
            },
            {
                name: "presetType",
                type: "select",
                options: [{id: StraightStairPresetType.ChildSafeStd, name: "ChildSafe STD"},
                    {id: StraightStairPresetType.IndustrialStd, name: "Industrial STD"}]
            }
        ]
    }, [supplierData])

    return (
        <div>
            <p>Select supplier. Keep in mind this will be used to generate EAN Article Number.</p>
            <p>Later you can edit the article as usual.</p>
            <NewFormRenderer
                object={form}
                columns={2}
                formDefinition={formDefinition}
                onChange={onChange}
                customSubmit={<Button color="success" style={{float: "right"}} onClick={() =>
                {
                    onSubmit(form);
                    toggle();
                }}>Create</Button>}
            />
        </div>
    );
}


export const CreateStraightStairV2 = withRouter(({quoteId, orderId, history}) =>
{
    const [createStraightStairV2] = useQuickCreateStraightStairV2Mutation({refetchQueries: ["quote", "getOrder"]})

    const onSubmit = useCallback(async ({supplierId, presetType}) =>
    {
        const variables = {quoteId, orderId, supplierId, presetType}

        const res = await createStraightStairV2({variables: variables});
        if (res.data)
        {
            const articleRow = res.data.quickCreateStraightStairV2;
            history.push(`/straightStairV2Detail/${articleRow.article?.id}/${articleRow.id}`);
        }
    }, [createStraightStairV2, orderId, quoteId, history]);

    if (config.environment === environment.PRODUCTION)
    {
        return null
    }
    return <ModalForm size="lg" title={"Straight stair V2"} noFooter activationButtonText={"Straight stair V2"}
                      buttonProps={{outline: true}}>
        {toggle => <CreateStraightStairV2Modal onSubmit={onSubmit} toggle={toggle}/>}
    </ModalForm>
});