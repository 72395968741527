import {useCallback, useEffect, useState} from "react";
import {StraightStairV2FragmentFragment} from "../../generated/graphql";
import {array, number, object, string, mixed} from "yup/es";

export type Props = {
    straightStairV2: StraightStairV2FragmentFragment | null
}

const platformRailingSchema = object().shape({
    length: number().when('checked', {
        is: true,
        then: number().positive(),
        otherwise: number().nullable(),
    })
})

const segmentSchema = object().shape({
    stairHeight: number().positive(),
    numberOfSteps: number().positive(),
    stepDepth: number().positive(),
    stepWidth: number().positive(),
    overlap: number().positive(),
    platformRailings: object().shape({
        L1: platformRailingSchema,
        L2: platformRailingSchema,
        W1: platformRailingSchema,
        W2: platformRailingSchema,
    }),
    platformRailingType: string().when('platformRailingExecution', {
        is: "childsafe",
        then: string().required(),
        otherwise: string().nullable(),
    }),
    geometry: object().shape({
        stringerLength: number().min(500).max(8600)
    }),
    platformLength: number().when('platformIntegrated', {
        is: true,
        then: number().min(600).max(4100),
        otherwise: number().nullable(),
    }),
    platformRailingExecution: mixed().nullable().test(
        function (this: any, value)
        {
            const {platformRailings} = this.parent;
            if (platformRailings.L1.checked || platformRailings.L2.checked || platformRailings.L2.checked || platformRailings.L2.checked)
            {
                return value !== null
            }
            return true;
        }
    ),
    railingSide: number().test(
        function (this: any, railingSide)
        {
            const {wallHandrail} = this.parent;
            if (railingSide === 1)
            {
                return wallHandrail <= 1
            }
            if (railingSide === 2)
            {
                return wallHandrail === 0
            }
            return true;
        }
    ),
})

const extraRailingSchema = object().shape({
    quantity: number().positive(),
    length: number().positive(),
    maxSectionLength: number().positive(),
})

const separateKickPlateSchema = object().shape({
    length: number().positive(),
    quantity: number().positive(),
})

const supportPlateSchema = object().shape({
    length: number().positive(),
    quantity: number().positive(),
})

const straightStairSchema = object().shape({
    segments: array().of(segmentSchema),
    extraRailingType: string().when('extraRailingExecution', {
        is: "childsafe",
        then: string().required(),
        otherwise: string().nullable(),
    }),
    extraRailingExecution: string().when('extraRailings', {
        is: (value) => value.length > 0,
        then: string().required(),
        otherwise: string().nullable(),
    }),
    extraRailings: array().of(extraRailingSchema),
    separateKickPlates: array().of(separateKickPlateSchema),
    supports: array().of(supportPlateSchema)
})

const useStraightStairValidationHook = (props: Props) =>
{
    const {straightStairV2} = props

    const [validationErrors, setValidationErrors] = useState<string[]>([])

    const validatePrice = useCallback(async () =>
    {
        try
        {
            await straightStairSchema.validate(straightStairV2, {abortEarly: false});
            setValidationErrors([]);
        } catch (e: any)
        {
            setValidationErrors(e?.errors ?? []);
        }
    }, [straightStairV2])

    useEffect(() =>
    {
        validatePrice()
    }, [validatePrice])

    return {validationErrors}
}

export default useStraightStairValidationHook;