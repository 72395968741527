export enum ArticleProductType
{
    SpiralStair = "spiralStair",
    StraightStair = "straightStair",
    Ramps = "ramps",
    Grating = "grating",
    Other = "other",
    StockArticle = "stock",
    Special = "special",
    Railings = "railings",
    Platforms = "platforms",
    Cages = "cages",
    GratingSteps = "gratingSteps",
    ProfilePlanks = "profilePlanks",
    ProductionMaterial = "productionMaterial",
    ProductionParts = "productionParts"
}

