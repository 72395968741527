import React, {useMemo, useState} from "react";
import {Link} from "react-router-dom";
import Pen from "../icons/Pen";
import {InventoryRoute} from "../InventoryRouters";
import UpdateStockTransaction from "../components/UpdateStockTransaction";
import NonPaginatedDataGrid from "../../common/data-grid/NonPaginatedDataGrid";
import ColumnQuantity from "../components/data-grid/ColumnQuantity";
import ColumnPrice from "../components/data-grid/ColumnPrice";
import ColumnDate from "../../common/data-grid/columns/ColumnDate";
import ColumnCreatedAt from "../../common/data-grid/columns/ColumnCreatedAt";
import {FORMAT_DATE_TIME, formatToSwedishTime} from "../../UTIL";

const TABLE_KEY = "TABLE_KEY_INVENTORY_ARTICLE_STOCK_TRANSACTION"

export default ({data, isEditable}) =>
{
    const [selectedStockTransaction, setSelectedStockTransaction] = useState(null);

    const columns = useMemo(() =>
    {
        return [
            {field: "description"},
            {
                field: "quantity",
                type: 'number',
                renderCell: (params) => <ColumnQuantity value={params.value}/>
            },
            {
                field: "price",
                renderCell: (params) => <ColumnPrice value={params.value} currency={params.row.currency}/>
            },
            ColumnDate('transactedAt', 'Received At'),
            {
                field: "purchaseOrder.id",
                headerName: "Purchase Order",
                renderCell: (params) =>
                {
                    const value = params.value
                    if (value)
                    {
                        return <Link to={`${InventoryRoute.PURCHASE_DETAIL}/${value}`}>
                            {value}
                        </Link>
                    }
                    return "-";
                }
            },
            ColumnCreatedAt((value) => formatToSwedishTime(value, FORMAT_DATE_TIME)),
            {
                field: "creator",
            },
            {
                field: "actions",
                renderCell: (params) =>
                {
                    return isEditable ? <div>
                                <span className={"link"} onClick={() =>
                                {
                                    setSelectedStockTransaction(params.row)
                                }}>
                                    <Pen/>
                                </span>
                        </div>
                        : null
                }
            },
        ]
    }, [isEditable])

    return <>
        <NonPaginatedDataGrid
            definition={{
                tableKey: {TABLE_KEY},
                columns: columns,
                pageSize: 20
            }}
            data={data}
        />
        <UpdateStockTransaction stockTransaction={selectedStockTransaction}
                                toggle={() => setSelectedStockTransaction(null)}/>
    </>
}