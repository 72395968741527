import {TenantData} from "../../lib/TenantData";

const ColumnTenantId = () =>
{
    return {
        field: 'tenantId',
        headerName: 'Tenant',
        type: 'singleSelect',
        width: 80,
        valueOptions: Object.keys(TenantData),
    }
}

export default ColumnTenantId