import {useCallback, useState} from "react";
import {STRAIGHT_STAIR_TAB_STAIR} from "../StraightStairConstants";

const useStairTabHook = () =>
{
    const [activeTab, setActiveTab] = useState(STRAIGHT_STAIR_TAB_STAIR)

    const updateActiveTab= useCallback((tab) =>
    {
        setActiveTab(tab)
    }, [])

    return {activeTab, updateActiveTab}
}

export default useStairTabHook;