import React, {useState} from 'react';
import GetPurchaseOrder from '../../purchaseOrder/graphql/GetPurchaseOrder.graphql';
import {Alert, Button, Col, Form, Input, Label, Row, Table} from "reactstrap";
import StdQuery from "../../common/StdQuery";
import Paragraph from "../../common/Paragraph";

const P = Paragraph;

const ArticleRows = ({articleRows})=>
{
    if (articleRows)
    {
        return <div>
            <Table bordered hover striped>
                <thead>
                <tr>
                    <th>
                        Art No.
                    </th>
                    <th>
                        Name
                    </th>
                    <th>
                        quantity
                    </th>
                    <th>
                        Unit
                    </th>
                    <th>
                        Discount
                    </th>
                    <th>
                        Amount
                    </th>
                </tr>
                </thead>
                <tbody>
                {articleRows.map(({id, article, name, price, unit, quantity, discount}) => <tr key={id}>
                    <td>
                        {article?article.artNo:""}
                    </td>
                    <td>
                        {name}
                    </td>
                    <td>
                        {quantity}
                    </td>
                    <td>
                        {unit}
                    </td>
                    <td>
                        {discount} %
                    </td>
                    <td>
                        {(price * quantity * ((100 - discount) / 100))}
                    </td>
                </tr>)}
                </tbody>
            </Table>
        </div>
    } else
    {
        return "No Rows";
    }
};


const renderDeliveryAddress = ({deliveryTitle,deliveryStreet,deliveryCity,
                                   deliveryCountry,deliveryPostalCode,deliveryFreeText})=>
{
    return <React.Fragment>
        <P>{deliveryTitle}</P>
        <P>{deliveryStreet}</P>
        <P>{deliveryPostalCode} {deliveryCity} {deliveryCountry}</P>
        <P>{deliveryFreeText}</P>
    </React.Fragment>;
};

export default ({variables,onSubmit}) =>
{
    const {purchaseOrderId,toWarehouse} = variables;
    const [invoiceNr,setInvoiceNr] = useState("");
    return <StdQuery query={GetPurchaseOrder} variables={{id: purchaseOrderId}}>
{({purchaseOrder}) => {

    if(purchaseOrder)
    {
        return <div>
            {purchaseOrder.order?.id && <div style={{margin: "5px"}}>
                This PO is under <a href={`orderDetail/${purchaseOrder.order.id}`}>{purchaseOrder.order.name}</a>
            </div>
            }
            <div style={{marginBottom: "25px"}}>
                <ArticleRows articleRows={purchaseOrder.articleRows} currency={purchaseOrder.currency}/>
            </div>
            <hr/>
            {!toWarehouse && <Alert color="warning">
                <h4 className="alert-heading">
                    Notice!
                </h4>
                <p>This order is sent directly to:</p>
                <hr/>
                <div>{renderDeliveryAddress(purchaseOrder)}</div>
            </Alert>}
            <div>
                <Form onSubmit={()=>
                {
                    onSubmit(
                    {
                        invoiceNumber:
                        {
                            value:invoiceNr,
                            type:"string"
                        }
                    }
                    )
                }}>
                    <Row>
                        <Col sm={6} >
                            <Label>
                                Invoice Number
                            </Label>
                            <Input type="text" required placeholder="Invoice Number!" value={invoiceNr} onChange={(e)=>setInvoiceNr(e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={{offset:10}}>
                            <Button style={{float:"right"}} type="submit" color='success'>
                                Complete task
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    }
    else
    {
        return `Failed to find Purchase order.`;
    }

}}
</StdQuery>

}
