import React, {Component} from 'react';
import {graphql} from '@apollo/client/react/hoc'
import {withRouter} from 'react-router';
import FormRenderer from "../common/FormRenderer";
import CreateSupplier from './graphql/CreateSupplier.graphql';
import {SupplierFormDef} from "./SupplierEditPage";
import NotificationPopup from "../common/lib/NotificationPopup";
import {Col, Row} from "reactstrap";
import {extractMeaningFromError} from "../UTIL";

class SupplierCreatePage extends Component
{



    async createSupplier(form)
    {
        console.log(form.object);
        try
        {
            const res = await this.props.createSupplier({
                variables:{supplier:form.object}
            });
            //extract id and go to detailview.
            this.props.history.push(`/supplierDetail/${res.data.createSupplier.id}`);
        }
        catch(e)
        {
            const useFulMessages = extractMeaningFromError(e);
            NotificationPopup.error(`Failed to create supplier. Make sure that the EAN-Value is unique. Message:   ${useFulMessages}`);
        }

    }

    render()
    {
        return <Row>
            <Col sm={12}>
                <FormRenderer columns={4} onSubmit={this.createSupplier.bind(this)} formDefinition={SupplierFormDef()} />
            </Col>
        </Row>
    }



}

export default graphql(CreateSupplier, {name: "createSupplier"})(withRouter(SupplierCreatePage));

