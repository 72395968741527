import { OrderInfo } from '../Order/SelectArticles';
import { CustomerDocsFileList } from './FileListComponents';
import ArticleList from './ArticleList';
import TaskFooter from '../../common/task-workflow/TaskFooter';
import {Button} from 'reactstrap';
import React, {useCallback, useState} from 'react';
import UploadFile, { CustomerDocCategoryType } from '../../common/UploadFile';
import {AddCommentModal, CommentTable} from "./CommentComponents";


export default ({variables,onSubmit,toggle})=>
{
    const { articles, orderId,autoDrawArticles,requiresMoreInformationComment } = variables;

    const [files,setFiles] = useState([]);
    const [comments, setComments] = useState(variables.comments ?? [])
    const [addCommentModal, setAddCommentModal] = useState(false)

    const toggleAddCommentModal = useCallback(() => {
        setAddCommentModal(value => !value)
    }, [setAddCommentModal])

    const handleAddComment = useCallback((newComments) => {
        setComments(newComments)
        toggleAddCommentModal()
    }, [setComments, toggleAddCommentModal])

    return <div>
        <OrderInfo orderId={orderId}/>
        <h3>Articles</h3>
        <ArticleList articles={articles} orderId={orderId}/>
        {autoDrawArticles && autoDrawArticles.length > 0 && <><h3>Auto drawing articles</h3>
            <ArticleList articles={autoDrawArticles} orderId={orderId}/>
        </>}
        <CustomerDocsFileList orderId={orderId}/>

        <h3>Upload files</h3>
        <UploadFile orderId={orderId} fileCategory={CustomerDocCategoryType}
                    onSubmit={files=>setFiles(oldFiles=>oldFiles.concat(files))} />

        <hr/>

        <CommentTable comments={comments}
                      requiresMoreInformationComment={requiresMoreInformationComment}/>
        <Button onClick={toggleAddCommentModal}>Add comment</Button>
        <AddCommentModal open={addCommentModal}
                         toggle={toggleAddCommentModal}
                         comments={comments}
                         submit={handleAddComment}/>

        <TaskFooter submit={() => onSubmit({
            moreInformationAddedFiles: {
                type: "json",
                value: JSON.stringify(files)
            },
            comments: {
                value: JSON.stringify(comments),
                type: "json"
            }
        })} toggle={toggle}/>

    </div>
}
