import {dateCellRenderer} from "../lib/UTIL";
import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import Pen from "../icons/Pen";
import Trash from "../icons/Trash";
import {Formik} from "formik";
import gql from "graphql-tag";
import {useQuery} from "@apollo/client";
import {useStdMutation} from "../../spiralStair/lib/Util";
import CodeScanner from "../components/CodeScanner";
import TableGrid from "../components/TableGrid";
import NotificationPopup from "../../common/lib/NotificationPopup";
import UpdateEANCode from "../components/UpdateEANCode";
import beep from "../assets/beep.mp3"

const eanCodesQuery = gql`
    query eanCodesByArticleId($articleId:Int!){
        result:eanCodes(
            articleId:$articleId
        ){
            id
            code
            createdAt
        }
    }`;

export default ({pageActive, articleId}) =>
{
    const [selectedEANCode, setSelectedEANCode] = useState(null);
    const [scannerActive, setScannerActive] = useState(true);
    const [incomingCode, setIncomingCode] = useState(null);
    const [audioElement, setAudioElement] = useState<HTMLAudioElement | null>(null);
    const {data, loading} = useQuery(eanCodesQuery, {variables: {articleId}});
    const [createEANCode] = useStdMutation("Create EAN Code",
        gql`mutation createEANCode($eanCode:EANCodeInputType!){
            createEANCode(eanCode: $eanCode){
                id
            }
        }`, {refetchQueries: ["eanCodesByArticleId"]});

    const [removeEANCode] = useStdMutation("Remove EAN Code",
        gql`mutation removeEANCode($id:Int!){
            removeEANCode(id:$id)
        }`, {refetchQueries: ["eanCodesByArticleId"]});

    useEffect(() =>
    {
        const myAudioElement = new Audio(beep);
        myAudioElement.addEventListener("canplaythrough", event =>
        {
            /* the audio is now playable; play it if permissions allow */
            setAudioElement(myAudioElement)
        });
        return () =>
        {
            myAudioElement.pause();
        }
    }, []);

    const onSubmit = useCallback(async (values, {resetForm}) =>
    {
        await createEANCode({
            variables: {
                eanCode: {
                    code: values.code,
                    articleId
                }
            }
        });
        resetForm();
    }, [articleId, createEANCode]);

    const handleScanSuccess = useCallback(async (decodedText) =>
    {
        await createEANCode({
            variables: {
                eanCode: {
                    code: decodedText,
                    articleId
                }
            }
        });

        setIncomingCode(decodedText);
        setScannerActive(false);
        await audioElement?.play();
    }, [setIncomingCode, setScannerActive, createEANCode, articleId, audioElement]);

    const handleScanError = useCallback(() =>
    {

    }, []);

    if (loading)
    {
        return <Spinner variant={"warning"} animation='border'/>
    }

    return <>
        <h3>Scan or key in EAN code.</h3>
        <CodeScanner scannerActive={scannerActive} pageActive={pageActive} scanErrorCallback={handleScanError}
                     scanSuccessCallback={handleScanSuccess}/>
        {!scannerActive && <div>Scan code: {incomingCode} <Button onClick={() =>
        {
            setScannerActive(true)
        }}>Continue scan</Button></div>}
        <Formik initialValues={{code: ""}} onSubmit={onSubmit}>
            {({handleChange, isSubmitting, handleSubmit, values}) =>
                <Form onSubmit={handleSubmit}>
                    <FormGroup className="mb-3">
                        <Label>code: </Label>
                        <Input name={"code"} value={values.code} style={{maxWidth: "500px"}} onChange={handleChange}/>
                    </FormGroup>
                    {!isSubmitting && <Button type={"submit"}>
                        Submit
                    </Button>}
                    {isSubmitting && <Spinner animation='border' variant={"warning"}/>}
                </Form>
            }
        </Formik>
        <hr/>

        <Col lg={6}>
            {data?.result.length !== 0 ?
                <TableGrid
                    data={data.result}
                    columns={[
                        {Header: "code", accessor: "code", Cell: ({row, value}) => value},
                        {Header: "Created At", accessor: "createdAt", disableSortBy: false, Cell: dateCellRenderer()},
                        {
                            Header: "Actions", Cell: ({row: {original}}) =>
                            {
                                return <div>
                                    <span className={"link"} onClick={() =>
                                    {
                                        setSelectedEANCode(original);
                                    }}>
                                        <Pen/>
                                    </span>
                                    <span className={"link"} onClick={async () =>
                                    {
                                        try
                                        {
                                            if (window.confirm(`Are you sure you want to delete EAN code: ${original.code} ?`))
                                            {
                                                await removeEANCode({variables: {id: parseInt(original.id)}});
                                                NotificationPopup.info(`Removed article row ${original.code}`);
                                            }
                                        } catch (e: any)
                                        {
                                            NotificationPopup.error(`Failed to remove article row.. ${e.message}`);
                                        }
                                    }}>
                                        <Trash/>
                                    </span>
                                </div>
                            }
                        }
                    ]}/>
                : ""
            }
        </Col>
        <UpdateEANCode eanCode={selectedEANCode}
                       toggle={() => setSelectedEANCode(null)}/>
    </>
}