import React, {useState} from 'react';
import {Button, Spinner} from 'reactstrap';
import _ from 'lodash';
export default (props)=>
{
    const [loading,setLoading] = useState(false);
    const [clicked,setClicked] = useState(false);
    const {onClick,notOnce,children} = props;
    if(loading)
    {
        return <Spinner color={"warning"} />
    }
    else if(!notOnce && clicked)
    {
        return <span>Submitted, thanks</span>;
    }
    else
    {
        return <Button {..._.omit(props,["onClick","once","children"])} onClick={async()=>
        {
            setClicked(true);
            setLoading(true);
            await onClick();
            if(notOnce)
            {
                setLoading(false);
            }

        }}>
            {children}
        </Button>
    }

}
