import OrderDeliveryInfoPanel from "./DeliveryInfoPanel";
import {ShipmentFragmentFragment} from "../../generated/graphql";
import PackageDataGrid from "../../package/component/PackageNonPaginatedDataGrid";
import React from "react";

export default (props: { shipment: ShipmentFragmentFragment }) =>
{
    const {shipment} = props
    return <div style={{padding: "30px"}}>
        <OrderDeliveryInfoPanel packages={shipment.packages} />
        <h3>Packages</h3>
        <PackageDataGrid packages={shipment.packages ?? []}/>
    </div>
}
