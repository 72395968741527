import {Alert, ButtonGroup, Card, CardBody, CardHeader, Input, Table} from "reactstrap";
import React, {useState} from 'react';
import {formatCurrency} from "../UTIL";
import Trash from "../common/icons/Trash";
import RemoveExtra from './graphql/RemoveSpiralStairExtra.graphql';
import {useMutation} from '@apollo/client';
import ModalForm from "../common/ModalForm";
import Paragraph from "../common/Paragraph";
import FormRenderer from "../common/FormRenderer";
import AddExtra from './graphql/AddSpiralStairExtra.graphql';
import gql from "graphql-tag";
import Checkmark from "../common/icons/Checkmark";
import Pen from "../common/icons/Pen";
import _ from 'lodash';
import {useStdMutation} from "./lib/Util";

const updateExtraMutation = gql`
    mutation updateExtra($id:ID!,$extra:SpiralStairExtraInputType!)
    {
        updateSpiralStairExtra(id:$id,extra: $extra)
        {
            id
        }
    }
`;

const ExtraRow = ({extra,onChange})=>
{
    const [updateExtra] = useStdMutation("update extra",updateExtraMutation,{refetchQueries:["spiralStairDetail","getPrice"]});
    const [removeExtra] = useStdMutation("Remove extra",RemoveExtra,{refetchQueries:["spiralStairDetail","getPrice"]});
    const [editing,setEditing] = useState(false);
    const [extraState,setExtra] = useState(extra);
    const inputChange = (key,e) =>
    {
        const value = e.target.value;
        if(e.target)
        {
            setExtra(val=>({...val,[key]:value}));
        }

    };
    if(editing)
    {
        return <tr>
            <td>
                <Input type="text" value={extraState.name} onChange={e=>inputChange("name",e)} />
            </td>
            <td>
                <Input type="number" value={extraState.price} onChange={e=>inputChange("price",e)} />
                {formatCurrency(extraState.price)}
            </td>
            <td>
                <span className="link" onClick={async() =>
                {

                    setEditing(false);
                    const {price,name} = extraState;
                    await updateExtra({variables:{id: extra.id,extra:{price:parseFloat(price),name}}});
                    // onChange();
                }}>
                     <Checkmark/>
                </span>
            </td>
        </tr>
    }
    return <tr>
        <td>
            {extra.name}
        </td>
        <td>
            {formatCurrency(extra.price)}
        </td>
        <td>
                <span className="link" onClick={()=>setEditing(true)}>
                     <Pen/>
                </span>
                 <span className="link" onClick={async() =>
                 {
                     if (window.confirm(`Are you sure you want to remove ${extra.name}?`))
                     {
                         await removeExtra({variables:{id: extra.id}});
                         // onChange();
                     }
                 }}>
                     <Trash/>
                </span>
        </td>
    </tr>
};

export default ({extra,spiralStairId})=>
{
    const [addExtra] = useMutation(AddExtra);
    return <Card>
        <CardHeader>
            <Paragraph style={{fontSize:"18px"}}>Price Additions</Paragraph>
        </CardHeader>
        <CardBody>
            <Alert color="warning">
                <strong>Price additions not manufactured by ESPR should be entered as a new product.</strong>
            </Alert>
            <ButtonGroup>
                <ModalForm title={`Add Spiral stair extra`} buttonProps={{size:"sm"}} activationButtonText={"Add"} >
                    {toggle=>
                    {
                        return <FormRenderer formDefinition={[
                            {
                                name: "name",
                                required: true,
                            },
                            {
                                name: "price",
                                label: "Price (SEK)",
                                required: true,
                                type: "currency"
                            }
                        ]} onSubmit={async ({object}) => {
                            await addExtra({variables:
                                {
                                    extra:object,
                                    spiralStairId:spiralStairId
                                }, refetchQueries:["spiralStairDetail", "getPrice"]});
                            toggle();
                        }}/>;
                    }}
                </ModalForm>
                <ModalForm size={"lg"} buttonProps={{color:"info"}} activationButtonText={"View Price List"} title={"Price list"}>
                    {toggle=>
                    <PriceTable/>}
                </ModalForm>

            </ButtonGroup>
            <Table>
                <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Price(SEK)
                    </th>
                    <th>
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                {extra.map(extra=>
                <ExtraRow key={extra.id} id={extra.id} extra={extra}/>)}
                </tbody>
            </Table>
        </CardBody>
    </Card>

}



const PriceTable = ()=>
{
    const array = ["", "Industrial", "Child Safe", "Child Safe railing with poles", "Follower", "2 Followers ", "Perforated plate",
        "ChildSafeSection on platform, per meter", 660, 982, 1095, 715, 768, 1674,
        "ChildSafeSection on platform, radii, per meter", 880, 1150, 1420, 975, 1041, 2049,
        "ChildSafeSection on top floor with horizontal/vertical connectors, per meter", 720, 1044, 1210, 788, 845, 1755,
        "ChildSafeSection on top floor, radii, with horizontal/vertical connectors, per meter", 940, 1229, 1658, 1009, 1074, 2049,
        "Kickplate on platform, per meter", 319, 319, 456, 319, 319, 319];
    const chunks = _.chunk(array,7);
    return <div>
        <p>All Prices in SEK</p>
        <Table bordered  size="sm">
            <tbody>
            {chunks.map((chunk,index)=><tr key={index}>
                {chunk.map(val=><td key={val}>{val}</td>)}
            </tr>)}
            </tbody>
        </Table>
    </div>
};
