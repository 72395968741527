import CSS from 'csstype';
import React, {useEffect, useRef, useState} from "react";
import {Input} from "../common/FormRenderer";

export default (
        {platformType, stairType, stairRadius, platformWidth, platformHeight, formObject, onChange, isLastSection}:
        {platformType: "straightExit"|"sideExit", stairType:"left"|"right", stairRadius:number, platformWidth:number, platformHeight:number, formObject:any, onChange:any, isLastSection:boolean}) => {
    const imageContainerRef = useRef<HTMLInputElement>(null);
    const [railingKey, setRailingKey] = useState<any>([]);
    const [style, setStyle] = useState<any>({});
    useEffect(()=>{
        const containerWidth = 250
        const style = getStyle(stairType, platformType, containerWidth, platformWidth, platformHeight, stairRadius, isLastSection);
        setStyle(style);
    }, [stairType, platformType, imageContainerRef?.current?.offsetWidth, platformWidth, platformHeight, stairRadius, isLastSection]);

    useEffect(()=>{
        if(stairType==="left"){
            setRailingKey(["railing3","railing4","railing1","railing2"]);
        }else{
            setRailingKey(["railing2","railing3","railing4","railing1"]);
        }
        }, [style, stairType, platformType]);

    return <>
        {!platformType? <span>Select a platform type first.</span> :
        (platformWidth<stairRadius || platformHeight<stairRadius) ?
            <span>Width or height cannot smaller than stair radius({stairRadius}).</span> :
        <div ref={imageContainerRef} style={style?.imageContainer}>
            <div style={style?.stair} />
            <div style={style[`arrowHead_${stairType}`]} />
            <div style={style?.arrowLine} />
            <div style={style?.platform} />
            {formObject[railingKey[0]] && <div style={{...style?.railingBox, ...style?.railingLeft}}/>}
            {formObject[railingKey[1]] && <div style={{...style?.railingBox, ...style?.railingTop}}/>}
            {formObject[railingKey[2]] && <div style={{...style?.railingBox, ...style?.railingRight}}/>}
            {formObject[railingKey[3]] && <div style={{...style?.railingBox, ...style?.railingBottom}}/>}

            <div style={style?.exitSide}>exit here</div>
            <Input id="side0" style={{...style?.checkBox, ...style?.checkBoxLeft}} type={'checkbox'} checked={formObject[railingKey[0]]}
                   onChange={(e, config) => onChange(e.target.checked, config, railingKey[0])}/>
            <Input id="side1" style={{...style?.checkBox, ...style?.checkBoxTop}} type={'checkbox'} checked={formObject[railingKey[1]]}
                   onChange={(e, config) => onChange(e.target.checked, config, railingKey[1])}/>
            <Input id="side2" style={{...style?.checkBox, ...style?.checkBoxRight}} type={'checkbox'} checked={formObject[railingKey[2]]}
                   onChange={(e, config) => onChange(e.target.checked, config, railingKey[2])}/>
            <Input id="side3" style={{...style?.checkBox, ...style?.checkBoxBottom}} type={'checkbox'} checked={formObject[railingKey[3]]}
                   onChange={(e, config) => onChange(e.target.checked, config, railingKey[3])}/>
        </div>
    }
    </>
}

const getStyle = (stairType, platformType, containerWidth, platformWidth, platformHeight, stairRadius, isLastSection):Record<string, CSS.Properties> => {
    let rotate = 0, side1 ,side2, scale, containerMarginLeft, containerHeight;
    if((stairType==="right" && platformType==="sideExit") || (stairType==="left" && platformType==="straightExit")){
        rotate = 90;
    }
    if(stairType==="right"){
        side1 = platformWidth;
        side2 = platformHeight;
    }else{
        side1 = platformHeight;
        side2 = platformWidth;
    }
    switch (rotate){
        case 0:
            scale = containerWidth/(side1+stairRadius);
            containerMarginLeft = "10%";
            containerHeight = `${(side2+stairRadius)*scale}px`;
            break;
        case 90:
            scale = containerWidth/(side2+stairRadius);
            containerMarginLeft = "90%";
            containerHeight = `${(side1+stairRadius)*scale}px`;
            break;
        default: return {};
    }

    return {
        imageContainer:{
            position: "relative",
            width: "80%",
            height: containerHeight,
            margin:"10%",
            transformOrigin: "top left",
            transform: `rotate(${rotate}deg)`,
            marginLeft: containerMarginLeft,
        },
        platform:{
            position: "absolute",
            border: "1px solid #666",
            background: "#eee",
            width: `${side1*scale}px`,
            height: `${side2*scale}px`,
            top: 0,
            left: 0,
            zIndex: 10,
        },
        stair:{
            position: "absolute",
            border: "3px solid #aaa",
            borderRadius: "100%",
            background: "#ccc",
            width: `${stairRadius*2*scale}px`,
            height: `${stairRadius*2*scale}px`,
            top: `${(side2-stairRadius)*scale}px`,
            left: `${(side1-stairRadius)*scale}px`,
            zIndex: 5,
        },
        arrowHead_left:{
            position: "absolute",
            width: 0,
            height: 0,
            borderTop: "5px solid transparent",
            borderBottom: "5px solid transparent",
            borderRight: "15px solid #33f",
            top: `${(side2-stairRadius/2)*scale-3}px`,
            left: `${side1*scale-2}px`,
            zIndex: 11,
        },
        arrowHead_right:{
            position: "absolute",
            width: 0,
            height: 0,
            borderLeft: "5px solid transparent",
            borderRight: "5px solid transparent",
            borderBottom: "15px solid #33f",
            top: `${side2*scale-2}px`,
            left: `${(side1-stairRadius/2)*scale-3}px`,
            zIndex: 11,
        },
        arrowLine:{
            position: "absolute",
            border: "3px dashed #33f",
            borderRadius: "100%",
            width: `${stairRadius*scale}px`,
            height: `${stairRadius*scale}px`,
            top: `${(side2-stairRadius/2)*scale}px`,
            left: `${(side1-stairRadius/2)*scale}px`,
            zIndex: 6,
        },
        exitSide: rotate===90 ? {
            position: "absolute",
            top: `${side2*scale/2-15}px`,
            left: "-10px",
            transform: "rotate(-90deg)",
            zIndex: "10",
        }:{
            position: "absolute",
            top: "-30px",
            left: `${side1*scale/2+15}px`,
            marginTop:"32px",
            marginLeft:"-40px",
            zIndex: "10",
        },
        railingBox:{
            position: "absolute",
            width: `${side1*scale+10}px`,
            height: `${side2*scale+10}px`,
            top: "-5px",
            left: "-5px",
            zIndex: 20,
        },
        railingTop:{
            borderTop: "5px dotted #f33",
        },
        railingLeft:{
            borderLeft: "5px dotted #f33",
        },
        railingRight:{
            borderRight: "5px dotted #f33",
            height:
                stairType==="left" ? `${(side2-stairRadius)*scale+10}px` :
                !isLastSection ? `${(side2-stairRadius)*scale+10}px` : `${side2*scale+10}px`,
        },
        railingBottom:{
            borderBottom: "5px dotted #f33",
            width:
                stairType==="right" ? `${(side1-stairRadius)*scale+10}px` :
                !isLastSection ? `${(side1-stairRadius)*scale+10}px` : `${side1*scale+10}px`,
        },
        checkBoxTop:{
            position: "absolute",
            top: "-30px",
            left: `${side1*scale/2+15}px`,
        },
        checkBoxLeft:{
            position: "absolute",
            top: `${side2*scale/2-15}px`,
            left: "-10px",
        },
        checkBoxBottom:{
            position: "absolute",
            left: stairType==="right" ? `${(side1-stairRadius)*scale/2+15}px` : `${side1*scale/2+15}px`,
            top: `${side2*scale+10}px`,
        },
        checkBoxRight:{
            position: "absolute",
            left: `${side1*scale+40}px`,
            top: stairType==="left" ? `${(side2-stairRadius)*scale/2-15}px` : `${side2*scale/2-15}px`,
        },
        checkBoxBottomWithOpen:{
            position: "absolute",
            left: `${side1*scale/2+15}px`,
            top: `${side2*scale+10}px`,
        },
        checkBoxRightWithOpen:{
            position: "absolute",
            left: `${side1*scale+40}px`,
            top: `${side2*scale/2-15}px`,
        },
        checkBox:{
            transform: `rotate(-${rotate}deg) scale(2)`,
            zIndex: 30,
        }
    }
}
