import {Link} from "react-router-dom";
import React from "react";

const ColumnsCompany = () =>
{
    return {
        field: 'company.name',
        headerName: 'Company name',
        searchable: true,
        renderCell: (params) =>
        {
            return params.value
                ? <Link to={`/companyDetail/${params.row.company.id}`}>{params.value}</Link>
                : <p>{"Missing company"}</p>
        },
    }
}

export default ColumnsCompany