import React from 'react';

export default () => {
    return <svg id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} width={"25px"} height={"25px"}>
        <polygon style={{fill:"#dc9506"}} points="17.351,170.311 256.534,8.008 495.716,170.311 495.716,503.458 17.351,503.458 "/>
        <polygon style={{fill:"#E5563C"}} points="273.618,19.601 256.534,8.008 17.351,170.311 17.351,503.458 51.52,503.458 51.52,170.311"/>
        <rect x="102.774" y="213.022" style={{fill:"#74757B"}} width={"307.52"} height={"290.436"}/>
	    <rect x="102.774" y="213.022" style={{fill:"#74757B"}} width={"307.52"} height={"290.436"}/>
        <rect x="102.774" y="213.022" style={{fill:"#606268"}} width={"34.169"} height={"290.436"}/>
        <rect x="85.689" y="178.853"  style={{fill:"#D7D8D9"}} width={"341.689"} height={"34.169"}/>
        <rect x="102.774" y="298.444" style={{fill:"#FDDD85"}} width={"102.507"} height={"102.507"}/>
        <rect x="102.774" y="298.444" style={{fill:"#FDD042"}} width={"25.627"} height={"102.507"}/>
        <rect x="136.943" y="298.444" style={{fill:"#E1A527"}} width={"34.169"} height={"51.253"}/>
        <rect x="102.774" y="400.951" style={{fill:"#FDDD85"}} width={"102.507"} height={"102.507"}/>
        <rect x="102.774" y="400.951" style={{fill:"#FDD042"}} width={"25.627"} height={"102.507"}/>
        <rect x="136.943" y="400.951" style={{fill:"#E1A527"}} width={"34.169"} height={"51.253"}/>
        <rect x="205.281" y="400.951" style={{fill:"#FDDD85"}} width={"102.507"} height={"102.507"}/>
        <rect x="205.281" y="400.951" style={{fill:"#FDD042"}} width={"25.627"} height={"102.507"}/>
        <rect x="239.449" y="400.951" style={{fill:"#E1A527"}} width={"34.169"} height={"51.253"}/>
        <path d="M499.946,163.684L260.897,1.382c-2.713-1.843-6.211-1.842-8.927,0L12.554,163.684c-2.196,1.491-3.745,3.972-3.745,6.627
	v333.147c0,4.423,4.119,8.542,8.542,8.542h478.365c4.424,0,7.474-4.119,7.474-8.542V170.311
	C503.191,167.656,502.143,165.175,499.946,163.684z M93.164,205.548v-18.152h325.673v18.152H93.164z M144.417,306.987h18.152v35.237
	h-18.152V306.987z M136.943,358.24h34.169c4.423,0,7.474-4.119,7.474-8.542v-42.711h18.152v86.49h-25.627h-34.169h-26.694v-86.49
	H128.4v42.711C128.4,354.12,132.52,358.24,136.943,358.24z M144.417,409.493h18.152v35.237h-18.152V409.493z M110.248,409.493H128.4
	v42.711c0,4.423,4.119,8.542,8.542,8.542h34.169c4.423,0,7.474-4.119,7.474-8.542v-42.711h18.152v86.49h-86.49V409.493z
	 M212.755,495.983v-86.49h18.152v42.711c0,4.423,4.119,8.542,8.542,8.542h34.169c4.424,0,7.474-4.119,7.474-8.542v-42.711h18.152
	v86.49H212.755z M246.924,409.493h18.152v35.237h-18.152V409.493z M487.174,495.983h-69.406V324.071
	c0-4.423-3.585-8.008-8.008-8.008c-4.424,0-8.008,3.586-8.008,8.008v171.912h-86.49v-95.032c0-4.423-3.051-7.474-7.474-7.474
	h-95.032v-95.032c0-4.423-3.052-7.474-7.474-7.474h-95.032v-69.406h291.504v68.338c0,4.423,3.585,8.008,8.008,8.008
	c4.424,0,8.008-3.586,8.008-8.008v-68.338h9.61c4.424,0,7.474-4.119,7.474-8.542v-34.169c0-4.423-3.051-7.474-7.474-7.474H85.689
	c-4.423,0-8.542,3.052-8.542,7.474v34.169c0,4.423,4.119,8.542,8.542,8.542h8.542v274.419H24.826V174.555L256,17.687
	l231.174,156.868V495.983z"/>
        <path d="M214.357,76.88c0,4.423,3.586,8.008,8.008,8.008h68.338c4.424,0,8.008-3.586,8.008-8.008s-3.585-8.008-8.008-8.008h-68.338
	C217.942,68.872,214.357,72.457,214.357,76.88z"/>
        <path d="M290.703,103.041h-68.338c-4.423,0-8.008,3.586-8.008,8.008c0,4.423,3.586,8.008,8.008,8.008h68.338
	c4.424,0,8.008-3.586,8.008-8.008C298.711,106.626,295.127,103.041,290.703,103.041z"/>
        <path d="M290.703,137.21h-68.338c-4.423,0-8.008,3.586-8.008,8.008s3.586,8.008,8.008,8.008h68.338c4.424,0,8.008-3.586,8.008-8.008
	S295.127,137.21,290.703,137.21z"/>
        <path d="M136.943,383.867h34.169c4.423,0,8.008-3.586,8.008-8.008c0-4.423-3.586-8.008-8.008-8.008h-34.169
	c-4.423,0-8.008,3.586-8.008,8.008C128.934,380.281,132.52,383.867,136.943,383.867z"/>
        <path d="M171.112,470.357h-34.169c-4.423,0-8.008,3.586-8.008,8.008c0,4.423,3.586,8.008,8.008,8.008h34.169
	c4.423,0,8.008-3.586,8.008-8.008C179.12,473.942,175.534,470.357,171.112,470.357z"/>
        <path d="M273.618,470.357h-34.169c-4.423,0-8.008,3.586-8.008,8.008c0,4.423,3.586,8.008,8.008,8.008h34.169
	c4.424,0,8.008-3.586,8.008-8.008C281.627,473.942,278.042,470.357,273.618,470.357z"/>

</svg>
}