import React, { useCallback} from 'react';
import gql from 'graphql-tag';
import { useMutation} from '@apollo/client';
import {withRouter} from 'react-router';
import Auth from "../Auth";
import {reduce,get} from 'lodash';
import CompanyFormDefinition from "./CompanyFormDefinition";
import FormRenderer from "../common/FormRenderer";
import NotificationPopup from "../common/lib/NotificationPopup";


const createCompanyMutation = gql`
    mutation createCompany($company:CompanyInputType){
        createCompany(company:$company){
            id
        }
    }`;


export default withRouter(({history})=>
{
    const [createCompany] = useMutation(createCompanyMutation);
    const create = useCallback(async (form)=>
    {
        form.object.tenantId = Auth.getTenant();

        try
        {
            const data = (await createCompany({
                variables:{
                    company:form.object
                }
            })).data;
            history.push(`/companyDetail/${data.createCompany.id}`);
        }
        catch(e)
        {
            let msg = `Failed to create company. `;
            if(e.graphQLErrors)
            {
                msg += reduce(e.graphQLErrors,(acc,error)=>`${acc} ${error.message}`,"");
            }
            const isUniqueError = get(e,"graphQLErrors[0].extensions.exception.name") === "SequelizeUniqueConstraintError";
            if(isUniqueError)
            {
                NotificationPopup.error(`A company with this organisation number already exists..`)
            }
            else
            {
                NotificationPopup.error(msg);
            }


        }
    },[history,createCompany]);

    return <FormRenderer columns={3} formDefinition={CompanyFormDefinition()} onSubmit={create}/>

});

