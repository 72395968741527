import {Link} from "react-router-dom";
import React from "react";

const ColumnsProject = () =>
{
    return {
        field: 'project.name',
        headerName: 'Project name',
        searchable: true,
        renderCell: (params) =>
        {
            return params.value
                ? <Link to={`/projectDetail/${params.row.project.id}`}>{params.value}</Link>
                : <div>{"Missing project"}</div>
        },
    }
}

export default ColumnsProject