import AnslAngle from "./AnslAngle";
import moment from "moment";
import _ from 'lodash';
import {SpiralStairCageTypes} from "../definitions";
import {FetchResult, MutationResult, useMutation} from "@apollo/client";
import {useCallback} from "react";
import NotificationPopup from "../../common/lib/NotificationPopup";
import Logger from "../../Logger";
import {Segment} from "../../generated/graphql";

const getLastStepRotation = (radius)=>
{
    switch(radius)
    {
        case 600:return 36;
        case 700:return 30.9;
        case 800:return 27;
        case 900:return 24;
        case 1000:return 12.6;
        case 1100:return 19.5;
        case 1200:return 17.7;
        case 1300:return 16.4;
        default:return -1;
    }
}

export const segmentRotation = (segment)=>
{
    const effAngle = AnslAngle[segment.radius][segment.stepsPrRot].effectiveAngle;
    const halfStepAngle = AnslAngle[segment.radius][segment.stepsPrRot].halfStepAngle;
    const connectionAngle = AnslAngle[segment.radius][segment.stepsPrRot].angle2;
    if(segment.connectionStep)
    {
        return effAngle * (segment.stepHeights - 2) + getLastStepRotation(segment.radius);
    }
    else
    {
        return effAngle * (segment.stepHeights - 2) + halfStepAngle + connectionAngle;
    }

};

export const freeHeightStair = (segment)=>
{
    const effAngle = AnslAngle[segment.radius][segment.stepsPrRot].effectiveAngle;
    const stepHeight = segment.height / segment.stepHeights;
    return ((360 / effAngle) - 1) * stepHeight;
};

export const calcStepHeight = (segment) =>
{
    return segment.height/segment.stepHeights;
};

export const railLength = (segments,offset=21.2) =>{
    return _.sum(_.map(segments,segment =>
    {
        try
        {
            const stepHeight = calcStepHeight(segment);
            const r = segment.radius - offset;
            const effAngle = AnslAngle[segment.radius][segment.stepsPrRot].effectiveAngle;
            const b = 2 * r * Math.PI * (effAngle/360);
            const c = Math.sqrt(Math.pow(stepHeight,2) + Math.pow(b,2));
            const res = (c * (segment.stepHeights -1)) - 100;
            return Math.round(res);
        }
        catch(e)
        {
            return 0;
        }


    }));
};


export const freeHeightPlatform =  (segment)=>
{
    const platformAngle = segment.startAngle;
    const effAngle = AnslAngle[segment.radius][segment.stepsPrRot].effectiveAngle;
    const angle2 = AnslAngle[segment.radius][segment.stepsPrRot].angle2;
    const halfStepAngle = AnslAngle[segment.radius][segment.stepsPrRot].halfStepAngle;
    const stepHeight = segment.height / segment.stepHeights;
    const platformThickness = 100;
    let freeHeightPlatform;
    if((segmentRotation(segment) + platformAngle) <360)
    {
        freeHeightPlatform = segment.height - platformThickness;
    }
    else
    {
        freeHeightPlatform = (Math.floor((360 - platformAngle - halfStepAngle - angle2) / effAngle)+1) * stepHeight -
        platformThickness;
    }
    return freeHeightPlatform;
};

export const round = (val)=>
{
    return Math.round(val*100)/100;
};

export const totalHeight = (segments)=>
{
    return _.sumBy(segments,(s:Segment)=>s.height);
};


export const cageValidation = (stair,segments)=>
{
    if(stair.cage === SpiralStairCageTypes.std.id || stair.cage === SpiralStairCageTypes.gate.id)
    {

        const radius = _.get(segments,"[0].radius");
        if(radius && radius < 800)
        {
            return `Standard cage and Gate is not compatible with radii under 800.`;
        }
    }
    return null;
};

export const sortByDate = (a,b)=>
{
    const aDate = moment(a);
    const bDate = moment(b);
    if(aDate.isAfter(bDate))
    {
        return 1;
    }
    else if(aDate.isBefore(bDate))
    {
        return -1;
    }
    else
    {
        return 0;
    }
};

export function useStdMutation(action, query, options):[(options: any) => Promise<FetchResult>,MutationResult<any>]
{
    const [mutation, obj] = useMutation(query, options);
    const wrappedMutation:any = useCallback(async (options) =>
    {
        try
        {
            const res = await mutation(options);
            NotificationPopup.success(`Action ${action} was successful.`);
            return res;
        } catch (e:any)
        {
            const errMsg = `Action ${action} failed. ${e.message}`;
            NotificationPopup.error(errMsg);
            Logger.error(errMsg, {error: JSON.stringify(e)});
            return Promise.reject(e);
        }

    }
    , [mutation, action]);

    return [wrappedMutation, obj];
}
