import {
    QuoteFragmentFragment,
    useSplitQuoteMutation
} from "../generated/graphql";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import React, {useCallback, useMemo, useState} from "react";
import NonPaginatedDataGrid from "../common/data-grid/NonPaginatedDataGrid";
import {GridRowSelectionModel} from "@mui/x-data-grid";
import CellArticleRow from "../common/data-grid/cell/CellArticleRow";
import ColumnId from "../common/data-grid/columns/ColumnId";
import {useMutationHandler} from "../custom-hook/useMutationHandler";
import NotificationPopup from "../common/lib/NotificationPopup";
import {withRouter} from "react-router";

const TABLE_KEY_ARTICLE_ROW = "TABLE_SPLIT_QUOTE_ARTICLE_ROW"
const TABLE_KEY_FILE = "TABLE_SPLIT_QUOTE_ARTICLE_FILE"

type Props = {
    history: any
    isOpen: boolean,
    toggle: () => void,
    quote: QuoteFragmentFragment,
}

const SplitQuoteModal = ({isOpen, toggle, quote, history}: Props) =>
{
    const [selectedArticleRowIds, setSelectedArticleRowIds] = useState<GridRowSelectionModel>([])
    const [selectedFileIds, setSelectedFileIds] = useState<GridRowSelectionModel>([])

    const {executeMutation: splitQuoteMutation, loading} = useMutationHandler(useSplitQuoteMutation)

    const onArticleRowCheckChange = useCallback((newRowSelectionModel) =>
    {
        setSelectedArticleRowIds(newRowSelectionModel)
    }, [])

    const onFileCheckChange = useCallback((newRowSelectionModel) =>
    {
        setSelectedFileIds(newRowSelectionModel)
    }, [])

    const articleRowColumns = useMemo(() =>
    {
        return [
            {
                headerName: 'Art no',
                field: 'article',
                sortable: false,
                width: 200,
                renderCell: (params) =>
                {
                    return <CellArticleRow articleRowId={params.id} article={params.value}
                                           stockArticle={params.row.stockArticle}/>
                }
            },
            {
                headerName: 'Name',
                field: 'name',
                sortable: false,
                width: 200,
            },
            {
                field: "quantity",
                headerName: "Quantity",
                type: 'number',
                sortable: false,
                width: 70,
            },
            ColumnId()
        ]
    }, [])

    const fileColumns = useMemo(() =>
    {
        return [
            {
                field: 'filename',
                headerName: 'Filename',
                width: 200,
            },
            {
                field: 'fileCategory.name',
                headerName: 'File Category',
            },
            {
                field: 'creator.displayName',
                headerName: 'Creator',
            },
            ColumnId()
        ]
    }, [])

    const onSplitSuccess = useCallback((id: string) =>
    {
        toggle()
        NotificationPopup.success(`Split quote success`)
        history.push(`/quoteDetail/${id}`)
    }, [toggle, history])

    const onSplitClick = useCallback(async () =>
    {
        if (!quote.id)
        {
            return
        }
        await splitQuoteMutation({
                variables: {
                    quoteId: quote.id,
                    articleRowIds: selectedArticleRowIds.map((e) => `${e}`),
                    fileIds: selectedFileIds.map((e) => `${e}`)
                }
            },
            {
                onSuccess: (response) => onSplitSuccess(response?.splitQuote ?? "")
            })

    }, [splitQuoteMutation, quote, selectedFileIds, selectedArticleRowIds, onSplitSuccess])

    return <Modal isOpen={isOpen} toggle={toggle} size={"lg"}>
        <ModalHeader toggle={toggle}>Split Quote</ModalHeader>
        <ModalBody>
            <h4>Article Rows</h4>
            <NonPaginatedDataGrid
                checkboxSelection
                disableRowSelectionOnClick
                definition={{
                    tableKey: TABLE_KEY_ARTICLE_ROW,
                    disableTooBar: true,
                    pageSize: 100,
                    columns: articleRowColumns,
                }}
                rowSelectionModel={selectedArticleRowIds}
                onRowSelectionModelChange={onArticleRowCheckChange}
                data={quote.articleRows}/>

            {
                (quote.files && quote.files.length > 0) && <>
                    <h4 style={{marginTop: "20px"}}>Files</h4>
                    <NonPaginatedDataGrid
                        checkboxSelection
                        disableRowSelectionOnClick
                        definition={{
                            tableKey: TABLE_KEY_FILE,
                            disableTooBar: true,
                            pageSize: 100,
                            columns: fileColumns,
                        }}
                        rowSelectionModel={selectedFileIds}
                        onRowSelectionModelChange={onFileCheckChange}
                        data={quote.files}/>
                </>
            }
        </ModalBody>
        <ModalFooter>
            {
                <Button color={"success"} onClick={onSplitClick} disabled={loading}>
                    Split
                    {loading ?? <Spinner/>}
                </Button>
            }
        </ModalFooter>
    </Modal>
}

export default withRouter(SplitQuoteModal)