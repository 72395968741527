import React from 'react';
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import gql from "graphql-tag";
import FormRenderer from "../../common/FormRenderer";
import {useStdMutation} from "../../spiralStair/lib/Util";

export default ({eanCode, toggle}) =>
{
    const [update] = useStdMutation("Update EAN Code",
        gql`mutation updateEANCode($id:Int!,$eanCode:EANCodeInputType!)
        {
            updateEANCode(id:$id,eanCode: $eanCode){
                id
            }
        }`, {refetchQueries: ["eanCodesByArticleId"]});

    if (eanCode)
    {
        return <Modal isOpen={true} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                Update EAN Code
            </ModalHeader>
            <ModalBody>
                <FormRenderer
                    columns={null}
                    customSubmit={null}
                    object={eanCode}
                    onSubmit={async ({object}) =>
                    {
                        toggle();
                        await update({variables: {id: eanCode.id, eanCode: object}});
                    }}
                    formDefinition={[
                        {name: "code", type: "text", required: true}
                    ]}
                />
            </ModalBody>
        </Modal>
    } else
    {
        return null;
    }
}
