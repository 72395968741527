import React, {useCallback, useMemo, useState} from "react";
import Auth from "../Auth";
import {useCreateDeviationReportMutation} from "../generated/graphql";
import {Button, Spinner} from "reactstrap";
import config from "../config";
import {each} from "lodash";
import NotificationPopup from "../common/lib/NotificationPopup";
import NewFormRenderer from "../common/NewFormRenderer";
import ReactDropZone from "react-dropzone";

const DeviationReportCategories = [
    {id: "incidentNoInjury", name: "Incident / No personal injury"},
    {id: "injuryNoAbsence", name: "Personal injury without absence"},
    {id: "injuryWithAbsence", name: "Personal injury with absence"},
    {id: "fireIncident", name: "Fire / Fire incident"},
    {id: "internalDeviation", name: "Internal deviation"},
    {id: "externalDeviation", name: "External deviation"},
]

const UploadDirectusFile = ({onChange}) =>
{
    const [loading, setLoading] = useState(false)
    const [fileNames, setFileNames] = useState<string[]>([])
    const [uuids] = useState<string[]>([])

    const addFileNames = useCallback((files) =>
    {
        setFileNames(prevState =>
        {
            each(files, (file) =>
            {
                prevState.push(file.name);
            })
            return prevState
        })
    }, [setFileNames])

    const handleFileChange = useCallback(async (files) =>
    {
        setLoading(true)
        const formData = new FormData();

        each(files, (file) =>
        {
            formData.append('files', file);
        })

        try
        {
            const response = await fetch(`${config.BACKEND_URI}upload/directus`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        Authorization: `bearer ${Auth.getToken()}`,
                    },
                }
            );
            if (response.status !== 200)
            {
                NotificationPopup.error(`upload file error. ${response.statusText}`)
                setLoading(false)

                return
            }
            const json = await response.json();

            addFileNames(files)
            uuids.push(...json.uuids as any)
            onChange(uuids)
        } catch (error: any)
        {
            NotificationPopup.error(`upload file error. ${error?.message}`)
        }
        setLoading(false)
    }, [setLoading, onChange, addFileNames, uuids]);

    return <>
        {
            fileNames.map((e) => <p>{e}</p>)
        }
        <ReactDropZone
            style={{
                width: '100%', height: '100px', border: '1px dashed black',
                display: 'flex', alignItems: 'center', justifyContent: 'center'
            }}
            onDrop={handleFileChange}>
            <h5>Drop your files here or click me.</h5>
        </ReactDropZone>
        {loading && <Spinner/>}
    </>
}

export default () =>
{
    const [createDeviationReport] = useCreateDeviationReportMutation()
    const [formData, setFormData] = useState<any>({sendBy: Auth.getUsername()})

    const formDefinition = useMemo(() =>
    {
        return [
            {name: "sendBy", label: "Sent by", required: true},
            {name: "category", type: "select", multi: true, options: DeviationReportCategories, required: true},
            {name: "date", type: "date", required: true, disableFuture: true},
            {name: "subject", required: true},
            {name: "referenceInfo", label: "Order number / invoice number / article ID", required: true},
            {name: "description", label: "What has happened?", type: "textarea", required: true},
            {name: "proposedAction", label: "Proposed action", type: "textarea", required: true},
            {name: "otherInformation", label: "Other information", type: "textarea"},
            {name: "blank", type: "hidden", hideField: () => true},
            {
                name: "files", label: "Upload files", type: "custom", input: (_, onChange) =>
                {
                    return <UploadDirectusFile onChange={onChange}/>
                }
            },
        ]
    }, [])

    const onSubmit = useCallback(async () =>
    {
        if (window.confirm(`Are you sure your want to send this report?`))
        {
            try
            {
                await createDeviationReport({
                    variables: {
                        deviationReport: {
                            ...formData,
                            category: formData?.category?.map(e => e.id)
                        }
                    }
                })
                NotificationPopup.success(`Send report success.`)
                setFormData(prevState => ({sendBy: prevState.sendBy}))
            } catch (e: any)
            {
                NotificationPopup.error(`${e?.message}`)
            }
        }
    }, [createDeviationReport, setFormData, formData])

    const handleOnChange = useCallback((key, value) =>
    {
        setFormData(prevState => ({...prevState, [key]: value}))
    }, [setFormData])

    const renderSubmitButton = () =>
    {
        const disabled = formDefinition.filter(e => e.required)
            .some((e) => formData[e.name] == null)
        return <Button disabled={disabled} onClick={onSubmit} color={"success"}>Submit</Button>
    }

    return <>
        <NewFormRenderer
            formDefinition={formDefinition}
            object={formData}
            columns={3}
            onChange={handleOnChange}
            customSubmit={renderSubmitButton()}
        />
    </>
}