import React from 'react';

export default ({size}:{size?:number})=>
{
    if(!size)
    {
        size = 20;
    }
    return <svg x="0px" y="0px" width={size} height={size} fill="currentcolor" viewBox="0 0 1000 1000" enableBackground="new 0 0 1000 1000">
        <g>
            <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
                <path
                d="M4283.8,1572.2L848.6-1868.2L469.2-3321.3C260.1-4121.4,95-4782.1,100.1-4789.9c7.7-5.2,668.5,160,1468.6,369.1l1453.1,379.4L6462.2-606.1L9900,2829.1v296.8v296.8l-794.9,794.9L8312.7,5010h-296.8h-296.8L4283.8,1572.2z M8795.4,3907.9l665.9-665.9v-116.1c0-116.1-2.6-118.7-405.2-521.4l-407.8-407.8l-779.5,779.4l-782,782l405.2,405.2c405.2,405.2,407.8,407.8,523.9,407.8H8132L8795.4,3907.9z M7094.5,3131.1l302-302L4743.3,175.9L2092.6-2474.8L1782.9-2165l-309.7,309.7L4118.7,790.1c1455.7,1455.7,2650.7,2645.5,2658.4,2645.5S6926.7,3298.8,7094.5,3131.1z M5687.9-763.6L3034.7-3416.8l-309.7,309.7l-309.7,309.7L5065.9-146.7l2653.2,2653.2l309.7-309.7l309.7-309.7L5687.9-763.6z M2665.6-3677.5c-31-23.2-1933.1-503.3-1946-493c-7.7,10.3,98.1,454.3,240,985.9l252.9,967.9l730.4-727.8C2343-3344.5,2668.2-3674.9,2665.6-3677.5z"/>
            </g>
        </g>
    </svg>
}
